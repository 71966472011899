import { Button, Dialog, Fade, Grid, Typography } from "@material-ui/core";
import { Form, Formik } from "formik";
import React from "react";
import { TextFieldWrapper } from "../../components/Textfields";
import Store from "../../redux/Store";
import { set_message } from "../../redux/ActionCreators";
import { add_promocode, update_promocode } from "../../api/PromoCode_Api";
const AddNewPromo = ({
  open,
  setOpen,
  setData,
  editMode,
  currentItem,
}) => {
  return (
    <Formik
      validateOnMount
      enableReinitialize
      initialValues={
        editMode === false
          ? { title: "", code: "" }
          : {
            title: currentItem.title, code: currentItem.code,

          }
      }
      onSubmit={(values, props) => {
        if (values.title === '' || values.code === '') { }
        else {
          if (editMode === false) {


            add_promocode(values).then((res) => {
              if (!res) return;
              setData(prev => [...prev, res.data]);
              props.resetForm();

              Store.dispatch(
                set_message({
                  mode: true,
                  message: "Add new Promo",
                  color: "success",
                })
              );
              setOpen(false);
            });
          }
          else {
            //update promotion

            // update_promocode(currentItem._id, values).then((res) => {
            //   if (!res) return;
            //   setData(prev =>
            //     prev.map(c => {
            //       if (c._id === currentItem._id)
            //         return res.data;
            //       else
            //         return c;
            //     })
            //   );
            //   props.resetForm();

            //   Store.dispatch(
            //     set_message({
            //       mode: true,
            //       message: "Update Promo code",
            //       color: "success",
            //     })
            //   );
            //   setOpen(false);
            // });
          }

        }
      }}
    >
      {(formik) => {
        const check = () => {
          if (
            formik.values.title === "" ||
            formik.values.code === ""

          ) {
            return false;
          }
          return true;
        };

        return (
          <Form>
            <Dialog
              maxWidth="sm"
              fullWidth={true}
              open={open}
              onClose={() => { setOpen(false); }}
              TransitionComponent={Transition}
              onClick={(e) => e.stopPropagation()}
            >
              <Grid
                xs={12}
                container
                item
                spacing={3}
                style={{ padding: '20px' }}
                justifyContent="center"
              >
                <Grid item xs={12} container alignItems="flex-end">
                  <Typography variant="h4">
                    {editMode === false
                      ? `Promo Codes`
                      : `Edit Promo Codes`}

                  </Typography>

                </Grid>
                <Grid
                  item
                  xs={12}
                  container
                  spacing={2}
                  alignItems="center"
                  style={{ margin: "auto" }}
                >
                  <Grid item xs={12}>
                    <Typography variant="body1" style={{ marginBottom: 8 }}>
                      Promo name
                    </Typography>
                    <TextFieldWrapper
                      name="title"
                      white={true}
                    // placeholder="e.g. 3 Months"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body1" style={{ marginBottom: 8 }}>
                      Promo code
                    </Typography>
                    <TextFieldWrapper
                      name="code"

                      white={true}
                    // placeholder="e.g. 12jjhkl123"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Typography variant="body1" style={{ marginBottom: 8 }}>
                      Promo Description
                    </Typography>
                    <TextFieldWrapper
                      name="description"

                      white={true}
                    // placeholder="e.g. 3 Months"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Typography variant="body1" style={{ marginBottom: 8 }}>
                      Month
                    </Typography>
                    <TextFieldWrapper
                      name="month"

                      white={true}
                    // placeholder="e.g. 3"
                    />
                  </Grid>


                </Grid>
                <Grid item xs={12} container justifyContent="flex-end">
                  <Button
                    variant="outlined"
                    color="default"
                    style={{ borderRadius: 8 }}
                    onClick={() => {
                      formik.setValues(formik.initialValues);
                      setOpen(false);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant='contained'
                    disabled={!check()}
                    style={{
                      borderRadius: 8,
                      background: check() ? '#65819D' : '#D2D2D2',
                      color: '#FFFFFF',
                      marginLeft: '8px'
                    }}
                    onClick={() => {
                      formik.submitForm();
                    }}

                  >Submit</Button>

                </Grid>
              </Grid>
            </Dialog>
          </Form>
        );
      }}
    </Formik >
  );
};

export default AddNewPromo;
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Fade timeout={350} ref={ref} {...props} />;
});
