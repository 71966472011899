import { useState, useEffect } from 'react'
import {
    FormLabel,
    Grid,
    FormControlLabel,
    TextareaAutosize,
    Typography,
} from '@material-ui/core'
import { Stack, TextField } from '@mui/material'
import { styles } from '../../../styles/styles'
import { Controller } from 'react-hook-form'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import AddAndRemoveQuestion from './addAndRemoveQuestion'

const AddQuizComponent = ({ register, control, setRows, rows }) => {
    const style = styles()

    return (
        <Grid spacing={2} className={style.box}>
            <Grid container spacing={2}>
                <Grid container item xs={6}>
                    <Grid item xs={12}>
                        <Stack direction="column">
                            <FormLabel htmlFor="quiz-title">Title:</FormLabel>
                            <TextField
                                {...register('title')}
                                placeholder="Title"
                                id="quiz-title"
                                fullWidth
                                margin="normal"
                                size="small"
                            />
                        </Stack>
                    </Grid>
                </Grid>

                <Grid item container xs={6}>
                    <Grid item xs={12}>
                        <Stack direction="column">
                            <FormLabel htmlFor="quiz-status" sx={{ pt: 4 }}>
                                Status:
                            </FormLabel>
                            <FormControlLabel
                                style={{
                                    paddingTop: '16px',
                                    paddingLeft: '12px',
                                }}
                                control={
                                    <Controller
                                        {...register('status')}
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                labelId="status"
                                                id="demo-simple-select-status"
                                                fullWidth
                                                margin="normal"
                                                size="small"
                                                value={
                                                    field.value
                                                        ? field.value
                                                        : ''
                                                }
                                                onChange={(event) =>
                                                    field.onChange(
                                                        event.target.value
                                                    )
                                                }
                                            >
                                                <MenuItem value={'0'}>
                                                    Select Quiz Status
                                                </MenuItem>
                                                {['Active', 'In-active']?.map(
                                                    (option) => {
                                                        return (
                                                            <MenuItem
                                                                value={option}
                                                            >
                                                                {option}
                                                            </MenuItem>
                                                        )
                                                    }
                                                )}
                                            </Select>
                                        )}
                                    />
                                }
                            />
                        </Stack>
                    </Grid>
                </Grid>

                <Grid container item xs={12}>
                    <Grid item xs={12}>
                        <Stack direction="column">
                            <FormLabel htmlFor="quiz-instructions">
                                Instructions:
                            </FormLabel>

                            <TextareaAutosize
                                placeholder="Instructions"
                                {...register('instructions')}
                                style={{
                                    marginTop: '18px',
                                    marginRight: '10px',
                                }}
                                minRows={5}
                                size="lg"
                                variant="solid"
                            />
                        </Stack>
                    </Grid>
                </Grid>
                <Grid container item xs={12}>
                    <Grid item xs={12}>
                        <Stack direction="column">
                            <FormLabel htmlFor="quiz-prompt">Prompt:</FormLabel>
                            <TextareaAutosize
                                placeholder="Prompt"
                                {...register('prompt')}
                                style={{
                                    marginTop: '18px',
                                    marginRight: '10px',
                                }}
                                minRows={5}
                                size="lg"
                                variant="solid"
                            />
                        </Stack>
                        <span style={{ fontSize: '22px' }}>Note: </span>
                        <Typography
                            variant="body2"
                            style={{
                                fontSize: 18,
                                fontWeight: 400,
                                display: 'inline',
                            }}
                            color="textSecondary"
                        >
                            Do not modify{' '}
                            <span
                                style={{
                                    fontWeight: 600,
                                }}
                            >
                                {'{{quiz_title}}'}
                            </span>{' '}
                            {' and '}{' '}
                            <span
                                style={{
                                    fontWeight: 600,
                                }}
                            >
                                {'{{question_answer}}'}
                            </span>
                            . These two values will be replaced with original
                            quiz title and user questionnaire.
                        </Typography>
                    </Grid>
                </Grid>
                <AddAndRemoveQuestion rows={rows} setRows={setRows} />
            </Grid>
        </Grid>
    )
}

export default AddQuizComponent
