import React from 'react'

export const BlockedIcon = () => {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="28" height="28" rx="6" fill="#BA5A6B" />
      <g clipPath="url(#clip0_2560_7800)">
        <path d="M14 5C9.0374 5 5 9.0374 5 14C5 18.9626 9.0374 23 14 23C18.9626 23 23 18.9626 23 14C23 9.0374 18.9626 5 14 5ZM6.8 14C6.8 12.3386 7.3706 10.8122 8.3192 9.5927L18.4073 19.6808C17.1491 20.6653 15.5975 21.2001 14 21.2C10.0301 21.2 6.8 17.9699 6.8 14ZM19.6808 18.4073L9.5927 8.3192C10.8509 7.33487 12.4025 6.80005 14 6.8C17.9699 6.8 21.2 10.0301 21.2 14C21.1995 15.5975 20.6648 17.1489 19.6808 18.4073Z" fill="white" />
      </g>
      <defs>
        <clipPath id="clip0_2560_7800">
          <rect width="18" height="18" fill="white" transform="translate(5 5)" />
        </clipPath>
      </defs>
    </svg>

  )

}