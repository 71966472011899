import moment from "moment";
import { page_change, set_message } from "../redux/ActionCreators";
import Store from "../redux/Store";

import fileDownload from "js-file-download";
import { api_instance } from "./Base_Api";

export const get_memberships = async (type, isPaging) => {
  const {
    search,
    pagination: { page },
    sort,
  } = Store.getState().GeneralReducer;
  try {
    const { data } = await api_instance.get("/admin/package", {
      params: {

        search: search,
        page: page,
        limit: 100,
        sort: sort.name,
        order: sort.mode,
      },
    });
    Store.dispatch(page_change(data.data.pagination));

    return data;
  } catch (error) { }
};

export const update_membership = async (id, values) => {
  try {
    const { data } = await api_instance.put(`/admin/package/${id}`, values);

    Store.dispatch(
      set_message({
        mode: true,
        message: data.data.message,
        color: "success",
      })
    );

    return data;
  } catch (error) { }
};
export const delete_membership = async (id) => {
  try {
    const { data } = await api_instance.delete(`/admin/package/${id}`);

    Store.dispatch(
      set_message({
        mode: true,
        message: data.data.message,
        color: "success",
      })
    );

    return data;
  } catch (error) { }
};

export const add_memberships = async (values) => {
  try {
    const { data } = await api_instance.post(
      `/admin/package`,
      values
    );
    return data;
  } catch (error) { }
};
