import { Button, Divider, Menu, MenuItem, Typography } from '@material-ui/core'
import React from 'react'
import more from '../../../../asset/img/More horizontal.png'
import ViewIcon from '../../../../asset/img/matchMakeMeIcon.png'
import EditIcon from '../../../../asset/img/editAdmin.png'
const RowActionButton = ({ set_Data, value }) => {
    const [anchorEl, setAnchorEl] = React.useState(null)

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    return (
        <>
            <Button
                onClick={handleClick}
                variant="outlined"
                startIcon={<img src={more} alt="" />}
            >
                Action
            </Button>
            <Menu
                anchorEl={anchorEl}
                onClose={handleClose}
                elevation={0}
                open={Boolean(anchorEl)}
                PaperProps={{
                    style: {
                        boxShadow: '0px 2px 12px rgba(205, 205, 205, 0.7)',
                        border: '1px solid rgba(0, 0, 0, 0.08)',
                        borderRadius: 10,
                        padding: 16,
                        opacity: 1,
                    },
                }}
                style={{
                    position: 'absolute',
                    top: 40,
                    left: -50,
                    opacity: 1,
                }}
            >
                <Typography style={{ color: 'black' }} variant="body1">
                    Actions
                </Typography>
                <Divider />

                <MenuItem style={{ paddingLeft: 0, opacity: 1 }} button={false}>
                    <Button
                        title="Edit Quiz"
                        variant="text"
                        onClick={() => {
                            window.open(`/quiz/edit/${value.row._id}`, '_blank')
                        }}
                        style={{ paddingLeft: 0 }}
                        // startIcon={<PackageEditIcon />}
                    >
                        <img
                            src={EditIcon}
                            alt="edit icon"
                            width={'15px'}
                            style={{ marginRight: '10px' }}
                        />
                        Edit Quiz
                    </Button>
                </MenuItem>
                <MenuItem
                    style={{
                        paddingLeft: 0,
                        paddingTop: '6px',
                        paddingBottom: 0,
                        opacity: 1,
                    }}
                    button={false}
                >
                    <Button
                        variant="text"
                        onClick={() => {
                            window.open(
                                `/quiz-attempted-users/${value.row._id}`,
                                '_blank'
                            )
                        }}
                        style={{
                            paddingLeft: 0,
                            paddingTop: 0,
                            opacity: 1,
                        }}
                    >
                        <img
                            src={ViewIcon}
                            alt="eye icon"
                            width={'20px'}
                            style={{ marginRight: '5px' }}
                        />{' '}
                        View Attempts
                    </Button>
                </MenuItem>
            </Menu>
        </>
    )
}

export default RowActionButton
