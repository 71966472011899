import React from 'react'

export const FakeAccountIcon = () => {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="28" height="28" rx="6" fill="#0E0F1A"/>
<path d="M16.25 15.5C18.2525 15.5 22.25 16.4975 22.25 18.5V20H10.25V18.5C10.25 16.4975 14.2475 15.5 16.25 15.5ZM16.25 14C15.4543 14 14.6913 13.6839 14.1287 13.1213C13.566 12.5587 13.25 11.7956 13.25 11C13.25 10.2044 13.566 9.44129 14.1287 8.87868C14.6913 8.31607 15.4543 8 16.25 8C17.0456 8 17.8087 8.31607 18.3713 8.87868C18.9339 9.44129 19.25 10.2044 19.25 11C19.25 11.7956 18.9339 12.5587 18.3713 13.1213C17.8087 13.6839 17.0456 14 16.25 14ZM8.74997 12.1925L10.34 10.595L11.405 11.66L9.80747 13.25L11.405 14.84L10.34 15.905L8.74997 14.3075L7.15997 15.905L6.09497 14.84L7.69247 13.25L6.09497 11.66L7.15997 10.595L8.74997 12.1925Z" fill="white"/>
</svg>

  )
}
