import React from "react";

export const DeletePackageIcon = () => {
  return (
    <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M15 6C15.5523 6 16 6.44771 16 7V17C16 18.6569 14.6569 20 13 20H5C3.34315 20 2 18.6569 2 17V7C2 6.44771 2.44772 6 3 6C3.55228 6 4 6.44771 4 7V17C4 17.5523 4.44771 18 5 18H13C13.5523 18 14 17.5523 14 17V7C14 6.44771 14.4477 6 15 6ZM7 8C7.55229 8 8 8.44772 8 9V15C8 15.5523 7.55229 16 7 16C6.44771 16 6 15.5523 6 15V9C6 8.44772 6.44771 8 7 8ZM11 8C11.5128 8 11.9355 8.38604 11.9933 8.88338L12 9V15C12 15.5523 11.5523 16 11 16C10.4872 16 10.0645 15.614 10.0067 15.1166L10 15V9C10 8.44772 10.4477 8 11 8ZM11 0C11.5523 0 12 0.447715 12 1V2H17C17.5523 2 18 2.44772 18 3C18 3.55228 17.5523 4 17 4H1C0.447716 4 0 3.55228 0 3C0 2.44772 0.447715 2 1 2H6V1C6 0.447715 6.44771 0 7 0H11Z" fill="#65819D" />
    </svg>


  );
};
