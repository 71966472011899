import { page_change, set_message } from "../redux/ActionCreators";
import Store from "../redux/Store";

import { api_instance } from "./Base_Api";

export const get_promoCodes = async () => {

  try {
    const { data } = await api_instance.get("/admin/promotion", {
      params: {

        search: '',
        page: 1,
        limit: 100,
        sort: '',
        order: '',
      },
    });


    return data;
  } catch (error) { }
};

export const update_promocode = async (id, values) => {
  try {
    const { data } = await api_instance.put(`/admin/promotion/${id}`, values);

    Store.dispatch(
      set_message({
        mode: true,
        message: data.data.message,
        color: "success",
      })
    );

    return data;
  } catch (error) { }
};
export const approvedPromotion = async (id) => {
  try {
    const { data } = await api_instance.put(`/admin/promotion/approvedPromotion/${id}`);
    return data;
  }
  catch (error) { }
}
export const delete_promocode = async (id) => {
  try {
    const { data } = await api_instance.delete(`/admin/promotion/${id}`);
    return data;
  } catch (error) { }
};

export const add_promocode = async (values) => {
  try {
    const { data } = await api_instance.post(
      `/admin/promotion`,
      values
    );
    return data;
  } catch (error) { }
};
