import React, { useState } from 'react'
import deleteImage from '../../asset/img/blog-close.svg'

const ActionImage = ({ setUserImages, setBlobImageLocalURL }) => {
    const handleClose = () => {
        setUserImages(null)
        setBlobImageLocalURL(null)
    }

    return (
        <div style={{ position: 'absolute', left: '56%', top: '-4%' }}>
            <img
                style={{ cursor: 'pointer' }}
                onClick={handleClose}
                src={deleteImage}
                width={30}
                height={30}
                alt=""
            />
        </div>
    )
}

export default ActionImage
