import React from 'react'

export const PromoCodeMoveBackIcon = () => {
  return (
    <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.0249 13.025L11.4249 11.625L9.79988 10H13.9999V8H9.79988L11.4249 6.375L10.0249 4.975L5.99988 9L10.0249 13.025ZM-0.00012207 16V0H7.99988L9.99988 2H19.9999V16H-0.00012207ZM1.99988 14H17.9999V4H9.17488L7.17488 2H1.99988V14ZM1.99988 14V2V4V14Z" fill="#65819D" />
    </svg>






  )

}