import {
    Button,
    Divider,
    Grid,
    Dialog,
    Typography,
    Fade,
    IconButton,
    TextareaAutosize,
} from '@material-ui/core'
import { DataGrid, GridOverlay } from '@material-ui/data-grid'
import { format } from 'date-fns'
import React, { useState, forwardRef } from 'react'
import { styles } from '../../../../styles/styles'
import { Avatar, CircularProgress, Stack, TextField } from '@mui/material'
import { useParams } from 'react-router-dom'
import { get_attempt_quiz } from '../../../../../src/api/Quiz_Module_Api'
import { Close } from '@material-ui/icons'
// import { PackageEditIcon } from '../../../../icons/PackageEditIcon'
import MatchMakeMeIcon from '../../../../asset/img/matchMakeMeIcon.png'
import Pagination from '../QuizList/Pagination'
import sorter from '../../../../asset/img/sorter.png'
import moment from 'moment'
import RowActionButton from './RowActionButton'
import { get_all_dropdowns } from '../../../../api/UserRequest_Api'
const QuizList = () => {
    const params = useParams()
    const [initialLoading, setInitialLoading] = useState(true)
    const style = styles()
    const [data, set_Data] = useState([])
    const [quizTitle, setQuizTitle] = useState(null)
    const [dialogueOpen, setDialogueOpen] = useState(false)
    const [loadingData, setLoadingData] = useState(false)
    const [paginateObj, setPaginateObj] = useState({ page: 1, totalPages: 1 })
    const [searchText, setSearchtext] = useState('')
    const [searchQuery, setSearchQuery] = useState('')
    const [sortOrder, setSortOrder] = useState({
        order: -1,
        column: 'createdAt',
    })
    const [deleteMuatation, setDeleteMutation] = useState({})
    const [industryDropdownOptions, setIndustryDropdownOptions] = useState([])
    const [educationDropdownOptions, setEducationDropdownOptions] = useState([])

    const [salaryDropdownOptions, setSalaryDropdownOptions] = useState([])

    const [lookingForDropdownOptions, setLookingForDropdownOptions] = useState(
        []
    )
    const [relocateForLoveDropdownOptions, setRelocateForLoveDropdownOptions] =
        useState([])
    const [personalityDropdownValues, setpersonalityDropdownValues] = useState(
        []
    )
    const [smokingDropdownOptions, setSmokingDropdownOptions] = useState([])
    const [zodiacDropdownOptions, setZodiacDropdownOptions] = useState([])
    const [drinkingDropdownOptions, setDrinkingDropdownOptions] = useState([])
    const [sleepingHabbitsDropdownOptions, setSleepingHabbitsDropdownOptions] =
        useState([])

    React.useEffect(() => {
        get_all_dropdowns()
            .then((res) => {
                const salaries = res?.find((d) => {
                    if (d.dropdownTitle === 'salary') {
                        return true
                    }
                })
                setSalaryDropdownOptions(
                    salaries?.optionList?.map((item) => item?.name)
                )
                const industry = res?.find((d) => {
                    if (d.dropdownTitle === 'industry') {
                        return true
                    }
                })
                setIndustryDropdownOptions(
                    industry?.optionList?.map((item) => item?.name)
                )
                const education = res?.find((d) => {
                    if (d.dropdownTitle === 'education') {
                        return true
                    }
                })
                setEducationDropdownOptions(
                    education?.optionList?.map((item) => item?.name)
                )

                const smoke = res?.find((d) => {
                    if (d.dropdownTitle === 'smoking') {
                        return true
                    }
                })
                setSmokingDropdownOptions(
                    smoke?.optionList?.map((item) => item?.name)
                )
                const drink = res?.find((d) => {
                    if (d.dropdownTitle === 'drinking') {
                        return true
                    }
                })
                setDrinkingDropdownOptions(
                    drink?.optionList?.map((item) => item?.name)
                )
                const personalityType = res?.find((d) => {
                    if (d.dropdownTitle === 'personalityType') {
                        return true
                    }
                })
                setpersonalityDropdownValues(
                    personalityType?.optionList?.map((item) => item?.name)
                )
                const zodiac = res?.find((d) => {
                    if (d.dropdownTitle === 'zodiac') {
                        return true
                    }
                })
                setZodiacDropdownOptions(
                    zodiac?.optionList?.map((item) => item?.name)
                )
                const relocateForLove = res?.find((d) => {
                    if (d.dropdownTitle === 'relocateForLove') {
                        return true
                    }
                })
                console.log('relocated', relocateForLove?.optionList)
                setRelocateForLoveDropdownOptions(
                    relocateForLove?.optionList?.map((item) => item?.name)
                )

                const sleepingHabbits = res?.find((d) => {
                    if (d.dropdownTitle === 'sleepingHabits') {
                        return true
                    }
                })

                setSleepingHabbitsDropdownOptions(
                    sleepingHabbits?.optionList?.map((item) => item?.name)
                )
                const looking = res?.find((d) => {
                    if (d.dropdownTitle === 'lookingFor') {
                        return true
                    }
                })
                setLookingForDropdownOptions(
                    looking?.optionList?.map((item) => item?.name)
                )
            })
            .catch((e) => console.log(e.message))
    }, [])
    // Debounce
    React.useEffect(() => {
        if (initialLoading === false) {
            setLoadingData(true)
            set_Data([])
        }
        get_attempt_quiz(
            params?.id,
            searchText,
            paginateObj.page,
            sortOrder.column,
            sortOrder.order
        )
            .then((res) => {
                console.log('err_res', res?.data)
                set_Data(res?.data?.result ?? [])
                setQuizTitle(res?.data?.quizTitle ?? null)
                setPaginateObj({
                    ...paginateObj,
                    totalPages: res?.data?.totalPages,
                })
                setInitialLoading(false)
                setLoadingData(false)
            })
            .catch((err) => {
                console.log('err', err)
                setInitialLoading(false)
                setLoadingData(false)
            })
    }, [deleteMuatation, searchText, paginateObj.page, sortOrder])

    React.useEffect(() => {
        const timeOutId = setTimeout(() => setSearchtext(searchQuery), 500)
        return () => {
            clearTimeout(timeOutId)
        }
    }, [searchQuery])
    const header_list = [
        {
            field: 'fullName',
            headerName: 'User',
            minWidth: 270,
            sortable: false,
            flex: 1,
            headerAlign: 'left',
            align: 'left',

            renderCell: (value) => {
                return (
                    <Grid
                        container
                        alignItems="center"
                        justifyContent="flex-start"
                    >
                        <Avatar
                            src={`${value.row?.userInfo?.images[0]?.croped}`}
                            style={{ marginRight: 16, width: 45, height: 45 }}
                        />
                        <Grid
                            container
                            alignItems="left"
                            direction="column"
                            item
                            style={{ width: 'calc(100% - 62px)' }}
                        >
                            <Grid container alignItems="center">
                                <Typography
                                    style={{ opacity: 1, color: 'black' }}
                                    variant="body1"
                                >
                                    {value.row?.userInfo?.firstName ??
                                        '' +
                                            ' ' +
                                            value.row?.userInfo?.lastName ??
                                        ''}
                                </Typography>
                            </Grid>
                            <Typography
                                style={{ fontSize: 12 }}
                                variant="body1"
                            >
                                {moment(
                                    value?.row?.userInfo?.dateOfBirth
                                ).format('DD.MM.YYYY')}
                            </Typography>
                            <Typography
                                style={{ fontSize: 12 }}
                                variant="body1"
                            >
                                {`${value.row?.userInfo?.address?.city}, ${value?.row?.userInfo?.address?.country}`}
                            </Typography>
                        </Grid>
                    </Grid>
                )
            },
        },
        {
            field: 'email',
            headerName: (
                <Stack
                    direction="column"
                    justifyContent={'space-around'}
                    alignItems="left"
                >
                    <Typography
                        style={{ opacity: 1, color: 'black' }}
                        variant="body1"
                    >
                        Email Phone Number
                    </Typography>
                </Stack>
            ),
            minWidth: 230,
            flex: 1,
            sortable: false,
            headerAlign: 'left',
            align: 'left',

            renderCell: (value) => (
                <Stack>
                    <Typography
                        style={{ opacity: 1, color: 'black' }}
                        variant="body1"
                    >
                        {value.row.userInfo?.email}
                    </Typography>
                    {value.row.userInfo?.isMobileVerified && (
                        <Typography variant="body1" style={{ fontSize: 12 }}>
                            {value.row.userInfo?.mobileNumber}
                        </Typography>
                    )}
                </Stack>
            ),
        },

        {
            field: 'lastActiveAt',
            headerName: 'Last Active',
            minWidth: 130,
            flex: 1,
            sortable: false,
            headerAlign: 'left',
            align: 'left',

            renderCell: (value) => (
                <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Grid container alignItems="center">
                        <Grid container alignItems="center">
                            <Typography
                                style={{ opacity: 1, color: 'black' }}
                                variant="body1"
                            >
                                {format(
                                    new Date(value.row.userInfo?.lastActiveAt),
                                    'dd LLL uuuu'
                                )}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            ),
        },
        {
            field: 'createdAt',
            headerName: (
                <Stack
                    direction="column"
                    justifyContent={'space-around'}
                    alignItems="left"
                >
                    <Typography
                        style={{
                            opacity: 1,
                            color: 'black',
                        }}
                        variant="body1"
                    >
                        Quiz Attempted Time
                    </Typography>
                </Stack>
            ),
            minWidth: 100,
            flex: 1,
            sortable: true,
            headerAlign: 'left',
            align: 'left',

            renderCell: (value) => (
                <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Grid container alignItems="center">
                        <Grid container alignItems="center">
                            <Typography
                                style={{
                                    opacity: 1,
                                    fontSize: '14px',
                                    // color: 'black',
                                    marginLeft: '6px',
                                }}
                                variant="body1"
                            >
                                {value?.row?.createdAt
                                    ? format(
                                          new Date(
                                              value?.row?.createdAt
                                                  ? value?.row?.createdAt
                                                  : 'NA'
                                          ),
                                          'dd LLL uuuu HH:mm:ss'
                                      )
                                    : '-'}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            ),
        },
        {
            field: 'action',
            headerName: 'Action',
            minWidth: 60,
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            sortable: false,
            renderCell: (value) => (
                <Grid item>
                    <RowActionButton
                        zodiacDropdownOptions={zodiacDropdownOptions}
                        sleepingHabbitsDropdownOptions={
                            sleepingHabbitsDropdownOptions
                        }
                        drinkingDropdownOptions={drinkingDropdownOptions}
                        smokingDropdownOptions={smokingDropdownOptions}
                        relocateForLoveDropdownOptions={
                            relocateForLoveDropdownOptions
                        }
                        personalityDropdownValues={personalityDropdownValues}
                        industryDropdownOptions={industryDropdownOptions}
                        educationDropdownOptions={educationDropdownOptions}
                        salaryDropdownOptions={salaryDropdownOptions}
                        lookingForDropdownOptions={lookingForDropdownOptions}
                        set_Data={set_Data}
                        value={value}
                        setDeleteMutation={setDeleteMutation}
                    />
                </Grid>
            ),
        },
    ]

    return initialLoading ? (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh', // Adjust this as needed
            }}
        >
            <CircularProgress />
        </div>
    ) : (
        <>
            <Grid xs={12} item>
                <Stack
                    direction="row"
                    width="100%"
                    justifyContent={'space-between'}
                >
                    <Typography variant="h5">
                        Quiz - {quizTitle ? quizTitle : null}
                    </Typography>
                    {/* <Typography variant="body1" style={{ fontSize: '20px' }}>
                        Quiz - {quizTitle ? quizTitle : null}
                    </Typography> */}
                </Stack>
            </Grid>
            <Grid xs={12}>
                <Grid xs={4} justifyContent="end">
                    <TextField
                        style={{
                            backgroundColor: 'white',
                            borderRadius: 12,
                            border: '3px solid #E6E6E6',
                        }}
                        value={searchQuery}
                        onChange={(event) => {
                            setSearchQuery(event.target.value)
                        }}
                        id="search_hub_tex"
                        placeholder="Search"
                        fullWidth
                        margin="normal"
                        size="small"
                    />
                </Grid>
            </Grid>
            <Grid
                item
                style={{ marginBottom: '20px', marginTop: '20px' }}
                xs={12}
            >
                <Divider />
            </Grid>

            <Grid
                item
                xs={12}
                className={style.box}
                container
                style={{
                    height: 'calc(100% - 180px)',
                    overflow: 'auto',
                }}
            >
                <DataGrid
                    columns={header_list}
                    autore
                    rows={data}
                    loading={loadingData}
                    disableColumnMenu
                    noda
                    getRowId={(row) => row._id}
                    hideFooter
                    style={{ border: 'none', fontSize: 14 }}
                    classes={{ cell: style.DataGridHeader }}
                    headerHeight={60}
                    rowHeight={90}
                    disableSelectionOnClick
                    disableColumnSelector
                    onColumnHeaderClick={(params) => {
                        setSortOrder({
                            column: params.field,
                            order: sortOrder.order === -1 ? 1 : -1,
                        })
                    }}
                    components={{
                        ColumnSortedAscendingIcon: CustomSort,
                        ColumnSortedDescendingIcon: CustomSort,
                        LoadingOverlay: CustomLoadingOverlay,
                        NoRowsOverlay: NoRowsOverlay,
                    }}
                />
            </Grid>
            {paginateObj ? (
                paginateObj?.totalPages ? (
                    initialLoading ? null : (
                        <Grid item xs={12} style={{ marginTop: '15px' }}>
                            <Pagination
                                currentPage={paginateObj.page}
                                totalPages={paginateObj.totalPages}
                                onPageChange={(_, value) => {
                                    setPaginateObj({
                                        page: value,
                                        totalPages: paginateObj.totalPages,
                                    })
                                }}
                            />
                        </Grid>
                    )
                ) : null
            ) : null}
        </>
    )
}

export default QuizList
const Transition = forwardRef(function Transition(props, ref) {
    return <Fade timeout={350} ref={ref} {...props} />
})
export function CustomLoadingOverlay() {
    return (
        <GridOverlay>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh', // Adjust this as needed
                }}
            >
                <CircularProgress />
            </div>
        </GridOverlay>
    )
}

export const NoRowsOverlay = () => {
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
                width: '100%',
            }}
        >
            <p>No data found</p>
        </div>
    )
}
export const CustomSort = () => {
    return (
        <img
            src={sorter}
            alt=""
            width={11}
            height={25}
            style={{ position: 'relative', bottom: 3 }}
        />
    )
}
