import { useState, useEffect } from 'react'
import {
    Box,
    FormControl,
    FormHelperText,
    FormLabel,
    Grid,
    Typography,
    Accordion,
    AccordionDetails,
    AccordionSummary,
} from '@material-ui/core'
import {
    TextField,
    InputLabel,
    Checkbox,
    FormGroup,
    FormControlLabel,
    Stack,
} from '@mui/material'
import { styles } from '../../../styles/styles'
import { GridExpandMoreIcon } from '@mui/x-data-grid'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { Controller } from 'react-hook-form'
import { useField } from 'formik'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import { GENDERDROPDOWN } from '../../../Utils/index'

const PersonalInformation = ({
    form,
    userData,
    dateOfBirth,
    salaryDropdownOptions,
    industryDropdownOptions,
    educationDropdownOptions,
    sexualOrientationDropdownPotions,
}) => {
    const style = styles()

    const [birthDate, setBirthDate] = useState(null)

    const { register, control } = form
    useEffect(() => {
        register('dateOfBirth')
        if (dateOfBirth) {
            setBirthDate(new Date(dateOfBirth))
        }
    }, [register, setBirthDate, dateOfBirth])

    return (
        <Grid spacing={2} className={style.box}>
            <Accordion style={{ boxShadow: 'none' }} defaultExpanded>
                <AccordionSummary
                    expandIcon={<GridExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-personal-info--questionnair"
                >
                    <Typography
                        style={{
                            fontSize: '1.2rem',
                            textDecoration: 'underline',
                        }}
                    >
                        Personal Information
                    </Typography>
                </AccordionSummary>
                {/* <Grid item xs={12}>
         
            </Grid> */}
                <AccordionDetails>
                    <Grid container spacing={2}>
                        <Grid container item xs={4}>
                            <Grid item xs={12}>
                                <Stack direction="column">
                                    <FormLabel htmlFor="first-name">
                                        First Name
                                        <span
                                            style={{
                                                color: '#dc3545',
                                            }}
                                        >
                                            *
                                        </span>
                                    </FormLabel>
                                    <TextField
                                        {...register('firstName')}
                                        id="first-name"
                                        placeholder="First Name"
                                        // value={""}
                                        // onChange={() => {}}
                                        fullWidth
                                        margin="normal"
                                        size="small"
                                    />
                                    {/* {errors.firstName && (
                                        <span style={{ color: '#dc3545' }}>
                                            {errors.firstName.message}
                                        </span>
                                    )} */}
                                    {/* <FormHelperText>error</FormHelperText> */}
                                </Stack>
                            </Grid>
                        </Grid>
                        <Grid container item xs={4}>
                            <Grid item xs={12}>
                                <Stack direction="column">
                                    <FormLabel htmlFor="last-name">
                                        Last Name:
                                    </FormLabel>
                                    <TextField
                                        {...register('lastName')}
                                        placeholder="Last Name"
                                        id="last-name"
                                        // value={""}
                                        // onChange={() => {}}
                                        fullWidth
                                        margin="normal"
                                        size="small"
                                    />
                                    {/* <FormHelperText>error</FormHelperText> */}
                                </Stack>
                            </Grid>
                        </Grid>
                        <Grid item container xs={4}>
                            <Grid item xs={12}>
                                <Stack direction="column">
                                    <FormLabel htmlFor="dateOfBirth">
                                        Date Of Birth
                                        <span
                                            style={{
                                                color: '#dc3545',
                                            }}
                                        >
                                            *
                                        </span>
                                    </FormLabel>
                                    <LocalizationProvider
                                        dateAdapter={AdapterDateFns}
                                    >
                                        <FormControlLabel
                                            style={{ paddingLeft: '12px' }}
                                            control={
                                                <Controller
                                                    {...register('dateOfBirth')}
                                                    control={control}
                                                    render={({ field }) => (
                                                        <DatePicker
                                                            {...field}
                                                            slotProps={{
                                                                textField: {
                                                                    fullWidth: true,
                                                                    size: 'small',
                                                                    margin: 'normal',
                                                                },
                                                            }}
                                                            renderInput={(
                                                                params
                                                            ) => (
                                                                <TextField
                                                                    {...params}
                                                                />
                                                            )}
                                                            value={
                                                                (field?.value
                                                                    ? new Date(
                                                                          field?.value
                                                                      )
                                                                    : null) ||
                                                                null
                                                            }
                                                            // onChange={(newValue) => {
                                                            //     setBirthDate(newValue)
                                                            //     setValue(
                                                            //         'dateOfBirth',
                                                            //         newValue,
                                                            //         {
                                                            //             shouldValidate: true,
                                                            //             shouldDirty: true,
                                                            //         }
                                                            //     )
                                                            // }}
                                                            onChange={(e) => {
                                                                field.onChange(
                                                                    new Date(e)
                                                                )
                                                            }}
                                                        />
                                                    )}
                                                />
                                            }
                                        />
                                        {/* {errors.dateOfBirth && (
                                            <span style={{ color: '#dc3545' }}>
                                                {errors.dateOfBirth.message}
                                            </span>
                                        )} */}
                                    </LocalizationProvider>
                                </Stack>
                            </Grid>
                        </Grid>
                        {/* <Grid item container xs={4}>
                            <Grid item xs={12}>
                                <Stack direction="column">
                                    <FormLabel htmlFor="gender">
                                        Gender
                                        <span
                                            style={{
                                                color: '#dc3545',
                                            }}
                                        >
                                            *
                                        </span>
                                    </FormLabel>
                                    <TextField
                                        {...register('gender')}
                                        placeholder="Gender"
                                        id="gender"
                                        // value={""}
                                        fullWidth
                                        margin="normal"
                                        size="small"
                                    />
                     
                                </Stack>
                            </Grid>
                        </Grid> */}
                        <Grid item container xs={4}>
                            <Grid item xs={12}>
                                <Stack direction="column">
                                    <FormLabel htmlFor="gender" sx={{ pt: 4 }}>
                                        Gender
                                        <span
                                            style={{
                                                color: '#dc3545',
                                            }}
                                        >
                                            *
                                        </span>
                                    </FormLabel>
                                    <FormControlLabel
                                        style={{
                                            paddingTop: '16px',
                                            paddingLeft: '12px',
                                        }}
                                        control={
                                            <Controller
                                                {...register('gender')}
                                                control={control}
                                                render={({ field }) => (
                                                    <Select
                                                        labelId="gender"
                                                        id="demo-simple-select-gender"
                                                        fullWidth
                                                        margin="normal"
                                                        size="small"
                                                        value={
                                                            field.value
                                                                ? field.value
                                                                : ''
                                                        }
                                                        onChange={(event) =>
                                                            field.onChange(
                                                                event.target
                                                                    .value
                                                            )
                                                        }
                                                    >
                                                        <MenuItem value={'0'}>
                                                            Select Gender
                                                        </MenuItem>
                                                        {GENDERDROPDOWN?.map(
                                                            (option) => {
                                                                return (
                                                                    <MenuItem
                                                                        value={
                                                                            option
                                                                        }
                                                                    >
                                                                        {option}
                                                                    </MenuItem>
                                                                )
                                                            }
                                                        )}
                                                    </Select>
                                                )}
                                            />
                                        }
                                    />
                                </Stack>
                            </Grid>
                        </Grid>
                        <Grid item container xs={4}>
                            <Grid item xs={12}>
                                <Stack direction="column">
                                    <FormLabel
                                        htmlFor="genderDescription"
                                        sx={{ pt: 4 }}
                                    >
                                        Gender Description:
                                    </FormLabel>
                                    <TextField
                                        {...register('genderDescription')}
                                        placeholder="Gender Description"
                                        id="genderDescription"
                                        // value={""}
                                        // onChange={() => {}}
                                        fullWidth
                                        margin="normal"
                                        size="small"
                                    />
                                </Stack>
                            </Grid>
                        </Grid>

                        <Grid item container xs={4}>
                            <Grid item xs={12}>
                                <Stack direction="column">
                                    <FormLabel
                                        htmlFor="sexualOrientation"
                                        sx={{ pt: 4 }}
                                    >
                                        Sexual Orientation:
                                    </FormLabel>
                                    <FormControlLabel
                                        style={{
                                            paddingTop: '16px',
                                            paddingLeft: '12px',
                                        }}
                                        control={
                                            <Controller
                                                {...register(
                                                    'sexualOrientation'
                                                )}
                                                control={control}
                                                render={({ field }) => (
                                                    <Select
                                                        labelId="sexualOrientation"
                                                        id="demo-simple-select-sexualOrientation"
                                                        fullWidth
                                                        margin="normal"
                                                        size="small"
                                                        value={
                                                            field.value
                                                                ? field.value
                                                                : ''
                                                        }
                                                        onChange={(event) =>
                                                            field.onChange(
                                                                event.target
                                                                    .value
                                                            )
                                                        }
                                                    >
                                                        <MenuItem value={'0'}>
                                                            Select Sexual
                                                            Orientation
                                                        </MenuItem>
                                                        {sexualOrientationDropdownPotions?.map(
                                                            (option) => {
                                                                return (
                                                                    <MenuItem
                                                                        value={
                                                                            option.name
                                                                        }
                                                                    >
                                                                        {
                                                                            option.name
                                                                        }
                                                                    </MenuItem>
                                                                )
                                                            }
                                                        )}
                                                    </Select>
                                                )}
                                            />
                                        }
                                    />
                                </Stack>
                            </Grid>
                        </Grid>
                        <Grid item container xs={4}>
                            <Grid item xs={12}>
                                <Stack direction="column">
                                    <FormLabel htmlFor="email" sx={{ pt: 4 }}>
                                        Email
                                        <span
                                            style={{
                                                color: '#dc3545',
                                            }}
                                        >
                                            *
                                        </span>
                                    </FormLabel>
                                    <TextField
                                        {...register('email')}
                                        placeholder="Email"
                                        id="email"
                                        // value={""}
                                        // onChange={() => {}}
                                        fullWidth
                                        margin="normal"
                                        size="small"
                                    />
                                </Stack>
                            </Grid>
                        </Grid>
                        <Grid item container xs={4}>
                            <Grid item xs={12}>
                                <Stack direction="column">
                                    <FormLabel
                                        htmlFor="mobileNumber"
                                        sx={{ pt: 4 }}
                                    >
                                        Mobile Number
                                        <span
                                            style={{
                                                color: '#dc3545',
                                            }}
                                        >
                                            *
                                        </span>
                                    </FormLabel>
                                    <TextField
                                        {...register('mobileNumber')}
                                        id="mobileNumber"
                                        // value={""}
                                        placeholder="Mobile Number"
                                        fullWidth
                                        margin="normal"
                                        size="small"
                                        // style={{
                                        //     border: '2px solid #dc3545',
                                        //     borderRadius: '5px',
                                        // }}
                                    />
                                    {/* {errors.mobileNumber && (
                                        <span style={{ color: '#dc3545' }}>
                                            {errors.mobileNumber.message}
                                        </span>
                                    )} */}
                                </Stack>
                            </Grid>
                        </Grid>
                        <Grid item container xs={4}>
                            <Grid item xs={12}>
                                <Stack direction="column">
                                    <FormLabel htmlFor="education">
                                        Education:
                                    </FormLabel>
                                    <FormControlLabel
                                        style={{
                                            paddingTop: '16px',
                                            paddingLeft: '12px',
                                        }}
                                        control={
                                            <Controller
                                                {...register('education')}
                                                control={control}
                                                render={({ field }) => (
                                                    <Select
                                                        labelId="education"
                                                        id="demo-simple-select"
                                                        fullWidth
                                                        margin="normal"
                                                        size="small"
                                                        // {...field}
                                                        // defaultValue={'0'}
                                                        value={
                                                            field.value
                                                                ? field.value
                                                                : ''
                                                        }
                                                        onChange={(event) =>
                                                            field.onChange(
                                                                event.target
                                                                    .value
                                                            )
                                                        }
                                                    >
                                                        <MenuItem value={'0'}>
                                                            Select Education
                                                        </MenuItem>
                                                        {educationDropdownOptions?.map(
                                                            (option) => {
                                                                return (
                                                                    <MenuItem
                                                                        value={
                                                                            option.name
                                                                        }
                                                                    >
                                                                        {
                                                                            option.name
                                                                        }
                                                                    </MenuItem>
                                                                )
                                                            }
                                                        )}
                                                    </Select>
                                                )}
                                            />
                                        }
                                    />
                                </Stack>
                            </Grid>
                        </Grid>
                        {/* <Grid item xs={4} style={{ paddingTop: "30px" }}>
      <Grid item xs={12}>
          <Stack direction="column">
          <FormLabel htmlFor="isEmailVerified">
          Is Email Verified?:
          </FormLabel>
          <FormControlLabel
            control={
              <Controller
                {...register("isEmailVerified")}
                control={control}
                render={({ field }) => (
                  <Checkbox
                    {...field}
                    id="isEmailVerified"
                    checked={field?.value || false}
                    onChange={(e) => field.onChange(e.target.checked)}
                  />
                )}
              />
            }
          />
            </Stack>
            </Grid>
      </Grid>
      
      <Grid item xs={4} style={{ paddingTop: "30px" }}>
        <FormGroup>
          <FormControlLabel
            control={
              <Controller
                {...register("isMobileVerified")}
                control={control}
                render={({ field }) => (
                  <Checkbox
                    {...field}
                    checked={field?.value || false}
                    onChange={(e) => field.onChange(e.target.checked)}
                  />
                )}
              />
            }
            label="Is Mobile Verified"
          />
        </FormGroup>
      </Grid>

      */}

                        <Grid item container xs={4} style={{ display: 'flex' }}>
                            <Grid item xs={12}>
                                <Stack direction="column">
                                    <FormLabel htmlFor="salary">
                                        Salary:
                                    </FormLabel>
                                    <FormControlLabel
                                        style={{
                                            paddingTop: '16px',
                                            paddingLeft: '12px',
                                        }}
                                        control={
                                            <Controller
                                                {...register('salary')}
                                                control={control}
                                                render={({ field }) => (
                                                    <Select
                                                        labelId="salary"
                                                        id="demo-simple-select"
                                                        fullWidth
                                                        margin="normal"
                                                        size="small"
                                                        // {...field}
                                                        // defaultValue={'0'}
                                                        value={
                                                            field.value
                                                                ? field.value
                                                                : ''
                                                        }
                                                        onChange={(event) =>
                                                            field.onChange(
                                                                event.target
                                                                    .value
                                                            )
                                                        }
                                                    >
                                                        <MenuItem value={'0'}>
                                                            Select Salary
                                                        </MenuItem>
                                                        {salaryDropdownOptions?.map(
                                                            (option) => {
                                                                return (
                                                                    <MenuItem
                                                                        value={
                                                                            option.name
                                                                        }
                                                                    >
                                                                        {
                                                                            option.name
                                                                        }
                                                                    </MenuItem>
                                                                )
                                                            }
                                                        )}
                                                    </Select>
                                                )}
                                            />
                                        }
                                    />
                                </Stack>
                            </Grid>
                        </Grid>

                        <Grid item container xs={4}>
                            <Grid item xs={12}>
                                <Stack direction="column">
                                    <FormLabel
                                        htmlFor="industry"
                                        sx={{ pt: 4 }}
                                    >
                                        Industry:
                                    </FormLabel>
                                    <FormControlLabel
                                        style={{
                                            paddingTop: '16px',
                                            paddingLeft: '12px',
                                        }}
                                        control={
                                            <Controller
                                                {...register('industry')}
                                                control={control}
                                                render={({ field }) => (
                                                    <Select
                                                        labelId="industry"
                                                        id="demo-simple-select"
                                                        fullWidth
                                                        margin="normal"
                                                        size="small"
                                                        //{...field}
                                                        //defaultValue={'0'}
                                                        value={
                                                            field.value
                                                                ? field.value
                                                                : ''
                                                        }
                                                        onChange={(event) =>
                                                            field.onChange(
                                                                event.target
                                                                    .value
                                                            )
                                                        }
                                                    >
                                                        <MenuItem value={'0'}>
                                                            Select Industry
                                                        </MenuItem>
                                                        {industryDropdownOptions?.map(
                                                            (option) => {
                                                                return (
                                                                    <MenuItem
                                                                        value={
                                                                            option.name
                                                                        }
                                                                    >
                                                                        {
                                                                            option.name
                                                                        }
                                                                    </MenuItem>
                                                                )
                                                            }
                                                        )}
                                                    </Select>
                                                )}
                                            />
                                        }
                                    />
                                </Stack>
                            </Grid>
                        </Grid>
                        <Grid container item xs={4}>
                            <Grid item xs={12}>
                                <Stack direction="column">
                                    <FormLabel htmlFor="occupation">
                                        Work/Occupation:
                                    </FormLabel>
                                    <TextField
                                        {...register('occupation')}
                                        placeholder="Work"
                                        id="occupation"
                                        // value={""}
                                        // onChange={() => {}}
                                        fullWidth
                                        margin="normal"
                                        size="small"
                                    />
                                    {/* <FormHelperText>error</FormHelperText> */}
                                </Stack>
                            </Grid>
                        </Grid>
                        {/* <Grid item container xs={4}>
                <Grid item xs={12}>
                    <Stack direction="column">
                        <FormLabel htmlFor="industryDescription" sx={{ pt: 4 }}>
                            Industry Description:
                        </FormLabel>
                        <TextField
                            {...register('industryDescription')}
                            placeholder="Industry Description"
                            id="industryDescription"
                            // value={""}
                            // onChange={() => {}}
                            fullWidth
                            margin="normal"
                            size="small"
                        />
                    </Stack>
                </Grid>
            </Grid> */}
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </Grid>
    )
}

export default PersonalInformation
