import React from 'react'

import ActionImage from './ActionImage'

const ViewUserImage = ({ setUserImages, setBlobImageLocalURL, imageURL }) => {
    return imageURL ? (
        <div style={{ position: 'relative' }}>
            <ActionImage
                setUserImages={setUserImages}
                setBlobImageLocalURL={setBlobImageLocalURL}
            />

            <img
                src={imageURL}
                alt="User Image"
                style={{ borderRadius: 12, objectFit: 'cover' }}
                height={'242px'}
                width={'60%'}
            />
        </div>
    ) : null
}

export default ViewUserImage
