import {
  Avatar,
  Button,
  Grid,
  Typography,
  Zoom,
  Pagination as MUIPagination,
} from "@material-ui/core";
import React from "react";

import { styles } from "../../styles/styles";

import block from "../../asset/img/Block.png";

import { AvatarGroup } from "@material-ui/lab";

import { get_reports } from "../../api/Reports_Api";

import { SearchTextField } from "../../components/Textfields";
import Pagination from "../Blogs/Pagination";
import { clear_search } from "../../redux/ActionCreators";
import { connect } from "react-redux";
import { DataGrid } from "@material-ui/data-grid";
import { CustomLoadingOverlay, CustomSort } from "../ManageUsers/ManageUsers";

import { block_user } from "../../api/Users_Api";
import RowActionButton from "./RowActionButton";
import moment from "moment";

const ReportsTable = ({
  search,
  sort,
  pagination,
  data,
  set_data,
  type,
  loading,
  set_counts,
}) => {
  const [users_to_block, set_users_to_block] = React.useState([]);

  React.useEffect(() => {
    get_reports(type, search).then((res) => {
      if (!res) return;
      let d = res.data.reports.map((item) => {
        return {
          ...item,
          userEmail: item.user.email,
          userName: item.user.firstName,
          _id: item.user._id,
        };
      });

      set_counts({
        all: res.data.reportsCount,
        clear: res.data.clearReportsCount,
      });
      set_data(d);
    });
    // eslint-disable-next-line
  }, [search]);

  React.useEffect(() => {
    return () => {
      clear_search();
      set_data([]);
    };
  }, [set_data]);

  const header_list = [
    {
      field: "userName",
      headerName: "User",
      flex: 1,
      minWidth: 250,
      headerAlign: "start",
      align: "start",
      renderCell: (value) => (
        <Grid
          container
          item
          alignItems="center"
          justifyContent="space-between"
          style={{}}
        >
          <Grid
            item
            alignItems="center"
            container
            style={{ width: "fit-content" }}
          >
            <Avatar
              style={{ marginRight: 16 }}
              src={`${value.row.user.images[0]?.croped}`}
            />
            <Grid item>
              <Typography color="textPrimary" variant="body1">
                {value.row.user.firstName} {value.row.user.lastName}
              </Typography>
              <Typography color="textSecondary" variant="subtitle2">
                {value.row.user.username}
              </Typography>
            </Grid>
          </Grid>

          {value.row.user.isBlocked && (
            <Grid
              item
              style={{
                backgroundColor: "rgba(255, 210, 210, 0.59",
                padding: "3px 6px",
                borderRadius: 10,
              }}
            >
              <Typography style={{ color: "#D81414" }} variant="body1">
                Blocked
              </Typography>
            </Grid>
          )}
        </Grid>
      ),
    },
    {
      field: "userEmail",
      headerName: "Email",
      flex: 0.5,
      minWidth: 20,
      headerAlign: "start",
      align: "start",

      renderCell: (value) => (
        <Typography style={{ opacity: 1, color: "black" }} variant="body1">
          {value.row.user.email}
        </Typography>
      ),
    },
    {
      field: "qty",
      headerName: "Number of Report",
      width: 252,
      headerAlign: "start",
      align: "start",
      headerClassName: "super-app-theme--header",
      renderCell: (value) => (
        <Grid item style={{ paddingLeft: 16, width: 300 }}>
          <AvatarGroup max={4} classes={{ avatar: style.Avatar }}>
            {value.row.profileReports.map((it, i) => (
              <Avatar
                key={i}
                src={`${it.reporter.images[0].croped}`}
                style={{
                  zIndex: i !== 0 ? i + 2 : 1,
                }}
              />
            ))}
            {value.row.messagesReports.map((it, i) => (
              <Avatar
                key={i}
                src={`${it.reporter.images[0].croped}`}
                style={{
                  zIndex: i !== 0 ? i + 2 : 1,
                }}
              />
            ))}
          </AvatarGroup>
        </Grid>
      ),
    },

    {
      field: "_id",
      headerName: "Registration Date",
      width: 400,
      headerAlign: "start",
      align: "start",
      sortable: true,
      headerClassName: "super-app-theme--header",
      renderCell: (value) => {
        return (
          <Grid
            item
            container
            justifyContent="space-between"
            alignItems="center"
            style={{}}
          >
            <Typography style={{ opacity: 1, color: "black" }} variant="body1">
              {moment(value.row?.user?.createdAt).format("DD MMM YYYY")}
            </Typography>
            <Grid item>
              <RowActionButton value={value} set_Data={set_data} />
            </Grid>
          </Grid>
        );
      },
    },
  ];
  const style = styles();
  return (
    <Grid
      item
      style={{ height: "100%", padding: 0, overflow: "auto" }}
      container
      className={style.box}
    >
      <Grid
        item
        container
        justifyContent="space-between"
        alignItems="center"
        style={{ height: 80, padding: 10 }}
      >
        <Grid item style={{ width: 300 }}>
          <Zoom in={users_to_block.length !== 0}>
            <Grid container justifyContent="space-between">
              <Button
                startIcon={<img src={block} alt="" />}
                style={{
                  backgroundColor: "#EFF1F9",
                  color: "#EB5757",
                  borderRadius: 4,
                }}
                onClick={() => {
                  block_user({
                    ids: users_to_block,
                  }).then((res) => {
                    if (!res) return;
                    set_data((pre) =>
                      pre.map((item) => {
                        if (users_to_block.indexOf(item.user._id) > -1) {
                          return {
                            ...item,
                            user: {
                              ...item.user,
                              isBlocked: true,
                            },
                          };
                        } else {
                          return item;
                        }
                      })
                    );
                  });
                }}
              >
                Block
              </Button>
              {/* <DeActiveReport
                set_data={set_data}
                set_block_time={set_block_time}
                users_to_block={users_to_block}
                block_time={block_time}
                set_users_to_block={set_users_to_block}
              /> */}
            </Grid>
          </Zoom>
        </Grid>
        <Grid
          item
          container
          alignItems="center"
          style={{ width: "fit-content", height: 80 }}
        >
          <Grid item style={{ marginRight: 32 }}>
            <SearchTextField placeholder={"Search name or email..."} />
          </Grid>
          <Grid item>
            <Pagination data={data.length} />
          </Grid>
        </Grid>
      </Grid>
      <DataGrid
        columns={header_list}
        sortingOrder={["asc", "desc"]}
        checkboxSelection
        selectionModel={users_to_block}
        onSelectionModelChange={(props, details) => {
          set_users_to_block(props);
        }}
        hideFooter
        classes={{ columnHeader: style.reportHeader, row: style.reportRow }}
        getRowId={(row) => row._id}
        rows={data}
        loading={loading}
        rowHeight={80}
        headerHeight={40}
        disableColumnMenu
        disableSelectionOnClick
        components={{
          LoadingOverlay: CustomLoadingOverlay,
          ColumnSortedAscendingIcon: CustomSort,
          ColumnSortedDescendingIcon: CustomSort,
        }}
        style={{ height: "calc(100% - 80px)" }}
      />
    </Grid>
  );
};

const mapStateToProps = ({
  GeneralReducer: { search, sort, pagination, loading },
}) => {
  return { search, sort, pagination, loading };
};

export default connect(mapStateToProps)(ReportsTable);
