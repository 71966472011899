import React from 'react'
import { FounderIcon } from '../icons/FounderIcon'
import { GoldenTicketIcon } from '../icons/GoldenTicketIcon'
import { FakeAccountIcon } from '../icons/FakeAccountIcon'
import { BlockedIcon } from '../icons/BlockedIcon'
import { MatchmakeMeIcon } from '../icons/MatchMakeMeIcon'
import { DeletedIcon } from '../icons/DeletedIcon'
// import { MoneyIcon } from '../icons/MoneyIcon'
// import { InsertEmoticon } from '@material-ui/icons'
import { Box, Stack, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
export const Status = (props) => {
    const { data, selected_state } = props
    console.log('🚀 ~ file: Status.js:14 ~ Status ~ data:', selected_state)
    const status = []
    console.log(selected_state) //Founder,Fake,Golden,Matchmake,Completed,Deleted
    // here icon depends on status filter

    // selected_state?.split(',')?.map((item, index) => {
    //     if (item === 'Golden' && data.goldenAt)
    //         status.push(<GoldenTicketIcon />)
    //     if (item === 'Founder' && data.isFunder === true)
    //         status.push(<FounderIcon />)
    //     if (item === 'Fake' && data.isFake === true)
    //         status.push(<FakeAccountIcon />)
    //     if (item === 'Matchmake' && data.matchMakeMe === true)
    //         status.push(<MatchmakeMeIcon />)
    //     // if (item === 'Completed') status.push(<BlockedIcon />)
    //     if (item === 'Deleted' && data.status === 'deActive')
    //         status.push(<DeletedIcon />)
    //     if (item === 'Deleted' && data.status === 'deleted')
    //         status.push(<DeletedIcon />)
    //     if (item === 'Blocked' && data.status === 'blocked')
    //         status.push(<BlockedIcon />)
    //     return []
    // })

    // here icon will not dependebt on status filter
    if (data.goldenAt) status.push(<GoldenTicketIcon />)
    if (data.isFunder === true) status.push(<FounderIcon />)
    if (data.isFake === true) status.push(<FakeAccountIcon />)
    if (data.matchMakeMe === true) status.push(<MatchmakeMeIcon />)
    if (data.status === 'deActive') status.push(<DeletedIcon />)
    if (data.status === 'deleted') status.push(<DeletedIcon />)
    if (data.status === 'blocked') status.push(<BlockedIcon />)
    // if (data.leveling) status.push(<MoneyIcon />)
    // if (data.goldenAt) status.push(<GoldenTicketIcon />)
    // if (data.status === 'blocked') status.push(<BlockedIcon />)
    // if (data.matchMakeMe === true) status.push(<MatchmakeMeIcon />)
    // if (data.isFake === true) status.push(<FakeAccountIcon />)
    // if (data.isFunder === true) status.push(<FounderIcon />)
    // if (data.status === 'deActive') status.push(<DeletedIcon />)
    const HtmlTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.arrow}`]: {
            color: '#FFFFFF',
            '&::before': {
                backgroundColor: '#FFFFFF',
                border: '3px solid #FFFFFF',
            },
        },
        [`& .${tooltipClasses.tooltip}`]: {
            background: '#FFFFFF',
            border: '1px solid rgba(0, 0, 0, 0.1)',
            boxShadow: '0px 2px 24px rgba(152, 152, 152, 0.55)',
            borderRadius: '8px',
            padding: '20px',
        },
    }))
    return (
        <>
            {status.length <= 3 ? (
                status.map((item) => item)
            ) : (
                <>
                    {status[0]}
                    {status[1]}
                    <HtmlTooltip
                        arrow
                        title={
                            <React.Fragment>
                                <Typography
                                    style={{
                                        fontWeight: 500,
                                        fontSize: '12px',
                                        lineHeight: '15px',
                                        marginBottom: '6px',
                                    }}
                                >
                                    Status
                                </Typography>
                                <Stack direction="row" spacing={1}>
                                    {status.map((item) => item)}
                                </Stack>
                            </React.Fragment>
                        }
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'center',
                                padding: '5px',
                                width: '29px',
                                height: '27px',
                                background: '#005441',
                                borderRadius: '6px',
                                color: '#FFFFFF',
                                cursor: 'pointer',
                            }}
                        >
                            +{status.length - 2}
                        </Box>
                    </HtmlTooltip>
                </>
            )}
        </>
    )
}
