import React from 'react'

export const BlockedOutlineIcon = () => {
  return (

    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.5 2C4.3645 2 1 5.3645 1 9.5C1 13.6355 4.3645 17 8.5 17C12.6355 17 16 13.6355 16 9.5C16 5.3645 12.6355 2 8.5 2ZM2.5 9.5C2.5 8.1155 2.9755 6.8435 3.766 5.82725L12.1727 14.234C11.1243 15.0544 9.83129 15.5001 8.5 15.5C5.19175 15.5 2.5 12.8082 2.5 9.5ZM13.234 13.1727L4.82725 4.766C5.87578 3.94572 7.16873 3.50004 8.5 3.5C11.8082 3.5 14.5 6.19175 14.5 9.5C14.4996 10.8312 14.054 12.124 13.234 13.1727Z" fill="black" />
    </svg>


  )
}