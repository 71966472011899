import React, { useState } from 'react'
import imageCompression from 'browser-image-compression'
import { Controller } from 'react-hook-form'
import { set_message } from '../../../../redux/ActionCreators'
import Store from '../../../../redux/Store'

import { Add } from '@material-ui/icons'
import { userImageUpload } from '../../../../api/Users_Api'
import { CircularProgress } from '@mui/material'

const UploadUserImage = ({ setUserImages, userId }) => {
    const [uploadingImage, setUploadingImage] = useState(false)
    const handleOnChange = async (event) => {
        const imageFile = event?.target?.files[0]
        console.log('imageFile', imageFile?.type)
        if (!imageFile?.type || !imageFile?.type?.startsWith('image')) {
            Store.dispatch(
                set_message({
                    mode: true,
                    message: 'Only image type file is allowed.',
                    color: 'error',
                })
            )
            return
        }
        // if (imageFile?.size > 10485760) {
        //     Store.dispatch(
        //         set_message({
        //             mode: true,
        //             message:
        //                 'Selected image is too large' +
        //                 (imageFile?.size / 1024 / 1024)?.toFixed(2) +
        //                 'MB',
        //             color: 'error',
        //         })
        //     )
        //     return
        // }

        if (imageFile) {
            console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`)
            const options = {
                maxSizeMB: 1,
                // maxWidthOrHeight: 250,
                useWebWorker: true,
            }
            let compressedFile = imageFile
            if (compressedFile?.size > 3145728) {
                compressedFile = await imageCompression(imageFile, options)
            }
            console.log(
                `compressedFile size ${compressedFile.size / 1024 / 1024} MB`
            )
            setUploadingImage(true)
            const formData = new FormData()
            formData.append('image', compressedFile)
            const data = await userImageUpload(formData, userId)
            setUploadingImage(false)
            setUserImages(data?.data?.user?.images)
        }
    }
    return uploadingImage ? (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <CircularProgress style={{ marginTop: '40px' }} size={'100px'} />
        </div>
    ) : (
        <div style={{ position: 'relative' }}>
            <input
                style={{
                    position: 'absolute',
                    top: '-10%',
                    left: '-10%',
                    lineHeight: '15rem',
                    opacity: 0,
                }}
                onChange={handleOnChange}
                accept="image/*"
                maxlength="5728640"
                id="image_upload"
                type="file"
            />
            <Add
                style={{
                    width: '200px',
                    height: '200px',
                }}
            ></Add>
        </div>
    )
}

export default UploadUserImage
