import { Grid, Typography } from "@material-ui/core";
import React from "react";
import { connect } from "react-redux";

import AccountSetting from "./AccountSetting";

const Settings = ({ loading }) => {
  return (
    <Grid item style={{ height: "100%" }}>
      {/* -----------------------------------------------------settings headers */}

      {/* -----------------------------------------------------headers tabs*/}
      <Grid
        item
        xs={12}
        md={6}
        style={{ height: 50 }}
        container
        direction="column"
        justifyContent="space-between"
      >
        <Typography variant="h5">Settings</Typography>
      </Grid>

      <Grid item xs={12} container style={{ height: "calc(100% - 50px)" }}>
        <AccountSetting />
      </Grid>
    </Grid>
  );
};
const mapStateToProps = (props) => {
  const { GeneralReducer } = props;

  const { loading } = GeneralReducer;

  return { loading };
};

export default connect(mapStateToProps)(Settings);
// function a11yProps(index) {
//   return {
//     id: `full-width-tab-${index}`,
//     "aria-controls": `full-width-tabpanel-${index}`,
//   };
// }
