import {
    Button,
    Checkbox,
    Chip,
    CircularProgress,
    Grid,
    IconButton,
    Tooltip,
    Typography,
    withStyles,
} from '@material-ui/core'
import React from 'react'

import apple from '../../asset/img/request/apple_request.svg'
// import close from "../../asset/img/close.svg";
import tick from '../../asset/img/tick.svg'
import insta from '../../asset/img/request/insta_request.svg'
// import fb from "../../asset/img/request/facebook_request.svg";
import linkdin from '../../asset/img/request/in_request.svg'
import qrcode from '../../asset/img/request/qr_request.svg'
import google from '../../asset/img/request/google_request.svg'
import location from '../../asset/img/request/Location.svg'
import mail from '../../asset/img/request/mail.svg'
import phone from '../../asset/img/request/phone.svg'
import cake from '../../asset/img/request/cake.svg'
import hat from '../../asset/img/request/hat.svg'
import factory from '../../asset/img/request/factory.svg'
import cado from '../../asset/img/request/cado.svg'
import money from '../../asset/img/request/money.svg'
import { styles } from '../../styles/styles'
import Slider from 'react-slick'
import moment from 'moment'
import { accept_request_notification } from '../../api/UserRequest_Api'
import { connect } from 'react-redux'
import RejectRequest from './Reject'
import { Status } from '../../components/Status'
import { WaitingList } from './WaitingList'
import { Stack } from '@mui/material'
import ViewUserProfile from './viewUserProfile'

const Request = ({
    data,
    dropdowns,
    set_group_req,
    group_req,
    set_data,
    set_count,
    tab_value,
    modal_loading,
    setDeleteMutation,
}) => {
    const settings = {
        infinite: false,
        speed: 500,
        arrows: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        className: 'react__slick__slider__parent',
    }
    const [current_item, set_current_item] = React.useState('')
    const style = styles()

    const instagramClickHandler = (instaId) => {
        console.log('--------------', instaId)
        const instagramProfileUrl = `https://www.instagram.com/${instaId}/?hl=en`
        window.open(instagramProfileUrl)
    }
    return (
        <Grid
            className={style.box}
            container
            alignItems="flex-start"
            item
            justifyContent="space-between"
            style={{
                padding: 20,
                backgroundColor:
                    group_req.find((item) => item.id === data._id) !== undefined
                        ? '#E7F0F4'
                        : 'white',
                transition: '0.3s',
                marginBottom: 24,
                minHeight: 200,
            }}
        >
            <Grid
                item
                container
                style={{ height: '100%', width: 'calc(100% - 300px)' }}
            >
                {/* ----------------------------------------------------------------------------------profile section */}
                <Grid
                    item
                    container
                    alignItems="center"
                    style={{ position: 'relative', right: 10 }}
                >
                    {tab_value === 'deleted' ? null : (
                        <Checkbox
                            style={{ color: 'black' }}
                            checked={
                                group_req.find(
                                    (item) => item.id === data._id
                                ) !== undefined
                                    ? true
                                    : false
                            }
                            onChange={() => {
                                if (
                                    group_req.length &&
                                    group_req.find(
                                        (item) => item.id === data._id
                                    ) !== undefined
                                ) {
                                    set_group_req([
                                        ...group_req.filter(
                                            (item) => item.id !== data._id
                                        ),
                                    ])
                                    return
                                }
                                set_group_req([
                                    ...group_req,
                                    { id: data._id, name: data.name },
                                ])
                            }}
                        />
                    )}

                    {/* <Grid container item style={{ marginLeft: 16 }}> */}
                    <Typography
                        style={{
                            opacity: 1,
                            fontFamily: 'interSemi',
                            fontSize: 17,
                            marginLeft: 44,
                        }}
                        variant="subtitle2"
                    >
                        {data.firstName} {data.lastName}
                    </Typography>
                    {data.goldenAt && (
                        <Grid style={{ marginLeft: '30px' }}>
                            <Status data={data} />
                        </Grid>
                    )}
                    {images.map((item, i) => {
                        if (data[item.value])
                            return (
                                <LightTooltip
                                    key={i}
                                    arrow
                                    title={
                                        data[item.value] ? data[item.value] : ''
                                    }
                                >
                                    <div style={{ marginLeft: 30 }}>
                                        {item.value === 'instagram' && (
                                            <a
                                                href={`https://www.instagram.com/${
                                                    data[item.value]
                                                }`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <img src={item.icon} alt="" />
                                            </a>
                                        )}
                                        {item.value === 'linkedin' && (
                                            <a
                                                href={`https://www.linkedin.com/in/${
                                                    data[item.value]
                                                }/`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <img src={item.icon} alt="" />
                                            </a>
                                        )}

                                        {item.value !== 'instagram' &&
                                            item.value !== 'linkedin' && (
                                                <img src={item.icon} alt="" />
                                            )}
                                    </div>
                                </LightTooltip>
                            )
                    })}
                    <Typography
                        style={{
                            opacity: 1,
                            fontFamily: 'interSemi',
                            fontSize: 13,
                            marginLeft: 60,
                            alignItems: 'center',
                        }}
                        variant="subtitle2"
                    >
                        Registered On:{' '}
                        {moment(data?.createdAt).format('DD.MM.YYYY')}
                    </Typography>
                    <Typography
                        style={{
                            opacity: 1,
                            fontFamily: 'interSemi',
                            fontSize: 13,
                            marginLeft: 16,
                            alignItems: 'center',
                        }}
                        variant="subtitle2"
                    >
                        Last Activity On:
                        {moment(data?.lastActiveAt).format('DD.MM.YYYY')}
                    </Typography>

                    {data?.deletedAtTime ? (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'end',
                            }}
                        >
                            <Typography
                                style={{
                                    opacity: 1,
                                    fontFamily: 'interSemi',
                                    fontSize: 13,
                                    marginLeft: 150,
                                    alignItems: 'center',
                                }}
                                variant="subtitle2"
                            >
                                <Stack direction="row" spacing={1}>
                                    <Chip
                                        label="Deleted User"
                                        style={{
                                            backgroundColor: '#d32f2f',
                                            color: 'white',
                                        }}
                                    />
                                </Stack>
                            </Typography>
                        </div>
                    ) : null}
                </Grid>

                {/* </Grid> */}
                {/* ----------------------------------------------------------------------------------profile email section */}

                <Grid
                    item
                    container
                    alignItems="center"
                    xs={12}
                    justifyContent="flex-start"
                    style={{ margin: '16px 0 0 70px' }}
                >
                    {announcement.map((item, i) => (
                        <Grid
                            container
                            key={i}
                            style={{
                                width: 'fit-content',
                                padding: '8px 13px 9px',
                                border: '2px solid #ECEFF4',
                                borderRadius: 50,
                                marginRight: 8,
                                marginBottom: 8,
                            }}
                        >
                            <img
                                src={item.img}
                                alt=""
                                style={{ marginRight: 8 }}
                            />
                            <Typography
                                variant="subtitle1"
                                style={{ color: 'black', fontSize: 13 }}
                            >
                                {item.title === 'country' &&
                                    `${data?.address?.city}, ${data?.address?.country}`}
                                {item.title === 'Birth' &&
                                    moment(data?.dateOfBirth).format(
                                        'DD.MM.YYYY'
                                    )}
                                {data[item.title]}
                            </Typography>
                        </Grid>
                    ))}
                </Grid>
                {/* ----------------------------------------------------------------------------------biography section */}

                <Grid
                    item
                    container
                    xs={12}
                    style={{ margin: '16px 0 0 70px' }}
                >
                    <Grid item container>
                        <Typography
                            color="textPrimary"
                            variant="body1"
                            style={{ opacity: 1, color: 'black' }}
                        >
                            Referral:
                        </Typography>
                        {data?.referralCode}

                        <Typography
                            color="textPrimary"
                            variant="body1"
                            style={{
                                opacity: 1,
                                color: '#050B7A',
                                marginLeft: 8,
                            }}
                        >
                            {/* {`${ref.user?.firstName} ${ref.user?.lastName}` } */}
                            {/* {(index>0 && index < data?.referrals?.user?.length) && ', '} */}
                        </Typography>
                        {/* })} */}
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: 16 }}>
                        <Typography
                            color="textPrimary"
                            variant="body1"
                            style={{ opacity: 1, color: 'black' }}
                        >
                            Why joined the REDDI community?
                        </Typography>
                        <Typography
                            style={{
                                color: '#05060F',
                                opacity: 1,
                                textAlign: 'justify',
                                marginLeft: 40,
                                marginTop: 16,
                            }}
                            variant="subtitle2"
                        >
                            {data?.whyToJoin}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>

            {/* ----------------------------------------------------------------------------------slider section */}
            <Grid item style={{ minHeight: 300, width: 276 }}>
                <Slider {...settings}>
                    {data?.images?.map((item, i) => {
                        return (
                            <Grid key={i} item style={{}}>
                                <img
                                    src={`${item?.croped}`}
                                    alt=""
                                    key={i}
                                    width={'100%'}
                                    height={300}
                                    style={{ objectFit: 'cover' }}
                                />
                            </Grid>
                        )
                    })}
                </Slider>
            </Grid>

            <Grid
                justifyContent="space-between"
                alignItems="center"
                item
                xs={12}
                container
                style={{ marginTop: '16px' }}
                // className={style.box}
            >
                <Grid item container alignItems="center" style={{ width: 250 }}>
                    {data ? (
                        <ViewUserProfile
                            dropdowns={dropdowns}
                            setDeleteMutation={setDeleteMutation}
                            data={data}
                        />
                    ) : null}
                </Grid>

                <Grid
                    item
                    justifyContent="flex-end"
                    style={{ width: 550 }}
                    container
                >
                    <>
                        <Grid item>
                            <WaitingList
                                deletedAtTime={data?.deletedAtTime}
                                tab_value={tab_value}
                                titleShow={false}
                                wating_list={[data]}
                                status={data.status}
                                set_data={set_data}
                                set_count={set_count}
                            />

                            <RejectRequest
                                tab_value={tab_value}
                                status={data.status}
                                req_ids={[data._id]}
                                set_Data={set_data}
                                set_req={set_group_req}
                                set_count={set_count}
                                user_name={
                                    data?.firstName + ' ' + data?.lastName
                                }
                            />
                        </Grid>
                        <Grid item>
                            {/* {tab_value === 'deleted' ? null : ( */}
                            <Button
                                startIcon={
                                    modal_loading &&
                                    current_item === data._id ? (
                                        <CircularProgress
                                            style={{
                                                width: 20,
                                                height: 20,
                                                color: 'white',
                                            }}
                                        />
                                    ) : (
                                        <img alt="" src={tick} />
                                    )
                                }
                                style={{
                                    backgroundColor: '#65819D',
                                    color: 'white',
                                    borderRadius: 12,
                                    padding: '6px 10px',
                                    marginLeft: 8,
                                }}
                                onClick={() => {
                                    set_current_item(data._id)
                                    accept_request_notification({
                                        ids: [data._id],
                                    }).then((res) => {
                                        if (!res) return
                                        set_data((pre) =>
                                            pre.filter(
                                                (item) => item._id !== data._id
                                            )
                                        )
                                        set_count((pre) => ({
                                            ...pre,
                                            [tab_value]: pre[tab_value] - 1,
                                        }))
                                        set_group_req([])
                                    })
                                    set_current_item('')
                                }}
                            >
                                Accept
                            </Button>
                            {/* )} */}
                        </Grid>
                    </>
                </Grid>
            </Grid>
        </Grid>
    )
}
const state = (props) => {
    const { GeneralReducer } = props
    const { modal_loading } = GeneralReducer
    return { modal_loading }
}
export default connect(state)(Request)
const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 14,
        padding: '8px 16px',
    },
    arrow: {
        color: 'white',
    },
}))(Tooltip)
const images = [
    { icon: qrcode, value: '' },
    { icon: google, value: 'email' },
    // { icon: fb, value: "" },
    { icon: linkdin, value: 'linkedin' },
    { icon: insta, value: 'instagram' },
    { icon: apple, value: '' },
]
const announcement = [
    { img: location, title: 'country' },
    { img: mail, title: 'email' },
    { img: phone, title: 'mobileNumber' },
    { img: cake, title: 'Birth' },
    { img: hat, title: 'education' },
    { img: factory, title: 'industry' },
    { img: cado, title: 'occupation' },
    { img: money, title: 'salary' },
]
