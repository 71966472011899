export const EthnicityDropdownValues = [
    'White/Caucasian',
    'Black / African',
    'Asian',
    'Arab',
    'Middle Eastern',
    'Mediterranean',
    'Hispanic / Latino',
    'East Indian',
    'Latin-American',
    'Mixed Race',
    'Other',
]

export const ReligionDropdownValues = [
    'Agnostic',
    'Atheist',
    'Buddhist',
    'Catholic',
    'Christian',
    'Hindu',
    'Jain',
    'Jewish',
    'Mormon',
    'Muslim',
    'Zoroastrian',
    'Sikh',
    'Spiritual',
    'Other',
]

export const FamilyPlansDropdownValues = [
    'I want children',
    'I don`t want children',
    'I have children and want more',
    'I have children and don`t want more',
    'Have children',
    'Open to a blended family',
    'Open to children',
    'Not sure yet',
]

export const PoliticsDropdownValues = [
    'Apolitical',
    'Moderate',
    'Liberal',
    'Conservative',
    'Prefer not to say',
]

export const LoveStyleDropdownValues = [
    'Thoughtful Gestures',
    'Compliments',
    'Quality Time',
    'Presents',
    'Physical Touch',
]

export const ExerciseDropdownValues = ['Active', 'Sometimes', 'Almost never']

export const dietaryDropdownValues = [
    'Vegan',
    'Vegetarian',
    'Pescatarian',
    'Kosher',
    'Halal',
    'Flexitarian',
    'Carnivore',
    'Omnivore',
]
