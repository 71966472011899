import { useState, useEffect } from 'react'
import {
    Box,
    FormControl,
    FormHelperText,
    FormLabel,
    Grid,
    Typography,
} from '@material-ui/core'
import {
    TextField,
    InputLabel,
    Checkbox,
    FormGroup,
    FormControlLabel,
    Stack,
} from '@mui/material'
import { styles } from '../../../styles/styles'

import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { Controller } from 'react-hook-form'
import { useField } from 'formik'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'

const UserPersonal = ({ form, avaliableOptions }) => {
    const style = styles()
    const numberOfColumn = 4
    const {
        register,
        setValue,
        watch,
        control,
        trigger,
        formState: { errors, isDirty },
        reset,
        getValues,
        handleSubmit,
        setErro,
    } = form
    return (
        <Grid container spacing={3} className={style.box}>
            <Grid item xs={12}>
                <Typography
                    style={{ fontSize: '1.2rem', textDecoration: 'underline' }}
                >
                    Personal Information 3
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={numberOfColumn}>
                        <FormControl fullWidth>
                            <FormLabel htmlFor="first-name">
                                First Name:
                            </FormLabel>
                            <Controller
                                name="firstName"
                                control={control}
                                // rules={{ required: true }}
                                render={({ field: { value, onChange } }) => (
                                    <TextField
                                        value={value}
                                        size="small"
                                        // label='First Name:'
                                        onChange={onChange}
                                        error={Boolean(errors.firstName)}
                                        aria-describedby="firstName"
                                    />
                                )}
                            />
                            {errors.firstName && (
                                <FormHelperText
                                    sx={{ color: 'error.main' }}
                                    id="validation-basic-email"
                                >
                                    {errors?.firstName?.message}
                                </FormHelperText>
                            )}
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={numberOfColumn}>
                        <FormControl fullWidth>
                            <FormLabel htmlFor="last-name">
                                Last Name:
                            </FormLabel>
                            <Controller
                                name="lastName"
                                control={control}
                                // rules={{ required: true }}
                                render={({ field }) => (
                                    <TextField
                                        value={field?.value}
                                        size="small"
                                        // label='Last Name'
                                        onChange={field.onChange}
                                        error={Boolean(errors.firstName)}
                                        aria-describedby="lastName"
                                    />
                                )}
                            />
                            {errors.lastName && (
                                <FormHelperText
                                    sx={{ color: 'error.main' }}
                                    id="validation-basic-email"
                                >
                                    {errors?.lastName?.message}
                                </FormHelperText>
                            )}
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={numberOfColumn}>
                        <Stack direction="column">
                            <FormLabel htmlFor="gender">Gender:</FormLabel>
                            <FormControlLabel
                                style={{
                                    paddingLeft: '12px',
                                }}
                                control={
                                    <Controller
                                        {...register('gender')}
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                labelId="gender"
                                                id="demo-simple-select"
                                                fullWidth
                                                margin="normal"
                                                size="small"
                                                {...field}
                                                defaultValue={[]}
                                                multiple={true}
                                            >
                                                {avaliableOptions?.gender?.map(
                                                    (option, index) => {
                                                        return (
                                                            <MenuItem
                                                                value={option}
                                                                key={index}
                                                            >
                                                                {option}
                                                            </MenuItem>
                                                        )
                                                    }
                                                )}
                                            </Select>
                                        )}
                                    />
                                }
                            />
                        </Stack>
                    </Grid>
                    <Grid item xs={12} sm={numberOfColumn}>
                        <Stack direction="column">
                            <FormLabel htmlFor="sexualOrientation">
                                Sexual Orientation:
                            </FormLabel>
                            <FormControlLabel
                                style={{
                                    paddingLeft: '12px',
                                }}
                                control={
                                    <Controller
                                        {...register('sexualOrientation')}
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                labelId="sexualOrientation"
                                                id="demo-simple-select"
                                                fullWidth
                                                margin="normal"
                                                size="small"
                                                {...field}
                                                defaultValue={[]}
                                                multiple={true}
                                            >
                                                {avaliableOptions?.sexualOrientation?.map(
                                                    (option, index) => {
                                                        return (
                                                            <MenuItem
                                                                value={option}
                                                                key={index}
                                                            >
                                                                {option}
                                                            </MenuItem>
                                                        )
                                                    }
                                                )}
                                            </Select>
                                        )}
                                    />
                                }
                            />
                        </Stack>
                    </Grid>
                    <Grid item xs={12} sm={numberOfColumn}>
                        <Stack direction="column">
                            <FormLabel htmlFor="address.country">
                                Country:
                            </FormLabel>
                            <FormControlLabel
                                style={{
                                    paddingLeft: '12px',
                                }}
                                control={
                                    <Controller
                                        {...register('address.country')}
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                labelId="address.country"
                                                id="demo-simple-select"
                                                fullWidth
                                                margin="normal"
                                                size="small"
                                                {...field}
                                                defaultValue={[]}
                                                multiple={true}
                                            >
                                                {avaliableOptions?.address?.country?.map(
                                                    (option, index) => {
                                                        return (
                                                            <MenuItem
                                                                value={option}
                                                                key={index}
                                                            >
                                                                {option}
                                                            </MenuItem>
                                                        )
                                                    }
                                                )}
                                            </Select>
                                        )}
                                    />
                                }
                            />
                        </Stack>
                    </Grid>
                    <Grid item xs={12} sm={numberOfColumn}>
                        <Stack direction="column">
                            <FormLabel htmlFor="address.state">
                                State:
                            </FormLabel>
                            <FormControlLabel
                                style={{
                                    paddingLeft: '12px',
                                }}
                                control={
                                    <Controller
                                        {...register('address.state')}
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                labelId="address.state"
                                                id="demo-simple-select"
                                                fullWidth
                                                margin="normal"
                                                size="small"
                                                {...field}
                                                defaultValue={[]}
                                                multiple={true}
                                            >
                                                {avaliableOptions?.address?.state?.map(
                                                    (option, index) => {
                                                        return (
                                                            <MenuItem
                                                                value={option}
                                                                key={index}
                                                            >
                                                                {option}
                                                            </MenuItem>
                                                        )
                                                    }
                                                )}
                                            </Select>
                                        )}
                                    />
                                }
                            />
                        </Stack>
                    </Grid>
                    <Grid item xs={12} sm={numberOfColumn}>
                        <Stack direction="column">
                            <FormLabel htmlFor="address.city">City:</FormLabel>
                            <FormControlLabel
                                style={{
                                    paddingLeft: '12px',
                                }}
                                control={
                                    <Controller
                                        {...register('address.city')}
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                labelId="address.city"
                                                id="demo-simple-select"
                                                fullWidth
                                                margin="normal"
                                                size="small"
                                                {...field}
                                                defaultValue={[]}
                                                multiple={true}
                                            >
                                                {avaliableOptions?.address?.city?.map(
                                                    (option, index) => {
                                                        return (
                                                            <MenuItem
                                                                value={option}
                                                                key={index}
                                                            >
                                                                {option}
                                                            </MenuItem>
                                                        )
                                                    }
                                                )}
                                            </Select>
                                        )}
                                    />
                                }
                            />
                        </Stack>
                    </Grid>
                    <Grid item xs={12} sm={numberOfColumn}>
                        <Stack direction="column">
                            <FormLabel htmlFor="living">Living:</FormLabel>
                            <FormControlLabel
                                style={{
                                    paddingLeft: '12px',
                                }}
                                control={
                                    <Controller
                                        {...register('living')}
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                labelId="living"
                                                id="demo-simple-select"
                                                fullWidth
                                                margin="normal"
                                                size="small"
                                                {...field}
                                                defaultValue={[]}
                                                multiple={true}
                                            >
                                                {avaliableOptions?.living?.map(
                                                    (option, index) => {
                                                        return (
                                                            <MenuItem
                                                                value={option}
                                                                key={index}
                                                            >
                                                                {option}
                                                            </MenuItem>
                                                        )
                                                    }
                                                )}
                                            </Select>
                                        )}
                                    />
                                }
                            />
                        </Stack>
                    </Grid>
                    <Grid item xs={12} sm={numberOfColumn}>
                        <Stack direction="column">
                            <FormLabel htmlFor="ethnicity">
                                Ethnicity:
                            </FormLabel>
                            <FormControlLabel
                                style={{
                                    paddingLeft: '12px',
                                }}
                                control={
                                    <Controller
                                        {...register('ethnicity')}
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                labelId="ethnicity"
                                                id="demo-simple-select"
                                                fullWidth
                                                margin="normal"
                                                size="small"
                                                {...field}
                                                defaultValue={[]}
                                                multiple={true}
                                            >
                                                {avaliableOptions?.ethnicity?.map(
                                                    (option, index) => {
                                                        return (
                                                            <MenuItem
                                                                value={option}
                                                                key={index}
                                                            >
                                                                {option}
                                                            </MenuItem>
                                                        )
                                                    }
                                                )}
                                            </Select>
                                        )}
                                    />
                                }
                            />
                        </Stack>
                    </Grid>
                    <Grid item xs={12} sm={numberOfColumn}>
                        <Stack direction="column">
                            <FormLabel htmlFor="religion">Religion:</FormLabel>
                            <FormControlLabel
                                style={{
                                    paddingLeft: '12px',
                                }}
                                control={
                                    <Controller
                                        {...register('religion')}
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                labelId="religion"
                                                id="demo-simple-select"
                                                fullWidth
                                                margin="normal"
                                                size="small"
                                                {...field}
                                                defaultValue={[]}
                                                multiple={true}
                                            >
                                                {avaliableOptions?.religion?.map(
                                                    (option, index) => {
                                                        return (
                                                            <MenuItem
                                                                value={option}
                                                                key={index}
                                                            >
                                                                {option}
                                                            </MenuItem>
                                                        )
                                                    }
                                                )}
                                            </Select>
                                        )}
                                    />
                                }
                            />
                        </Stack>
                    </Grid>
                    <Grid item xs={12} sm={numberOfColumn}>
                        <Stack direction="column">
                            <FormLabel htmlFor="politics">Politics:</FormLabel>
                            <FormControlLabel
                                style={{
                                    paddingLeft: '12px',
                                }}
                                control={
                                    <Controller
                                        {...register('politics')}
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                labelId="politics"
                                                id="demo-simple-select"
                                                fullWidth
                                                margin="normal"
                                                size="small"
                                                {...field}
                                                defaultValue={[]}
                                                multiple={true}
                                            >
                                                {avaliableOptions?.politics?.map(
                                                    (option, index) => {
                                                        return (
                                                            <MenuItem
                                                                value={option}
                                                                key={index}
                                                            >
                                                                {option}
                                                            </MenuItem>
                                                        )
                                                    }
                                                )}
                                            </Select>
                                        )}
                                    />
                                }
                            />
                        </Stack>
                    </Grid>
                    <Grid item xs={12} sm={numberOfColumn}>
                        <Stack direction="column">
                            <FormLabel htmlFor="values">Values:</FormLabel>
                            <FormControlLabel
                                style={{
                                    paddingLeft: '12px',
                                }}
                                control={
                                    <Controller
                                        {...register('values')}
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                labelId="values"
                                                id="demo-simple-select"
                                                fullWidth
                                                margin="normal"
                                                size="small"
                                                {...field}
                                                defaultValue={[]}
                                                multiple={true}
                                            >
                                                {avaliableOptions?.values?.map(
                                                    (option, index) => {
                                                        return (
                                                            <MenuItem
                                                                value={option}
                                                                key={index}
                                                            >
                                                                {option}
                                                            </MenuItem>
                                                        )
                                                    }
                                                )}
                                            </Select>
                                        )}
                                    />
                                }
                            />
                        </Stack>
                    </Grid>
                    <Grid item xs={12} sm={numberOfColumn}>
                        <Stack direction="column">
                            <FormLabel htmlFor="drinking">Drinking:</FormLabel>
                            <FormControlLabel
                                style={{
                                    paddingLeft: '12px',
                                }}
                                control={
                                    <Controller
                                        {...register('drinking')}
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                labelId="drinking"
                                                id="demo-simple-select"
                                                fullWidth
                                                margin="normal"
                                                size="small"
                                                {...field}
                                                defaultValue={[]}
                                                multiple={true}
                                            >
                                                {avaliableOptions?.drinking?.map(
                                                    (option, index) => {
                                                        return (
                                                            <MenuItem
                                                                value={option}
                                                                key={index}
                                                            >
                                                                {option}
                                                            </MenuItem>
                                                        )
                                                    }
                                                )}
                                            </Select>
                                        )}
                                    />
                                }
                            />
                        </Stack>
                    </Grid>
                    <Grid item xs={12} sm={numberOfColumn}>
                        <Stack direction="column">
                            <FormLabel htmlFor="smoking">Smoking:</FormLabel>
                            <FormControlLabel
                                style={{
                                    paddingLeft: '12px',
                                }}
                                control={
                                    <Controller
                                        {...register('smoking')}
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                labelId="smoking"
                                                id="demo-simple-select"
                                                fullWidth
                                                margin="normal"
                                                size="small"
                                                {...field}
                                                defaultValue={[]}
                                                multiple={true}
                                            >
                                                {avaliableOptions?.smoking?.map(
                                                    (option, index) => {
                                                        return (
                                                            <MenuItem
                                                                value={option}
                                                                key={index}
                                                            >
                                                                {option}
                                                            </MenuItem>
                                                        )
                                                    }
                                                )}
                                            </Select>
                                        )}
                                    />
                                }
                            />
                        </Stack>
                    </Grid>
                    <Grid item xs={12} sm={numberOfColumn}>
                        <Stack direction="column">
                            <FormLabel htmlFor="exercise">Exercise:</FormLabel>
                            <FormControlLabel
                                style={{
                                    paddingLeft: '12px',
                                }}
                                control={
                                    <Controller
                                        {...register('exercise')}
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                labelId="exercise"
                                                id="demo-simple-select"
                                                fullWidth
                                                margin="normal"
                                                size="small"
                                                {...field}
                                                defaultValue={[]}
                                                multiple={true}
                                            >
                                                {avaliableOptions?.exercise?.map(
                                                    (option, index) => {
                                                        return (
                                                            <MenuItem
                                                                value={option}
                                                                key={index}
                                                            >
                                                                {option}
                                                            </MenuItem>
                                                        )
                                                    }
                                                )}
                                            </Select>
                                        )}
                                    />
                                }
                            />
                        </Stack>
                    </Grid>
                    <Grid item xs={12} sm={numberOfColumn}>
                        <Stack direction="column">
                            <FormLabel htmlFor="diet">Diet:</FormLabel>
                            <FormControlLabel
                                style={{
                                    paddingLeft: '12px',
                                }}
                                control={
                                    <Controller
                                        {...register('diet')}
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                labelId="diet"
                                                id="demo-simple-select"
                                                fullWidth
                                                margin="normal"
                                                size="small"
                                                {...field}
                                                defaultValue={[]}
                                                multiple={true}
                                            >
                                                {avaliableOptions?.diet?.map(
                                                    (option, index) => {
                                                        return (
                                                            <MenuItem
                                                                value={option}
                                                                key={index}
                                                            >
                                                                {option}
                                                            </MenuItem>
                                                        )
                                                    }
                                                )}
                                            </Select>
                                        )}
                                    />
                                }
                            />
                        </Stack>
                    </Grid>
                    <Grid item xs={12} sm={numberOfColumn}>
                        <Stack direction="column">
                            <FormLabel htmlFor="pet">Pet:</FormLabel>
                            <FormControlLabel
                                style={{
                                    paddingLeft: '12px',
                                }}
                                control={
                                    <Controller
                                        {...register('pet')}
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                labelId="pet"
                                                id="demo-simple-select"
                                                fullWidth
                                                margin="normal"
                                                size="small"
                                                {...field}
                                                defaultValue={[]}
                                                multiple={true}
                                            >
                                                {avaliableOptions?.pet?.map(
                                                    (option, index) => {
                                                        return (
                                                            <MenuItem
                                                                value={option}
                                                                key={index}
                                                            >
                                                                {option}
                                                            </MenuItem>
                                                        )
                                                    }
                                                )}
                                            </Select>
                                        )}
                                    />
                                }
                            />
                        </Stack>
                    </Grid>
                    <Grid item xs={12} sm={numberOfColumn}>
                        <Stack direction="column">
                            <FormLabel htmlFor="likes">Likes:</FormLabel>
                            <FormControlLabel
                                style={{
                                    paddingLeft: '12px',
                                }}
                                control={
                                    <Controller
                                        {...register('likes')}
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                labelId="likes"
                                                id="demo-simple-select"
                                                fullWidth
                                                margin="normal"
                                                size="small"
                                                {...field}
                                                defaultValue={[]}
                                                multiple={true}
                                            >
                                                {avaliableOptions?.likes?.map(
                                                    (option, index) => {
                                                        return (
                                                            <MenuItem
                                                                value={option}
                                                                key={index}
                                                            >
                                                                {option}
                                                            </MenuItem>
                                                        )
                                                    }
                                                )}
                                            </Select>
                                        )}
                                    />
                                }
                            />
                        </Stack>
                    </Grid>
                    <Grid item xs={12} sm={numberOfColumn}>
                        <Stack direction="column">
                            <FormLabel htmlFor="dislikes">Dislikes:</FormLabel>
                            <FormControlLabel
                                style={{
                                    paddingLeft: '12px',
                                }}
                                control={
                                    <Controller
                                        {...register('dislikes')}
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                labelId="dislikes"
                                                id="demo-simple-select"
                                                fullWidth
                                                margin="normal"
                                                size="small"
                                                {...field}
                                                defaultValue={[]}
                                                multiple={true}
                                            >
                                                {avaliableOptions?.dislikes?.map(
                                                    (option, index) => {
                                                        return (
                                                            <MenuItem
                                                                value={option}
                                                                key={index}
                                                            >
                                                                {option}
                                                            </MenuItem>
                                                        )
                                                    }
                                                )}
                                            </Select>
                                        )}
                                    />
                                }
                            />
                        </Stack>
                    </Grid>
                    <Grid item xs={12} sm={numberOfColumn}>
                        <Stack direction="column">
                            <FormLabel htmlFor="marriage">Marriage:</FormLabel>
                            <FormControlLabel
                                style={{
                                    paddingLeft: '12px',
                                }}
                                control={
                                    <Controller
                                        {...register('marriage')}
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                labelId="marriage"
                                                id="demo-simple-select"
                                                fullWidth
                                                margin="normal"
                                                size="small"
                                                {...field}
                                                defaultValue={[]}
                                                multiple={true}
                                            >
                                                {avaliableOptions?.marriage?.map(
                                                    (option, index) => {
                                                        return (
                                                            <MenuItem
                                                                value={option}
                                                                key={index}
                                                            >
                                                                {option}
                                                            </MenuItem>
                                                        )
                                                    }
                                                )}
                                            </Select>
                                        )}
                                    />
                                }
                            />
                        </Stack>
                    </Grid>
                    <Grid item xs={12} sm={numberOfColumn}>
                        <Stack direction="column">
                            <FormLabel htmlFor="children">Children:</FormLabel>
                            <FormControlLabel
                                style={{
                                    paddingLeft: '12px',
                                }}
                                control={
                                    <Controller
                                        {...register('children')}
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                labelId="children"
                                                id="demo-simple-select"
                                                fullWidth
                                                margin="normal"
                                                size="small"
                                                {...field}
                                                defaultValue={[]}
                                                multiple={true}
                                            >
                                                {avaliableOptions?.children?.map(
                                                    (option, index) => {
                                                        return (
                                                            <MenuItem
                                                                value={option}
                                                                key={index}
                                                            >
                                                                {option}
                                                            </MenuItem>
                                                        )
                                                    }
                                                )}
                                            </Select>
                                        )}
                                    />
                                }
                            />
                        </Stack>
                    </Grid>
                    <Grid item xs={12} sm={numberOfColumn}>
                        <Stack direction="column">
                            <FormLabel htmlFor="lookingFor">
                                Looking For:
                            </FormLabel>
                            <FormControlLabel
                                style={{
                                    paddingLeft: '12px',
                                }}
                                control={
                                    <Controller
                                        {...register('lookingFor')}
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                labelId="lookingFor"
                                                id="demo-simple-select"
                                                fullWidth
                                                margin="normal"
                                                size="small"
                                                {...field}
                                                defaultValue={[]}
                                                multiple={true}
                                            >
                                                {avaliableOptions?.lookingFor?.map(
                                                    (option, index) => {
                                                        return (
                                                            <MenuItem
                                                                value={option}
                                                                key={index}
                                                            >
                                                                {option}
                                                            </MenuItem>
                                                        )
                                                    }
                                                )}
                                            </Select>
                                        )}
                                    />
                                }
                            />
                        </Stack>
                    </Grid>
                    <Grid item xs={12} sm={numberOfColumn}>
                        <Stack direction="column">
                            <FormLabel htmlFor="attachmentStyle">
                                Attachment Style:
                            </FormLabel>
                            <FormControlLabel
                                style={{
                                    paddingLeft: '12px',
                                }}
                                control={
                                    <Controller
                                        {...register('attachmentStyle')}
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                labelId="attachmentStyle"
                                                id="demo-simple-select"
                                                fullWidth
                                                margin="normal"
                                                size="small"
                                                {...field}
                                                defaultValue={[]}
                                                multiple={true}
                                            >
                                                {avaliableOptions?.attachmentStyle?.map(
                                                    (option, index) => {
                                                        return (
                                                            <MenuItem
                                                                value={option}
                                                                key={index}
                                                            >
                                                                {option}
                                                            </MenuItem>
                                                        )
                                                    }
                                                )}
                                            </Select>
                                        )}
                                    />
                                }
                            />
                        </Stack>
                    </Grid>
                    <Grid item xs={12} sm={numberOfColumn}>
                        <Stack direction="column">
                            <FormLabel htmlFor="vaccinationStatus">
                                Vaccination Status:
                            </FormLabel>
                            <FormControlLabel
                                style={{
                                    paddingLeft: '12px',
                                }}
                                control={
                                    <Controller
                                        {...register('vaccinationStatus')}
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                labelId="vaccinationStatus"
                                                id="demo-simple-select"
                                                fullWidth
                                                margin="normal"
                                                size="small"
                                                {...field}
                                                defaultValue={[]}
                                                multiple={true}
                                            >
                                                {avaliableOptions?.vaccinationStatus?.map(
                                                    (option, index) => {
                                                        return (
                                                            <MenuItem
                                                                value={option}
                                                                key={index}
                                                            >
                                                                {option}
                                                            </MenuItem>
                                                        )
                                                    }
                                                )}
                                            </Select>
                                        )}
                                    />
                                }
                            />
                        </Stack>
                    </Grid>
                    <Grid item xs={12} sm={numberOfColumn}>
                        <FormControl fullWidth>
                            <FormLabel htmlFor="ageFrom">Age From:</FormLabel>
                            <Controller
                                name="ageFrom"
                                control={control}
                                // rules={{ required: true }}
                                render={({ field }) => (
                                    <TextField
                                        value={field?.value}
                                        size="small"
                                        type="number"
                                        // label='Last Name'
                                        onChange={field.onChange}
                                        error={Boolean(errors.firstName)}
                                        aria-describedby="lastName"
                                    />
                                )}
                            />
                            {errors.age && (
                                <FormHelperText
                                    sx={{ color: 'error.main' }}
                                    id="validation-basic-email"
                                >
                                    {errors?.age?.message}
                                </FormHelperText>
                            )}
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={numberOfColumn}>
                        <FormControl fullWidth>
                            <FormLabel htmlFor="ageTo">Age To:</FormLabel>
                            <Controller
                                name="ageTo"
                                control={control}
                                // rules={{ required: true }}
                                render={({ field }) => (
                                    <TextField
                                        value={field?.value}
                                        size="small"
                                        type="number"
                                        // label='Last Name'
                                        onChange={field.onChange}
                                        error={Boolean(errors.firstName)}
                                        aria-describedby="lastName"
                                    />
                                )}
                            />
                            {errors.age && (
                                <FormHelperText
                                    sx={{ color: 'error.main' }}
                                    id="validation-basic-email"
                                >
                                    {errors?.age?.message}
                                </FormHelperText>
                            )}
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={numberOfColumn}>
                        <Stack direction="column">
                            <FormLabel htmlFor="status">Status:</FormLabel>
                            <FormControlLabel
                                style={{
                                    paddingLeft: '12px',
                                }}
                                control={
                                    <Controller
                                        {...register('status')}
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                labelId="status"
                                                id="demo-simple-select"
                                                fullWidth
                                                margin="normal"
                                                size="small"
                                                {...field}
                                                defaultValue={[]}
                                                multiple={true}
                                            >
                                                {avaliableOptions?.status?.optionList?.map(
                                                    (option, index) => {
                                                        return (
                                                            <MenuItem
                                                                value={
                                                                    option?.name
                                                                }
                                                                key={index}
                                                            >
                                                                {option?.name}
                                                            </MenuItem>
                                                        )
                                                    }
                                                )}
                                            </Select>
                                        )}
                                    />
                                }
                            />
                        </Stack>
                    </Grid>
                    <Grid item xs={2} style={{ paddingTop: '30px' }}>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Controller
                                        {...register('matchMakeMe')}
                                        control={control}
                                        render={({ field }) => (
                                            <Checkbox
                                                {...field}
                                                checked={field?.value || false}
                                                onChange={(e) =>
                                                    field.onChange(
                                                        e.target.checked
                                                    )
                                                }
                                            />
                                        )}
                                    />
                                }
                                label="MatchmakeMe Users?"
                            />
                        </FormGroup>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default UserPersonal
