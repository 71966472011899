import { api_instance_modal } from "./Base_Api";
export const get_notification = async (params) => {
  try {
    const { data } = await api_instance_modal.get("/notifications");

    return data.data.notifications;
  } catch (error) {}
};
export const clear_notification = async () => {
  try {
    const { data } = await api_instance_modal.put("/notifications/clear");

    return data;
  } catch (error) {}
};
export const send_now_promotion = async () => {
  try {
    const { data } = await api_instance_modal.post(
      "/admin/notifications/send/promotion"
    );
    return data;
  } catch (error) {}
};
