import { Button, Divider, Menu, MenuItem, Typography } from '@material-ui/core'
import React from 'react'
import more from '../../asset/img/More horizontal.png'
import VeiwProfile from './UserProfilePopUp/ViewProfile/ViewProfile'
import { useHistory } from 'react-router-dom'

const RowAction = ({
    setDeleteMutation,
    value,
    zodiacDropdownOptions = [],
    sleepingHabbitsDropdownOptions = [],
    drinkingDropdownOptions = [],
    smokingDropdownOptions = [],
    relocateForLoveDropdownOptions = [],
    personalityDropdownValues = [],
    industryDropdownOptions = [],
    educationDropdownOptions = [],
    salaryDropdownOptions = [],
    lookingForDropdownOptions = [],
}) => {
    console.log('salaryDropdownOptions11', smokingDropdownOptions)
    const [anchorEl, setAnchorEl] = React.useState(null)
    const history = useHistory()
    const [openForceDeletePopUp, setOpenForceDeletePopUp] =
        React.useState(false)
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
        setOpenForceDeletePopUp(false)
    }
    return (
        <>
            {lookingForDropdownOptions && (
                <VeiwProfile
                    setDeleteMutation={setDeleteMutation}
                    isUser={true}
                    data={{ user: value.row }}
                    zodiacDropdownOptions={zodiacDropdownOptions}
                    sleepingHabbitsDropdownOptions={
                        sleepingHabbitsDropdownOptions
                    }
                    drinkingDropdownOptions={drinkingDropdownOptions}
                    smokingDropdownOptions={smokingDropdownOptions}
                    relocateForLoveDropdownOptions={
                        relocateForLoveDropdownOptions
                    }
                    personalityDropdownValues={personalityDropdownValues}
                    industryDropdownOptions={industryDropdownOptions}
                    educationDropdownOptions={educationDropdownOptions}
                    salaryDropdownOptions={salaryDropdownOptions}
                    lookingForDropdownOptions={lookingForDropdownOptions}
                />
            )}
        </>
    )
}

export default RowAction
