import {
  Button,
  CircularProgress,
  Grid,
  InputAdornment,
  Typography,
} from "@material-ui/core";
import { Visibility } from "@material-ui/icons";
import { Form, Formik } from "formik";
import React, { useState } from "react";
// eslint-disable-next-line
import ReCAPTCHA from "react-google-recaptcha";
import { connect } from "react-redux";
import { setLogin } from "../../api/Login_Api";
import Logo from "../../asset/img/logo.svg";
import { TextFieldWrapper } from "../../components/Textfields";
import { styles } from "../../styles/styles";
import { validation } from "../../validations/login_validation";
const Login = ({ loading }) => {
  const [showPass, setShowPass] = useState(true);
  let { login } = styles();
  return (
    <Formik
      initialValues={{ email: "", password: "" }}
      validationSchema={validation}
      onSubmit={(value) => {
        setLogin(value);
      }}
      validateOnMount
    >
      {(formik) => {
        return (
          <Form>
            <Grid
              item
              container
              justifyContent="center"
              alignItems="flex-start"
              style={{ backgroundColor: "#050B7A", minHeight: "100vh" }}
            >
              <Grid
                item
                container
                xs={10}
                md={7}
                lg={6}
                xl={4}
                spacing={3}
                style={{ margin: "auto" }}
                justifyContent="center"
                alignItems="center"
              >
                <Grid item container justifyContent="center">
                  <img src={Logo} alt="" />
                </Grid>
                <Grid
                  item
                  container
                  spacing={4}
                  style={{ backgroundColor: "#050B7A" }}
                  justifyContent="center"
                  className={`${login}`}
                >
                  <Grid item>
                    <Typography
                      style={{ color: "#fff", fontSize: 72, fontWeight: 800 }}
                      variant="h1"
                    >
                      Log in to REDDI
                    </Typography>
                  </Grid>
                  <Grid item container xs={10}>
                    <Typography style={{ color: "#fff" }} variant="subtitle1">
                      Email
                    </Typography>
                    <TextFieldWrapper name="email" variant="filled" />
                  </Grid>
                  <Grid item container xs={10}>
                    <Typography style={{ color: "#fff" }} variant="subtitle1">
                      Password
                    </Typography>
                    <TextFieldWrapper
                      name="password"
                      variant="filled"
                      type={showPass ? "password" : null}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start">
                            <Visibility
                              color="primary"
                              fontSize="small"
                              onClick={() => setShowPass(!showPass)}
                              style={{ cursor: "pointer", color: "white" }}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  {/* <Grid item container xs={10}>
                    <ReCAPTCHA
                      theme="dark"
                      sitekey={"process.env.REACT_APP_CAPTCHA_SATE_KEY"}
                    />
                  </Grid> */}
                  <Grid item container xs={10}>
                    <Button
                      variant="contained"
                      fullWidth
                      style={{ borderRadius: 50 }}
                      color="primary"
                      size="large"
                      type="submit"
                      disabled={!formik.isValid || loading}
                    >
                      {loading ? (
                        <CircularProgress
                          style={{ height: 30, width: 30, color: "white" }}
                        />
                      ) : (
                        "  Log In"
                      )}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};
const state = (props) => {
  const { GeneralReducer } = props;
  const { loading } = GeneralReducer;
  return { loading };
};
export default connect(state)(Login);
