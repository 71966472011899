import {
    Avatar,
    Button,
    Divider,
    Grid,
    LinearProgress,
    Typography,
} from '@material-ui/core'

import { TextField } from '@mui/material'
import { DataGrid, GridOverlay } from '@material-ui/data-grid'
import { format } from 'date-fns'
import React, { useState } from 'react'
import { styles } from '../../../../styles/styles'
import { CircularProgress, Stack } from '@mui/material'
import { useParams } from 'react-router-dom'
import { get_quiz_list } from '../../../../../src/api/Quiz_Module_Api'
import { PackageEditIcon } from '../../../../icons/PackageEditIcon'
import { Add } from '@material-ui/icons'
import Pagination from './Pagination'
import sorter from '../../../../asset/img/sorter.png'
import RowActionButton from './RowActionButton'
const QuizList = () => {
    const params = useParams()
    const [initialLoading, setInitialLoading] = useState(true)
    const style = styles()
    const [data, set_Data] = useState([])
    const [loadingData, setLoadingData] = useState(false)
    const [paginateObj, setPaginateObj] = useState({ page: 1, totalPages: 1 })
    const [searchText, setSearchtext] = useState('')
    const [searchQuery, setSearchQuery] = useState('')
    const [sortOrder, setSortOrder] = useState({
        order: -1,
        column: 'createdAt',
    })
    // Debounce
    React.useEffect(() => {
        if (initialLoading === false) {
            setLoadingData(true)
            set_Data([])
        }

        // sortOrder.column,
        // sortOrder.order
        get_quiz_list(
            paginateObj.page,
            searchText,
            sortOrder.column,
            sortOrder.order
        )
            .then((res) => {
                console.log('err_res', res?.data?.data)
                set_Data(res?.data?.data?.result)
                setPaginateObj({
                    ...paginateObj,
                    totalPages: res?.data?.data?.totalPages,
                })
                setInitialLoading(false)
                setLoadingData(false)
            })
            .catch((err) => {
                console.log('err', err)
                setInitialLoading(false)
                setLoadingData(false)
            })
    }, [paginateObj.page, searchText, sortOrder])

    React.useEffect(() => {
        const timeOutId = setTimeout(() => setSearchtext(searchQuery), 500)
        return () => {
            clearTimeout(timeOutId)
        }
    }, [searchQuery])
    const header_list = [
        {
            field: 'Quiz Title',
            headerName: (
                <Stack
                    direction="column"
                    justifyContent={'space-around'}
                    alignItems="left"
                >
                    <Typography
                        style={{
                            opacity: 1,
                            color: 'black',
                        }}
                        variant="body1"
                    >
                        Title
                    </Typography>
                </Stack>
            ),
            minWidth: 400,
            sortable: false,
            flex: 1,
            headerAlign: 'left',
            align: 'left',

            renderCell: (value) => {
                return (
                    <Grid
                        container
                        alignItems="center"
                        justifyContent="flex-start"
                    >
                        <Typography
                            style={{
                                opacity: 1,
                                fontSize: '14px',
                                // color: 'black',
                                marginLeft: '7px',
                            }}
                            variant="body1"
                        >
                            {value?.row?.title}
                        </Typography>
                    </Grid>
                )
            },
        },

        {
            field: 'Quiz Status',
            headerName: (
                <Stack
                    direction="column"
                    justifyContent={'space-around'}
                    alignItems="center"
                >
                    <Typography
                        style={{
                            opacity: 1,
                            color: 'black',
                        }}
                        variant="body1"
                    >
                        Status
                    </Typography>
                </Stack>
            ),
            minWidth: 40,
            sortable: false,
            // flex: 1,
            headerAlign: 'left',
            align: 'left',

            renderCell: (value) => (
                <Stack>
                    {
                        <Typography
                            variant="body1"
                            style={{
                                opacity: 1,
                                fontSize: '14px',
                                // color: 'black',
                                marginLeft: '6px',
                            }}
                        >
                            {value?.row?.active_status ? 'Active' : 'In-active'}
                        </Typography>
                    }
                </Stack>
            ),
        },
        {
            field: 'attempted_users',
            headerName: (
                <Stack
                    direction="column"
                    justifyContent={'space-around'}
                    alignItems="center"
                >
                    <Typography
                        style={{
                            opacity: 1,
                            color: 'black',
                        }}
                        variant="body1"
                    >
                        Quiz Attempted Users
                    </Typography>
                </Stack>
            ),
            minWidth: 60,
            sortable: false,
            flex: 1,
            headerAlign: 'center',
            align: 'center',

            renderCell: (value) => (
                <Stack>
                    {
                        <Typography
                            variant="body1"
                            style={{
                                opacity: 1,
                                fontSize: '14px',
                                textAlign: 'center',
                                // width: '100%',
                                // color: 'black',
                            }}
                        >
                            {value?.row?.totalUsersAttemptedThisQuiz ?? 0}
                        </Typography>
                    }
                </Stack>
            ),
        },
        {
            field: 'createdAt',
            headerName: (
                <Stack
                    direction="column"
                    justifyContent={'space-around'}
                    alignItems="left"
                >
                    <Typography
                        style={{
                            opacity: 1,
                            color: 'black',
                        }}
                        variant="body1"
                    >
                        Created Time
                    </Typography>
                </Stack>
            ),
            minWidth: 100,
            flex: 1,
            sortable: true,
            headerAlign: 'left',
            align: 'left',

            renderCell: (value) => (
                <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Grid container alignItems="center">
                        <Grid container alignItems="center">
                            <Typography
                                style={{
                                    opacity: 1,
                                    fontSize: '14px',
                                    // color: 'black',
                                    marginLeft: '6px',
                                }}
                                variant="body1"
                            >
                                {value?.row?.createdAt
                                    ? format(
                                          new Date(
                                              value?.row?.createdAt
                                                  ? value?.row?.createdAt
                                                  : 'NA'
                                          ),
                                          'dd LLL uuuu HH:mm:ss'
                                      )
                                    : '-'}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            ),
        },
        {
            field: 'action',
            headerName: 'Action',
            minWidth: 60,
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            sortable: false,
            renderCell: (value) => (
                <Grid item>
                    <RowActionButton value={value} set_Data={null} />
                </Grid>
            ),
        },
    ]

    return initialLoading ? (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh', // Adjust this as needed
            }}
        >
            <CircularProgress />
        </div>
    ) : (
        <>
            <Grid xs={12} item>
                <Stack
                    direction="row"
                    width="100%"
                    justifyContent={'space-between'}
                >
                    <Typography variant="h5">Manage Quiz</Typography>
                    <Button
                        onClick={() => {
                            const win = window.open(`/quiz/add`)
                        }}
                        startIcon={<Add />}
                        style={{
                            padding: '8px 16px 8px 12px',
                            marginRight: '10px',
                            border: '2px solid #65819D',
                            borderRadius: '16px',
                            fontWeight: 600,
                            fontSize: '16px',

                            color: '#65819D',
                        }}
                    >
                        Add Quiz
                    </Button>
                </Stack>
            </Grid>
            <Grid xs={12}>
                <Grid xs={4} justifyContent="end">
                    <TextField
                        style={{
                            backgroundColor: 'white',
                            borderRadius: 12,
                            border: '3px solid #E6E6E6',
                        }}
                        value={searchQuery}
                        onChange={(event) => {
                            setSearchQuery(event.target.value)
                        }}
                        id="search_hub_tex"
                        placeholder="Search"
                        fullWidth
                        margin="normal"
                        size="small"
                    />
                </Grid>
            </Grid>
            <Grid item style={{ marginBottom: '20px' }} xs={12}>
                <Divider />
            </Grid>

            <Grid
                item
                xs={12}
                className={style.box}
                container
                style={{
                    height: 'calc(100% - 180px)',
                    overflow: 'auto',
                }}
            >
                <DataGrid
                    columns={header_list}
                    autore
                    rows={data}
                    loading={loadingData}
                    disableColumnMenu
                    noda
                    getRowId={(row) => row._id}
                    hideFooter
                    style={{ border: 'none', fontSize: 14 }}
                    classes={{ cell: style.DataGridHeader }}
                    headerHeight={60}
                    rowHeight={90}
                    disableSelectionOnClick
                    disableColumnSelector
                    onColumnHeaderClick={(params) => {
                        if (params.field === 'createdAt') {
                            setSortOrder({
                                column: params.field,
                                order: sortOrder.order === -1 ? 1 : -1,
                            })
                        }
                    }}
                    components={{
                        ColumnSortedAscendingIcon: CustomSort,
                        ColumnSortedDescendingIcon: CustomSort,
                        LoadingOverlay: CustomLoadingOverlay,
                        NoRowsOverlay: NoRowsOverlay,
                    }}
                />
            </Grid>
            {paginateObj ? (
                paginateObj?.totalPages ? (
                    initialLoading ? null : (
                        <Grid item xs={12} style={{ marginTop: '15px' }}>
                            <Pagination
                                currentPage={paginateObj.page}
                                totalPages={paginateObj.totalPages}
                                onPageChange={(_, value) => {
                                    setPaginateObj({
                                        page: value,
                                        totalPages: paginateObj.totalPages,
                                    })
                                }}
                            />
                        </Grid>
                    )
                ) : null
            ) : null}
        </>
    )
}

export default QuizList
export function CustomLoadingOverlay() {
    return (
        <GridOverlay>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh', // Adjust this as needed
                }}
            >
                <CircularProgress />
            </div>
        </GridOverlay>
    )
}

export const NoRowsOverlay = () => {
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
                width: '100%',
            }}
        >
            <p>No data found</p>
        </div>
    )
}
export const CustomSort = () => {
    return (
        <img
            src={sorter}
            alt=""
            width={11}
            height={25}
            style={{ position: 'relative', bottom: 3 }}
        />
    )
}
