import { Typography } from '@material-ui/core'
import { FormLabel, Grid, MenuItem, Select, Stack } from '@mui/material'
import React from 'react'

const Optional = ({ item2, matchMakeMeDataObj, handleChangeQuestionnair }) => {
    console.log('matchMakeMeDataObj', matchMakeMeDataObj)
    return (
        <Grid container item xs={6}>
            <Grid item xs={12}>
                <Stack direction="column">
                    <FormLabel
                        style={{
                            marginBottom: '5px',
                        }}
                        htmlFor="first-name"
                    >
                        {/* {item2?.sequence}. */}
                        {item2?.question_title}
                    </FormLabel>

                    <Select
                        fullWidth
                        multiple={item2?.multiple_choice_answer}
                        margin="normal"
                        size="small"
                        value={
                            matchMakeMeDataObj[item2?._id]
                                ? matchMakeMeDataObj[item2?._id]
                                : []
                        }
                        onChange={(event) => {
                            // const id = event.target.name
                            const value = event.target.value
                            handleChangeQuestionnair(item2?._id, value)
                        }}
                        labelId="demo-simple-select-label"
                        label="question"
                        id="demo-simple-select"
                    >
                        {item2?.question_option_list?.map((option, index) => {
                            return (
                                <MenuItem value={option} key={index}>
                                    {option}
                                </MenuItem>
                            )
                        })}
                    </Select>
                    {item2?.question_subtext ? (
                        <Typography
                            variant="body1"
                            style={{ fontSize: 14, marginTop: '4px' }}
                            color="textSecondary"
                        >
                            <b>Note</b>: <i>{item2?.question_subtext}</i>
                        </Typography>
                    ) : (
                        ''
                    )}
                </Stack>
            </Grid>
        </Grid>
    )
}

export default Optional
