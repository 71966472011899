import { useState, useEffect } from 'react'
import {
    Grid,
    Typography,
    Accordion,
    AccordionDetails,
    AccordionSummary,
} from '@material-ui/core'
import {
    TextField,
    InputLabel,
    Checkbox,
    FormGroup,
    FormControlLabel,
} from '@mui/material'
import { GridExpandMoreIcon } from '@mui/x-data-grid'
import { styles } from '../../../styles/styles'
import { Controller } from 'react-hook-form'

const NotificationPreferences = ({ register, control }) => {
    const style = styles()
    return (
        <Grid spacing={2} className={style.box}>
            <Accordion style={{ boxShadow: 'none' }}>
                <AccordionSummary
                    expandIcon={<GridExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-notification-info--questionnair"
                >
                    <Typography
                        style={{
                            fontSize: '1.2rem',
                            textDecoration: 'underline',
                        }}
                    >
                        Notification Preferences
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={2}>
                        <Grid
                            item
                            xs={2}

                            // style={{ paddingTop: '30px' }}
                        >
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Controller
                                            {...register(
                                                'notificationSetting.isNewLike'
                                            )}
                                            control={control}
                                            render={({ field }) => (
                                                <Checkbox
                                                    {...field}
                                                    checked={
                                                        field?.value || false
                                                    }
                                                    onChange={(e) =>
                                                        field.onChange(
                                                            e.target.checked
                                                        )
                                                    }
                                                />
                                            )}
                                        />
                                    }
                                    label="New Like"
                                />
                            </FormGroup>
                        </Grid>
                        <Grid
                            item
                            xs={2}
                            // style={{ paddingTop: '30px' }}
                        >
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Controller
                                            {...register(
                                                'notificationSetting.isNewMatch'
                                            )}
                                            control={control}
                                            render={({ field }) => (
                                                <Checkbox
                                                    {...field}
                                                    checked={
                                                        field?.value || false
                                                    }
                                                    onChange={(e) =>
                                                        field.onChange(
                                                            e.target.checked
                                                        )
                                                    }
                                                />
                                            )}
                                        />
                                    }
                                    label="New Match"
                                />
                            </FormGroup>
                        </Grid>
                        <Grid
                            item
                            xs={2}
                            // style={{ paddingTop: '30px' }}
                        >
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Controller
                                            {...register(
                                                'notificationSetting.isNewMessage'
                                            )}
                                            control={control}
                                            render={({ field }) => (
                                                <Checkbox
                                                    {...field}
                                                    checked={
                                                        field?.value || false
                                                    }
                                                    onChange={(e) =>
                                                        field.onChange(
                                                            e.target.checked
                                                        )
                                                    }
                                                />
                                            )}
                                        />
                                    }
                                    label="New Message"
                                />
                            </FormGroup>
                        </Grid>
                        <Grid
                            item
                            xs={2}
                            // style={{ paddingTop: '30px' }}
                        >
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Controller
                                            {...register(
                                                'notificationSetting.isPromotion'
                                            )}
                                            control={control}
                                            render={({ field }) => (
                                                <Checkbox
                                                    {...field}
                                                    checked={
                                                        field?.value || false
                                                    }
                                                    onChange={(e) =>
                                                        field.onChange(
                                                            e.target.checked
                                                        )
                                                    }
                                                />
                                            )}
                                        />
                                    }
                                    label="Promotion"
                                />
                            </FormGroup>
                        </Grid>
                        <Grid
                            item
                            xs={2}
                            // style={{ paddingTop: '30px' }}
                        >
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Controller
                                            {...register(
                                                'notificationSetting.isAnnouncement'
                                            )}
                                            control={control}
                                            render={({ field }) => (
                                                <Checkbox
                                                    {...field}
                                                    checked={
                                                        field?.value || false
                                                    }
                                                    onChange={(e) =>
                                                        field.onChange(
                                                            e.target.checked
                                                        )
                                                    }
                                                />
                                            )}
                                        />
                                    }
                                    label="Announcement"
                                />
                            </FormGroup>
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </Grid>
    )
}

export default NotificationPreferences
