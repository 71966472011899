import React from 'react'

export const PromoCodeHideIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M19.775 22.625L17.5 20.35C16.6833 20.8833 15.8127 21.2917 14.888 21.575C13.9627 21.8583 13 22 12 22C10.6167 22 9.31667 21.7373 8.1 21.212C6.88333 20.6873 5.825 19.975 4.925 19.075C4.025 18.175 3.31267 17.1167 2.788 15.9C2.26267 14.6833 2 13.3833 2 12C2 11 2.14167 10.0373 2.425 9.112C2.70833 8.18733 3.11667 7.31667 3.65 6.5L1.375 4.225L2.8 2.8L21.2 21.2L19.775 22.625ZM12 20C12.7167 20 13.4127 19.9083 14.088 19.725C14.7627 19.5417 15.4167 19.2667 16.05 18.9L5.1 7.95C4.73333 8.58333 4.45833 9.23733 4.275 9.912C4.09167 10.5873 4 11.2833 4 12C4 14.2167 4.77933 16.1043 6.338 17.663C7.896 19.221 9.78333 20 12 20ZM20.35 17.5L18.9 16.05C19.2667 15.4167 19.5417 14.7623 19.725 14.087C19.9083 13.4123 20 12.7167 20 12C20 9.78333 19.221 7.89567 17.663 6.337C16.1043 4.779 14.2167 4 12 4C11.2833 4 10.5877 4.09167 9.913 4.275C9.23767 4.45833 8.58333 4.73333 7.95 5.1L6.5 3.65C7.31667 3.11667 8.18767 2.70833 9.113 2.425C10.0377 2.14167 11 2 12 2C13.3833 2 14.6833 2.26233 15.9 2.787C17.1167 3.31233 18.175 4.025 19.075 4.925C19.975 5.825 20.6873 6.88333 21.212 8.1C21.7373 9.31667 22 10.6167 22 12C22 13 21.8583 13.9623 21.575 14.887C21.2917 15.8123 20.8833 16.6833 20.35 17.5Z" fill="#65819D" />
    </svg>





  )

}