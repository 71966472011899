import {
    Avatar,
    Button,
    Divider,
    Grid,
    LinearProgress,
    Menu,
    MenuItem,
    ListItemIcon,
    Typography,
} from '@material-ui/core'
import { Chip, FormControlLabel, TextField } from '@mui/material'
import { DataGrid, GridOverlay } from '@material-ui/data-grid'
import { format } from 'date-fns'
import Select from '@mui/material/Select'
import React, { useCallback, useState } from 'react'

import { styles } from '../../../styles/styles'
import { CircularProgress, Stack } from '@mui/material'

import { useParams } from 'react-router-dom'
import {
    get_all_dropdowns,
    get_hubs,
} from '../../../../src/api/UserRequest_Api'
import { PackageEditIcon } from '../../../icons/PackageEditIcon'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { Add, KeyboardArrowDown } from '@material-ui/icons'
import Pagination from './Pagination'
import sorter from '../../../asset/img/sorter.png'
import HubTabs from './HubTabs'
import moment from 'moment'
import { Controller } from 'react-hook-form'
import { Done } from '@material-ui/icons'
const BlogList = () => {
    const params = useParams()
    const [initialLoading, setInitialLoading] = useState(true)
    const style = styles()
    const [data, set_Data] = useState([])
    const [loadingData, setLoadingData] = useState(false)
    const [paginateObj, setPaginateObj] = useState({ page: 1, totalPages: 1 })
    const [searchText, setSearchtext] = useState('')
    const [searchQuery, setSearchQuery] = useState('')

    const [tabsDataCount, setTabsDatacount] = useState({
        PUBLISHED: 0,
        DRAFT: 0,
        UNPUBLISHED: 0,
    })
    const [tabInitialValue, setTabInitialValue] = useState('PUBLISHED')
    const [hubTypesDropdown, setHubTypesDropdown] = useState([])
    const [selectedTypesValues, setSelectedTypesValues] = useState(['all'])

    const [anchorElSubsEventType, setAnchorElSubsEventType] =
        React.useState(null)
    const [sortOrder, setSortOrder] = useState({
        order: -1,
        column: 'createdAt',
    })

    const handleClickSubscriptionEventType = (event) => {
        setAnchorElSubsEventType(event.currentTarget)
    }
    const handleCloseSubscriptionEventType = () => {
        setAnchorElSubsEventType(null)
    }
    const tabClickedCallback = () => {
        set_Data([])
    }

    // Debounce
    React.useEffect(() => {
        if (initialLoading === false) {
            setLoadingData(true)
            set_Data([])
        }
        get_hubs(
            paginateObj.page,
            searchText,
            tabInitialValue,
            selectedTypesValues
        )
            .then((res) => {
                set_Data(res?.data?.result)
                setPaginateObj({
                    ...paginateObj,
                    totalPages: res?.data?.totalPages,
                })
                if (res?.data?.groupByStatusOption) {
                    const tabCountObj = {
                        PUBLISHED: 0,
                        UNPUBLISHED: 0,
                        DRAFT: 0,
                    }
                    const pubCount = res?.data?.groupByStatusOption?.filter(
                        (item) => item._id === 'PUBLISHED'
                    )
                    if (Array.isArray(pubCount) && pubCount.length > 0) {
                        tabCountObj.PUBLISHED = pubCount[0].count
                    }
                    const draftCount = res?.data?.groupByStatusOption?.filter(
                        (item) => item._id === 'DRAFT'
                    )
                    if (Array.isArray(draftCount) && draftCount.length > 0) {
                        tabCountObj.DRAFT = draftCount[0].count
                    }
                    const unPubCount = res?.data?.groupByStatusOption?.filter(
                        (item) => item._id === 'UNPUBLISHED'
                    )
                    if (Array.isArray(unPubCount) && unPubCount.length > 0) {
                        tabCountObj.UNPUBLISHED = unPubCount[0].count
                    }
                    setTabsDatacount(tabCountObj)
                }

                setInitialLoading(false)
                setLoadingData(false)
            })
            .catch((err) => {
                console.log('err', err)
                setInitialLoading(false)
                setLoadingData(false)
            })
    }, [paginateObj.page, searchText, tabInitialValue, selectedTypesValues])

    React.useEffect(() => {
        get_all_dropdowns()
            .then((res) => {
                const hubTypesDropdown = res?.find((d) => {
                    if (d.dropdownTitle === 'hub_types') {
                        return true
                    }
                })
                setHubTypesDropdown([
                    { name: 'ALL', value: 'all' },
                    ...hubTypesDropdown?.optionList?.map((item) => {
                        return { name: item.name, value: item.value }
                    }),
                ])
            })
            .catch((err) => {})
    }, [])
    React.useEffect(() => {
        const timeOutId = setTimeout(() => setSearchtext(searchQuery), 500)
        return () => {
            clearTimeout(timeOutId)
        }
    }, [searchQuery])
    const header_list = [
        {
            field: '',
            headerName: null,
            flex: 1,
            // minWidth: 300,
            align: 'left',

            renderCell: (value) => {
                return (
                    <>
                        <Grid>
                            <Avatar
                                src={value.row?.image}
                                style={{
                                    width: 150,
                                    height: 90,
                                    borderRadius: '10px',
                                }}
                                alt="hub image"
                            />
                        </Grid>
                        <Grid
                            style={{ marginLeft: '20px' }}
                            alignItems="center"
                            justifyContent="flex-start"
                        >
                            {value?.row?.tag ? (
                                <Typography
                                    style={{
                                        fontSize: 14,
                                        lineHeight: '20px',
                                        color: 'blue',
                                        fontWeight: 'bold',
                                    }}
                                    variant="body1"
                                >
                                    {value?.row?.tag ?? null}
                                </Typography>
                            ) : null}

                            <Typography
                                style={{
                                    fontSize: 18,
                                    lineHeight: '30px',
                                    whiteSpace: 'normal',
                                }}
                                variant="h5"
                            >
                                {value.row?.title ?? ''}
                            </Typography>
                            <Typography
                                style={{ fontSize: 14, lineHeight: '20px' }}
                                variant="body1"
                            >
                                {value.row?.category ?? 'NA'}
                                {' | '}
                                {moment(value.row.createdAt).format(
                                    'MMM D YYYY HH:mm:ss'
                                )}
                                {value.row?.location
                                    ? ' | ' + value?.row?.location
                                    : null}
                            </Typography>
                            <Typography
                                title={value.row?.subtype_category?.join(', ')}
                                style={{ fontSize: 14, lineHeight: '20px' }}
                                variant="body1"
                            >
                                {value.row?.subtype_category
                                    ?.slice(0, 6)
                                    ?.join(', ') ?? ''}
                                {value.row?.subtype_category?.length > 6 &&
                                    '...'}
                            </Typography>
                        </Grid>
                    </>
                )
            },
        },
        {
            field: 'Hub Views',
            headerName: 'Hub Views',
            minWidth: 150,
            align: 'right',
            renderCell: (value) => (
                <>
                    {value?.row?.numberOfViews ? (
                        <Grid>
                            <Typography
                                title="Number Of Views"
                                variant="numberOfViews"
                            >
                                {value?.row?.numberOfViews}
                            </Typography>
                        </Grid>
                    ) : (
                        '0'
                    )}
                </>
            ),
        },

        {
            field: 'action',
            headerName: 'Action',
            minWidth: 300,

            align: 'right',

            renderCell: (value) => (
                <>
                    {value?.row?.type ? (
                        <Grid style={{ marginRight: '50px' }}>
                            <Typography variant="subtitle2">
                                <Chip
                                    label={
                                        value?.row?.type[0]?.toUpperCase() +
                                        value?.row?.type?.slice(1)
                                    }
                                    style={{
                                        backgroundColor: 'rgb(155 163 74)',
                                        color: 'white',
                                        padding: '15px',
                                        fontSize: 16,
                                    }}
                                />
                            </Typography>
                        </Grid>
                    ) : null}
                    <Button
                        title="Edit hub"
                        style={{ border: '0' }}
                        onClick={() => {
                            const win = window.open(
                                `/hub/${value.row._id}`,
                                '_blank'
                            )
                        }}
                        variant="outlined"
                        startIcon={<PackageEditIcon />}
                    />
                </>
            ),
        },
    ]

    return initialLoading ? (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh', // Adjust this as needed
            }}
        >
            <CircularProgress />
        </div>
    ) : (
        <>
            <Grid xs={12} item>
                <Stack direction="row" width="100%" justifyContent={'end'}>
                    {/* <Typography variant="h5">Manage hub</Typography> */}
                    <Button
                        onClick={() => {
                            const win = window.open(`/hub/add`)
                        }}
                        startIcon={<Add />}
                        style={{
                            padding: '8px 16px 8px 12px',
                            marginRight: '10px',
                            border: '2px solid #65819D',
                            borderRadius: '16px',
                            fontWeight: 600,
                            fontSize: '16px',

                            color: '#65819D',
                        }}
                    >
                        Add Hub
                    </Button>
                </Stack>
            </Grid>
            <HubTabs
                tabClickedCallback={tabClickedCallback}
                loader={loadingData}
                tabsDataCount={tabsDataCount}
                tabInitialValue={tabInitialValue}
                setTabInitialValue={(tabsValue) => {
                    setTabInitialValue(tabsValue)
                    setPaginateObj({ page: 1, totalPages: 1 })
                }}
            />{' '}
            <Grid
                xs={12}
                style={{
                    display: 'flex',
                    justifyContent: 'end',
                }}
            >
                <Grid
                    item
                    container
                    xs={2}
                    style={{ display: 'flex', justifyContent: 'end' }}
                >
                    <Button
                        variant="outlined"
                        style={{
                            borderRadius: 8,
                            height: 40,
                            marginTop: 12,
                            backgroundColor: '#FBFAFB',
                            position: 'relative',
                            color: '#005441',
                            borderColor: '#005441',
                        }}
                        endIcon={
                            <KeyboardArrowDown
                                style={{
                                    position: 'relative',
                                    top: 2,
                                    transform: `rotate(${
                                        anchorElSubsEventType ? 180 : 0
                                    }deg)`,
                                    transition: '0.2s',
                                }}
                            />
                        }
                        onClick={handleClickSubscriptionEventType}
                    >
                        Select Hub Type
                    </Button>

                    <Menu
                        id="simple-menu-subscription-event-dropdown"
                        anchorEl={anchorElSubsEventType}
                        keepMounted
                        style={{ position: 'absolute', top: 45 }}
                        open={Boolean(anchorElSubsEventType)}
                        onClose={handleCloseSubscriptionEventType}
                    >
                        {hubTypesDropdown?.map((item) => {
                            return (
                                <MenuItem
                                    sx={{ fontWeight: 'bold' }}
                                    style={{
                                        width: 270,
                                        color: selectedTypesValues.includes(
                                            item?.value
                                        )
                                            ? 'black'
                                            : null,
                                    }}
                                    value={item?.value}
                                    onClick={(e) => {
                                        if (item?.value === 'all') {
                                            if (
                                                selectedTypesValues[0] === 'all'
                                            ) {
                                            } else {
                                                setSelectedTypesValues([
                                                    item?.value,
                                                ])
                                            }
                                        } else {
                                            if (
                                                selectedTypesValues.includes(
                                                    item.value
                                                )
                                            ) {
                                                setSelectedTypesValues(
                                                    (prev) => {
                                                        const filterSubEvent =
                                                            prev.filter(
                                                                (item1) => {
                                                                    return (
                                                                        item1 !==
                                                                        item.value
                                                                    )
                                                                }
                                                            )

                                                        return filterSubEvent?.length ===
                                                            0
                                                            ? ['all']
                                                            : filterSubEvent
                                                    }
                                                )
                                            } else {
                                                setSelectedTypesValues(
                                                    (prev) => {
                                                        const filterSubEvent =
                                                            prev.filter(
                                                                (item1) =>
                                                                    item1 !==
                                                                    'all'
                                                            )
                                                        const subEvent = [
                                                            ...filterSubEvent,
                                                            item?.value,
                                                        ]

                                                        return subEvent
                                                    }
                                                )
                                            }
                                        }
                                        handleCloseSubscriptionEventType()
                                    }}
                                >
                                    <Button
                                        style={{
                                            width: '180px',
                                            justifyContent: 'flex-start',
                                        }}
                                        startIcon={item.icon}
                                    >
                                        <Typography
                                            style={{
                                                fontWeight:
                                                    selectedTypesValues?.includes(
                                                        item?.name
                                                    )
                                                        ? 700
                                                        : 400,

                                                fontSize: '16px',
                                                lineHeight: '10px',
                                                color: '#000000',
                                            }}
                                        >
                                            {item?.name}
                                        </Typography>
                                    </Button>

                                    <ListItemIcon
                                        style={{
                                            justifyContent: 'flex-end',
                                            padding: 0,
                                        }}
                                    >
                                        {selectedTypesValues?.includes(
                                            item?.value
                                        ) ? (
                                            <Done />
                                        ) : null}
                                    </ListItemIcon>
                                </MenuItem>
                            )
                        })}
                    </Menu>
                </Grid>

                <Grid xs={3}>
                    <TextField
                        style={{
                            backgroundColor: 'white',
                            borderRadius: 12,
                            border: '3px solid #E6E6E6',
                            marginTop: '9px',
                        }}
                        value={searchQuery}
                        onChange={(event) => {
                            setSearchQuery(event.target.value)
                        }}
                        id="search_hub_tex"
                        placeholder="Search"
                        fullWidth
                        margin="normal"
                        size="small"
                    />
                </Grid>
            </Grid>
            {/* <Divider style={{ margin: '8px 0' }} /> */}
            <Grid
                item
                xs={12}
                className={style.box}
                container
                style={{
                    height: 'calc(100% - 220px)',
                    overflow: 'auto',
                    marginTop: 0,
                    padding: 0,
                }}
            >
                <DataGrid
                    columns={header_list}
                    autore
                    rows={data}
                    loading={loadingData}
                    disableColumnMenu
                    noda
                    getRowId={(row) => row._id}
                    hideFooter
                    style={{ border: 'none', fontSize: 14 }}
                    classes={{ cell: style.DataGridHeader }}
                    headerHeight={0}
                    rowHeight={140}
                    disableSelectionOnClick
                    disableColumnSelector
                    components={{
                        ColumnSortedAscendingIcon: CustomSort,
                        ColumnSortedDescendingIcon: CustomSort,
                        LoadingOverlay: CustomLoadingOverlay,
                        NoRowsOverlay: NoRowsOverlay,
                    }}
                />
            </Grid>
            {paginateObj ? (
                paginateObj?.totalPages ? (
                    initialLoading ? null : (
                        <Grid item xs={12} style={{ marginTop: '15px' }}>
                            <Pagination
                                currentPage={paginateObj.page}
                                totalPages={paginateObj.totalPages}
                                onPageChange={(_, value) => {
                                    setPaginateObj({
                                        page: value,
                                        totalPages: paginateObj.totalPages,
                                    })
                                }}
                            />
                        </Grid>
                    )
                ) : null
            ) : null}
        </>
    )
}

export default BlogList
export function CustomLoadingOverlay() {
    return (
        <GridOverlay>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh', // Adjust this as needed
                }}
            >
                <CircularProgress />
            </div>
        </GridOverlay>
    )
}

export const NoRowsOverlay = () => {
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
                width: '100%',
            }}
        >
            <p>No data found</p>
        </div>
    )
}
export const CustomSort = () => {
    return (
        <img
            src={sorter}
            alt=""
            width={11}
            height={25}
            style={{ position: 'relative', bottom: 3 }}
        />
    )
}
