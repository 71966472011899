import {
  Button,
  Dialog,
  Fab,
  Fade,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core";
import { Add, Close } from "@material-ui/icons";
import { Form, Formik } from "formik";
import React from "react";
import { post_admins } from "../../api/Setting_Api";
import GrayButton from "../../components/GrayButton";
import { TextFieldWrapper } from "../../components/Textfields";
import { styles } from "../../styles/styles";
import {
  AddinitialValues,
  validation,
} from "../../validations/add_Admin_validation";

const AddNewAdmin = ({ set_data }) => {
  const [open, setOpen] = React.useState(false);

  const style = styles();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = (formik) => {
    setOpen(false);
    formik.resetForm();
  };
  const handleSubmit = (values, props) => {
    post_admins({
      email: values.emaill,
      password: values.passwordd,
      name: values.name,
    }).then((res) => {
      if (!res) return;

      set_data((pre) => [...pre, res.user]);
      props.resetForm();
      setOpen(false);
    });
  };
  return (
    <>
      <Fab
        onClick={handleOpen}
        variant="extended"
        classes={{ extended: style.addIceBreaker }}
        style={{ boxShadow: "none" }}
      >
        <Add />
        Add New Admin
      </Fab>
      <Formik
        initialValues={AddinitialValues}
        validationSchema={validation}
        validateOnMount
        onSubmit={(values, props) => handleSubmit(values, props)}
      >
        {(formik) => {
          return (
            <Dialog
              maxWidth="sm"
              fullWidth={true}
              open={open}
              onClose={() => {
                handleClose(formik);
              }}
              TransitionComponent={Transition}
              onClick={(e) => e.stopPropagation()}
            >
              <Form>
                <Grid
                  xs={12}
                  container
                  item
                  spacing={6}
                  style={{ margin: "auto" }}
                  justifyContent="center"
                >
                  <Grid
                    item
                    xs={12}
                    container
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography variant="h4">Add new admin</Typography>
                    <IconButton onClick={() => handleClose()}>
                      <Close />
                    </IconButton>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography style={{ marginBottom: 8 }} variant="subtitle2">
                      Full name
                    </Typography>
                    <TextFieldWrapper
                      name="name"
                      placeholder="Enter full name"
                      white={true}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography style={{ marginBottom: 8 }} variant="subtitle2">
                      Email
                    </Typography>
                    <TextFieldWrapper
                      name="emaill"
                      placeholder="Enter email address"
                      white={true}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography style={{ marginBottom: 8 }} variant="subtitle2">
                      Password
                    </Typography>
                    <TextFieldWrapper
                      name="passwordd"
                      type="password"
                      placeholder="Enter password"
                      white={true}
                    />
                  </Grid>
                  <Grid item xs={12} container justifyContent="flex-end">
                    <Button
                      variant="outlined"
                      color="default"
                      style={{ borderRadius: 8 }}
                      onClick={() => handleClose(formik)}
                    >
                      Cancel
                    </Button>
                    <GrayButton
                      disabled={!formik.isValid}
                      // onClick={() => formik.submitForm()}
                      title="submit"
                    />
                  </Grid>
                </Grid>
              </Form>
            </Dialog>
          );
        }}
      </Formik>
    </>
  );
};

export default AddNewAdmin;
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Fade timeout={350} ref={ref} {...props} />;
});
