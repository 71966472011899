import { Button, Dialog, Fade, Grid, Typography } from '@material-ui/core'
import React from 'react'

import { Form, Formik } from 'formik'

import { Close } from '@material-ui/icons'
import { reject_requests } from '../../api/UserRequest_Api'

const RejectRequest = ({
    req_ids,
    tab_value,
    set_Data,
    set_req,
    set_count,
    user_name,
    status,
    user_id = null,
}) => {
    const [open, setOpen] = React.useState(false)
    const handleOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }
    return (
        <>
            <Button
                onClick={handleOpen}
                disabled={req_ids.length === 0 || status === 'rejected'}
                startIcon={<Close />}
                color="secondary"
                variant="text"
                style={{
                    background: 'rgba(228, 185, 185, 0.17)',
                    borderRadius: '12px',
                    color: '#BA5A6B',
                }}
            >
                Reject
            </Button>
            <Formik
                initialValues={{
                    reason: '',
                    photo: false,
                    video: false,
                    bio: false,
                    general: false,
                }}
                onSubmit={(value, props) => {
                    reject_requests({
                        reason: 'image',
                        ids: req_ids,
                    }).then((res) => {
                        if (!res) return
                        set_Data(
                            (pre) =>
                                pre.filter(
                                    (item) => !req_ids.includes(item._id)
                                )
                            // pre.filter((item) => {
                            //     if (req_ids.includes(item._id)) {
                            //         return { ...item, status: 'rejected' }
                            //     } else {
                            //         return item
                            //     }
                            // })
                        )
                        console.log('tab_value21', tab_value)
                        set_count((prev) => ({
                            ...prev,
                            rejected: ++prev.rejected,
                            [tab_value]: --prev[tab_value],
                        }))
                        props.resetForm()
                        handleClose()
                        set_req([])
                    })
                }}
                validateOnMount
            >
                {(formik) => {
                    const check = () => {
                        if (
                            !formik.values.bio &&
                            !formik.values.general &&
                            !formik.values.photo &&
                            !formik.values.video
                        ) {
                            return true
                        }
                        return false
                    }
                    return (
                        <Form>
                            <Dialog
                                maxWidth="sm"
                                fullWidth={true}
                                open={open}
                                onClose={() => {
                                    handleClose()
                                    formik.resetForm()
                                }}
                                TransitionComponent={Transition}
                                onClick={(e) => e.stopPropagation()}
                            >
                                <Grid
                                    container
                                    style={{
                                        padding: 32,
                                        borderRadius: '12px',
                                    }}
                                >
                                    <Grid
                                        item
                                        xs={12}
                                        style={{ marginBottom: 24 }}
                                    >
                                        <Typography
                                            variant="subtitle1"
                                            style={{
                                                fontWeight: 600,
                                                fontSize: 18,
                                                fontFamily: 'manrope',
                                                color: 'black',
                                            }}
                                        >
                                            Are you sure you want to reject
                                            {user_name && (
                                                <b
                                                    style={{
                                                        fontWeight: 'bolder',
                                                        color: 'black',
                                                        marginLeft: 4,
                                                    }}
                                                >
                                                    “ {user_name} ”{' '}
                                                </b>
                                            )}
                                            ?
                                        </Typography>
                                    </Grid>

                                    <Grid
                                        style={{ marginTop: 24 }}
                                        container
                                        justifyContent="space-between"
                                    >
                                        <Button
                                            onClick={() => {
                                                handleClose()
                                                formik.resetForm()
                                            }}
                                            variant="text"
                                            sx={{
                                                fontSize: '16px',
                                                fontWeight: 600,
                                                color: 'black',
                                            }}
                                        >
                                            cancel
                                        </Button>
                                        <Grid
                                            container
                                            item
                                            xs={8}
                                            justifyContent="flex-end"
                                        >
                                            <Button
                                                // disabled={check()}
                                                variant="contained"
                                                color="secondary"
                                                onClick={() =>
                                                    formik.submitForm()
                                                }
                                                type="submit"
                                                style={{
                                                    // marginLeft: 16,
                                                    border: '1px solid #DBDBDB',
                                                    borderRadius: '8px',
                                                    marginRight: '24px',
                                                    fontSize: '16px',
                                                    fontWeight: 600,
                                                    color: 'white',
                                                    padding: '13px 27px',
                                                }}
                                            >
                                                Reject
                                            </Button>
                                            {/* <Button
                        variant="contained"
                        color="secondary"
                        fontWeight="700"
                        style={{
                          borderRadius: "8px",
                          fontSize: "16px",
                          "& > .MuiButton-label": {
                            fontWeight: "700 !important",
                          },
                        }}
                      >
                        Ask to upload photos
                      </Button> */}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Dialog>
                        </Form>
                    )
                }}
            </Formik>
        </>
    )
}

export default RejectRequest
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Fade timeout={350} ref={ref} {...props} />
})
