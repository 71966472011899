import React from 'react'

export const GoldenTicketOutlineIcon = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16.3125 7.875C16.4617 7.875 16.6048 7.81574 16.7102 7.71025C16.8157 7.60476 16.875 7.46168 16.875 7.3125V4.5C16.875 4.20163 16.7565 3.91548 16.5455 3.7045C16.3345 3.49353 16.0484 3.375 15.75 3.375H2.25C1.95163 3.375 1.66548 3.49353 1.4545 3.7045C1.24353 3.91548 1.125 4.20163 1.125 4.5V7.3125C1.125 7.46168 1.18426 7.60476 1.28975 7.71025C1.39524 7.81574 1.53832 7.875 1.6875 7.875C1.98587 7.875 2.27202 7.99353 2.483 8.2045C2.69397 8.41548 2.8125 8.70163 2.8125 9C2.8125 9.29837 2.69397 9.58452 2.483 9.7955C2.27202 10.0065 1.98587 10.125 1.6875 10.125C1.53832 10.125 1.39524 10.1843 1.28975 10.2898C1.18426 10.3952 1.125 10.5383 1.125 10.6875V13.5C1.125 13.7984 1.24353 14.0845 1.4545 14.2955C1.66548 14.5065 1.95163 14.625 2.25 14.625H15.75C16.0484 14.625 16.3345 14.5065 16.5455 14.2955C16.7565 14.0845 16.875 13.7984 16.875 13.5V10.6875C16.875 10.5383 16.8157 10.3952 16.7102 10.2898C16.6048 10.1843 16.4617 10.125 16.3125 10.125C16.0141 10.125 15.728 10.0065 15.517 9.7955C15.306 9.58452 15.1875 9.29837 15.1875 9C15.1875 8.70163 15.306 8.41548 15.517 8.2045C15.728 7.99353 16.0141 7.875 16.3125 7.875ZM15.75 11.1769V13.5H11.8125V11.8125H10.6875V13.5H2.25V11.1769C2.7315 11.051 3.15769 10.7691 3.46186 10.3752C3.76604 9.9813 3.93104 9.49767 3.93104 9C3.93104 8.50233 3.76604 8.0187 3.46186 7.6248C3.15769 7.23091 2.7315 6.94897 2.25 6.82313V4.5H10.6875V6.1875H11.8125V4.5H15.75V6.82313C15.2685 6.94897 14.8423 7.23091 14.5381 7.6248C14.234 8.0187 14.069 8.50233 14.069 9C14.069 9.49767 14.234 9.9813 14.5381 10.3752C14.8423 10.7691 15.2685 11.051 15.75 11.1769Z" fill="black" />
      <path d="M10.6875 7.3125H11.8125V10.6875H10.6875V7.3125Z" fill="black" />
    </svg>


  )
}