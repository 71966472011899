import { Button, Divider, Menu, MenuItem, Typography } from '@material-ui/core'
import React, { useState } from 'react'
import more from '../../../../asset/img/More horizontal.png'
import { Delete } from '@material-ui/icons'
import DeleteUserImage from './DeleteUserImage'
import MakeProfileImage from './MakeProfileImage'
const ActionImage = ({ setUserImages, userId, imageId }) => {
    const [anchorEl, setAnchorEl] = useState(null)

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }
    return (
        <div style={{ position: 'absolute', left: '50%' }}>
            {/* <Button
                variant="outlined"
                style={{ border: 0 }}
                startIcon={}
            ></Button> */}
            <img
                style={{ cursor: 'pointer' }}
                onClick={handleClick}
                src={more}
                alt=""
            />
            <Menu
                anchorEl={anchorEl}
                onClose={handleClose}
                elevation={0}
                open={Boolean(anchorEl)}
                PaperProps={{
                    style: {
                        boxShadow: '0px 2px 12px rgba(205, 205, 205, 0.7)',
                        border: '1px solid rgba(0, 0, 0, 0.08)',
                        borderRadius: 10,
                        padding: 16,
                        opacity: 1,
                    },
                }}
                style={{
                    position: 'absolute',
                    top: 40,
                    left: -50,
                    opacity: 1,
                }}
            >
                <Typography
                    style={{
                        color: 'black',
                        marginBottom: '3px',
                        fontSize: '1.1rem',
                    }}
                    variant="body1"
                >
                    Actions
                </Typography>
                <Divider style={{ marginBottom: '10px' }} />
                <MenuItem
                    style={{ paddingLeft: 0, opacity: 1, paddingBottom: 0 }}
                    button={false}
                >
                    <DeleteUserImage
                        setUserImages={setUserImages}
                        userId={userId}
                        imageId={imageId}
                        handleCloseAction={handleClose}
                    />
                </MenuItem>
                <MenuItem
                    style={{ paddingLeft: 0, opacity: 1, paddingBottom: 0 }}
                    button={false}
                >
                    <MakeProfileImage
                        handleClose={handleClose}
                        userId={userId}
                        imageId={imageId}
                    />
                </MenuItem>
            </Menu>
        </div>
    )
}

export default ActionImage
