import { Grid, IconButton, Typography } from "@material-ui/core";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@material-ui/icons";
import React from "react";
import Slider from "react-slick";

const Photos = ({ data }) => {
  const slideRef = React.createRef();
  const goToNext = () => {
    slideRef.current.slickNext();
  };

  const goToPrev = () => {
    slideRef.current.slickPrev();
  };
  const settings = {
    infinite: false,
    speed: 500,
    arrows: true,
    slidesToShow: data?.length > 2 ? 3 : 2,
    slidesToScroll: 1,
  };

  return (
    <Grid container justifyContent="center">
      <Grid
        item
        xs={12}
        container
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="h4">Photos</Typography>
        <Grid item container xs={3} justifyContent="flex-end">
          <IconButton
            onClick={goToPrev}
            disabled={data?.length === 0 || data?.length <= 3}
          >
            <KeyboardArrowLeft fontSize="large" />
          </IconButton>
          <IconButton
            onClick={goToNext}
            disabled={data?.length === 0 || data?.length <= 3}
          >
            <KeyboardArrowRight fontSize="large" />
          </IconButton>
        </Grid>
      </Grid>
      <Grid item style={{ width: "98%" }}>
        <Slider {...settings} ref={slideRef}>
          {data?.map((item, i) => (
            <Grid item xs={11} key={i}>
              {/* <Grid
                item
                xs={12}
                style={{
                  backgroundImage: `url(${
                    process.env.REACT_APP_ASSETS_URL + item?.croped
                  })`,
                  backgroundRepeat: "round",

                  backgroundSize: "",
                  height: 300,
                }}
              > */}
              <img
                src={`${item?.croped}`}
                alt=""
                style={{ borderRadius: 12, objectFit: "cover" }}
                width={"100%"}
                height={300}
              // style={{ marginLeft: 16 }}
              />
              {/* </Grid> */}
            </Grid>
          ))}
        </Slider>
      </Grid>
    </Grid>
  );
};

export default Photos;
