import React, { useEffect, useState } from 'react'
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    FormLabel,
    Grid,
    Typography,
} from '@material-ui/core'
import {
    TextField,
    TextareaAutosize,
    Stack,
    FormGroup,
    FormControlLabel,
    Checkbox,
} from '@mui/material'
import { styles } from '../../../styles/styles'
import { Controller } from 'react-hook-form'
import { Add } from '@material-ui/icons'
import ViewUserImage from './UserImageUpload/ViewUserImage'
import UploadUserImage from './UserImageUpload/UploadUserImage'
import { GridExpandMoreIcon } from '@mui/x-data-grid'
const UserImageUploadSection = ({ isGuest, images, setUserImages, userId }) => {
    console.log('isGuest', { isGuest, images })
    const style = styles()
    return (
        <Grid spacing={2} className={style.box}>
            <Accordion style={{ boxShadow: 'none' }}>
                <AccordionSummary
                    expandIcon={<GridExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header-questionnair"
                >
                    <Typography
                        style={{
                            fontSize: '1.2rem',
                            textDecoration: 'underline',
                        }}
                    >
                        Profile Photos
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid
                        container
                        spacing={2}
                        style={{
                            // padding: '12px',
                            marginTop: '10px',
                        }}
                    >
                        {images?.map((item) => {
                            return (
                                <Grid
                                    style={{ marginBottom: '10px' }}
                                    key={item?._id}
                                    xs={3}
                                >
                                    <ViewUserImage
                                        setUserImages={setUserImages}
                                        imageId={item?._id}
                                        imageURL={item?.original}
                                        userId={userId}
                                    />
                                </Grid>
                            )
                        })}

                        {images?.length >= 6 ? null : (
                            <Grid
                                spacing={2}
                                alignItems="center"
                                xs={3}
                                style={{
                                    border: '2px solid #05060fb3',
                                    borderRadius: '10px',
                                    maxWidth: '15.333333%',
                                    padding: '20px',
                                    height: '242.25px',
                                }}
                            >
                                <UploadUserImage
                                    userId={userId}
                                    setUserImages={setUserImages}
                                />
                            </Grid>
                        )}
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </Grid>
    )
}

export default UserImageUploadSection
