import { useState, useEffect } from 'react'
import {
    FormLabel,
    Grid,
    Typography,
    Accordion,
    AccordionDetails,
    AccordionSummary,
} from '@material-ui/core'
import {
    TextField,
    Checkbox,
    FormGroup,
    FormControlLabel,
    Stack,
} from '@mui/material'
import { GridExpandMoreIcon } from '@mui/x-data-grid'
import { styles } from '../../../styles/styles'
import { Controller } from 'react-hook-form'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
const Preferences = ({
    register,
    control,
    preferencesGenderDropdownOptions,
}) => {
    const style = styles()
    return (
        <Grid spacing={2} className={style.box}>
            <Accordion style={{ boxShadow: 'none' }}>
                <AccordionSummary
                    expandIcon={<GridExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-preference-info--questionnair"
                >
                    <Typography
                        style={{
                            fontSize: '1.2rem',
                            textDecoration: 'underline',
                        }}
                    >
                        Preferences
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={2}>
                        <Grid item container xs={4}>
                            <Grid item xs={12}>
                                <Stack direction="column">
                                    <FormLabel htmlFor="gender" sx={{ pt: 4 }}>
                                        Gender:
                                    </FormLabel>
                                    <FormControlLabel
                                        style={{
                                            paddingTop: '16px',
                                            paddingLeft: '12px',
                                            marginRight: '0',
                                        }}
                                        control={
                                            <Controller
                                                {...register(
                                                    'preferences.gender'
                                                )}
                                                control={control}
                                                render={({ field }) => (
                                                    <>
                                                        <Select
                                                            labelId="preferences.gender"
                                                            id="demo-simple-select"
                                                            fullWidth
                                                            margin="normal"
                                                            size="small"
                                                            value={
                                                                field.value
                                                                    ? field.value
                                                                    : []
                                                            }
                                                            onChange={(event) =>
                                                                field.onChange(
                                                                    event.target
                                                                        .value
                                                                )
                                                            }
                                                        >
                                                            <MenuItem
                                                                value={'0'}
                                                            >
                                                                Select
                                                                Preference
                                                                Gender
                                                            </MenuItem>
                                                            {preferencesGenderDropdownOptions?.map(
                                                                (option) => {
                                                                    return (
                                                                        <MenuItem
                                                                            value={
                                                                                option.name
                                                                            }
                                                                        >
                                                                            {
                                                                                option.name
                                                                            }
                                                                        </MenuItem>
                                                                    )
                                                                }
                                                            )}
                                                        </Select>
                                                    </>
                                                )}
                                            />
                                        }
                                    />
                                </Stack>
                            </Grid>
                        </Grid>

                        <Grid item container xs={4}>
                            <Grid item xs={12}>
                                <Stack direction="column">
                                    <FormLabel
                                        htmlFor="distance"
                                        sx={{ pt: 4 }}
                                    >
                                        Distance:
                                    </FormLabel>
                                    <TextField
                                        {...register('preferences.distance')}
                                        placeholder="Distance"
                                        id="distance"
                                        fullWidth
                                        margin="normal"
                                        size="small"
                                    />
                                </Stack>

                                <Stack direction="column">
                                    <FormGroup>
                                        <FormControlLabel
                                            class="showOtherPeopleIfRunOutDistanceText"
                                            control={
                                                <Controller
                                                    {...register(
                                                        'preferences.isShowOtherPeopleIfRunOutDistance'
                                                    )}
                                                    control={control}
                                                    render={({ field }) => (
                                                        <Checkbox
                                                            {...field}
                                                            checked={
                                                                field?.value ||
                                                                false
                                                            }
                                                            onChange={(e) =>
                                                                field.onChange(
                                                                    e.target
                                                                        .checked
                                                                )
                                                            }
                                                        />
                                                    )}
                                                />
                                            }
                                            label="Show people further away if I run out of profiles to see"
                                        />
                                    </FormGroup>
                                </Stack>
                            </Grid>
                        </Grid>

                        <Grid item container xs={4}>
                            <Grid item xs={12}>
                                <Stack direction="column">
                                    <FormLabel htmlFor="age" sx={{ pt: 4 }}>
                                        Age:
                                    </FormLabel>
                                    <TextField
                                        {...register('preferences.age')}
                                        placeholder="Age"
                                        id="age"
                                        // value={""}
                                        // onChange={() => {}}
                                        fullWidth
                                        margin="normal"
                                        size="small"
                                    />
                                </Stack>
                                <Stack direction="column">
                                    <FormGroup>
                                        <FormControlLabel
                                            class="showOtherPeopleIfRunOutAgeText"
                                            control={
                                                <Controller
                                                    {...register(
                                                        'preferences.isShowOtherPeopleIfRunOutAge'
                                                    )}
                                                    control={control}
                                                    render={({ field }) => (
                                                        <Checkbox
                                                            {...field}
                                                            checked={
                                                                field?.value ||
                                                                false
                                                            }
                                                            onChange={(e) =>
                                                                field.onChange(
                                                                    e.target
                                                                        .checked
                                                                )
                                                            }
                                                        />
                                                    )}
                                                />
                                            }
                                            label="Show people slightly out of my preferred range if I run out of profiles to see"
                                        />
                                    </FormGroup>
                                </Stack>
                            </Grid>
                        </Grid>
                        <Grid item container xs={4}>
                            <Grid item xs={12}>
                                <Stack direction="column">
                                    <FormLabel htmlFor="country" sx={{ pt: 4 }}>
                                        Country:
                                    </FormLabel>
                                    <TextField
                                        {...register(
                                            'preferences.address.country'
                                        )}
                                        placeholder="Country"
                                        id="country"
                                        // value={""}
                                        // onChange={() => {}}
                                        fullWidth
                                        margin="normal"
                                        size="small"
                                    />
                                </Stack>
                            </Grid>
                        </Grid>
                        <Grid item container xs={4}>
                            <Grid item xs={12}>
                                <Stack direction="column">
                                    <FormLabel htmlFor="state" sx={{ pt: 4 }}>
                                        State:
                                    </FormLabel>
                                    <TextField
                                        {...register(
                                            'preferences.address.state'
                                        )}
                                        placeholder="State"
                                        id="state"
                                        // value={""}
                                        fullWidth
                                        margin="normal"
                                        size="small"
                                    />
                                </Stack>
                            </Grid>
                        </Grid>
                        <Grid item container xs={4}>
                            <Grid item xs={12}>
                                <Stack direction="column">
                                    <FormLabel htmlFor="city" sx={{ pt: 4 }}>
                                        City:
                                    </FormLabel>
                                    <TextField
                                        {...register(
                                            'preferences.address.city'
                                        )}
                                        placeholder="City"
                                        id="city"
                                        // value={""}
                                        // onChange={() => {}}
                                        fullWidth
                                        margin="normal"
                                        size="small"
                                    />
                                </Stack>
                            </Grid>
                        </Grid>
                        <Grid item container xs={4}>
                            <Grid item xs={12}>
                                <Stack direction="column">
                                    <FormLabel htmlFor="address" sx={{ pt: 4 }}>
                                        Address:
                                    </FormLabel>
                                    <TextField
                                        {...register(
                                            'preferences.address.address'
                                        )}
                                        placeholder="Address"
                                        id="address"
                                        // value={""}
                                        // onChange={() => {}}
                                        fullWidth
                                        margin="normal"
                                        size="small"
                                    />
                                </Stack>
                            </Grid>
                        </Grid>

                        {/* <Grid xs={4} /> */}
                        <Grid item xs={4}>
                            <Grid item xs={12}>
                                <FormLabel htmlFor="Longitude">
                                    Location (Longitude)
                                </FormLabel>

                                <TextField
                                    {...register(
                                        'preferences.address.location[0]'
                                    )}
                                    placeholder="Location"
                                    id="location1"
                                    // value={""}
                                    // onChange={() => {}}
                                    fullWidth
                                    margin="normal"
                                    size="small"
                                />
                            </Grid>
                        </Grid>

                        <Grid item container xs={4}>
                            <Grid item xs={12}>
                                <FormLabel htmlFor="Latitude">
                                    Location (Latitude)
                                </FormLabel>

                                <TextField
                                    {...register(
                                        'preferences.address.location[1]'
                                    )}
                                    placeholder="Latitude"
                                    id="latitude"
                                    // value={""}
                                    // onChange={() => {}}
                                    fullWidth
                                    margin="normal"
                                    size="small"
                                />
                            </Grid>
                        </Grid>
                        <Grid item container xs={12} spacing={2}>
                            <Grid item xs={1} className="dealBreakersText">
                                <FormLabel htmlFor="location">
                                    Deal Breakers:
                                </FormLabel>
                            </Grid>
                            <Grid item xs={2}>
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Controller
                                                {...register(
                                                    'preferences.dealBreaker.isLookingfor'
                                                )}
                                                control={control}
                                                render={({ field }) => (
                                                    <Checkbox
                                                        {...field}
                                                        checked={
                                                            field?.value ||
                                                            false
                                                        }
                                                        onChange={(e) =>
                                                            field.onChange(
                                                                e.target.checked
                                                            )
                                                        }
                                                    />
                                                )}
                                            />
                                        }
                                        label="Is Looking For?"
                                    />
                                </FormGroup>
                            </Grid>
                            <Grid item xs={2}>
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Controller
                                                {...register(
                                                    'preferences.dealBreaker.isEthnicity'
                                                )}
                                                control={control}
                                                render={({ field }) => (
                                                    <Checkbox
                                                        {...field}
                                                        checked={
                                                            field?.value ||
                                                            false
                                                        }
                                                        onChange={(e) =>
                                                            field.onChange(
                                                                e.target.checked
                                                            )
                                                        }
                                                    />
                                                )}
                                            />
                                        }
                                        label="Is Ethnicity?"
                                    />
                                </FormGroup>
                            </Grid>
                            <Grid item xs={2}>
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Controller
                                                {...register(
                                                    'preferences.dealBreaker.isReligion'
                                                )}
                                                control={control}
                                                render={({ field }) => (
                                                    <Checkbox
                                                        {...field}
                                                        checked={
                                                            field?.value ||
                                                            false
                                                        }
                                                        onChange={(e) =>
                                                            field.onChange(
                                                                e.target.checked
                                                            )
                                                        }
                                                    />
                                                )}
                                            />
                                        }
                                        label="Is Religion?"
                                    />
                                </FormGroup>
                            </Grid>
                            <Grid item xs={2}>
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Controller
                                                {...register(
                                                    'preferences.dealBreaker.isAge'
                                                )}
                                                control={control}
                                                render={({ field }) => (
                                                    <Checkbox
                                                        {...field}
                                                        checked={
                                                            field?.value ||
                                                            false
                                                        }
                                                        onChange={(e) =>
                                                            field.onChange(
                                                                e.target.checked
                                                            )
                                                        }
                                                    />
                                                )}
                                            />
                                        }
                                        label="Is Age?"
                                    />
                                </FormGroup>
                            </Grid>
                            <Grid item xs={2}>
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Controller
                                                {...register(
                                                    'preferences.dealBreaker.isDistance'
                                                )}
                                                control={control}
                                                render={({ field }) => (
                                                    <Checkbox
                                                        {...field}
                                                        checked={
                                                            field?.value ||
                                                            false
                                                        }
                                                        onChange={(e) =>
                                                            field.onChange(
                                                                e.target.checked
                                                            )
                                                        }
                                                    />
                                                )}
                                            />
                                        }
                                        label="Is Distance?"
                                    />
                                </FormGroup>
                            </Grid>
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </Grid>
    )
}

export default Preferences
