import { Grid, IconButton, Typography, Button } from "@material-ui/core";
import { DragIndicator } from "@material-ui/icons";
import React from "react";
import { delete_FAQ } from "../../api/HelpCenter_Api";

import Delete from "../../asset/img/DeleteBlack.png";

import { styles } from "../../styles/styles";
import EditFAQ from "./Edit_FAQ";

const FAQ = ({ data, set_data }) => {
  const style = styles();

  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      className={style.box}
    >
      {/* --------------------------------------------------------------------------------------------- description of the FAQ section */}
      <Grid
        item
        xs={9}
        container
        alignItems="center"
        justifyContent="flex-start"
      >
        <Grid item style={{ width: 50 }}>
          <IconButton>
            <DragIndicator />
          </IconButton>
        </Grid>
        <Grid item style={{ width: "calc(100% - 50px)" }}>
          <Typography style={{ fontSize: 16 }} variant="body2">
            {data.title}
          </Typography>
          <Typography variant="body1">{data.description}</Typography>
        </Grid>
      </Grid>
      {/* --------------------------------------------------------------------------------------------- description of the FAQ section */}
      <Grid item xs={3} container alignItems="center" justifyContent="flex-end">
        <EditFAQ data={data} set_data={set_data} />
        <Button
          startIcon={<img src={Delete} alt="" />}
          onClick={() => {
            delete_FAQ(data._id).then((res) => {
              if (!res) return;
              set_data((pre) => pre.filter((item) => item._id !== data._id));
            });
          }}
          style={{
            color: "#CA1F1F",
            backgroundColor: "rgba(202, 31, 31,0.12)",
            border: "1px solid #D2D2D2",
            borderRadius: 12,
          }}
          variant="outlined"
        >
          Delete
        </Button>
      </Grid>
    </Grid>
  );
};

export default FAQ;
