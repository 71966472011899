import { Grid, Zoom } from "@material-ui/core";
import React from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { update_orders } from "../../api/HelpCenter_Api";
import {
  onDragEndPrefences,
  onDragUpdatePreferences,
} from "../../redux/ActionCreators";

import FAQ from "./FAQ";

const GeneralFAQ = ({ data, set_data }) => {
  const [updated_data, set_updated_data] = React.useState([]);

  React.useEffect(() => {
    set_updated_data(data);
  }, [data]);

  return (
    <DragDropContext
      onDragEnd={async (result) => {
        onDragEndPrefences(result, data, set_data, set_updated_data);

        let d = [];
        updated_data.map((item) => {
          return d.push({ _id: item._id, order: item.order });
        });
        update_orders(d);
      }}
      onDragUpdate={(initial) => {
        if (!initial.destination) return;
        onDragUpdatePreferences(
          data,
          initial.source.index,
          initial.destination.index,
          set_updated_data
        );
      }}
    >
      <Droppable droppableId="FAQ">
        {({ droppableProps, placeholder, innerRef }) => {
          return (
            <Grid
              ref={innerRef}
              {...droppableProps}
              item
              container
              justifyContent="flex-start"
              style={{
                overflowY: "auto",
              }}
            >
              {data.map((item, i) => {
                return (
                  <Draggable draggableId={i.toString()} key={i} index={i}>
                    {(provided) => (
                      <Grid
                        item
                        xs={12}
                        style={{ width: "100%" }}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <Zoom
                          timeout={i * 200}
                          in={
                            data.find((d) => d._id === item._id) !== undefined
                          }
                        >
                          <div style={{}}>
                            <FAQ data={item} set_data={set_data} />
                          </div>
                        </Zoom>
                      </Grid>
                    )}
                  </Draggable>
                );
              })}
              {placeholder}
            </Grid>
          );
        }}
      </Droppable>
    </DragDropContext>
  );
};

export default GeneralFAQ;
