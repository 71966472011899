import moment from "moment";
import { set_message, page_change } from "../redux/ActionCreators";
import Store from "../redux/Store";
import { api_instance, api_instance_modal } from "./Base_Api";

export const get_reports = async (type, search) => {
  const { pagination } = Store.getState().GeneralReducer;

  try {
    const { data } = await api_instance.get(type, {
      params: {
        search: search ?? '',
        page: pagination.page,
        limit: 50,
        // sort: sort.name,
        // order: sort.mode,
      },
    });
    data.data.reports.map(
      (item) =>
        (item.user.birthDate = moment().diff(item.user.birthDate, "years"))
    );
    Store.dispatch(page_change(data.data.pagination));
    return data;
  } catch (error) { }
};

export const block_users = async (values) => {
  try {
    const { data } = await api_instance.put("/users/timedBlock", values);

    Store.dispatch(
      set_message({ mode: true, message: data.data.message, color: "success" })
    );
    return data;
  } catch (error) { }
};

export const clear_reports = async (values) => {
  try {
    const { data } = await api_instance.put("/reports/clear", values);
    Store.dispatch(
      set_message({ mode: true, message: data.data.message, color: "success" })
    );
    return data;
  } catch (error) { }
};

export const get_message_reports = async (id) => {
  try {
    const { data } = await api_instance_modal.get(`/reports/messages/${id}`);
    return data;
  } catch (error) { }
};
