import React, { useEffect, useState } from 'react'
import { Close } from '@material-ui/icons'
import {
    Dialog,
    Divider,
    Fade,
    Grid,
    IconButton,
    Typography,
} from '@material-ui/core'
import ViewIcon from '../../../asset/img/matchMakeMeIcon.png'
import { styles } from '../../../styles/styles'

const ReddiScore = ({ userData }) => {
    console.log('userData', userData)
    const [open, setOpen] = React.useState(false)
    const classes = styles()
    const handleOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }
    return (
        <>
            <Typography
                style={{
                    fontSize: 14,
                    color: 'black',
                    marginLeft: '18%',
                }}
                variant="body1"
            >
                {userData?.point ?? '0'}{' '}
            </Typography>
            <img
                onClick={handleOpen}
                src={ViewIcon}
                alt="eye icon"
                width={'20px'}
                style={{ marginLeft: '10px' }}
            />{' '}
            <Dialog
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
                onClick={(e) => e.stopPropagation()}
                classes={{
                    paper: classes.viewProfileDialog,
                }}
            >
                <Grid
                    xs={12}
                    container
                    item
                    spacing={0}
                    style={{}}
                    justifyContent="center"
                >
                    <Grid
                        item
                        xs={12}
                        container
                        justifyContent="space-between"
                        alignItems="center"
                        style={{ padding: 16 }}
                    >
                        <Typography variant="h4">
                            Match Making Scoring {'('}
                            {userData?.firstName ??
                                '' + ' ' + userData?.lastName ??
                                ''}
                            {')'}
                        </Typography>

                        <IconButton onClick={handleClose}>
                            <Close />
                        </IconButton>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    {/* ------------------------------------------------------------------------------------ header section */}
                    <Grid
                        item
                        xs={12}
                        container
                        spacing={2}
                        alignItems="center"
                        style={{ margin: 'auto', padding: 16 }}
                    >
                        <Grid item xs={12}>
                            <Grid
                                container
                                xs={12}
                                md={9}
                                item
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <Typography
                                    style={{ fontSize: 16 }}
                                    variant="h4"
                                >
                                    Recommend User Points:
                                </Typography>

                                <Typography
                                    style={{ fontSize: 16 }}
                                    variant="h4"
                                >
                                    {userData?.point == 500
                                        ? userData?.point
                                        : 0}
                                </Typography>
                            </Grid>
                            <Grid
                                style={{ marginTop: '10px' }}
                                container
                                xs={12}
                                md={9}
                                item
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <Typography
                                    style={{ fontSize: 16 }}
                                    variant="h4"
                                >
                                    Priority Liked Points:
                                </Typography>

                                <Typography
                                    style={{ fontSize: 16 }}
                                    variant="h4"
                                >
                                    {userData?.pointPriorityLike ??
                                    userData?.pointPriorityLike === 400
                                        ? userData?.pointPriorityLike
                                        : 0}
                                </Typography>
                            </Grid>
                            <Grid
                                style={{ marginTop: '10px' }}
                                container
                                xs={12}
                                md={9}
                                item
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <Typography
                                    style={{ fontSize: 16 }}
                                    variant="h4"
                                >
                                    Liked Points:
                                </Typography>

                                <Typography
                                    style={{ fontSize: 16 }}
                                    variant="h4"
                                >
                                    {userData?.pointLike ??
                                    userData?.pointLike === 300
                                        ? userData?.pointLike
                                        : 0}
                                </Typography>
                            </Grid>
                            <Grid
                                style={{ marginTop: '10px' }}
                                container
                                xs={12}
                                md={9}
                                item
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <Typography
                                    style={{ fontSize: 16 }}
                                    variant="h4"
                                >
                                    VIP User Points:
                                </Typography>

                                <Typography
                                    style={{ fontSize: 16 }}
                                    variant="h4"
                                >
                                    {userData?.pointUserLevel ??
                                    userData?.point === 200
                                        ? userData?.point
                                        : 0}
                                </Typography>
                            </Grid>
                            <Grid
                                style={{ marginTop: '10px' }}
                                container
                                xs={12}
                                md={9}
                                item
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <Typography
                                    style={{ fontSize: 16 }}
                                    variant="h4"
                                >
                                    Location:
                                </Typography>

                                <Typography
                                    style={{ fontSize: 16 }}
                                    variant="h4"
                                >
                                    {userData?.core_score_obj?.locationScore ??
                                        0}
                                </Typography>
                            </Grid>
                            <Grid
                                style={{ marginTop: '10px' }}
                                container
                                xs={12}
                                md={9}
                                item
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <Typography
                                    style={{ fontSize: 16 }}
                                    variant="h4"
                                >
                                    Gender:
                                </Typography>

                                <Typography
                                    style={{ fontSize: 16 }}
                                    variant="h4"
                                >
                                    {userData?.core_score_obj?.genderScore ?? 0}
                                </Typography>
                            </Grid>
                            <Grid
                                style={{ marginTop: '10px' }}
                                container
                                xs={12}
                                md={9}
                                item
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <Typography
                                    style={{ fontSize: 16 }}
                                    variant="h4"
                                >
                                    Attachment Style:
                                </Typography>

                                <Typography
                                    style={{ fontSize: 16 }}
                                    variant="h4"
                                >
                                    {userData?.core_score_obj
                                        ?.attachmentStyleScore ?? 0}
                                </Typography>
                            </Grid>
                            <Grid
                                style={{ marginTop: '10px' }}
                                container
                                xs={12}
                                md={9}
                                item
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <Typography
                                    style={{ fontSize: 16 }}
                                    variant="h4"
                                >
                                    Age:
                                </Typography>

                                <Typography
                                    style={{ fontSize: 16 }}
                                    variant="h4"
                                >
                                    {userData?.core_score_obj?.ageScore ?? 0}
                                </Typography>
                            </Grid>
                            <Grid
                                style={{ marginTop: '10px' }}
                                container
                                xs={12}
                                md={9}
                                item
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <Typography
                                    style={{ fontSize: 16 }}
                                    variant="h4"
                                >
                                    Drinking:
                                </Typography>

                                <Typography
                                    style={{ fontSize: 16 }}
                                    variant="h4"
                                >
                                    {userData?.reddi_score_obj?.drinkingScore ??
                                        0}
                                </Typography>
                            </Grid>
                            <Grid
                                style={{ marginTop: '10px' }}
                                container
                                xs={12}
                                md={9}
                                item
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <Typography
                                    style={{ fontSize: 16 }}
                                    variant="h4"
                                >
                                    Family Plans:
                                </Typography>

                                <Typography
                                    style={{ fontSize: 16 }}
                                    variant="h4"
                                >
                                    {userData?.reddi_score_obj
                                        ?.familyPlansScore ?? 0}
                                </Typography>
                            </Grid>
                            <Grid
                                style={{ marginTop: '10px' }}
                                container
                                xs={12}
                                md={9}
                                item
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <Typography
                                    style={{ fontSize: 16 }}
                                    variant="h4"
                                >
                                    Exercise:
                                </Typography>

                                <Typography
                                    style={{ fontSize: 16 }}
                                    variant="h4"
                                >
                                    {userData?.reddi_score_obj?.exerciseScore ??
                                        0}
                                </Typography>
                            </Grid>
                            <Grid
                                style={{ marginTop: '10px' }}
                                container
                                xs={12}
                                md={9}
                                item
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <Typography
                                    style={{ fontSize: 16 }}
                                    variant="h4"
                                >
                                    Love Style Score:
                                </Typography>

                                <Typography
                                    style={{ fontSize: 16 }}
                                    variant="h4"
                                >
                                    {userData?.reddi_score_obj
                                        ?.loveStyleScore ?? 0}
                                </Typography>
                            </Grid>
                            <Grid
                                style={{ marginTop: '10px' }}
                                container
                                xs={12}
                                md={9}
                                item
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <Typography
                                    style={{ fontSize: 16 }}
                                    variant="h4"
                                >
                                    Leveling:
                                </Typography>

                                <Typography
                                    style={{ fontSize: 16 }}
                                    variant="h4"
                                >
                                    {userData?.reddi_score_obj?.levelScore ?? 0}
                                </Typography>
                            </Grid>
                            <Grid
                                style={{ marginTop: '10px' }}
                                container
                                xs={12}
                                md={9}
                                item
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <Typography
                                    style={{ fontSize: 16 }}
                                    variant="h4"
                                >
                                    Interests:
                                </Typography>

                                <Typography
                                    style={{ fontSize: 16 }}
                                    variant="h4"
                                >
                                    {userData?.reddi_score_obj
                                        ?.interestsScore ?? 0}
                                </Typography>
                            </Grid>
                            <Grid
                                style={{ marginTop: '10px' }}
                                container
                                xs={12}
                                md={9}
                                item
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <Typography
                                    style={{ fontSize: 16 }}
                                    variant="h4"
                                >
                                    Non-Negotiable:
                                </Typography>

                                <Typography
                                    style={{ fontSize: 16 }}
                                    variant="h4"
                                >
                                    {userData?.reddi_score_obj
                                        ?.nonNegotiablesScore ?? 0}
                                </Typography>
                            </Grid>
                            <Grid
                                style={{ marginTop: '10px' }}
                                container
                                xs={12}
                                md={9}
                                item
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <Typography
                                    style={{ fontSize: 16 }}
                                    variant="h4"
                                >
                                    Political:
                                </Typography>

                                <Typography
                                    style={{ fontSize: 16 }}
                                    variant="h4"
                                >
                                    {userData?.reddi_score_obj?.politicsScore ??
                                        0}
                                </Typography>
                            </Grid>
                            <Grid
                                style={{ marginTop: '10px' }}
                                container
                                xs={12}
                                md={9}
                                item
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <Typography
                                    style={{ fontSize: 16 }}
                                    variant="h4"
                                >
                                    Preferences:
                                </Typography>

                                <Typography
                                    style={{ fontSize: 16 }}
                                    variant="h4"
                                >
                                    {userData?.reddi_score_obj
                                        ?.preferencesScore ?? 0}
                                </Typography>
                            </Grid>
                            <Grid
                                style={{ marginTop: '10px' }}
                                container
                                xs={12}
                                md={9}
                                item
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <Typography
                                    style={{ fontSize: 16 }}
                                    variant="h4"
                                >
                                    Practical Criteria:
                                </Typography>

                                <Typography
                                    style={{ fontSize: 16 }}
                                    variant="h4"
                                >
                                    {userData?.reddi_score_obj
                                        ?.practicalCriteriaScore ?? 0}
                                </Typography>
                            </Grid>
                            <Grid
                                style={{ marginTop: '10px' }}
                                container
                                xs={12}
                                md={9}
                                item
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <Typography
                                    style={{ fontSize: 16 }}
                                    variant="h4"
                                >
                                    values:
                                </Typography>

                                <Typography
                                    style={{ fontSize: 16 }}
                                    variant="h4"
                                >
                                    {userData?.reddi_score_obj?.valuesScore ??
                                        0}
                                </Typography>
                            </Grid>
                            <Grid
                                style={{ marginTop: '10px' }}
                                container
                                xs={12}
                                md={9}
                                item
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <Typography
                                    style={{ fontSize: 16 }}
                                    variant="h4"
                                >
                                    Smoking:
                                </Typography>

                                <Typography
                                    style={{ fontSize: 16 }}
                                    variant="h4"
                                >
                                    {userData?.reddi_score_obj?.smokingScore ??
                                        0}
                                </Typography>
                            </Grid>
                            <Grid
                                style={{ marginTop: '10px' }}
                                container
                                xs={12}
                                md={9}
                                item
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <Typography
                                    style={{ fontSize: 16 }}
                                    variant="h4"
                                >
                                    Salary:
                                </Typography>

                                <Typography
                                    style={{ fontSize: 16 }}
                                    variant="h4"
                                >
                                    {userData?.reddi_score_obj?.salaryScore ??
                                        0}
                                </Typography>
                            </Grid>
                            <Grid
                                style={{ marginTop: '10px' }}
                                container
                                xs={12}
                                md={9}
                                item
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <Typography
                                    style={{ fontSize: 16 }}
                                    variant="h4"
                                >
                                    Religion:
                                </Typography>

                                <Typography
                                    style={{ fontSize: 16 }}
                                    variant="h4"
                                >
                                    {userData?.reddi_score_obj?.religionScore ??
                                        0}
                                </Typography>
                            </Grid>
                            <Grid
                                style={{ marginTop: '10px' }}
                                container
                                xs={12}
                                md={9}
                                item
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <Typography
                                    style={{ fontSize: 16 }}
                                    variant="h4"
                                >
                                    Causes:
                                </Typography>

                                <Typography
                                    style={{ fontSize: 16 }}
                                    variant="h4"
                                >
                                    {userData?.reddi_score_obj?.causesScore ??
                                        0}
                                </Typography>
                            </Grid>
                            <Grid
                                style={{ marginTop: '10px' }}
                                container
                                xs={12}
                                md={9}
                                item
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <Typography
                                    style={{ fontSize: 16 }}
                                    variant="h4"
                                >
                                    Point Last Activity:
                                </Typography>

                                <Typography
                                    style={{ fontSize: 16 }}
                                    variant="h4"
                                >
                                    {userData?.pointLastActivity ?? 0}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    <Grid item xs={12}>
                        <Grid
                            style={{
                                marginTop: '10px',
                                marginBottom: '10px',
                            }}
                            container
                            xs={12}
                            md={9}
                            item
                            justifyContent="space-between"
                        >
                            <Typography
                                style={{
                                    fontSize: 18,

                                    marginLeft: '24px',
                                }}
                                variant="h4"
                            >
                                Total Points
                            </Typography>

                            <Typography style={{ fontSize: 18 }} variant="h4">
                                {userData?.point ?? 0}
                            </Typography>
                        </Grid>
                    </Grid>
                    {/* ------------------------------------------------------------------------------------ pages section */}
                    {/* ------------------------------------------------------------------------------------ footer section */}
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                </Grid>
            </Dialog>
        </>
    )
}

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Fade timeout={350} ref={ref} {...props} />
})
export default ReddiScore
