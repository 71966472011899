import { Button, Divider, Menu, MenuItem, Typography } from "@material-ui/core";
import React from "react";
import BlockUser from "../ManageUsers/UserBlock";
import VeiwProfile from "./ViewProfile/ViewProfile";
import more from "../../asset/img/More horizontal.png";
import ReportReasons from "./ReportReasons";
const RowActionButton = ({ set_Data, value }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        onClick={handleClick}
        variant="outlined"
        startIcon={<img src={more} alt="" />}
      >
        Action
      </Button>
      <Menu
        anchorEl={anchorEl}
        onClose={handleClose}
        elevation={0}
        open={Boolean(anchorEl)}
        PaperProps={{
          style: {
            boxShadow: "0px 2px 12px rgba(205, 205, 205, 0.7)",
            border: "1px solid rgba(0, 0, 0, 0.08)",
            borderRadius: 10,
            padding: 16,
            opacity: 1,
          },
        }}
        style={{
          position: "absolute",
          top: 40,
          left: -50,
          opacity: 1,
        }}
      >
        <Typography style={{ color: "black" }} variant="body1">
          Actions
        </Typography>
        <Divider />
        <MenuItem style={{ paddingLeft: 0, opacity: 1 }} button={false}>
          <ReportReasons data={value.row} />
        </MenuItem>
        <MenuItem style={{ paddingLeft: 0, opacity: 1 }} button={false}>
          <BlockUser
            data={value.row}
            userName={value.row.name ? value.row.name : value.row.userName}
            set_Data={set_Data}
          />
        </MenuItem>
        <MenuItem style={{ paddingLeft: 0, opacity: 1 }} button={false}>
          <VeiwProfile isUser={true} data={value.row} set_data={set_Data} />
        </MenuItem>
      </Menu>
    </>
  );
};

export default RowActionButton;
