import { Button } from "@material-ui/core";
import React from "react";
import { update_user } from "../../api/Users_Api";
import { SetAsFounderIcon } from "../../icons/SetAsFounderIcon";

export const SetAsFounder = ({data,set_Data}) => {
 
  const handleClick = (value) => {
    update_user(data._id, { isFunder: value })
      .then((res) => {
        set_Data((pre) =>
        pre.map((item) => {
          if (item._id === data._id) {
            return { ...item, isFunder: value };
          } else {
            return item;
          }
        })
      );
      })
      .catch((error) => {});
  };

  return (
    <Button type="text" startIcon={<SetAsFounderIcon />} onClick={()=>handleClick(!data.isFunder)}>
      {data.isFunder === true ? "Remove founder" : "Set as founder"}
    </Button>
  );
};
