import * as yup from "yup";

export const validation = yup.object().shape({
  name: yup.string().required("Type your name"),
  emaill: yup.string().required("Type your email").email("Email is wrong"),
  passwordd: yup.string().required("Fill the password section"),
  confirm_password: yup
    .string()
    .oneOf([yup.ref("passwordd"), null], "Wrong password")
    .required("Type your password again"),
});
