import { Button, Dialog, Fade, Grid, Typography } from "@material-ui/core";
import React from "react";
import Store from "../../redux/Store";
import { set_message } from "../../redux/ActionCreators";
import { delete_membership } from "../../api/Memberships";
const DeletePackage = ({
  open,
  setOpen,
  currentPackage,
  setData,
}) => {
  const handleDelete = () => {
    delete_membership(currentPackage._id).then((res) => {
      if (!res) return;
      setData(prev => prev.filter(c => c._id !== res.data._id));
      Store.dispatch(
        set_message({
          mode: true,
          message: "Delete Package success.",
          color: "success",
        })
      );
      setOpen(false);
    });
  }
  return (


    <Dialog
      maxWidth="sm"
      fullWidth={true}
      open={open}
      onClose={() => { setOpen(false); }}
      TransitionComponent={Transition}
      onClick={(e) => e.stopPropagation()}
    >
      <Grid
        xs={12}
        container
        item
        spacing={3}
        style={{ margin: "auto", padding: '20px' }}
        justifyContent="center"
      >
        <Grid item xs={12} container alignItems="flex-end">
          <Typography style={{
            fontFamily: "'Manrope'",
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: "18px",
            lineHeight: "24px"
          }}>{`Are you sure you want to delete  “${currentPackage.title}” ?`}</Typography>

        </Grid>

      </Grid>
      <Grid item xs={12} container justifyContent="flex-end" style={{ padding: '20px' }}>
        <Button
          variant="outlined"
          color="default"
          style={{ borderRadius: 8 }}
          onClick={() => {

            setOpen(false);
          }}
        >
          Cancel
        </Button>
        <Button
          variant='contained'

          style={{
            borderRadius: 8,
            background: '#BA5A6B',
            color: '#FFFFFF',
            marginLeft: '8px'
          }}
          onClick={() => {
            handleDelete();
          }}

        >Submit</Button>

      </Grid>
    </Dialog >

  );
};

export default DeletePackage;
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Fade timeout={350} ref={ref} {...props} />;
});
