import React from 'react'
import { countryData } from '../asset/countryData'
export const getCountryFlag = (country) => {
    let flag = ''
    if (country && country.length > 2) {
        const temp = countryData.filter((x) =>
            x.name.toLowerCase().includes(country.toLowerCase())
        )
        if (temp.length > 0) {
            flag = temp[0].code
        } else {
            //console.log(country,'Not Found');
        }
    } else {
        flag = country?.toUpperCase() || ''
    }
    return `https://purecatamphetamine.github.io/country-flag-icons/3x2/${flag}.svg`
}

export const cmHeightArrForUser = [
    '122',
    '123',
    '124',
    '125',
    '126',
    '127',
    '128',
    '129',
    '130',
    '131',
    '132',
    '133',
    '134',
    '135',
    '136',
    '137',
    '138',
    '139',
    '140',
    '141',
    '142',
    '143',
    '144',
    '145',
    '146',
    '147',
    '148',
    '149',
    '150',
    '151',
    '152',
    '153',
    '154',
    '155',
    '156',
    '157',
    '158',
    '159',
    '160',
    '161',
    '162',
    '163',
    '164',
    '165',
    '166',
    '167',
    '168',
    '169',
    '170',
    '171',
    '172',
    '173',
    '174',
    '175',
    '176',
    '177',
    '178',
    '179',
    '180',
    '181',
    '182',
    '183',
    '184',
    '185',
    '186',
    '187',
    '188',
    '189',
    '190',
    '191',
    '192',
    '193',
    '194',
    '195',
    '196',
    '197',
    '198',
    '199',
    '200',
    '201',
    '202',
    '203',
    '204',
    '205',
    '206',
    '207',
    '208',
    '209',
    '210',
    '211',
    '212',
    '213',
    '214',
    '215',
    '216',
    '217',
    '218',
    '219',
    '220',
]

export const feetHeightArrForUser = [
    '4.0',
    '4.1',
    '4.2',
    '4.3',
    '4.4',
    '4.5',
    '4.6',
    '4.7',
    '4.8',
    '4.9',
    '4.10',
    '4.11',
    '5.0',
    '5.1',
    '5.2',
    '5.3',
    '5.4',
    '5.5',
    '5.6',
    '5.7',
    '5.8',
    '5.9',
    '5.10',
    '5.11',
    '6.0',
    '6.1',
    '6.2',
    '6.3',
    '6.4',
    '6.5',
    '6.6',
    '6.7',
    '6.8',
    '6.9',
    '6.10',
    '6.11',
    '7.0',
    '7.1',
    '7.2',
    '7.3',
    '7.4',
    '7.5',
    '7.6',
    '7.7',
    '7.8',
    '7.9',
    '7.10',
    '7.11',
    '8.0',
    '8.1',
    '8.2',
    '8.3',
    '8.4',
    '8.5',
    '8.6',
    '8.7',
    '8.8',
    '8.9',
    '8.10',
    '8.11',
]

export const heightTypeArr = ['CM', 'FEET']

export const cmToFeetAndInches = (cm) => {
    // Convert cm to total inches
    var totalInches = cm * 0.393701

    // Calculate feet from total inches
    var feet = Math.floor(totalInches / 12)

    // Calculate remaining inches
    var inches = totalInches % 12

    return feet + '.' + inches.toFixed()
}

// feet and inches into cm
export const feetAndInchesToCm = (feetAndCM) => {
    const feet = Number(feetAndCM.split('.')[0])
    const inches = Number(feetAndCM.split('.')[1])
    // Convert feet to inches and add the remaining inches
    var totalInches = feet * 12 + inches

    // Convert total inches to centimeters
    var cm = totalInches * 2.54

    return Number(cm.toFixed())?.toFixed(1)
}

export const VACCINATIONSTATUSDROPDOWN = [
    'Vaccinated',
    'Partially vaccinated',
    'Not yet vaccinated',
    'Prefer Not to Say',
]

export const PETDROPDOWNVALUES = ['Dog', 'Cat', 'Other pets', 'No pets']

export const LIKESDROPDOWNDOWN = [
    'Humour & playfulness',
    'Fondness & admiration',
    'Accountability',
    'Generosity',
    'Vulnerability',
    'Shared interests',
    'Empathy & understanding',
    'Physical attraction',
    'Effective communication',
    'Ambition',
    'Independence',
    'Compassion',
    'Kindness',
    'Alignment',
    'Flexibility',
    'Intellectual stimulation',
    'Supportiveness',
    'Financial responsibility',
    'Partnership',
    'Friendship',
    'Spontaneity & Adventure',
    'Respect',
    'Family-oriented',
    'Open-mindedness',
    'Integrity',
    'Affectionate gestures',
    'Compatibility',
    'Emotional connection',
    'Shared values',
    'Ability to manage conflict',
    'Emotional maturity',
    'Trustworthiness',
    'Honesty',
    'Loyalty & commitment',
    'Non-judgmental',
    'Patience',
]
export const DIsLIKESDROPDOWNDOWN = [
    'Conflict',
    'Criticism',
    'Defensiveness',
    'Stonewalling',
    'Gaslighting',
    'Conflicting beliefs',
    'Inconsistency',
    'Absent-mindedness',
    'Self-neglect',
    'Sexual mismatch',
    'Substance abuse',
    'Geographical distance',
    'Insecurity',
    'Domineering',
    'Disrespect',
    'Lack of consideration',
    'Lack of interest',
    'Jealousy',
    'Lack of commitment',
    'Poor communication',
    'Inability to solve problems',
    'Persistent disagreements',
    'Lifestyle clash',
    'Inability to resolve conflicts',
    'Emotional instability',
    'Detachment',
    'Rudeness',
    "Stubbornness'z",
    'Absence of support',
    'Appreciation of differences',
    'Self-interest',
    'Closed-mindedness',
    'Unreliability',
    'Irresponsibility',
    'Lack of compromise',
    'Financial dependence',
]

export const GENDERDROPDOWN = ['Female', 'Male', 'Transgender', 'Other']
