import { Grid, Typography, Collapse, LinearProgress } from "@material-ui/core";

import React from "react";

import AddNewFAQ from "./AddNewFAQ";
import GeneralFAQ from "./GeneralFAQ";

import { get_FAQ } from "../../api/HelpCenter_Api";
import { connect } from "react-redux";

const HelpCenter = ({ loading }) => {
  const [FAQS, set_FAQS] = React.useState([]);

  React.useEffect(() => {
    get_FAQ().then((res) => {
      if (!res) return;
      let data = res.data.faqs.sort((a, b) => {
        if (a.order > b.order) return 1;
        return -1;
      });

      set_FAQS(data);
    });
  }, []);

  return (
    <Grid item xs={12} style={{ minHeight: "100%" }}>
      <Grid
        item
        container
        direction="column"
        justifyContent="space-between"
        style={{ height: 80 }}
      >
        <Typography variant="h5">FAQ</Typography>
        <Typography variant="subtitle1">
          View, add, edit and delete the FAQs
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Collapse in={loading}>
          <LinearProgress />
        </Collapse>
      </Grid>
      {/* ------------------------------------------------------------------------------------------- FAQ section */}
      <GeneralFAQ data={FAQS} set_data={set_FAQS} />
      <Grid item xs={12}>
        <AddNewFAQ set_data={set_FAQS} />
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (props) => {
  const { GeneralReducer } = props;

  const { loading, search } = GeneralReducer;

  return { loading, search };
};

export default connect(mapStateToProps)(HelpCenter);
