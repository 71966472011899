import { createTheme } from "@material-ui/core";

export const Theme = createTheme({
  palette: {
    primary: {
      main: "#050B7A",
      light: "#65819D",
      dark: "#fff",
    },
    secondary: {
      main: "#BA5A6B",
      light: "#050B7A",
    },
    success: {
      main: "#000",
    },
    error: {
      main: "#FF6666",
    },
    info: {
      main: "#007E47",
    },
    action: {
      disabled: "#7D7D7D",
    },
  },
  typography: {
    fontFamily: "manrope",
    h1: {
      fontFamily: "inter",
      fontWeight: 100,
      fontSize: 64,
    },
    h3: {
      fontFamily: "inter",
      fontSize: 40,
    },
    h4: {
      fontFamily: "inter",
      fontWeight: 700,
      fontSize: 24,
    },
    h5: {
      fontSize: 32,
      fontFamily: "interBold",
    },
    h6: {
      fontSize: 16,
      fontFamily: "interBold",
    },
    body1: {
      fontSize: 14,
      fontFamily: "inter",
      fontWeight: 400,
      opacity: 0.7,
      color: "rgba(5, 6, 15,0.7)",
    },
    body2: {
      fontSize: 18,
      fontFamily: "inter",
      fontWeight: 700,
    },
    subtitle1: {
      fontSize: 16,
      fontWeight: 500,
      color: "rgba(5, 6, 15, 0.4)",
      fontFamily: "inter",
    },
    subtitle2: {
      fontSize: 14,
      fontWeight: 500,
      color: "#040921",
      fontFamily: "manrope",
      opacity: 0.7,
    },
    caption: {
      fontSize: 13,
    },
    normalText: {
      color: "black",
      fontWeight: 500,
      fontSize: "14px",

    },
  },
  overrides: {
    MuiButton: {
      label: {
        textTransform: "capitalize",
        fontWeight: 400,
        fontFamily: "inter",
        fontSize: 14,
      },

      containedSizeLarge: {
        borderRadius: 12,
        boxShadow: "unset",
        color: "black",
        backgroundColor: "#fff",
        fontSize: 20,
        fontWeight: 800,
        height: 40,
      },
    },
    MuiTab: {
      labelIcon: {
        position: "relative",
        top: 50,
      },
      textColorSecondary: {
        fontWeight: 500,
        fontFamily: "inter",
        fontSize: 16,
        color: "rgba(5, 6, 15, 0.4)",
      },
    },

    MuiFab: {
      extended: { padding: "0 8px", height: 40 },
    },
    MuiDivider: {
      root: {
        border: "1.5px solid #D4D4D4",
      },
    },
    // MuiList: {

    //   root: {
    //     background: "#FFFFFF",
    //     boxShadow: "0px 4px 17px rgba(0, 0, 0, 0.13)",
    //     borderRadius: "10px"

    //   }
    // },
    // MuiListItemText: {
    //   root: {
    //     color: '#0E0F1A'

    //   }
    // }
  },
});
