import { Typography } from '@material-ui/core'
import { FormLabel, Grid, Stack, TextField } from '@mui/material'
import React from 'react'

const FreeType = ({ item2, matchMakeMeDataObj, handleChangeQuestionnair }) => {
    return (
        <Grid container item xs={6}>
            <Grid item xs={12}>
                <Stack direction="column">
                    <FormLabel
                        style={{
                            marginBottom: '5px',
                        }}
                        htmlFor="first-name"
                    >
                        {/* {item2?.sequence}.  */}
                        {item2?.question_title}
                    </FormLabel>

                    <TextField
                        size="small"
                        value={matchMakeMeDataObj[item2?._id]}
                        id={item2?._id}
                        onChange={(event) => {
                            const value = event.target.value
                            const id = event.target.id
                            handleChangeQuestionnair(id, value)
                        }}
                    />
                    {item2?.question_subtext ? (
                        <Typography
                            variant="body1"
                            style={{ fontSize: 14, marginTop: '4px' }}
                            color="textSecondary"
                        >
                            <b>Note</b>: <i>{item2?.question_subtext}</i>
                        </Typography>
                    ) : (
                        ''
                    )}
                </Stack>
            </Grid>
        </Grid>
    )
}

export default FreeType
