import { Button, Dialog, Fade, Grid, Typography } from '@material-ui/core'

import React, { useState } from 'react'

import { deleteUserImageApi } from '../../../../api/Users_Api'
import { Delete } from '@material-ui/icons'
import { CircularProgress } from '@mui/material'
const DeleteUserImage = ({
    handleCloseAction,
    setUserImages,
    imageId,
    userId,
}) => {
    const [open, setOpen] = React.useState(false)
    const [loading, setLoading] = useState(false)
    const handleOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const handleDeleteUserImage = async () => {
        setLoading(true)
        const data = await deleteUserImageApi(imageId, userId)
        setLoading(false)
        setUserImages(data?.data?.user?.images)
        handleClose()
        handleCloseAction()
        console.log('data_debugg', data)
    }

    return (
        <>
            <Button
                style={{ color: 'black', fontSize: '1rem' }}
                variant="text"
                onClick={handleOpen}
            >
                Delete Image
            </Button>
            <Dialog
                maxWidth="sm"
                fullWidth={true}
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
                onClick={(e) => e.stopPropagation()}
            >
                <Grid container style={{ padding: 32 }}>
                    <Grid item xs={12} style={{ marginBottom: 24 }}>
                        <Typography variant="body2">
                            Are you sure you want to delete this image?
                        </Typography>
                    </Grid>

                    <Grid
                        style={{ marginTop: 24 }}
                        container
                        justifyContent="flex-end"
                    >
                        <Button
                            variant="outlined"
                            onClick={() => {
                                handleClose()
                            }}
                        >
                            cancel
                        </Button>
                        <Button
                            variant="contained"
                            color="secondary"
                            // disabled={formik.values.reason === ""}
                            onClick={() => handleDeleteUserImage()}
                            style={{
                                color: 'white',
                                marginLeft: 16,
                            }}
                        >
                            {loading ? (
                                <CircularProgress
                                    style={{ color: '#ffffff' }}
                                    size={20}
                                />
                            ) : (
                                'Delete'
                            )}
                        </Button>
                    </Grid>
                </Grid>
            </Dialog>
        </>
    )
}

export default DeleteUserImage
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Fade timeout={350} ref={ref} {...props} />
})
