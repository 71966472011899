import { Grid } from '@material-ui/core'
import React, { useState } from 'react'

import { Tooltip, withStyles } from '@material-ui/core'

import { styles } from '../../../styles/styles'
import { Stack } from '@mui/material'

const AttachmentStyleReport = ({ attachment_style_report }) => {
    return attachment_style_report ? (
        <Grid className={styles.box} style={{ padding: '20px 15px' }}>
            <Grid spacing={3} style={{ marginTop: '10px' }}>
                <div
                    style={{
                        padding: '10px',
                        height: '500px',
                        overflowY: 'auto',
                    }}
                >
                    {attachment_style_report.split('\n').map((line, index) => (
                        <p
                            key={index}
                            style={{
                                fontSize: '16px',
                                fontWeight: 400,
                            }}
                        >
                            {line
                                .split(/(\*\*.*?\*\*)/g)
                                .map((part, i) =>
                                    part.startsWith('**') ? (
                                        <strong key={i}>
                                            {part.replace(/\*\*/g, '')}
                                        </strong>
                                    ) : (
                                        part
                                    )
                                )}
                        </p>
                    ))}
                </div>
            </Grid>
        </Grid>
    ) : (
        <Grid
            className={styles.box}
            style={{ padding: '20px 15px', height: '300px' }}
        >
            Attachment Style Report Not Generated
        </Grid>
    )
}

export default AttachmentStyleReport

const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 14,
        padding: '8px 16px',
    },
    arrow: {
        color: 'white',
    },
}))(Tooltip)
