import {
    Avatar,
    Divider,
    Grid,
    LinearProgress,
    Typography,
} from '@material-ui/core'
import { DataGrid, GridOverlay } from '@material-ui/data-grid'
import { format } from 'date-fns'

import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import { ExportTextfield, SearchTextField } from '../../components/Textfields'
import { styles } from '../../styles/styles'
import RowAction from './RowAction'
import sorter from '../../asset/img/sorter.png'
// import clock from "../../asset/img/clock.png";
import { get_all_dropdowns } from '../../api/UserRequest_Api'
import {
    get_matchmakeme_users,
    get_matchmakeme_users_list,
    get_users,
} from '../../api/Users_Api'
import moment from 'moment'
import CustomPagination from '../Blogs/Pagination'
import Store from '../../redux/Store'
import { page_change, set_sort } from '../../redux/ActionCreators'
import { connect } from 'react-redux'
import { CircularProgress, Stack } from '@mui/material'
import { Status } from '../../components/Status'
import { StatusFilter } from '../../components/StatusFilter'
import CustomBackdrop from '../../components/CustomBackdrop'
import { useParams } from 'react-router-dom'
import BasicPagination from './Pagination'

const MatchMakeMeUsers = ({ sort, search, pagination, loading }) => {
    const params = useParams()
    const [userName, setUserName] = useState('')
    const [loader, setLoader] = useState(true)
    const style = styles()
    const [data, set_Data] = useState([])
    const [error, setError] = useState(false)
    const [deleteMuatation, setDeleteMutation] = useState({})

    const [industryDropdownOptions, setIndustryDropdownOptions] = useState([])
    const [educationDropdownOptions, setEducationDropdownOptions] = useState([])

    const [salaryDropdownOptions, setSalaryDropdownOptions] = useState([])

    const [lookingForDropdownOptions, setLookingForDropdownOptions] = useState(
        []
    )
    const [relocateForLoveDropdownOptions, setRelocateForLoveDropdownOptions] =
        useState([])
    const [personalityDropdownValues, setpersonalityDropdownValues] = useState(
        []
    )
    const [smokingDropdownOptions, setSmokingDropdownOptions] = useState([])
    const [zodiacDropdownOptions, setZodiacDropdownOptions] = useState([])
    const [drinkingDropdownOptions, setDrinkingDropdownOptions] = useState([])
    const [sleepingHabbitsDropdownOptions, setSleepingHabbitsDropdownOptions] =
        useState([])
    const [paginateObj, setPaginateObj] = useState({
        page: 1,
        totalPages: 1,
        totalRecords: 0,
    })
    console.log('paginateObj', paginateObj)
    const [counts, set_count] = useState({
        active: 0,
        blocked: 0,
        deleted: 0,
    })

    React.useEffect(() => {
        get_all_dropdowns()
            .then((res) => {
                const salaries = res?.find((d) => {
                    if (d.dropdownTitle === 'salary') {
                        return true
                    }
                })
                setSalaryDropdownOptions(
                    salaries?.optionList?.map((item) => item?.name)
                )
                const industry = res?.find((d) => {
                    if (d.dropdownTitle === 'industry') {
                        return true
                    }
                })
                setIndustryDropdownOptions(
                    industry?.optionList?.map((item) => item?.name)
                )
                const education = res?.find((d) => {
                    if (d.dropdownTitle === 'education') {
                        return true
                    }
                })
                setEducationDropdownOptions(
                    education?.optionList?.map((item) => item?.name)
                )

                const smoke = res?.find((d) => {
                    if (d.dropdownTitle === 'smoking') {
                        return true
                    }
                })
                setSmokingDropdownOptions(
                    smoke?.optionList?.map((item) => item?.name)
                )
                const drink = res?.find((d) => {
                    if (d.dropdownTitle === 'drinking') {
                        return true
                    }
                })
                setDrinkingDropdownOptions(
                    drink?.optionList?.map((item) => item?.name)
                )
                const personalityType = res?.find((d) => {
                    if (d.dropdownTitle === 'personalityType') {
                        return true
                    }
                })
                setpersonalityDropdownValues(
                    personalityType?.optionList?.map((item) => item?.name)
                )
                const zodiac = res?.find((d) => {
                    if (d.dropdownTitle === 'zodiac') {
                        return true
                    }
                })
                setZodiacDropdownOptions(
                    zodiac?.optionList?.map((item) => item?.name)
                )
                const relocateForLove = res?.find((d) => {
                    if (d.dropdownTitle === 'relocateForLove') {
                        return true
                    }
                })
                console.log('relocated', relocateForLove?.optionList)
                setRelocateForLoveDropdownOptions(
                    relocateForLove?.optionList?.map((item) => item?.name)
                )

                const sleepingHabbits = res?.find((d) => {
                    if (d.dropdownTitle === 'sleepingHabits') {
                        return true
                    }
                })

                setSleepingHabbitsDropdownOptions(
                    sleepingHabbits?.optionList?.map((item) => item?.name)
                )
                const looking = res?.find((d) => {
                    if (d.dropdownTitle === 'lookingFor') {
                        return true
                    }
                })
                setLookingForDropdownOptions(
                    looking?.optionList?.map((item) => item?.name)
                )
            })
            .catch((e) => console.log(e.message))
    }, [])
    React.useEffect(() => {
        setLoader(true)
        const userId = params?.id
        get_matchmakeme_users_list(userId, paginateObj.page)
            .then((res) => {
                if (res?.response?.data?.data?.error?.message) {
                    setError(true)
                    setLoader(false)
                } else {
                    const responseData = res?.data?.data
                    setPaginateObj({
                        page: paginateObj.page,
                        totalPages: responseData?.totalPages,
                        totalRecords: responseData?.totalRecords,
                    })

                    set_Data(responseData?.matchedUsers)
                    setUserName(res?.data?.userName)
                    setError(false)
                    setLoader(false)
                }
            })
            .catch((err) => {
                setLoader(false)
                setError(true)
                console.log('error', err?.response)
            })
    }, [paginateObj.page, deleteMuatation])

    const header_list = [
        {
            field: 'fullName',
            headerName: (
                <Stack
                    direction="column"
                    justifyContent={'space-around'}
                    alignItems="left"
                >
                    <Typography
                        style={{
                            opacity: 1,
                            color: 'black',
                            paddingTop: '12px',
                        }}
                        variant="body1"
                    >
                        User
                    </Typography>
                </Stack>
            ),
            minWidth: 270,
            sortable: false,
            flex: 1,
            headerAlign: 'left',
            align: 'left',

            renderCell: (value) => {
                return (
                    <Grid
                        container
                        alignItems="center"
                        justifyContent="flex-start"
                    >
                        <Avatar
                            src={`${value.row?.images[0]?.croped}`}
                            style={{ marginRight: 16, width: 45, height: 45 }}
                        />
                        <Grid
                            container
                            alignItems="left"
                            direction="column"
                            item
                            style={{ width: 'calc(100% - 62px)' }}
                        >
                            <Grid container alignItems="center">
                                <Typography
                                    style={{ opacity: 1, color: 'black' }}
                                    variant="body1"
                                >
                                    {(value?.row?.firstName ?? '') +
                                        ' ' +
                                        (value?.row?.lastName ?? '')}
                                    {/* <Link to={`/user-information/${value.id}`}>{value.value}</Link> */}
                                </Typography>
                            </Grid>
                            <Typography
                                style={{ fontSize: 12 }}
                                variant="body1"
                            >
                                {moment(value.row?.dateOfBirth).format(
                                    'DD.MM.YYYY'
                                )}
                            </Typography>
                            {/* <Typography
                                style={{ fontSize: 12 }}
                                variant="body1"
                            >
                                    {value.row?.address
                                        ? `${value.row?.address?.city} ,${value?.row?.address?.country}`
                                        : null}
                            </Typography> */}
                        </Grid>
                    </Grid>
                )
            },
        },
        {
            field: 'email',
            headerName: (
                <Stack
                    direction="column"
                    justifyContent={'space-around'}
                    alignItems="left"
                >
                    <Typography
                        style={{
                            opacity: 1,
                            color: 'black',
                            paddingTop: '12px',
                            marginLeft: '5px',
                        }}
                        variant="body1"
                    >
                        Email
                    </Typography>
                </Stack>
            ),
            minWidth: 230,
            flex: 1,
            sortable: false,
            headerAlign: 'left',
            align: 'left',

            renderCell: (value) => (
                <Stack>
                    {
                        <Typography
                            variant="body1"
                            style={{
                                fontSize: 12,
                                color: 'black',
                                marginLeft: '10px',
                            }}
                        >
                            {value?.row?.email ? value?.row?.email : 'NA'}
                        </Typography>
                    }
                </Stack>
            ),
        },
        {
            field: 'phoneNumber',
            headerName: (
                <Stack
                    direction="column"
                    justifyContent={'space-around'}
                    alignItems="left"
                >
                    <Typography
                        style={{
                            opacity: 1,
                            color: 'black',
                            paddingTop: '12px',
                            marginLeft: '5px',
                        }}
                        variant="body1"
                    >
                        Phone Number
                    </Typography>
                </Stack>
            ),
            minWidth: 230,
            sortable: false,
            flex: 1,
            headerAlign: 'left',
            align: 'left',

            renderCell: (value) => (
                <Stack>
                    {
                        <Typography
                            variant="body1"
                            style={{
                                fontSize: 12,
                                color: 'black',
                                marginLeft: '10px',
                            }}
                        >
                            {value?.row?.mobileNumber
                                ? value?.row?.mobileNumber
                                : 'NA'}
                        </Typography>
                    }
                </Stack>
            ),
        },

        {
            field: 'lastActiveAt',
            headerName: (
                <Stack
                    direction="column"
                    justifyContent={'space-around'}
                    alignItems="left"
                >
                    <Typography
                        style={{
                            opacity: 1,
                            color: 'black',
                            paddingTop: '12px',
                            marginLeft: '5px',
                        }}
                        variant="body1"
                    >
                        Last Active
                    </Typography>
                </Stack>
            ),
            minWidth: 130,
            flex: 1,
            sortable: false,
            headerAlign: 'left',
            align: 'left',

            renderCell: (value) => (
                <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Grid container alignItems="center">
                        <Grid container alignItems="center">
                            <Typography
                                style={{
                                    fontSize: 12,
                                    color: 'black',
                                    marginLeft: '10px',
                                }}
                                variant="body1"
                            >
                                {format(
                                    new Date(
                                        value?.row?.lastActiveAt
                                            ? value?.row?.lastActiveAt
                                            : 'NA'
                                    ),
                                    'dd LLL uuuu'
                                )}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            ),
        },
        {
            field: 'action',
            headerName: 'Action',
            minWidth: 95,
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            sortable: false,
            renderCell: (value) => (
                <RowAction
                    zodiacDropdownOptions={zodiacDropdownOptions}
                    sleepingHabbitsDropdownOptions={
                        sleepingHabbitsDropdownOptions
                    }
                    drinkingDropdownOptions={drinkingDropdownOptions}
                    smokingDropdownOptions={smokingDropdownOptions}
                    relocateForLoveDropdownOptions={
                        relocateForLoveDropdownOptions
                    }
                    personalityDropdownValues={personalityDropdownValues}
                    industryDropdownOptions={industryDropdownOptions}
                    educationDropdownOptions={educationDropdownOptions}
                    salaryDropdownOptions={salaryDropdownOptions}
                    lookingForDropdownOptions={lookingForDropdownOptions}
                    setDeleteMutation={setDeleteMutation}
                    value={value}
                />
            ),
        },
    ]

    return !loader ? (
        <>
            {error ? (
                'No Data Found'
            ) : (
                <>
                    {console.log('res?.data111')}
                    <Grid xs={12} item style={{ height: '100%' }}>
                        <Grid item xs={12} style={{ marginBottom: '15px' }}>
                            <Typography variant="h5">
                                MatchMake Me Users {`(${userName?.trim()})`}
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            container
                            alignItems="center"
                            justifyContent="space-between"
                        >
                            <Grid item>
                                <Typography variant="body1">
                                    View and manage users with 'MatchMake Me'
                                    Access
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            container
                            alignItems="center"
                            justifyContent="space-between"
                            style={{ marginTop: 40 }}
                        >
                            <Grid item>
                                <Typography
                                    style={{
                                        fontSize: '18px',
                                        fontWeight: '500',
                                        color: 'black',
                                    }}
                                >
                                    Total MatchMake Me Users:{' '}
                                    {paginateObj?.totalRecords
                                        ? +paginateObj?.totalRecords >= 100
                                            ? '100+'
                                            : paginateObj?.totalRecords
                                        : 0}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            className={style.box}
                            container
                            style={{
                                height: 'calc(100% - 180px)',
                                overflow: 'auto',
                            }}
                        >
                            <DataGrid
                                columns={header_list}
                                autore
                                rows={data}
                                loading={loading}
                                disableColumnMenu
                                noda
                                getRowId={(row) => row._id}
                                hideFooter
                                style={{ border: 'none', fontSize: 14 }}
                                classes={{ cell: style.DataGridHeader }}
                                headerHeight={60}
                                rowHeight={90}
                                disableSelectionOnClick
                                disableColumnSelector
                                components={{
                                    ColumnSortedAscendingIcon: CustomSort,
                                    ColumnSortedDescendingIcon: CustomSort,
                                    LoadingOverlay: CustomLoadingOverlay,
                                    NoRowsOverlay: NoRowsOverlay,
                                }}
                            />
                        </Grid>{' '}
                        {/* <Grid item xs={12}>
                <CustomPagination data={data?.length} />
            </Grid> */}
                    </Grid>
                    {paginateObj?.totalRecords ? (
                        <Grid item xs={12}>
                            <BasicPagination
                                currentPage={paginateObj.page}
                                totalPages={paginateObj.totalPages}
                                onPageChange={(_, value) => {
                                    setPaginateObj({
                                        page: value,
                                        totalPages: paginateObj.totalPages,
                                        totalRecords: paginateObj.totalRecords,
                                    })
                                }}
                            />
                        </Grid>
                    ) : null}
                </>
            )}
        </>
    ) : (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh', // Adjust this as needed
            }}
        >
            <CircularProgress />
        </div>
    )
}

export default MatchMakeMeUsers
export function CustomLoadingOverlay() {
    return (
        <GridOverlay>
            <div style={{ position: 'absolute', top: 0, width: '100%' }}>
                <LinearProgress color="secondary" />
            </div>
        </GridOverlay>
    )
}
export const CustomSort = () => {
    return (
        <img
            src={sorter}
            alt=""
            width={11}
            height={25}
            style={{ position: 'relative', bottom: 3 }}
        />
    )
}

export const NoRowsOverlay = () => {
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
                width: '100%',
            }}
        >
            <p>No data found</p>
        </div>
    )
}
