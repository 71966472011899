import { Snackbar, Slide } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import { connect } from 'react-redux'

import { set_message } from '../redux/ActionCreators'
import { styles } from '../styles/styles'
import Store from '../redux/Store'

function MessageHandler({ message }) {
    console.log('message', message)
    const handleClose = (event, reason) => {
        console.log('reason', event, reason)
        if (reason === 'timeout') {
            Store.dispatch(
                set_message({ mode: false, message: '', color: 'success' })
            )
        } else if (reason === undefined && event) {
            Store.dispatch(
                set_message({ mode: false, message: '', color: 'success' })
            )
        }
    }
    const style = styles()
    return (
        <Snackbar
            open={message.mode}
            onClose={handleClose}
            autoHideDuration={2000}
            classes={{ anchorOriginTopRight: style.customMessage }}
            anchorOrigin={{
                vertical: message.top ? 'top' : 'bottom',
                horizontal: 'right',
            }}
            TransitionComponent={(props) => {
                return <Slide {...props} direction="left" />
            }}
        >
            <Alert
                variant="filled"
                onClose={handleClose}
                severity={message.color}
                style={{
                    color: 'white',
                    fontSize: 16,
                    height: 40,
                    display: 'flex',
                    alignItems: 'center',
                    borderRadius: 8,
                    minWidth: 310,
                }}
            >
                {message.message}
            </Alert>
        </Snackbar>
    )
}

const mapStateToProps = (props) => {
    const { GeneralReducer } = props
    const { message } = GeneralReducer
    return { message }
}
export default connect(mapStateToProps)(MessageHandler)
