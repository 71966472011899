import {
    Grid,
    Typography,
    FormLabel,
    Accordion,
    AccordionDetails,
    AccordionSummary,
    InputLabel,
} from '@material-ui/core'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import { GridExpandMoreIcon } from '@mui/x-data-grid'
import {
    TextField,
    Checkbox,
    FormGroup,
    FormControlLabel,
    Stack,
} from '@mui/material'
import { styles } from '../../../styles/styles'
import { useState } from 'react'
import { Controller } from 'react-hook-form'

const RelationshipAndCompatibility = ({
    familyPlansDropdownValues,
    register,
    form,
    errors,
}) => {
    const { control } = form
    const style = styles()
    // console.log(
    //     'familyPlansDropdownValues',
    //     form.getValues('children'),
    //     familyPlansDropdownValues
    // )
    const [marriageDropdownValues, setMarriageDropdownValues] = useState([
        'Soonish',
        'Eventually',
        'Not for me',
        'Undecided',
        'Prefer not to say',
        'Not applicable',
    ])

    const [attachmentStyleDropdownValues, setAttachmentStyleDropdownValues] =
        useState(['Anxious', 'Secure', 'Avoidant'])
    return (
        <Grid spacing={2} className={style.box}>
            <Accordion style={{ boxShadow: 'none' }}>
                <AccordionSummary
                    expandIcon={<GridExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-profile-and-compatibility-info--questionnair"
                >
                    <Typography
                        style={{
                            fontSize: '1.2rem',
                            textDecoration: 'underline',
                        }}
                    >
                        Relationship & Compatibility
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={2}>
                        {/* <Grid item container xs={4}>
                            <Grid item xs={12}>
                                <Stack direction="column">
                                    <FormLabel
                                        htmlFor="marriage"
                                        sx={{ pt: 4 }}
                                    >
                                        Marriage:
                                    </FormLabel>
                                    <TextField
                                        {...register('marriage')}
                                        placeholder="Marriage"
                                        id="marriage"
                                        // value={""}
                                        // onChange={() => {}}
                                        fullWidth
                                        margin="normal"
                                        size="small"
                                    />
                                </Stack>
                            </Grid>
                        </Grid> */}
                        <Grid item container xs={3}>
                            <Grid item xs={12}>
                                <Stack direction="column">
                                    <InputLabel htmlFor="marriage">
                                        Marriage:
                                    </InputLabel>
                                    <FormControlLabel
                                        style={{
                                            paddingTop: '16px',
                                            paddingLeft: '12px',
                                        }}
                                        control={
                                            <Controller
                                                {...register('marriage')}
                                                control={control}
                                                render={({ field }) => (
                                                    <Select
                                                        labelId="marriage"
                                                        id="demo-simple-select-marriage"
                                                        fullWidth
                                                        placeholder="marriage"
                                                        margin="normal"
                                                        size="small"
                                                        // {...field}
                                                        // defaultValue={'0'}
                                                        value={
                                                            field.value
                                                                ? field.value
                                                                : ''
                                                        }
                                                        onChange={(event) =>
                                                            field.onChange(
                                                                event.target
                                                                    .value
                                                            )
                                                        }
                                                    >
                                                        <MenuItem value={'0'}>
                                                            Select Marriage
                                                        </MenuItem>
                                                        {marriageDropdownValues?.map(
                                                            (option) => {
                                                                return (
                                                                    <MenuItem
                                                                        value={
                                                                            option
                                                                        }
                                                                    >
                                                                        {option}
                                                                    </MenuItem>
                                                                )
                                                            }
                                                        )}
                                                    </Select>
                                                )}
                                            />
                                        }
                                    />
                                </Stack>
                            </Grid>
                        </Grid>
                        {/* <Grid item container xs={4}>
                            <Grid item xs={12}>
                                <Stack direction="column">
                                    <FormLabel
                                        htmlFor="attachmentStyle"
                                        sx={{ pt: 4 }}
                                    >
                                        Attachment Style:
                                    </FormLabel>
                                    <TextField
                                        {...register('attachmentStyle')}
                                        placeholder="Attachment Style"
                                        id="attachmentStyle"
                                        // value={""}
                                        // onChange={() => {}}
                                        fullWidth
                                        margin="normal"
                                        size="small"
                                    />
                                </Stack>
                            </Grid>
                        </Grid> */}
                        <Grid item container xs={3}>
                            <Grid item xs={12}>
                                <Stack direction="column">
                                    <InputLabel htmlFor="attachmentStyle">
                                        Attachment Style:
                                    </InputLabel>
                                    <FormControlLabel
                                        style={{
                                            paddingTop: '16px',
                                            paddingLeft: '12px',
                                        }}
                                        control={
                                            <Controller
                                                {...register('attachmentStyle')}
                                                control={control}
                                                render={({ field }) => (
                                                    <Select
                                                        labelId="attachmentStyle"
                                                        id="demo-simple-select-attachmentStyle"
                                                        fullWidth
                                                        margin="normal"
                                                        size="small"
                                                        // {...field}
                                                        // defaultValue={'0'}
                                                        value={
                                                            field.value
                                                                ? field.value
                                                                : ''
                                                        }
                                                        onChange={(event) =>
                                                            field.onChange(
                                                                event.target
                                                                    .value
                                                            )
                                                        }
                                                    >
                                                        <MenuItem value={'0'}>
                                                            Select Attachment
                                                            Style
                                                        </MenuItem>
                                                        {attachmentStyleDropdownValues?.map(
                                                            (option) => {
                                                                return (
                                                                    <MenuItem
                                                                        value={
                                                                            option
                                                                        }
                                                                    >
                                                                        {option}
                                                                    </MenuItem>
                                                                )
                                                            }
                                                        )}
                                                    </Select>
                                                )}
                                            />
                                        }
                                    />
                                </Stack>
                            </Grid>
                        </Grid>
                        <Grid item container xs={3}>
                            <Grid item xs={12}>
                                <Stack direction="column">
                                    <FormLabel
                                        htmlFor="maxAttemptAttachmentStyleCounts"
                                        sx={{ pt: 4 }}
                                    >
                                        Maximum Count of Attachment Style
                                        Attempts:
                                    </FormLabel>
                                    <TextField
                                        min="1"
                                        max="100"
                                        defaultValue={3}
                                        type="number"
                                        {...register(
                                            'maxCountAttachmentStyleAttempt',
                                            {
                                                min: {
                                                    value: 3,
                                                    message:
                                                        'Value must be greater than or equal to 3',
                                                },
                                                max: {
                                                    value: 100,
                                                    message:
                                                        'Value must be less than or equal to 100',
                                                },
                                                validate: {
                                                    integerOnly: (value) =>
                                                        Number.isInteger(
                                                            Number(value)
                                                        ) ||
                                                        'Value must be an integer',
                                                },
                                            }
                                        )}
                                        placeholder="maxCountAttachmentStyleAttempt"
                                        id="maxCountAttachmentStyleAttempt"
                                        // value={""}
                                        // onChange={() => {}}
                                        fullWidth
                                        margin="normal"
                                        size="small"
                                        onFocus={(e) =>
                                            e.target.addEventListener(
                                                'wheel',
                                                function (e) {
                                                    e.preventDefault()
                                                },
                                                { passive: false }
                                            )
                                        }
                                    />

                                    <p
                                        style={{
                                            color: '#d32f2f',
                                            marginTop: 0,
                                            fontWeight: 400,
                                            fontSize: '0.75rem',
                                            textAlign: 'left',
                                            marginTop: '4px',
                                            marginBottom: 0,
                                            lineHeight: 1.66,
                                        }}
                                    >
                                        {!!errors?.maxCountAttachmentStyleAttempt
                                            ? errors
                                                  ?.maxCountAttachmentStyleAttempt
                                                  ?.message
                                            : null}
                                    </p>
                                </Stack>
                            </Grid>
                        </Grid>
                        <Grid item container xs={3}>
                            <Grid item xs={12}>
                                <Stack direction="column">
                                    <InputLabel htmlFor="children">
                                        Family Plans:
                                    </InputLabel>
                                    <FormControlLabel
                                        style={{
                                            paddingTop: '16px',
                                            paddingLeft: '12px',
                                        }}
                                        control={
                                            <Controller
                                                {...register('children')}
                                                control={control}
                                                render={({ field }) => (
                                                    <Select
                                                        placeholder="Family Plans"
                                                        labelId="children"
                                                        id="demo-simple-select-children"
                                                        fullWidth
                                                        margin="normal"
                                                        size="small"
                                                        // {...field}
                                                        // defaultValue={'0'}
                                                        value={
                                                            field.value
                                                                ? field.value
                                                                : ''
                                                        }
                                                        onChange={(event) =>
                                                            field.onChange(
                                                                event.target
                                                                    .value
                                                            )
                                                        }
                                                    >
                                                        <MenuItem value={'0'}>
                                                            Select Family Plans
                                                        </MenuItem>
                                                        {familyPlansDropdownValues?.map(
                                                            (option) => {
                                                                return (
                                                                    <MenuItem
                                                                        value={
                                                                            option
                                                                        }
                                                                    >
                                                                        {option}
                                                                    </MenuItem>
                                                                )
                                                            }
                                                        )}
                                                    </Select>
                                                )}
                                            />
                                        }
                                    />
                                </Stack>
                            </Grid>
                        </Grid>
                        {/* <Grid item container xs={6}>
            <Grid item xs={5}>
              <FormLabel htmlFor="relationshipMateValueScore" sx={{ pt: 4 }}>
                Relationship Mate Value Score:
              </FormLabel>
            </Grid>
            <Grid item xs={7}>
              <TextField
                {...register("relationshipMatevalueScore")}
                placeholder="Relationship Mate Value Score"
                id="relationshipMateValueScore"
                // value={""}
                // onChange={() => {}}
                fullWidth
                margin="normal"
                size="small"
              />
            </Grid>
          </Grid>
          <Grid item container xs={6}>
            <Grid item xs={4}>
              <FormLabel htmlFor="attachmentStyleScore" sx={{ pt: 4 }}>
                Attachment Style Score:
              </FormLabel>
            </Grid>
            <Grid item container spacing={2} xs={8}>
              <Grid item xs={4}>
                <TextField
                  {...register("attachmentStyleScores.Secure")}
                  placeholder="Secure"
                  id="attachmentStyleScore"
                  // onChange={() => {}}
                  fullWidth
                  margin="normal"
                  size="small"
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  {...register("attachmentStyleScores.Anxious")}
                  placeholder="Anxious"
                  id="attachmentStyleScore"
                  // onChange={() => {}}
                  fullWidth
                  margin="normal"
                  size="small"
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  {...register("attachmentStyleScores.Avoident")}
                  placeholder="Avoident"
                  id="attachmentStyleScore"
                  // onChange={() => {}}
                  fullWidth
                  margin="normal"
                  size="small"
                />
              </Grid>
            </Grid>
          </Grid> */}
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </Grid>
    )
}

export default RelationshipAndCompatibility
