import React from "react";

export const PackageEditIcon = () => {
  return (
    <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M8 0.999668C8.55228 0.999668 9 1.44738 9 1.99967C9 2.55195 8.55228 2.99967 8 2.99967H3C2.44772 2.99967 2 3.44738 2 3.99967V15.9997C2 16.552 2.44772 16.9997 3 16.9997H15C15.5523 16.9997 16 16.552 16 15.9997V9.99967C16 9.44738 16.4477 8.99967 17 8.99967C17.5523 8.99967 18 9.44738 18 9.99967V15.9997C18 17.6565 16.6569 18.9997 15 18.9997H3C1.34315 18.9997 0 17.6565 0 15.9997V3.99967C0 2.34281 1.34315 0.999668 3 0.999668H8ZM4.86322 11.0463C4.94441 10.7712 5.26701 10.7496 5.49266 10.9156L5.55711 10.971L7.58472 12.9986C7.7876 13.2015 7.80951 13.5352 7.58439 13.6612L7.50938 13.6925L5.29495 14.3458C4.63453 14.5406 4.06706 14.0182 4.18401 13.3699L4.20995 13.2608L4.86322 11.0463ZM17.1427 1.41391C17.9238 2.19496 17.9238 3.46129 17.1427 4.24234L10.0717 11.3134C9.68114 11.7039 9.04797 11.7039 8.65745 11.3134L7.24324 9.89919C6.85271 9.50867 6.85271 8.8755 7.24324 8.48498L14.3143 1.41391C15.0954 0.632863 16.3617 0.632863 17.1427 1.41391Z" fill="#65819D" />
    </svg>


  );
};
