import {
    Avatar,
    Box,
    Button,
    Divider,
    Grid,
    LinearProgress,
    ListItemIcon,
    Menu,
    MenuItem,
    Typography,
} from '@material-ui/core'
import { DataGrid, GridOverlay } from '@material-ui/data-grid'
import { format } from 'date-fns'
import { PackageEditIcon } from '../../icons/PackageEditIcon'
import React, { useState } from 'react'
// import {Link} from 'react-router-dom'
import { Add, Done, KeyboardArrowDown } from '@material-ui/icons'
import { ExportTextfield, SearchTextField } from '../../components/Textfields'
import { styles } from '../../styles/styles'
import icon from '../../asset/img/export.png'
import sorter from '../../asset/img/sorter.png'
// import clock from "../../asset/img/clock.png";

import { get_users } from '../../api/Users_Api'
import moment from 'moment'
import CustomPagination from '../Blogs/Pagination'
import Store from '../../redux/Store'
import { page_change, set_sort } from '../../redux/ActionCreators'
import { connect } from 'react-redux'
import RowAction from './RowAction'
import { Stack } from '@mui/material'
import { Status } from '../../components/Status'
import { StatusFilter } from '../../components/StatusFilter'
import CustomBackdrop from '../../components/CustomBackdrop'
import UserAdvanceSearch from './UserAdvanceSearch'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { get_all_dropdowns } from '../../api/UserRequest_Api'
const subscriptionType = {
    GUEST: 'GUEST',
    COMMUNITY: 'COMMUNITY',
    MEMBER: 'MEMBER',
    notSubscribe: 'NOT SUBSCRIBED',
}
const buttonStyle = {
    padding: '12px 24px 12px 16px',
    background: 'rgba(101, 129, 157, 0.1)',
    border: '1px solid #D2D2D2',
    // borderRadius: '12px',
    borderRadius: 8,
    height: 40,
    marginLeft: 16,
    backgroundColor: '#FBFAFB',
    position: 'relative',
    color: '#005441',
    borderColor: '#005441',
}

const ManageUsers = ({ sort, search, pagination, loading }) => {
    const history = useHistory()
    const style = styles()
    const [openBackdrop, setOpenBackdrop] = useState(false)
    const [modalName, setModalName] = useState('')
    const [currentSubscription, setCurrentSubscription] = useState(['ALL'])
    const [Subscription, setSubscription] = useState([])
    const [subscriptionEventDropDown, setSubscriptionEventDropDown] = useState(
        []
    )
    const [currentSubcriptionEvent, setCurrentSubscriptionEvent] = useState([
        'ALL',
    ])
    const [leveling, setLeveling] = useState(['ALL'])
    const [levelingDropdown, setLevelingDropdown] = useState(['ALL'])
    console.log('currentSubcriptionEvent_value', currentSubcriptionEvent)
    const [statusValue, setStatusValue] = useState(
        'Founder,Fake,Matchmake,Golden'
    )
    const [anchorElLeveling, setAnchorElleveling] = React.useState(null)
    const [anchorEl, setAnchorEl] = React.useState(null)
    const [anchorElSubsEventType, setAnchorElSubsEventType] =
        React.useState(null)
    const [advanceSearchFormData, setAdvanceSearchFormData] = useState({})
    const [onlyAdvanceSearch, setOnlyAdvnaceSearch] = useState(false)

    const handleClickSubscription = (event) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClickLevelingEnchor = (event) => {
        setAnchorElleveling(event.currentTarget)
    }
    const handleCloseSubscription = () => {
        setAnchorEl(null)
    }
    const handleClickSubscriptionEventType = (event) => {
        setAnchorElSubsEventType(event.currentTarget)
    }
    const handleCloseSubscriptionEventType = () => {
        setAnchorElSubsEventType(null)
    }
    const handleCloseLeveling = () => {
        setAnchorElleveling(null)
    }
    const [deleteMuatation, setDeleteMutation] = useState({})
    const [data, set_Data] = useState([])
    const [industryDropdownOptions, setIndustryDropdownOptions] = useState([])
    const [educationDropdownOptions, setEducationDropdownOptions] = useState([])

    const [salaryDropdownOptions, setSalaryDropdownOptions] = useState([])

    const [lookingForDropdownOptions, setLookingForDropdownOptions] = useState(
        []
    )
    const [relocateForLoveDropdownOptions, setRelocateForLoveDropdownOptions] =
        useState([])
    const [personalityDropdownValues, setpersonalityDropdownValues] = useState(
        []
    )
    const [smokingDropdownOptions, setSmokingDropdownOptions] = useState([])
    const [zodiacDropdownOptions, setZodiacDropdownOptions] = useState([])
    const [drinkingDropdownOptions, setDrinkingDropdownOptions] = useState([])
    const [sleepingHabbitsDropdownOptions, setSleepingHabbitsDropdownOptions] =
        useState([])
    const [subscriptionDropdownOptions, setSubscriptionDropdownOptions] =
        useState([])

    console.log('🚀 ~ file: ManageUsers.js:60 ~ ManageUsers ~ value:', data)

    const modealNameObj = {
        advanced_search: 'Advanced Search',
    }

    const [counts, set_count] = useState({
        active: 0,
        blocked: 0,
        deleted: 0,
    })

    console.log('🚀 ~ file: ManageUsers.js:86 ~ ManageUsers ~ counts:', counts)
    React.useEffect(() => {
        get_all_dropdowns()
            .then((res) => {
                const salaries = res?.find((d) => {
                    if (d.dropdownTitle === 'salary') {
                        return true
                    }
                })
                setSalaryDropdownOptions(
                    salaries?.optionList?.map((item) => item?.name)
                )
                const industry = res?.find((d) => {
                    if (d.dropdownTitle === 'industry') {
                        return true
                    }
                })
                setIndustryDropdownOptions(
                    industry?.optionList?.map((item) => item?.name)
                )
                const education = res?.find((d) => {
                    if (d.dropdownTitle === 'education') {
                        return true
                    }
                })
                setEducationDropdownOptions(
                    education?.optionList?.map((item) => item?.name)
                )
                const levelingOptions = res?.find((d) => {
                    if (d.dropdownTitle === 'leveling') {
                        return true
                    }
                })
                setLevelingDropdown((prev) => {
                    const arr = levelingOptions?.optionList?.map(
                        (item) => item?.name
                    )
                    return ['ALL', ...arr]
                })

                const smoke = res?.find((d) => {
                    if (d.dropdownTitle === 'smoking') {
                        return true
                    }
                })
                setSmokingDropdownOptions(
                    smoke?.optionList?.map((item) => item?.name)
                )
                const drink = res?.find((d) => {
                    if (d.dropdownTitle === 'drinking') {
                        return true
                    }
                })
                setDrinkingDropdownOptions(
                    drink?.optionList?.map((item) => item?.name)
                )
                const personalityType = res?.find((d) => {
                    if (d.dropdownTitle === 'personalityType') {
                        return true
                    }
                })
                setpersonalityDropdownValues(
                    personalityType?.optionList?.map((item) => item?.name)
                )
                const zodiac = res?.find((d) => {
                    if (d.dropdownTitle === 'zodiac') {
                        return true
                    }
                })
                setZodiacDropdownOptions(
                    zodiac?.optionList?.map((item) => item?.name)
                )
                const relocateForLove = res?.find((d) => {
                    if (d.dropdownTitle === 'relocateForLove') {
                        return true
                    }
                })
                console.log('relocated', relocateForLove?.optionList)
                setRelocateForLoveDropdownOptions(
                    relocateForLove?.optionList?.map((item) => item?.name)
                )

                const sleepingHabbits = res?.find((d) => {
                    if (d.dropdownTitle === 'sleepingHabits') {
                        return true
                    }
                })

                setSleepingHabbitsDropdownOptions(
                    sleepingHabbits?.optionList?.map((item) => item?.name)
                )
                const looking = res?.find((d) => {
                    if (d.dropdownTitle === 'lookingFor') {
                        return true
                    }
                })
                setLookingForDropdownOptions(
                    looking?.optionList?.map((item) => item?.name)
                )
            })
            .catch((e) => console.log(e.message))
    }, [])
    React.useEffect(() => {
        if (statusValue.length === 0) {
            set_Data([])
        } else {
            setOpenBackdrop(true)
            get_users(
                statusValue,
                true,
                currentSubscription,
                advanceSearchFormData,
                onlyAdvanceSearch,
                currentSubcriptionEvent,
                leveling
            )
                .then((res) => {
                    if (res) {
                        set_count(res.data.topBar)
                        set_Data(res.data.users)
                        const sortSubs = res.data?.subscriptionDropdown ?? []
                        setSubscription(sortSubs)
                        const subsEventDropdown =
                            res.data?.subscriptionEventTypeDropdown ?? []
                        setSubscriptionEventDropDown(subsEventDropdown)
                    }
                })
                .finally(() => {
                    setOpenBackdrop(false)
                })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        pagination.page,
        statusValue,
        currentSubscription,
        search,
        sort,
        onlyAdvanceSearch,
        advanceSearchFormData,
        deleteMuatation,
        currentSubcriptionEvent,
        leveling,
    ])
    // React.useEffect(
    //     () => {
    //         if (statusValue.length === 0) {
    //             set_Data([])
    //             Store.dispatch(
    //                 page_change({
    //                     total: 0,
    //                     page: 1,
    //                     limit: 10,
    //                 })
    //             )
    //         } else {
    //             setOpenBackdrop(true)
    //             get_users(statusValue, false, currentSubscription)
    //                 .then((res) => {
    //                     if (res) {
    //                         set_Data(res.data.users)
    //                         set_count(res.data.topBar)
    //                         setSubscription(
    //                             res.data?.subscriptionDropdown || []
    //                         )
    //                     }
    //                 })
    //                 .finally(() => {
    //                     setOpenBackdrop(false)
    //                 })
    //         }
    //     },
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    //     [
    //         search,
    //         sort,
    //         statusValue,
    //         // currentSubscription,
    //         //, timer
    //     ]
    // )

    const header_list = [
        {
            field: 'fullName',
            headerName: 'User',
            minWidth: 270,
            flex: 1,
            headerAlign: 'left',
            align: 'left',

            renderCell: (value) => {
                return (
                    <Grid
                        container
                        alignItems="center"
                        justifyContent="flex-start"
                    >
                        <Avatar
                            src={`${value.row?.images[0]?.croped}`}
                            style={{ marginRight: 16, width: 45, height: 45 }}
                        />
                        <Grid
                            container
                            alignItems="left"
                            direction="column"
                            item
                            style={{ width: 'calc(100% - 62px)' }}
                        >
                            <Grid container alignItems="center">
                                <Typography
                                    style={{ opacity: 1, color: 'black' }}
                                    variant="body1"
                                >
                                    {value.value}
                                    {/* <Link to={`/user-information/${value.id}`}>{value.value}</Link> */}
                                </Typography>
                            </Grid>
                            <Typography
                                style={{ fontSize: 12 }}
                                variant="body1"
                            >
                                {moment(value.row.dateOfBirth).format(
                                    'DD.MM.YYYY'
                                )}
                            </Typography>
                            <Typography
                                style={{ fontSize: 12 }}
                                variant="body1"
                            >
                                {`${value.row.address.city}, ${value.row.address.country}`}
                            </Typography>
                        </Grid>
                    </Grid>
                )
            },
        },
        {
            field: 'email',
            headerName: (
                <Stack
                    direction="column"
                    justifyContent={'space-around'}
                    alignItems="left"
                >
                    {/* <Typography
                        style={{ opacity: 1, color: 'black' }}
                        variant="body1"
                    >
                        Email
                    </Typography> */}
                    <Typography
                        style={{ opacity: 1, color: 'black' }}
                        variant="body1"
                    >
                        Email Phone Number
                    </Typography>
                </Stack>
            ),
            minWidth: 230,
            flex: 1,
            headerAlign: 'left',
            align: 'left',

            renderCell: (value) => (
                <Stack>
                    <Typography
                        style={{ opacity: 1, color: 'black' }}
                        variant="body1"
                    >
                        {value.value}
                    </Typography>
                    {value.row.isMobileVerified && (
                        <Typography variant="body1" style={{ fontSize: 12 }}>
                            {value.row.mobileNumber}
                        </Typography>
                    )}
                </Stack>
            ),
        },
        {
            field: 'createdAt',
            headerName: (
                <Stack
                    direction="column"
                    justifyContent={'space-around'}
                    alignItems="left"
                >
                    <Typography
                        style={{ opacity: 1, color: 'black' }}
                        variant="body1"
                    >
                        Registration date
                    </Typography>
                    {/* <Typography style={{ opacity: 1, color: "black" }} variant="body1">
            Last active
          </Typography> */}
                </Stack>
            ),
            minWidth: 130,
            flex: 1,
            headerAlign: 'left',
            align: 'left',

            renderCell: (value) => (
                // <Grid container alignItems="center" justifyContent="space-between">
                //   <Grid container alignItems="center">
                //     <Grid container alignItems="center">
                <Stack>
                    <Typography
                        style={{ opacity: 1, color: 'black' }}
                        variant="body1"
                    >
                        {format(new Date(value.value), 'dd LLL uuuu')}
                    </Typography>
                    {/* <Typography variant="body1" style={{ opacity: 1, color: "black" }}>
            {moment(value.row.lastActiveAt).fromNow()}
          </Typography> */}
                </Stack>
                //     </Grid>
                //   </Grid>
                // </Grid>
            ),
        },

        {
            field: 'lastActiveAt',
            headerName: 'Last active',
            minWidth: 130,
            flex: 1,
            headerAlign: 'left',
            align: 'left',

            renderCell: (value) => (
                <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Grid container alignItems="center">
                        <Grid container alignItems="center">
                            <Typography
                                style={{ opacity: 1, color: 'black' }}
                                variant="body1"
                            >
                                {format(
                                    new Date(value.row.lastActiveAt),
                                    'dd LLL uuuu'
                                )}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            ),
        },
        {
            field: 'expirePackage',

            headerName: (
                <Stack
                    direction="column"
                    justifyContent={'space-around'}
                    alignItems="left"
                >
                    <Typography
                        style={{ opacity: 1, color: 'black' }}
                        variant="body1"
                    >
                        Subscription Expiry date
                    </Typography>
                </Stack>
            ),
            minWidth: 250,
            flex: 1,
            headerAlign: 'left',
            align: 'left',

            renderCell: (value) => (
                <Stack>
                    {/* {console.log('debug', value?.row?.expirePackage)} */}
                    <Typography
                        style={{
                            opacity: 1,
                            color: 'black',
                            paddingLeft: '7px',
                        }}
                        variant="body1"
                    >
                        {value?.row?.expirePackage
                            ? format(
                                  new Date(value?.row?.expirePackage),
                                  'dd LLL uuuu'
                              )
                            : 'NA'}
                    </Typography>
                </Stack>
            ),
        },
        {
            field: 'subscription',
            headerName: 'Subscription',
            minWidth: 150,
            flex: 1,
            headerAlign: 'left',
            align: 'left',
            sortable: true,
            renderCell: (value) => {
                return (
                    <Typography
                        style={{ color: 'black', opacity: 1, marginLeft: 8 }}
                        variant="body1"
                    >
                        {/* {value?.row?.expirePackage &&
                            new Date(value?.row?.expirePackage) > new Date() &&
                            subscriptionType['COMMUNITY']}
                        {value?.row?.expirePackage &&
                            new Date(value?.row?.expirePackage) <= new Date() &&
                            subscriptionType['notSubscribe']}
                        {!value?.row?.expirePackage && value?.row?.subscription
                            ? subscriptionType[value?.row?.subscription]
                            : ''} */}

                        {/* {value?.row?.expirePackage &&
                            new Date(value?.row?.expirePackage) > new Date() &&
                            value?.row.subscription === 'COMMUNITY' &&
                            subscriptionType['community']} */}
                        {/* {value?.row?.expirePackage &&
                            new Date(value?.row?.expirePackage) > new Date() &&
                            value?.row.subscription === 'MEMBER' &&
                            subscriptionType['member']} */}
                        {/* {value?.row?.expirePackage &&
                            new Date(value?.row?.expirePackage) <= new Date() &&
                            subscriptionType['guest']} */}
                        {/* {!value?.row?.expirePackage
                            ? subscriptionType['guest']
                            : ''} */}
                        {value?.row?.subscription ?? '-'}
                    </Typography>
                )
            },
        },
        {
            field: 'subscriptionEventType',
            headerName: 'Subscription Event Type',
            minWidth: 280,
            flex: 1,
            headerAlign: 'left',
            align: 'left',
            // sortable: true,
            renderCell: (value) => {
                return (
                    <Typography
                        style={{ color: 'black', opacity: 1, marginLeft: 8 }}
                        variant="body1"
                    >
                        {value?.row?.subscriptionEventType ? (
                            value?.row?.subscriptionEventType
                        ) : (
                            <div
                                style={{ textAlign: 'center', width: '166px' }}
                            >
                                -
                            </div>
                        )}
                    </Typography>
                )
            },
        },
        {
            field: 'leveling',
            headerName: 'User Level',
            minWidth: 280,
            flex: 1,
            headerAlign: 'left',
            align: 'left',
            // sortable: true,
            renderCell: (value) => {
                return (
                    <Typography
                        style={{ color: 'black', opacity: 1, marginLeft: 8 }}
                        variant="body1"
                    >
                        {value?.row?.leveling ? (
                            value?.row?.leveling
                        ) : (
                            <div
                                style={{ textAlign: 'center', width: '166px' }}
                            >
                                -
                            </div>
                        )}
                    </Typography>
                )
            },
        },
        {
            field: 'status',
            headerName: 'Status',
            minWidth: 115,
            headerAlign: 'left',
            align: 'left',
            sortable: true,
            renderCell: (value) => (
                <Stack direction={'row'} spacing={'8px'}>
                    <Status
                        data={value.row}
                        selected_state={statusValue}
                        set_data={set_Data}
                    />
                </Stack>
            ),
        },
        {
            field: 'action',
            headerName: 'Action',
            minWidth: 95,
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            sortable: false,
            renderCell: (value) => (
                <RowAction
                    zodiacDropdownOptions={zodiacDropdownOptions}
                    sleepingHabbitsDropdownOptions={
                        sleepingHabbitsDropdownOptions
                    }
                    drinkingDropdownOptions={drinkingDropdownOptions}
                    smokingDropdownOptions={smokingDropdownOptions}
                    relocateForLoveDropdownOptions={
                        relocateForLoveDropdownOptions
                    }
                    personalityDropdownValues={personalityDropdownValues}
                    industryDropdownOptions={industryDropdownOptions}
                    educationDropdownOptions={educationDropdownOptions}
                    salaryDropdownOptions={salaryDropdownOptions}
                    lookingForDropdownOptions={lookingForDropdownOptions}
                    set_Data={set_Data}
                    value={value}
                    setDeleteMutation={setDeleteMutation}
                />
            ),
        },
    ]

    return (
        <Grid xs={12} item style={{ height: '100%' }}>
            <Stack
                direction="row"
                width="100%"
                justifyContent={'space-between'}
            >
                <Typography variant="h5">Manage Users</Typography>
                <Button
                    onClick={() => {
                        history.push('/user-information/new')
                    }}
                    startIcon={<Add />}
                    style={{
                        padding: '8px 16px 8px 12px',
                        marginRight: '10px',
                        border: '2px solid #65819D',
                        borderRadius: '16px',
                        fontWeight: 600,
                        fontSize: '16px',

                        color: '#65819D',
                    }}
                >
                    Add User
                </Button>
            </Stack>
            <Grid
                item
                container
                alignItems="center"
                justifyContent="space-between"
            >
                <Grid item>
                    <Typography variant="body1">
                        View and manage users
                    </Typography>
                </Grid>
            </Grid>
            <Grid
                item
                xs={12}
                container
                justifyContent="flex-end"
                alignItems="center"
                style={{ height: 48, marginTop: 30 }}
            >
                <Grid item>
                    <CustomBackdrop open={openBackdrop} />
                </Grid>
                {/* advanced search */}
                <Grid
                    item
                    xs={12}
                    container
                    alignItems="center"
                    justifyContent="flex-end"
                >
                    {onlyAdvanceSearch === false && (
                        <SearchTextField
                            user={true}
                            placeholder={'Search name...'}
                        />
                    )}

                    <Button
                        variant="outlined"
                        size="small"
                        onClick={() => {
                            setModalName(modealNameObj.advanced_search)
                        }}
                        startIcon={<PackageEditIcon />}
                        style={buttonStyle}
                    >
                        Adv. Search
                    </Button>

                    {onlyAdvanceSearch === false && (
                        <Button
                            variant="outlined"
                            style={{
                                borderRadius: 8,
                                height: 40,
                                marginLeft: 16,
                                backgroundColor: '#FBFAFB',
                                position: 'relative',
                                color: '#005441',
                                borderColor: '#005441',
                            }}
                            endIcon={
                                <KeyboardArrowDown
                                    style={{
                                        position: 'relative',
                                        top: 2,
                                        transform: `rotate(${
                                            anchorEl ? 180 : 0
                                        }deg)`,
                                        transition: '0.2s',
                                    }}
                                />
                            }
                            onClick={handleClickSubscription}
                            startIcon={<img src={icon} alt="" />}
                        >
                            subscription
                        </Button>
                    )}

                    {onlyAdvanceSearch === false && (
                        <Menu
                            id="simple-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            style={{ position: 'absolute', top: 45 }}
                            open={Boolean(anchorEl)}
                            onClose={handleCloseSubscription}
                        >
                            {Subscription?.map((item, index) => {
                                return (
                                    <MenuItem
                                        sx={{ fontWeight: 'bold' }}
                                        style={{
                                            width: 140,
                                            color:
                                                item._id === currentSubscription
                                                    ? 'black'
                                                    : null,
                                        }}
                                        value={item?._id}
                                        onClick={(e) => {
                                            if (item?._id === 'ALL') {
                                                if (
                                                    currentSubscription[0] ===
                                                    'ALL'
                                                ) {
                                                } else {
                                                    setCurrentSubscription([
                                                        item?._id,
                                                    ])
                                                }
                                            } else {
                                                if (
                                                    currentSubscription.includes(
                                                        item._id
                                                    )
                                                ) {
                                                    setCurrentSubscription(
                                                        (prev) => {
                                                            const filterSubEvent =
                                                                prev.filter(
                                                                    (item1) => {
                                                                        console.log(
                                                                            'item__1',
                                                                            item1,
                                                                            item._id
                                                                        )
                                                                        return (
                                                                            item1 !==
                                                                            item._id
                                                                        )
                                                                    }
                                                                )

                                                            return filterSubEvent?.length ===
                                                                0
                                                                ? ['ALL']
                                                                : filterSubEvent
                                                        }
                                                    )
                                                } else {
                                                    setCurrentSubscription(
                                                        (prev) => {
                                                            const filterSubEvent =
                                                                prev.filter(
                                                                    (item1) =>
                                                                        item1 !==
                                                                        'ALL'
                                                                )
                                                            const subEvent = [
                                                                ...filterSubEvent,
                                                                item?._id,
                                                            ]

                                                            return subEvent
                                                        }
                                                    )
                                                }
                                            }

                                            handleCloseSubscription()
                                        }}
                                    >
                                        <Button
                                            style={{
                                                width: '180px',
                                                justifyContent: 'flex-start',
                                            }}
                                            startIcon={item.icon}
                                        >
                                            <Typography
                                                style={{
                                                    fontWeight:
                                                        currentSubscription?.includes(
                                                            item?._id
                                                        )
                                                            ? 700
                                                            : 400,
                                                    fontFamily: "'Inter'",
                                                    fontStyle: 'normal',

                                                    fontSize: '14px',
                                                    lineHeight: '17px',
                                                    color: '#0E0F1A',
                                                }}
                                            >
                                                {item?._id}
                                            </Typography>
                                        </Button>

                                        <ListItemIcon
                                            style={{
                                                justifyContent: 'flex-end',
                                                padding: 0,
                                            }}
                                        >
                                            {currentSubscription?.includes(
                                                item?._id
                                            ) ? (
                                                <Done />
                                            ) : null}
                                        </ListItemIcon>
                                    </MenuItem>
                                )
                            })}
                        </Menu>
                    )}

                    {onlyAdvanceSearch === false && (
                        <Button
                            variant="outlined"
                            style={{
                                borderRadius: 8,
                                height: 40,
                                marginLeft: 16,
                                backgroundColor: '#FBFAFB',
                                position: 'relative',
                                color: '#005441',
                                borderColor: '#005441',
                            }}
                            endIcon={
                                <KeyboardArrowDown
                                    style={{
                                        position: 'relative',
                                        top: 2,
                                        transform: `rotate(${
                                            anchorElSubsEventType ? 180 : 0
                                        }deg)`,
                                        transition: '0.2s',
                                    }}
                                />
                            }
                            onClick={handleClickSubscriptionEventType}
                            startIcon={<img src={icon} alt="" />}
                        >
                            Sub. Event Type
                        </Button>
                    )}

                    {onlyAdvanceSearch === false && (
                        <Menu
                            id="simple-menu-subscription-event-dropdown"
                            anchorEl={anchorElSubsEventType}
                            keepMounted
                            style={{ position: 'absolute', top: 45 }}
                            open={Boolean(anchorElSubsEventType)}
                            onClose={handleCloseSubscriptionEventType}
                        >
                            {subscriptionEventDropDown?.map((item) => {
                                return (
                                    <MenuItem
                                        sx={{ fontWeight: 'bold' }}
                                        style={{
                                            width: 270,
                                            color: currentSubcriptionEvent.includes(
                                                item?._id
                                            )
                                                ? 'black'
                                                : null,
                                        }}
                                        value={item?._id}
                                        onClick={(e) => {
                                            if (item?._id === 'ALL') {
                                                if (
                                                    currentSubcriptionEvent[0] ===
                                                    'ALL'
                                                ) {
                                                } else {
                                                    setCurrentSubscriptionEvent(
                                                        [item?._id]
                                                    )
                                                }
                                            } else {
                                                if (
                                                    currentSubcriptionEvent.includes(
                                                        item._id
                                                    )
                                                ) {
                                                    console.log('filterr')
                                                    setCurrentSubscriptionEvent(
                                                        (prev) => {
                                                            const filterSubEvent =
                                                                prev.filter(
                                                                    (item1) => {
                                                                        console.log(
                                                                            'item__1',
                                                                            item1,
                                                                            item._id
                                                                        )
                                                                        return (
                                                                            item1 !==
                                                                            item._id
                                                                        )
                                                                    }
                                                                )

                                                            return filterSubEvent?.length ===
                                                                0
                                                                ? ['ALL']
                                                                : filterSubEvent
                                                        }
                                                    )
                                                } else {
                                                    setCurrentSubscriptionEvent(
                                                        (prev) => {
                                                            const filterSubEvent =
                                                                prev.filter(
                                                                    (item1) =>
                                                                        item1 !==
                                                                        'ALL'
                                                                )
                                                            const subEvent = [
                                                                ...filterSubEvent,
                                                                item?._id,
                                                            ]

                                                            return subEvent
                                                        }
                                                    )
                                                }
                                            }
                                            handleCloseSubscriptionEventType()
                                        }}
                                    >
                                        <Button
                                            style={{
                                                width: '180px',
                                                justifyContent: 'flex-start',
                                            }}
                                            startIcon={item.icon}
                                        >
                                            <Typography
                                                style={{
                                                    fontWeight:
                                                        currentSubcriptionEvent?.includes(
                                                            item?._id
                                                        )
                                                            ? 700
                                                            : 400,
                                                    fontFamily: "'Inter'",
                                                    fontStyle: 'normal',

                                                    fontSize: '14px',
                                                    lineHeight: '17px',
                                                    color: '#0E0F1A',
                                                }}
                                            >
                                                {item?._id
                                                    ? item?._id
                                                          ?.toLowerCase()
                                                          ?.includes(
                                                              'subscription'
                                                          )
                                                        ? item?._id?.replace(
                                                              'SUBSCRIPTION',
                                                              'SUB.'
                                                          )
                                                        : item?._id
                                                    : null}
                                            </Typography>
                                        </Button>

                                        <ListItemIcon
                                            style={{
                                                justifyContent: 'flex-end',
                                                padding: 0,
                                            }}
                                        >
                                            {currentSubcriptionEvent?.includes(
                                                item?._id
                                            ) ? (
                                                <Done />
                                            ) : null}
                                        </ListItemIcon>
                                    </MenuItem>
                                )
                            })}
                        </Menu>
                    )}
                    {onlyAdvanceSearch === false && (
                        <Button
                            variant="outlined"
                            style={{
                                borderRadius: 8,
                                height: 40,
                                marginLeft: 16,
                                backgroundColor: '#FBFAFB',
                                position: 'relative',
                                color: '#005441',
                                borderColor: '#005441',
                            }}
                            endIcon={
                                <KeyboardArrowDown
                                    style={{
                                        position: 'relative',
                                        top: 2,
                                        transform: `rotate(${
                                            anchorElLeveling ? 180 : 0
                                        }deg)`,
                                        transition: '0.2s',
                                    }}
                                />
                            }
                            onClick={handleClickLevelingEnchor}
                            startIcon={<img src={icon} alt="" />}
                        >
                            User level
                        </Button>
                    )}
                    {console.log(
                        'levelingDropdown',
                        leveling,
                        levelingDropdown
                    )}
                    {onlyAdvanceSearch === false && (
                        <Menu
                            id="simple-menu-leveling"
                            anchorEl={anchorElLeveling}
                            keepMounted
                            style={{ position: 'absolute', top: 45 }}
                            open={Boolean(anchorElLeveling)}
                            onClose={handleCloseLeveling}
                        >
                            {levelingDropdown?.map((item) => {
                                return (
                                    <MenuItem
                                        sx={{ fontWeight: 'bold' }}
                                        style={{
                                            width: 200,
                                            color: leveling.includes(item)
                                                ? 'black'
                                                : null,
                                        }}
                                        value={item}
                                        onClick={(e) => {
                                            if (item === 'ALL') {
                                                if (leveling[0] === 'ALL') {
                                                } else {
                                                    setLeveling([item])
                                                }
                                            } else {
                                                if (leveling.includes(item)) {
                                                    console.log('filterr')
                                                    setLeveling((prev) => {
                                                        const filterLevelEvent =
                                                            prev.filter(
                                                                (item1) => {
                                                                    console.log(
                                                                        'item__1',
                                                                        item1,
                                                                        item
                                                                    )
                                                                    return (
                                                                        item1 !==
                                                                        item
                                                                    )
                                                                }
                                                            )

                                                        return filterLevelEvent?.length ===
                                                            0
                                                            ? ['ALL']
                                                            : filterLevelEvent
                                                    })
                                                } else {
                                                    setLeveling((prev) => {
                                                        const filterLevelEvent =
                                                            prev.filter(
                                                                (item1) =>
                                                                    item1 !==
                                                                    'ALL'
                                                            )
                                                        const subEvent = [
                                                            ...filterLevelEvent,
                                                            item,
                                                        ]

                                                        return subEvent
                                                    })
                                                }
                                            }
                                            handleCloseLeveling()
                                        }}
                                    >
                                        <Button
                                            style={{
                                                width: '180px',
                                                justifyContent: 'flex-start',
                                            }}
                                            startIcon={item.icon}
                                        >
                                            <Typography
                                                style={{
                                                    fontWeight:
                                                        leveling?.includes(item)
                                                            ? 700
                                                            : 400,
                                                    fontFamily: "'Inter'",
                                                    fontStyle: 'normal',

                                                    fontSize: '14px',
                                                    lineHeight: '17px',
                                                    color: '#0E0F1A',
                                                }}
                                            >
                                                {item ? item : null}
                                            </Typography>
                                        </Button>

                                        <ListItemIcon
                                            style={{
                                                justifyContent: 'flex-end',
                                                padding: 0,
                                            }}
                                        >
                                            {leveling?.includes(item) ? (
                                                <Done />
                                            ) : null}
                                        </ListItemIcon>
                                    </MenuItem>
                                )
                            })}
                        </Menu>
                    )}
                    {onlyAdvanceSearch === false && (
                        <StatusFilter setSelectedItems={setStatusValue} />
                    )}
                    <ExportTextfield
                        tabValue={statusValue}
                        advanceSearchFormData={advanceSearchFormData}
                        subscription={currentSubscription}
                        onlyAdvanceSearch={onlyAdvanceSearch}
                    />
                    {onlyAdvanceSearch === true && (
                        <Button
                            variant="outlined"
                            style={{
                                borderRadius: 8,
                                height: 40,
                                marginLeft: 16,
                                backgroundColor: '#FBFAFB',
                                position: 'relative',
                                color: '#005441',
                                borderColor: '#005441',
                            }}
                            onClick={() => {
                                setOnlyAdvnaceSearch(false)
                            }}
                        >
                            Normal Search
                        </Button>
                    )}
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Divider />
            </Grid>
            <Grid
                item
                xs={12}
                className={style.box}
                container
                style={{ height: 'calc(100% - 180px)', overflow: 'auto' }}
            >
                <DataGrid
                    columns={header_list}
                    autore
                    sortingMode="server"
                    rows={data}
                    loading={loading}
                    disableColumnMenu
                    getRowId={(row) => row._id}
                    hideFooter
                    style={{ border: 'none', fontSize: 14 }}
                    classes={{ cell: style.DataGridHeader }}
                    headerHeight={60}
                    rowHeight={90}
                    disableSelectionOnClick
                    onColumnHeaderClick={(params, event, details) => {
                        Store.dispatch(
                            set_sort({
                                name: params.field,
                                mode: sort.mode === 1 ? -1 : 1,
                            })
                        )
                    }}
                    disableColumnSelector
                    components={{
                        ColumnSortedAscendingIcon: CustomSort,
                        ColumnSortedDescendingIcon: CustomSort,
                        LoadingOverlay: CustomLoadingOverlay,
                    }}
                />
            </Grid>{' '}
            <Grid item xs={12}>
                <CustomPagination data={data?.length} />
            </Grid>
            {/* modal dialog for advance search */}
            <Grid item xs={12}>
                {modalName === modealNameObj.advanced_search && (
                    <Box style={{ padding: '20px' }}>
                        <UserAdvanceSearch
                            open={modalName === modealNameObj.advanced_search}
                            onClose={() => setModalName('')}
                            handleRowDataChange={(data) => {
                                setAdvanceSearchFormData(data)
                                setOnlyAdvnaceSearch(true)
                            }}
                        />
                    </Box>
                )}
            </Grid>
        </Grid>
    )
}
const mapStateToProps = (props) => {
    const {
        GeneralReducer: { sort, search, pagination, loading },
    } = props

    return { sort, search, pagination, loading }
}

export default connect(mapStateToProps)(ManageUsers)
export function CustomLoadingOverlay() {
    return (
        <GridOverlay>
            <div style={{ position: 'absolute', top: 0, width: '100%' }}>
                <LinearProgress color="secondary" />
            </div>
        </GridOverlay>
    )
}

export const CustomSort = () => {
    return (
        <img
            src={sorter}
            alt=""
            width={11}
            height={25}
            style={{ position: 'relative', bottom: 3 }}
        />
    )
}
