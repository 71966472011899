import { Button, Visibility } from '@material-ui/core'

import React from 'react'
import MatchMakeMeIcon from '../../asset/img/matchMakeMeIcon.png'
export const MatchMakeMeButton = ({ userId }) => {
    return (
        <Button
            variant="outlined"
            style={{ padding: '6px 15px', borderRadius: 6 }}
            // style={{
            //     borderRadius: 8,
            //     background: '#BA5A6B',
            //     color: '#FFFFFF',
            //     marginRight: '10px',
            // }}
            onClick={() => {
                window.open(`/user-connect-list/${userId}`)
            }}
        >
            <img
                src={MatchMakeMeIcon}
                alt="Match Make Me Icon"
                width={20}
                height={20}
                style={{ marginRight: 5 }}
            />
            User Stats
        </Button>
    )
}
