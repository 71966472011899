import React from 'react'

export const SetAsFounderIcon = () => {
  return (
<svg width="15" height="13" viewBox="0 0 15 13" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0.220313 9.05234L2.33672 6.93828C2.61797 6.65703 3 6.5 3.39844 6.5H5.25703C5.67188 6.5 6.00703 6.83516 6.00703 7.25V9.10625C6.00703 9.50469 5.85 9.88437 5.56875 10.1656L3.45234 12.2797C3.15937 12.5727 2.68359 12.5727 2.39062 12.2797L0.222656 10.1117C-0.0726566 9.81875 -0.0726557 9.34531 0.220313 9.05234ZM4.50469 8.56016C4.81641 8.56016 5.06719 8.30937 5.06719 7.99766C5.06719 7.68594 4.81641 7.43516 4.50469 7.43516C4.19297 7.43516 3.94219 7.68594 3.94219 7.99766C3.94219 8.30703 4.19297 8.56016 4.50469 8.56016ZM9.75 6.49766C8.09297 6.49766 6.75 5.15469 6.75 3.49766C6.75 1.84297 8.09297 0.5 9.75 0.5C11.407 0.5 12.75 1.84297 12.75 3.5C12.75 5.15469 11.407 6.49766 9.75 6.49766ZM6.75469 9.10391V7.39062C7.04063 7.30625 7.33828 7.24531 7.65 7.24531H8.04141C8.56172 7.48437 9.14062 7.62031 9.75 7.62031C10.3594 7.62031 10.9359 7.48437 11.4586 7.24531H11.85C13.5891 7.24766 15 8.65859 15 10.3977V11.3727C15 11.9937 14.4961 12.4977 13.875 12.4977H5.625C5.26172 12.4977 4.94297 12.3219 4.73672 12.0547L6.09609 10.6953C6.52031 10.2711 6.75469 9.70625 6.75469 9.10391Z" fill="black"/>
</svg>

  )
}
