import React from 'react'

export const FakeAccountOutlineIcon = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.429 10.875C12.2883 10.875 16 11.7894 16 13.625V15H4.85794V13.625C4.85794 11.7894 8.56964 10.875 10.429 10.875ZM10.429 9.5C9.6902 9.5 8.9817 9.21027 8.45931 8.69454C7.93693 8.17882 7.64345 7.47935 7.64345 6.75C7.64345 6.02065 7.93693 5.32118 8.45931 4.80546C8.9817 4.28973 9.6902 4 10.429 4C11.1677 4 11.8762 4.28973 12.3986 4.80546C12.921 5.32118 13.2145 6.02065 13.2145 6.75C13.2145 7.47935 12.921 8.17882 12.3986 8.69454C11.8762 9.21027 11.1677 9.5 10.429 9.5ZM3.46518 7.84313L4.9415 6.37875L5.93036 7.355L4.44707 8.8125L5.93036 10.27L4.9415 11.2462L3.46518 9.78188L1.98886 11.2462L1 10.27L2.48329 8.8125L1 7.355L1.98886 6.37875L3.46518 7.84313Z" stroke="black" stroke-linejoin="round" />
    </svg>




  )
}