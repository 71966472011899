import React from 'react'

export const WaitingIcon = () => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M22.6484 9.65625H16.6484C16.5453 9.65625 16.4609 9.74062 16.4609 9.84375V10.9688C16.4609 11.0719 16.5453 11.1562 16.6484 11.1562H22.6484C22.7516 11.1562 22.8359 11.0719 22.8359 10.9688V9.84375C22.8359 9.74062 22.7516 9.65625 22.6484 9.65625ZM19.5078 12.8438H16.6484C16.5453 12.8438 16.4609 12.9281 16.4609 13.0312V14.1562C16.4609 14.2594 16.5453 14.3438 16.6484 14.3438H19.5078C19.6109 14.3438 19.6953 14.2594 19.6953 14.1562V13.0312C19.6953 12.9281 19.6109 12.8438 19.5078 12.8438ZM11.6867 7.55859H10.6719C10.5266 7.55859 10.4094 7.67578 10.4094 7.82109V13.6336C10.4094 13.718 10.4492 13.7953 10.5172 13.8445L14.007 16.3898C14.1242 16.4742 14.2883 16.4508 14.3726 16.3336L14.975 15.5109V15.5086C15.0594 15.3914 15.0336 15.2273 14.9164 15.143L11.9469 12.9961V7.82109C11.9492 7.67578 11.8297 7.55859 11.6867 7.55859Z" fill="#005441"/>
    <path d="M19.3625 15.7945H18.0078C17.8765 15.7945 17.7523 15.8625 17.682 15.975C17.3844 16.4461 17.0375 16.882 16.639 17.2804C15.9523 17.9671 15.1531 18.5062 14.2648 18.8812C13.3437 19.2703 12.3664 19.4671 11.3586 19.4671C10.3484 19.4671 9.37108 19.2703 8.45233 18.8812C7.56405 18.5062 6.76483 17.9671 6.07811 17.2804C5.39139 16.5937 4.85233 15.7945 4.47733 14.9062C4.08827 13.9875 3.89139 13.0101 3.89139 12C3.89139 10.9898 4.08827 10.0148 4.47733 9.09371C4.85233 8.20543 5.39139 7.40621 6.07811 6.71949C6.76483 6.03277 7.56405 5.49371 8.45233 5.11871C9.37108 4.72965 10.3508 4.53277 11.3586 4.53277C12.3687 4.53277 13.3461 4.72965 14.2648 5.11871C15.1531 5.49371 15.9523 6.03277 16.639 6.71949C17.0375 7.11793 17.3844 7.55386 17.682 8.02496C17.7523 8.13746 17.8765 8.20543 18.0078 8.20543H19.3625C19.5242 8.20543 19.6273 8.03668 19.5547 7.89371C18.0265 4.85386 14.9281 2.87808 11.4687 2.83824C6.40389 2.77496 2.19686 6.92105 2.18749 11.9812C2.17811 17.0507 6.2867 21.164 11.3562 21.164C14.8601 21.164 18.0101 19.1812 19.5547 16.1062C19.6273 15.9632 19.5219 15.7945 19.3625 15.7945Z" fill="#005441"/>
    </svg>
    
  )
}
