import { Drawer, Grid, Tab, Tabs, useTheme, Zoom } from '@material-ui/core'
import clsx from 'clsx'
import React from 'react'
import Logo from '../asset/img/Component 4.png'
import { sideStyles } from '../styles/sideBarStyle'
import d_home from '../asset/img/deactive_side/Home.png'
import d_manage_user from '../asset/img/deactive_side/manageuser.png'
import d_report from '../asset/img/deactive_side/reportmanagement.png'
import d_notification from '../asset/img/deactive_side/deactive_notification.svg'
import d_user_info from '../asset/img/deactive_side/userinformation.png'
import d_user_req from '../asset/img/deactive_side/userrequest.png'
import d_membership from '../asset/img/deactive_side/membership.svg'
import a_home from '../asset/img/active_side/Home.svg'
import a_manage_user from '../asset/img/active_side/userManagement.svg'
import a_report from '../asset/img/active_side/Report.svg'
import a_notification from '../asset/img/active_side/active_notification.svg'
import a_user_info from '../asset/img/active_side/UserInfo.svg'
import a_user_req from '../asset/img/active_side/UserRequest.svg'
import a_membership from '../asset/img/active_side/membership.svg'
import {
    useHistory,
    useRouteMatch,
    useLocation,
    useParams,
} from 'react-router-dom'
import a_promocode from '../asset/img/active_side/promocode.svg'
import d_promocode from '../asset/img/deactive_side/promocode.svg'
import hubIconSelected from '../asset/img/hub_icon.png'
import hubIconUnSelected from '../asset/img/hub_icon_unselected.png'
import { connect } from 'react-redux'
import Store from '../redux/Store'
import { set_side_value } from '../redux/ActionCreators'

const Sidebar = ({ sideBarStatus, set_sideBarStatus, sideValue }) => {
    const classes = sideStyles()
    const { pathname } = useLocation()
    const { id } = useParams()
    React.useState(() => {
        let path = pathname.split('/')
        Store.dispatch(set_side_value(path[path.length - 1]))
    }, [pathname])
    const history = useHistory()
    const { path } = useRouteMatch()
    const theme = useTheme()

    let menus = [
        { title: 'Home', DIcon: d_home, AIcon: a_home, link: '' },
        {
            title: 'Manage users',
            DIcon: d_manage_user,
            AIcon: a_manage_user,
            link: 'user-management',
        },
        // {
        //     title: 'Add Or Edit users',
        //     DIcon: d_manage_user,
        //     AIcon: a_manage_user,
        //     link: `user-information/new`,
        // },
        // {
        //     title: 'Matchmake Me users',
        //     DIcon: d_manage_user,
        //     AIcon: a_manage_user,
        //     link: 'matchMakeMe-users',
        // },
        {
            title: 'User Request',
            DIcon: d_user_req,
            AIcon: a_user_req,
            link: 'user-request',
        },
        {
            title: 'Report Management',
            DIcon: d_report,
            AIcon: a_report,
            link: 'report-management',
        },

        {
            title: 'Notifications',
            DIcon: d_notification,
            AIcon: a_notification,
            link: 'notification',
        },
        {
            title: 'Manage Memberships',
            DIcon: d_membership,
            AIcon: a_membership,
            link: 'membership',
        },
        // {
        //   title: "FAQ",
        //   DIcon: d_user_info,
        //   AIcon: a_user_info,
        //   link: "faqs",
        // },
        {
            title: 'settings',
            DIcon: d_user_info,
            AIcon: a_user_info,
            link: 'settings',
        },
        {
            title: 'Promo code',
            DIcon: d_promocode,
            AIcon: a_promocode,
            link: 'promocode',
        },
        {
            title: 'Attachment Style Report',
            DIcon: d_promocode,
            AIcon: a_promocode,
            link: 'quiz-module',
        },

        {
            title: 'Quiz',
            DIcon: d_promocode,
            AIcon: a_promocode,
            link: 'quiz',
        },
        {
            title: 'hub',
            DIcon: hubIconUnSelected,
            AIcon: hubIconSelected,
            link: 'hub',
        },
    ]

    return (
        <Drawer
            open={sideBarStatus}
            variant="permanent"
            style={{ zIndex: theme.zIndex.appBar + 1 }}
            anchor="left"
            className={clsx(classes.drawer, {
                [classes.drawerOpen]: sideBarStatus,
                [classes.drawerClose]: !sideBarStatus,
            })}
            classes={{
                paper: clsx({
                    [classes.drawerOpen]: sideBarStatus,
                    [classes.drawerClose]: !sideBarStatus,
                }),
            }}
        >
            <Grid item container style={{ height: '100%', color: 'white' }}>
                <Grid
                    item
                    xs={12}
                    container
                    justifyContent="center"
                    alignItems="center"
                    style={{ height: 80 }}
                >
                    <Zoom in={sideBarStatus} timeout={300} unmountOnExit>
                        <div style={{}}>
                            {/* <img
                src={sandwhatch}
                alt=""
                style={{
                  width: 30,
                  height: 50,
                }}
              /> */}
                            <img
                                src={Logo}
                                alt=""
                                style={{
                                    position: 'relative',
                                    width: 180,
                                    height: 30,
                                    right: 10,
                                }}
                            />
                        </div>
                    </Zoom>
                </Grid>
                <Grid item container style={{ height: 'calc(100% - 80px)' }}>
                    <Tabs
                        variant="fullWidth"
                        orientation="vertical"
                        value={sideValue}
                        onChange={(e, value) => {
                            Store.dispatch(set_side_value(value))
                            console.log(value)
                        }}
                        classes={{ indicator: classes.tabIndicator }}
                        style={{
                            width: '100%',
                            padding: 0,
                        }}
                    >
                        {menus.map((item, i) => {
                            return (
                                <Tab
                                    key={i}
                                    onClick={() => {
                                        history.push(`${path}${item.link}`)
                                        set_sideBarStatus(true)
                                    }}
                                    label={item.title}
                                    icon={
                                        sideValue === item.link ? (
                                            <img
                                                src={item.AIcon}
                                                alt=""
                                                style={{ margin: '0 12px' }}
                                            />
                                        ) : (
                                            <img
                                                src={item.DIcon}
                                                alt=""
                                                style={{ margin: '0 12px' }}
                                            />
                                        )
                                    }
                                    value={item.link}
                                    style={{
                                        backgroundColor:
                                            sideValue !== item.link
                                                ? 'transparent'
                                                : 'white',
                                        color:
                                            sideValue !== item.link
                                                ? 'white'
                                                : theme.palette.primary.main,
                                        fontSize: 14,
                                        fontFamily: 'inter',
                                        padding: '25px 0px',
                                        display:
                                            item.link === 'settings' && 'none',
                                        textTransform: 'capitalize',
                                        whiteSpace: 'nowrap',
                                    }}
                                    classes={{
                                        wrapper: classes.SideBarTab,
                                        root: classes.test,
                                    }}
                                />
                            )
                        })}
                    </Tabs>
                </Grid>
            </Grid>
        </Drawer>
    )
}

const mapStateToProps = (props) => {
    const { GeneralReducer } = props
    const { sideValue } = GeneralReducer

    return { sideValue }
}

export default connect(mapStateToProps)(Sidebar)
