import React from 'react'

export const GoldenTicketIcon = () => {

  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="28" height="28" rx="6" fill="url(#paint0_linear_2560_7803)"/>
<path d="M21.3125 12.875C21.4617 12.875 21.6048 12.8157 21.7102 12.7102C21.8157 12.6048 21.875 12.4617 21.875 12.3125V9.5C21.875 9.20163 21.7565 8.91548 21.5455 8.7045C21.3345 8.49353 21.0484 8.375 20.75 8.375H7.25C6.95163 8.375 6.66548 8.49353 6.4545 8.7045C6.24353 8.91548 6.125 9.20163 6.125 9.5V12.3125C6.125 12.4617 6.18426 12.6048 6.28975 12.7102C6.39524 12.8157 6.53832 12.875 6.6875 12.875C6.98587 12.875 7.27202 12.9935 7.483 13.2045C7.69397 13.4155 7.8125 13.7016 7.8125 14C7.8125 14.2984 7.69397 14.5845 7.483 14.7955C7.27202 15.0065 6.98587 15.125 6.6875 15.125C6.53832 15.125 6.39524 15.1843 6.28975 15.2898C6.18426 15.3952 6.125 15.5383 6.125 15.6875V18.5C6.125 18.7984 6.24353 19.0845 6.4545 19.2955C6.66548 19.5065 6.95163 19.625 7.25 19.625H20.75C21.0484 19.625 21.3345 19.5065 21.5455 19.2955C21.7565 19.0845 21.875 18.7984 21.875 18.5V15.6875C21.875 15.5383 21.8157 15.3952 21.7102 15.2898C21.6048 15.1843 21.4617 15.125 21.3125 15.125C21.0141 15.125 20.728 15.0065 20.517 14.7955C20.306 14.5845 20.1875 14.2984 20.1875 14C20.1875 13.7016 20.306 13.4155 20.517 13.2045C20.728 12.9935 21.0141 12.875 21.3125 12.875ZM20.75 16.1769V18.5H16.8125V16.8125H15.6875V18.5H7.25V16.1769C7.7315 16.051 8.15769 15.7691 8.46186 15.3752C8.76604 14.9813 8.93104 14.4977 8.93104 14C8.93104 13.5023 8.76604 13.0187 8.46186 12.6248C8.15769 12.2309 7.7315 11.949 7.25 11.8231V9.5H15.6875V11.1875H16.8125V9.5H20.75V11.8231C20.2685 11.949 19.8423 12.2309 19.5381 12.6248C19.234 13.0187 19.069 13.5023 19.069 14C19.069 14.4977 19.234 14.9813 19.5381 15.3752C19.8423 15.7691 20.2685 16.051 20.75 16.1769Z" fill="white"/>
<path d="M15.6875 12.3125H16.8125V15.6875H15.6875V12.3125Z" fill="white"/>
<defs>
<linearGradient id="paint0_linear_2560_7803" x1="28" y1="1.27816" x2="-4.90629" y2="12.5833" gradientUnits="userSpaceOnUse">
<stop stop-color="#FFEA7E"/>
<stop offset="1" stop-color="#FEC123"/>
</linearGradient>
</defs>
</svg>


  )
}
