import { api_instance } from './Base_Api'
import Store from '../redux/Store'
import { set_message } from '../redux/ActionCreators'
export const get_all_quiz_names = async (body) => {
    try {
        const { data } = await api_instance.post(
            '/admin/questionnair/all-questionnair-names',
            body
        )
        return data
    } catch (e) {
        console.log(e.message)
    }
}

export const get_detailed_quiz_module = async (
    questionnairType = 'ATTACHMENT_RELATIONAL'
) => {
    try {
        const { data } = await api_instance.get(
            '/admin/questionnair/questionnair-detail',
            {
                params: {
                    questionnairType,
                },
            }
        )
        return data
    } catch (e) {
        console.log(e.message)
    }
}

export const add_quiz_hub = async (data) => {
    try {
        const res = await api_instance.post('/admin/questionnair/add', data)
        console.log('hell', res)
        Store.dispatch(
            set_message({
                mode: true,
                message: res.data?.data?.success,
                color: 'success',
            })
        )
        return res
    } catch (e) {
        Store.dispatch(
            set_message({
                mode: true,
                message: e?.response?.data?.message,
                color: 'error',
            })
        )
        console.log(e.message)
    }
}

export const getQuizDetailsById = async (id) => {
    try {
        const res = await api_instance.get(
            `/admin/questionnair/quiz-details/${id}`
        )
        console.log('hell', res)
        return res?.data
    } catch (e) {
        Store.dispatch(
            set_message({
                mode: true,
                message: e?.response?.data?.message,
                color: 'error',
            })
        )
        console.log(e.message)
    }
}

export const updateQuizDetailsById = async (
    id,
    data,
    isDeactivateHubAndQuiz
) => {
    try {
        const res = await api_instance.put(
            `/admin/questionnair/quiz-details/${id}`,
            { data, isDeactivateHubAndQuiz }
        )
        if (res.data?.data?.success) {
            Store.dispatch(
                set_message({
                    mode: true,
                    message: res.data?.data?.success,
                    color: 'success',
                })
            )
        }
        return res
    } catch (e) {
        Store.dispatch(
            set_message({
                mode: true,
                message: e?.response?.data?.message,
                color: 'error',
            })
        )
    }
}

export const get_quiz_list = async (page, search, sortColumn, sortOrder) => {
    try {
        const data = await api_instance.post('/admin/questionnair/quiz-list', {
            search: search ? search : '',
            page: page ? page : 1,
            sort: sortColumn,
            sort_order: sortOrder,
        })
        return data
    } catch (e) {
        console.log(e.message)
    }
}

export const get_attempt_quiz = async (
    userId,
    search,
    page,
    sortColumn,
    sortOrder
) => {
    try {
        const data = await api_instance.post(
            `/admin/questionnair/quiz-attempted-users/${userId}`,
            {
                search: search,
                page: page,
                sort: sortColumn,
                sort_order: sortOrder,
            }
        )
        return data?.data
    } catch (e) {
        console.log(e.message)
    }
}
export const get_quiz_questionnair = async (user_id, quizId) => {
    try {
        const data = await api_instance.get(
            `/admin/questionnair/quiz-questionnair/${user_id}/${quizId}`
        )
        return data?.data
    } catch (e) {
        console.log(e.message)
    }
}
