import React from 'react'
import {
    FormLabel,
    Grid,
    Typography,
    Accordion,
    AccordionDetails,
    AccordionSummary,
} from '@material-ui/core'
import {
    TextField,
    TextareaAutosize,
    Stack,
    FormGroup,
    FormControlLabel,
    Checkbox,
} from '@mui/material'
import { GridExpandMoreIcon } from '@mui/x-data-grid'
import { styles } from '../../../styles/styles'
import { Controller } from 'react-hook-form'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
const AdminSection = ({ register, control, levelingDropdownOptions }) => {
    const style = styles()
    return (
        <Grid spacing={2} className={style.box}>
            <Accordion style={{ boxShadow: 'none' }}>
                <AccordionSummary
                    expandIcon={<GridExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-admin-info--questionnair"
                >
                    <Typography
                        style={{
                            fontSize: '1.2rem',
                            textDecoration: 'underline',
                        }}
                    >
                        Admin
                    </Typography>
                </AccordionSummary>

                <AccordionDetails>
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Controller
                                            {...register('admin.isIdChecked')}
                                            control={control}
                                            render={({ field }) => (
                                                <Checkbox
                                                    {...field}
                                                    checked={
                                                        field?.value || false
                                                    }
                                                    onChange={(e) =>
                                                        field.onChange(
                                                            e.target.checked
                                                        )
                                                    }
                                                />
                                            )}
                                        />
                                    }
                                    label="ID Check?"
                                />
                            </FormGroup>
                        </Grid>
                        <Grid item xs={4}>
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Controller
                                            {...register('admin.isNDAChecked')}
                                            control={control}
                                            render={({ field }) => (
                                                <Checkbox
                                                    {...field}
                                                    checked={
                                                        field?.value || false
                                                    }
                                                    onChange={(e) =>
                                                        field.onChange(
                                                            e.target.checked
                                                        )
                                                    }
                                                />
                                            )}
                                        />
                                    }
                                    label="NDA Check?"
                                />
                            </FormGroup>
                        </Grid>
                        <Grid item container xs={4}>
                            <Grid item xs={12}>
                                <Stack direction="column">
                                    <FormLabel htmlFor="gender" sx={{ pt: 4 }}>
                                        User Level:
                                    </FormLabel>
                                    <FormControlLabel
                                        style={{
                                            paddingTop: '16px',
                                            paddingLeft: '12px',
                                            marginRight: '0',
                                        }}
                                        control={
                                            <Controller
                                                {...register('leveling')}
                                                control={control}
                                                render={({ field }) => (
                                                    <>
                                                        <Select
                                                            labelId="admin.leveling"
                                                            id="demo-simple-select"
                                                            fullWidth
                                                            margin="normal"
                                                            size="small"
                                                            value={
                                                                field.value
                                                                    ? field.value
                                                                    : []
                                                            }
                                                            onChange={(event) =>
                                                                field.onChange(
                                                                    event.target
                                                                        .value
                                                                )
                                                            }
                                                        >
                                                            <MenuItem
                                                                value={'0'}
                                                            >
                                                                Select Level
                                                            </MenuItem>
                                                            {levelingDropdownOptions?.map(
                                                                (option) => {
                                                                    return (
                                                                        <MenuItem
                                                                            value={
                                                                                option.name
                                                                            }
                                                                        >
                                                                            {
                                                                                option.name
                                                                            }
                                                                        </MenuItem>
                                                                    )
                                                                }
                                                            )}
                                                        </Select>
                                                    </>
                                                )}
                                            />
                                        }
                                    />
                                </Stack>
                            </Grid>
                        </Grid>
                        {/* <Grid item xs={2}></Grid> */}
                        <Grid item container xs={6}>
                            <Grid item xs={12}>
                                <Stack direction="column">
                                    <FormLabel htmlFor="Notes" sx={{ pt: 4 }}>
                                        Notes:
                                    </FormLabel>
                                    <TextField
                                        {...register('admin.notes')}
                                        placeholder="Notes"
                                        id="notes"
                                        multiline
                                        rows={5}
                                        fullWidth
                                        margin="normal"
                                        size="small"
                                    />
                                </Stack>
                            </Grid>
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </Grid>
    )
}

export default AdminSection
