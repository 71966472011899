import * as React from 'react'
import Pagination from '@mui/material/Pagination'
import Stack from '@mui/material/Stack'
import { makeStyles } from '@material-ui/core/styles'
const useStyles = makeStyles((theme) => ({
    root: {
        '& > * + *': {
            marginTop: theme.spacing(5),
        },
    },
}))
export default function BasicPagination({
    currentPage,
    totalPages,
    onPageChange,
}) {
    const classes = useStyles()
    return (
        <div
            style={{
                marginTop: '10px',
                display: 'flex',
                justifyContent: 'right',
            }}
            className={classes.root}
        >
            <Pagination
                count={totalPages}
                page={currentPage}
                onChange={onPageChange}
            />
        </div>
    )
}
