import { Button } from '@material-ui/core'
import React from 'react'
import {
    add_to_waiting_list,
    remove_from_waiting_list,
    accept_requests,
} from '../../api/UserRequest_Api'
import { WaitingIcon } from '../../icons/WaitingIcon'

export const WaitingList = ({
    titleShow = false,
    wating_list,
    status,
    tab_value = null,
    set_data,
    deletedAtTime = false,
    set_count,
}) => {
    console.log('deletedAtTime', tab_value, deletedAtTime)
    const handleAddToWaitingList = () => {
        const ids = []
        wating_list.forEach((element) => {
            ids.push(element._id)
            set_data((prev) => prev.filter((c) => c._id !== element._id))
        })
        if (titleShow) {
            set_count((prev) => ({
                ...prev,
                [tab_value]: prev[tab_value] - ids.length,
                waiting: prev.waiting + ids.length,
            }))
        } else {
            set_count((prev) => ({
                ...prev,
                [tab_value]: prev[tab_value] - ids.length,
                waiting: prev.waiting + ids.length,
            }))
        }

        add_to_waiting_list({ ids })
            .then((res) => {
                // res.
                console.log(res)
            })
            .catch((error) => {})
    }
    const handleRemoveFromWaitingList = () => {
        const ids = []

        wating_list.forEach((element) => {
            ids.push(element._id)
            set_data((prev) => prev.filter((c) => c._id !== element._id))
        })
        console.log('ids', ids)

        if (deletedAtTime) {
            set_count((prev) => ({
                ...prev,
                deleted: prev.deleted + 1,
                waiting: prev.waiting - ids.length,
            }))
        } else {
            set_count((prev) => ({
                ...prev,
                pending: prev.pending + ids.length,
                waiting: prev.waiting - ids.length,
            }))
        }
        console.log(ids)
        remove_from_waiting_list(ids)
            .then((res) => {
                console.log(res)
            })
            .catch((error) => {})
    }

    return (
        <>
            {status !== 'waiting' ? (
                <Button
                    onClick={() => handleAddToWaitingList()}
                    variant="text"
                    startIcon={titleShow ? null : <WaitingIcon />}
                    style={{ color: '#005441' }}
                >
                    {titleShow ? 'Reactivate' : 'Add to waiting list'}
                </Button>
            ) : (
                <Button
                    onClick={() => handleRemoveFromWaitingList()}
                    variant="text"
                    startIcon={<WaitingIcon />}
                    style={{ color: '#005441' }}
                >
                    Remove from waiting list
                </Button>
            )}
        </>
    )
}
