import {
    Dialog,
    Fade,
    Button,
    Grid,
    Typography,
    IconButton,
    Chip,
} from '@material-ui/core'
import { Close, KeyboardArrowRight } from '@material-ui/icons'

import React from 'react'
import male from '../../asset/img/male.png'
import female from '../../asset/img/female.png'
import other from '../../asset/img/otherGender.svg'

import { styles } from '../../styles/styles'
import { getCountryFlag } from '../../Utils'
import { Box } from '@mui/material'

const TopLocationModal = ({ data }) => {
    console.log('top', data)
    const [open, setOpen] = React.useState(false)
    const style = styles()
    const handleOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }
    return (
        <>
            <Button
                variant="outlined"
                // color=""
                endIcon={<KeyboardArrowRight />}
                fullWidth
                onClick={handleOpen}
                style={{
                    borderRadius: 12,
                    height: 40,
                    borderColor: '#65819D',
                    color: '#65819D',
                }}
            >
                View all
            </Button>
            <Dialog
                maxWidth="md"
                fullWidth={true}
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
                onClick={(e) => e.stopPropagation()}
            >
                <Grid
                    xs={12}
                    container
                    item
                    spacing={6}
                    justifyContent="center"
                    style={{ margin: 'auto' }}
                >
                    <Grid
                        item
                        xs={12}
                        container
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Grid item xs={10}>
                            <Typography variant="h4">
                                User's Location
                                <span style={{ fontSize: 15, marginLeft: 4 }}>
                                    {data && (
                                        <>
                                            {data?.length}{' '}
                                            {data?.length > 1
                                                ? 'Countries'
                                                : 'Country'}
                                        </>
                                    )}
                                </span>
                            </Typography>
                        </Grid>
                        <IconButton onClick={handleClose}>
                            <Close />
                        </IconButton>
                    </Grid>
                    {data && data?.length !== 0
                        ? data?.map((item, i) => {
                              return (
                                  <Grid
                                      key={i}
                                      alignItems="center"
                                      justifyContent="space-between"
                                      item
                                      container
                                      xs={11}
                                      style={{ marginBottom: 8 }}
                                      className={style.box}
                                  >
                                      <Grid container item xs={8}>
                                          <Chip
                                              variant="outlined"
                                              label={i + 1}
                                              style={{ fontWeight: 'bolder' }}
                                          />
                                          <img
                                              src={getCountryFlag(item.country)}
                                              width={50}
                                              height={30}
                                              alt=""
                                              style={{
                                                  margin: '0 8px 0 8px',
                                                  backgroundColor:
                                                      !item.image &&
                                                      'CaptionText',
                                                  borderRadius: 5,
                                              }}
                                          />
                                          <Typography
                                              variant="body1"
                                              style={{
                                                  fontSize: 16,
                                                  color: 'black',
                                                  fontWeight: 600,
                                                  margin: '0 8px',
                                              }}
                                          >
                                              {`${item.country} (${item.total} Members)`}
                                          </Typography>
                                      </Grid>
                                      <Box
                                          direction="row"
                                          display={'flex'}
                                          alignItems="center"
                                      >
                                          <img src={male} alt="" />
                                          <Typography
                                              variant="body1"
                                              style={{
                                                  fontSize: 16,
                                                  color: 'black',
                                                  fontWeight: 600,
                                                  margin: '0 8px',
                                              }}
                                          >
                                              {item.male}
                                          </Typography>
                                      </Box>
                                      <Box
                                          direction="row"
                                          display={'flex'}
                                          alignItems="center"
                                      >
                                          <img src={female} alt="" />
                                          <Typography
                                              variant="body1"
                                              style={{
                                                  fontSize: 16,
                                                  color: 'black',
                                                  fontWeight: 600,
                                                  margin: '0 8px',
                                              }}
                                          >
                                              {item.female}
                                          </Typography>
                                      </Box>
                                      <Box
                                          direction="row"
                                          display={'flex'}
                                          alignItems="center"
                                      >
                                          <img src={other} alt="" />
                                          <Typography
                                              variant="body1"
                                              style={{
                                                  fontSize: 16,
                                                  color: 'black',
                                                  fontWeight: 600,
                                                  margin: '0 8px',
                                              }}
                                          >
                                              {item.other}
                                          </Typography>
                                      </Box>
                                  </Grid>
                              )
                          })
                        : null}
                </Grid>
            </Dialog>
        </>
    )
}

export default TopLocationModal
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Fade timeout={350} ref={ref} {...props} />
})
