import { Button } from '@material-ui/core'
import React from 'react'
import Store from '../../redux/Store'
import { set_message } from '../../redux/ActionCreators'
import { add_to_waiting_list } from '../../api/UserRequest_Api'
import UserReactivate from '../../asset/img/user-reactivate.svg'
export const DeletedUserReactivated = (props) => {
    const { data, set_Data, setDeleteMutation } = props
    const handleClick = (value) => {
        const ids = [value]
        add_to_waiting_list({ ids })
            .then((res) => {
                Store.dispatch(
                    set_message({
                        message: res.data.message,
                        color: 'success',
                        mode: true,
                        bottom: true,
                    })
                )
                setDeleteMutation({})
            })
            .catch((error) => {})
    }

    return (
        <Button
            style={{ paddingLeft: 0 }}
            type="text"
            // startIcon={<SetAsFakeAccountIcon />}
            onClick={() => handleClick(data?.user?._id)}
        >
            <img
                onClick={() => handleClick(data?.user?._id)}
                src={UserReactivate}
                alt="user reactivation"
                width={20}
                height={20}
                style={{ marginRight: 8 }}
            />
            Reactivate User
        </Button>
    )
}
