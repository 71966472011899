import { set_message } from "../redux/ActionCreators";
import Store from "../redux/Store";
import { api_instance } from "./Base_Api";
export const get_iceBreakers = async () => {
  try {
    const { data } = await api_instance.get("/settings/icebreakers");
    return data.data;
  } catch (error) {}
};
export const get_notifications = async () => {
  try {
    const { data } = await api_instance.get("/admin/notifications");
    return data.data;
  } catch (error) {}
};
export const add_iceBreaker = async (valeus) => {
  try {
    const { data } = await api_instance.post(
      "/settings/icebreakers/add",
      valeus
    );
    return data.data;
  } catch (error) {}
};
export const update_notification = async (key, valeus) => {
  try {
    const { data } = await api_instance.put(
      `/admin/notifications/${key}`,
      valeus
    );
    return data.data;
  } catch (error) {}
};
export const update_account = async (valeus) => {
  try {
    const { data } = await api_instance.put(`/admin/settings/account`, valeus);
    Store.dispatch(
      set_message({
        mode: true,
        message: "Account settings updated",
        color: "success",
      })
    );
    return data;
  } catch (error) {}
};
export const update_all_active = async (valeus) => {
  try {
    const { data } = await api_instance.put(`/admin/notifications/send/all`);
    Store.dispatch(
      set_message({
        mode: true,
        message: "All activate",
        color: "success",
      })
    );

    return data;
  } catch (error) {}
};

export const delete_ice_breaker = async (value) => {
  try {
    const { data } = await api_instance.delete(`/settings/icebreakers`, {
      data: { icebreaker: value },
    });
    Store.dispatch(
      set_message({
        mode: true,
        message: "IceBreaker Deleted",
        color: "success",
      })
    );

    return data;
  } catch (error) {}
};
export const get_admins = async () => {
  try {
    const { data } = await api_instance.get("/admin/settings/account/admin");
    return data.data;
  } catch (error) {}
};
export const update_admins = async (id, values) => {
  try {
    const { data } = await api_instance.put(
      `/admin/settings/account/admin/${id}`,
      values
    );
    return data.data;
  } catch (error) {}
};
export const post_admins = async (values) => {
  try {
    const { data } = await api_instance.post(
      `/admin/settings/account/admin/add`,
      values
    );
    return data.data;
  } catch (error) {}
};
export const delete_admins = async (id) => {
  try {
    const { data } = await api_instance.delete(
      `/admin/settings/account/admin/${id}`
    );
    return data.data;
  } catch (error) {}
};
