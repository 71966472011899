import {
  Button,
  Dialog,
  Fade,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { Form, Formik } from "formik";
import Edit from "../../asset/img/EditBlack .png";
import React from "react";
import { TextFieldWrapper } from "../../components/Textfields";
import { update_FAQ } from "../../api/HelpCenter_Api";
import GrayButton from "../../components/GrayButton";

const EditFAQ = ({ data, set_data }) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Button
        startIcon={<img src={Edit} alt="" />}
        onClick={handleOpen}
        style={{
          backgroundColor: "rgba(0, 126, 71, 0.05)",
          border: "1px solid #D2D2D2",
          borderRadius: 12,
          marginRight: 16,
        }}
        variant="outlined"
      >
        Edit
      </Button>

      <Formik
        initialValues={{ title: "", description: "" }}
        onSubmit={(values) => {
          update_FAQ(data._id, values).then((res) => {
            if (!res) return;
            set_data((pre) =>
              pre.map((faq) => {
                if (faq._id === data._id) {
                  return {
                    ...faq,
                    title: values.title,
                    description: values.description,
                  };
                } else {
                  return faq;
                }
              })
            );
            setOpen(false);
          });
        }}
      >
        {(formik) => {
          if (data.title !== null) {
            formik.initialValues.title = data.title;
          }
          if (data.description !== null) {
            formik.initialValues.description = data.description;
          }
          return (
            <Form>
              <Dialog
                maxWidth="sm"
                fullWidth={true}
                open={open}
                onClose={() => {
                  handleClose();
                  formik.resetForm();
                }}
                TransitionComponent={Transition}
                onClick={(e) => e.stopPropagation()}
              >
                <Grid xs={12} container item style={{}} justifyContent="center">
                  {/* ------------------------------------------------------------------------------------ header section */}
                  <Grid
                    item
                    xs={12}
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    style={{ padding: 16 }}
                  >
                    <Typography variant="h4">Edit FAQ</Typography>
                    <IconButton onClick={handleClose}>
                      <Close />
                    </IconButton>
                  </Grid>
                  {/* ------------------------------------------------------------------------------------ content section */}
                  <Grid item container style={{ padding: 16 }}>
                    <Grid item xs={12}>
                      <Typography style={{ margin: "16px 0" }} variant="body1">
                        Title
                      </Typography>
                      <TextFieldWrapper name="title" white={true} />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography style={{ margin: "16px 0" }} variant="body1">
                        Description
                      </Typography>
                      <TextFieldWrapper
                        multiline
                        rows={4}
                        name="description"
                        white={true}
                      />
                    </Grid>
                  </Grid>

                  {/* ------------------------------------------------------------------------------------ footer section */}

                  <Grid
                    item
                    xs={12}
                    container
                    alignItems="center"
                    justifyContent="flex-end"
                    style={{ padding: 16 }}
                  >
                    <Grid item>
                      <Button
                        variant="outlined"
                        style={{ borderRadius: 6 }}
                        color="default"
                        onClick={() => {
                          handleClose();
                          formik.resetForm();
                        }}
                      >
                        Cancel
                      </Button>
                      <GrayButton
                        disabled={
                          formik.values.description === "" ||
                          formik.values.title === ""
                        }
                        title="Edit"
                        onClick={() => formik.submitForm()}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Dialog>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default EditFAQ;
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Fade timeout={350} ref={ref} {...props} />;
});
