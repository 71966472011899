import { Grid, Typography } from "@material-ui/core";
import React from "react";
import { TabPanelContainer } from "../../components/TabPanel";
import ReportsTable from "./ReportsTable";

const ReportManagement = () => {
  const [value, setValue] = React.useState(0);

  const [data, set_data] = React.useState([]);
  const [counts, set_counts] = React.useState({ all: 0, clear: 0 });
  const [cleard_data, set_cleared_data] = React.useState([]);

  return (
    <Grid item xs={12} style={{ height: "100%" }}>
      <Grid xs={12} item style={{ height: 80 }}>
        <Typography variant="h5">Report Management</Typography>
        <Typography style={{ position: "relative", top: 10 }} variant="body1">
          Manage and view the reports and reasons and take action
        </Typography>
      </Grid>

      <Grid
        item
        xs={12}
        style={{
          height: "calc(100% - 100px)",
        }}
      >
        <TabPanelContainer value={value} index={0}>
          <ReportsTable
            data={data}
            set_data={set_data}
            type={"/admin/reports"}
            set_counts={set_counts}
          />
        </TabPanelContainer>
        <TabPanelContainer value={value} index={1}>
          <ReportsTable
            data={cleard_data}
            set_data={set_cleared_data}
            type={"/reports/cleared"}
            set_counts={set_counts}
          />
        </TabPanelContainer>
      </Grid>
    </Grid>
  );
};

export default ReportManagement;
