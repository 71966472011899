import { Button, Grid, Typography } from "@material-ui/core";
import { useState, useEffect } from "react";
import { styles } from "../../styles/styles";
import { Add } from "@material-ui/icons";
import { PackageIcon } from "../../icons/PackageIcon";
import { ClockIcon } from "../../icons/ClockIcon";
import { MoneyIcon } from "../../icons/MoneyIcon";
import { Box, CircularProgress } from "@mui/material";
import { Stack } from "@mui/system";
import { DeletePackageIcon } from "../../icons/DeletePackageIcon";
import { PackageEditIcon } from "../../icons/PackageEditIcon";
import { get_memberships, update_membership } from "../../api/Memberships";
import AddNewPlan from "./Addnewplan";
import DeletePackage from "./DeletePackage";
import InfiniteScroll from "react-infinite-scroll-component";
import Store from "../../redux/Store";
import { set_message } from "../../redux/ActionCreators";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';

const buttonStyle = {
  padding: "12px 24px 12px 16px",
  background: "rgba(101, 129, 157, 0.1)",
  border: "1px solid #D2D2D2",
  borderRadius: "12px",
};
const typoStyle = {
  fontWeight: 500,
  fontSize: "16px",
  color: "#1C2439",
  overflowWrap: "break-word",
};

const ManageMemberships = () => {
  const [openAddNewPlan, setOpenAddNewPlan] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [currentPackage, setCurrentPackage] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const style = styles();
  const [data, set_data] = useState([]);

  useEffect(() => {
    get_memberships().then((res) => {
      if (!res) return;
      setTotal(res.data.pagination.total);
      set_data(res.data.packages);
      setIsLoading(false);
    });
  }, []);

  return (
    <Grid container>
      <Stack direction="row" width="100%" justifyContent={"space-between"}>
        <Typography
          style={{
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: "32px",
            color: "#05060F",
          }}
        >
          Manage Memberships
        </Typography>

        <Button
          onClick={() => {
            setOpenAddNewPlan(true);
          }}
          startIcon={<Add />}
          style={{
            padding: "8px 16px 8px 12px",
            border: "2px solid #65819D",
            borderRadius: "16px",
            fontWeight: 600,
            fontSize: "16px",
            textAlign: "center",
            color: "#65819D",
          }}
        >
          Add new plan
        </Button>
      </Stack>

      {isLoading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          width="100%"
          height="60vh"
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Grid
            id="scrollableDiv"
            container
            item
            md={12}
            style={{
              flexGrow: 1,
              overflow: "auto",
              display: "flex",
              flexDirection: "column-reverse",
            }}
          >
            <InfiniteScroll
              dataLength={data.length}
              style={{ width: "100%" }}
              scrollableTarget="scrollableDiv"
              next={() => {
                setPage((prev) => prev + 1);
              }}
              hasMore={data.length < total}
              loader={
                <Stack direction="row" justifyContent="center" p={1}>
                  <CircularProgress />
                </Stack>
              }
              endMessage={
                data.length > 0 && <p style={{ textAlign: "center" }}></p>
              }
            >
              {data.length > 0
                ? data.map((item, i) => (
                    <Stack
                      flexDirection={"row"}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                      sx={{
                        mb: "20px",
                        p: "32px",
                      }}
                      className={style.box}
                    >
                      <Stack
                        flexDirection={"row"}
                        alignItems={"center"}
                        gap={"28px"}
                      >
                        <PackageIcon />
                        <Typography style={typoStyle}>{item.title}</Typography>
                        <Box
                          style={{
                            width: "5px",
                            height: "5px",
                            background: "#D9D9D9",
                            borderRadius: "8px",
                          }}
                        />

                        <Box
                          display={"flex"}
                          alignItems={"center"}
                          gap={"13px"}
                        >
                          <ClockIcon />
                          <Typography style={typoStyle}>
                            {`${item.duration} Months`}
                          </Typography>
                        </Box>

                        <Box
                          display={"flex"}
                          alignItems={"center"}
                          gap={"13px"}
                        >
                          <MoneyIcon />
                          <Typography style={typoStyle}>
                            <span style={{ paddingRight: "5px" }}>£</span>{" "}
                            {item.price}
                          </Typography>
                        </Box>
                      </Stack>

                      <Stack
                        flexDirection={"row"}
                        alignItems={"center"}
                        gap={"12px"}
                      >
                        <Button
                          onClick={() => {
                            update_membership(item._id, {
                              isActive: item.isActive === true ? false : true,
                            }).then((res) => {
                              if (!res) return;
                              set_data((prev) =>
                                prev.map((c) => {
                                  if (c._id === item._id)
                                    return res.data;
                                  else return c;
                                })
                              );
                            });

                            Store.dispatch(
                              set_message({
                                mode: true,
                                message: "Update plan",
                                color: "success",
                              })
                            );
                          }}
                          startIcon={item.isActive ? <CancelIcon /> : <CheckCircleIcon />}
                          style={{ ...buttonStyle }}
                        >
                          {item.isActive ? "Inactive" : "active"}
                        </Button>
                        <Button
                          onClick={() => {
                            setCurrentPackage(item);
                            setOpenEditDialog(true);
                          }}
                          startIcon={<PackageEditIcon />}
                          style={buttonStyle}
                        >
                          Edit
                        </Button>
                        <Button
                          onClick={() => {
                            setCurrentPackage(item);
                            setOpenDeleteDialog(true);
                          }}
                          startIcon={<DeletePackageIcon />}
                          style={{ ...buttonStyle }}
                        >
                          Delete
                        </Button>
                      </Stack>
                    </Stack>
                  ))
                : null}
            </InfiniteScroll>
          </Grid>
        </>
      )}
      {openAddNewPlan && (
        <AddNewPlan
          open={openAddNewPlan}
          setOpen={setOpenAddNewPlan}
          setData={set_data}
          editMode={false}
          currentItem={null}
        />
      )}
      {openEditDialog && (
        <AddNewPlan
          open={openEditDialog}
          setOpen={setOpenEditDialog}
          setData={set_data}
          editMode={true}
          currentItem={currentPackage}
        />
      )}
      {openDeleteDialog && (
        <DeletePackage
          open={openDeleteDialog}
          setOpen={setOpenDeleteDialog}
          setData={set_data}
          currentPackage={currentPackage}
        />
      )}
    </Grid>
  );
};

export default ManageMemberships;
