import { CssBaseline, ThemeProvider } from "@material-ui/core";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import Store from "./redux/Store";
import { Theme } from "./styles/Theme";

ReactDOM.render(
  <Provider store={Store}>
    <BrowserRouter>
      <CssBaseline />
      <ThemeProvider theme={Theme}>
        <App />
      </ThemeProvider>
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);
