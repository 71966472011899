//Important: if you must to change a country name,
//please dont change the country name.
//ADD new country name with SPACE.
export const countryData=[
    {
      "code": "AD",
      "name": "Andorra",
      "phone_code": "+376",
      "icon": "drawable.flag_ad"
    },
    {
      "code": "AE",
      "name": "United Arab Emirates",
      "phone_code": "+971",
      "icon": "drawable.flag_ae"
    },
    {
      "code": "AF",
      "name": "Afghanistan",
      "phone_code": "+93",
      "icon": "drawable.flag_af"
    },
    {
      "code": "AG",
      "name": "Antigua and Barbuda",
      "phone_code": "+1",
      "icon": "drawable.flag_ag"
    },
    {
      "code": "AI",
      "name": "Anguilla",
      "phone_code": "+1",
      "icon": "drawable.flag_ai"
    },
    {
      "code": "AL",
      "name": "Albania",
      "phone_code": "+355",
      "icon": "drawable.flag_al"
    },
    {
      "code": "AM",
      "name": "Armenia",
      "phone_code": "+374",
      "icon": "drawable.flag_am"
    },
    {
      "code": "AO",
      "name": "Angola",
      "phone_code": "+244",
      "icon": "drawable.flag_ao"
    },
    {
      "code": "AQ",
      "name": "Antarctica",
      "phone_code": "+672",
      "icon": "drawable.flag_aq"
    },
    {
      "code": "AR",
      "name": "Argentina",
      "phone_code": "+54",
      "icon": "drawable.flag_ar"
    },
    {
      "code": "AS",
      "name": "AmericanSamoa",
      "phone_code": "+1",
      "icon": "drawable.flag_as"
    },
    {
      "code": "AT",
      "name": "Austria",
      "phone_code": "+43",
      "icon": "drawable.flag_at"
    },
    {
      "code": "AU",
      "name": "Australia",
      "phone_code": "+61",
      "icon": "drawable.flag_au"
    },
    {
      "code": "AW",
      "name": "Aruba",
      "phone_code": "+297",
      "icon": "drawable.flag_aw"
    },
    {
      "code": "AX",
      "name": "Åland Islands",
      "phone_code": "+358",
      "icon": "drawable.flag_ax"
    },
    {
      "code": "AZ",
      "name": "Azerbaijan",
      "phone_code": "+994",
      "icon": "drawable.flag_az"
    },
    {
      "code": "BA",
      "name": "Bosnia and Herzegovina",
      "phone_code": "+387",
      "icon": "drawable.flag_ba"
    },
    {
      "code": "BB",
      "name": "Barbados",
      "phone_code": "+1",
      "icon": "drawable.flag_bb"
    },
    {
      "code": "BD",
      "name": "Bangladesh",
      "phone_code": "+880",
      "icon": "drawable.flag_bd"
    },
    {
      "code": "BE",
      "name": "Belgium Belgique ",
      "phone_code": "+32",
      "icon": "drawable.flag_be"
    },
    {
      "code": "BF",
      "name": "Burkina Faso",
      "phone_code": "+226",
      "icon": "drawable.flag_bf"
    },
    {
      "code": "BG",
      "name": "Bulgaria",
      "phone_code": "+359",
      "icon": "drawable.flag_bg"
    },
    {
      "code": "BH",
      "name": "Bahrain",
      "phone_code": "+973",
      "icon": "drawable.flag_bh"
    },
    {
      "code": "BI",
      "name": "Burundi",
      "phone_code": "+257",
      "icon": "drawable.flag_bi"
    },
    {
      "code": "BJ",
      "name": "Benin",
      "phone_code": "+229",
      "icon": "drawable.flag_bj"
    },
    {
      "code": "BL",
      "name": "Saint Barthélemy",
      "phone_code": "+590",
      "icon": "drawable.flag_bl"
    },
    {
      "code": "BM",
      "name": "Bermuda",
      "phone_code": "+1",
      "icon": "drawable.flag_bm"
    },
    {
      "code": "BN",
      "name": "Brunei Darussalam",
      "phone_code": "+673",
      "icon": "drawable.flag_bn"
    },
    {
      "code": "BO",
      "name": "Bolivia, Plurinational State of",
      "phone_code": "+591",
      "icon": "drawable.flag_bo"
    },
    {
      "code": "BQ",
      "name": "Bonaire",
      "phone_code": "+599",
      "icon": "drawable.flag_bq"
    },
    {
      "code": "BR",
      "name": "Brazil",
      "phone_code": "+55",
      "icon": "drawable.flag_br"
    },
    {
      "code": "BS",
      "name": "Bahamas",
      "phone_code": "+1",
      "icon": "drawable.flag_bs"
    },
    {
      "code": "BT",
      "name": "Bhutan",
      "phone_code": "+975",
      "icon": "drawable.flag_bt"
    },
    {
      "code": "BV",
      "name": "Bouvet Island",
      "phone_code": "+47",
      "icon": "drawable.flag_bv"
    },
    {
      "code": "BW",
      "name": "Botswana",
      "phone_code": "+267",
      "icon": "drawable.flag_bw"
    },
    {
      "code": "BY",
      "name": "Belarus",
      "phone_code": "+375",
      "icon": "drawable.flag_by"
    },
    {
      "code": "BZ",
      "name": "Belize",
      "phone_code": "+501",
      "icon": "drawable.flag_bz"
    },
    {
      "code": "CA",
      "name": "Canada",
      "phone_code": "+1",
      "icon": "drawable.flag_ca"
    },
    {
      "code": "CC",
      "name": "Cocos (Keeling) Islands",
      "phone_code": "+61",
      "icon": "drawable.flag_cc"
    },
    {
      "code": "CD",
      "name": "Congo, The Democratic Republic of the",
      "phone_code": "+243",
      "icon": "drawable.flag_cd"
    },
    {
      "code": "CF",
      "name": "Central African Republic",
      "phone_code": "+236",
      "icon": "drawable.flag_cf"
    },
    {
      "code": "CG",
      "name": "Congo",
      "phone_code": "+242",
      "icon": "drawable.flag_cg"
    },
    {
      "code": "CH",
      "name": "Switzerland Svájc",
      "phone_code": "+41",
      "icon": "drawable.flag_ch"
    },
    {
      "code": "CI",
      "name": "Ivory Coast",
      "phone_code": "+225",
      "icon": "drawable.flag_ci"
    },
    {
      "code": "CK",
      "name": "Cook Islands",
      "phone_code": "+682",
      "icon": "drawable.flag_ck"
    },
    {
      "code": "CL",
      "name": "Chile",
      "phone_code": "+56",
      "icon": "drawable.flag_cl"
    },
    {
      "code": "CM",
      "name": "Cameroon",
      "phone_code": "+237",
      "icon": "drawable.flag_cm"
    },
    {
      "code": "CN",
      "name": "China",
      "phone_code": "+86",
      "icon": "drawable.flag_cn"
    },
    {
      "code": "CO",
      "name": "Colombia",
      "phone_code": "+57",
      "icon": "drawable.flag_co"
    },
    {
      "code": "CR",
      "name": "Costa Rica",
      "phone_code": "+506",
      "icon": "drawable.flag_cr"
    },
    {
      "code": "CU",
      "name": "Cuba",
      "phone_code": "+53",
      "icon": "drawable.flag_cu"
    },
    {
      "code": "CV",
      "name": "Cape Verde",
      "phone_code": "+238",
      "icon": "drawable.flag_cv"
    },
    {
      "code": "CW",
      "name": "Curacao",
      "phone_code": "+599",
      "icon": "drawable.flag_cw"
    },
    {
      "code": "CX",
      "name": "Christmas Island",
      "phone_code": "+61",
      "icon": "drawable.flag_cx"
    },
    {
      "code": "CY",
      "name": "Cyprus",
      "phone_code": "+357",
      "icon": "drawable.flag_cy"
    },
    {
      "code": "CZ",
      "name": "Czech Republic",
      "phone_code": "+420",
      "icon": "drawable.flag_cz"
    },
    {
      "code": "DE",
      "name": "Germany Németország Deutschland",
      "phone_code": "+49",
      "icon": "drawable.flag_de"
    },
    {
      "code": "DJ",
      "name": "Djibouti",
      "phone_code": "+253",
      "icon": "drawable.flag_dj"
    },
    {
      "code": "DK",
      "name": "Denmark",
      "phone_code": "+45",
      "icon": "drawable.flag_dk"
    },
    {
      "code": "DM",
      "name": "Dominica",
      "phone_code": "+1",
      "icon": "drawable.flag_dm"
    },
    {
      "code": "DO",
      "name": "Dominican Republic",
      "phone_code": "+1",
      "icon": "drawable.flag_do"
    },
    {
      "code": "DZ",
      "name": "Algeria",
      "phone_code": "+213",
      "icon": "drawable.flag_dz"
    },
    {
      "code": "EC",
      "name": "Ecuador",
      "phone_code": "+593",
      "icon": "drawable.flag_ec"
    },
    {
      "code": "EE",
      "name": "Estonia",
      "phone_code": "+372",
      "icon": "drawable.flag_ee"
    },
    {
      "code": "EG",
      "name": "Egypt",
      "phone_code": "+20",
      "icon": "drawable.flag_eg"
    },
    {
      "code": "EH",
      "name": "Western Sahara",
      "phone_code": "+212",
      "icon": "drawable.flag_eh"
    },
    {
      "code": "ER",
      "name": "Eritrea",
      "phone_code": "+291",
      "icon": "drawable.flag_er"
    },
    {
      "code": "ES",
      "name": "Spain",
      "phone_code": "+34",
      "icon": "drawable.flag_es"
    },
    {
      "code": "ET",
      "name": "Ethiopia",
      "phone_code": "+251",
      "icon": "drawable.flag_et"
    },
    {
      "code": "FI",
      "name": "Finland",
      "phone_code": "+358",
      "icon": "drawable.flag_fi"
    },
    {
      "code": "FJ",
      "name": "Fiji",
      "phone_code": "+679",
      "icon": "drawable.flag_fj"
    },
    {
      "code": "FK",
      "name": "Falkland Islands (Malvinas)",
      "phone_code": "+500",
      "icon": "drawable.flag_fk"
    },
    {
      "code": "FM",
      "name": "Micronesia, Federated States of",
      "phone_code": "+691",
      "icon": "drawable.flag_fm"
    },
    {
      "code": "FO",
      "name": "Faroe Islands",
      "phone_code": "+298",
      "icon": "drawable.flag_fo"
    },
    {
      "code": "FR",
      "name": "France Pháp ",
      "phone_code": "+33",
      "icon": "drawable.flag_fr"
    },
    {
      "code": "GA",
      "name": "Gabon",
      "phone_code": "+241",
      "icon": "drawable.flag_ga"
    },
    {
      "code": "GB",
      "name": "United Kingdom Egyesült Királyság Vereinigtes Königreich",
      "phone_code": "+44",
      "icon": "drawable.flag_gb"
    },
    {
      "code": "GD",
      "name": "Grenada",
      "phone_code": "+1",
      "icon": "drawable.flag_gd"
    },
    {
      "code": "GE",
      "name": "Georgia",
      "phone_code": "+995",
      "icon": "drawable.flag_ge"
    },
    {
      "code": "GF",
      "name": "French Guiana",
      "phone_code": "+594",
      "icon": "drawable.flag_gf"
    },
    {
      "code": "GG",
      "name": "Guernsey",
      "phone_code": "+44",
      "icon": "drawable.flag_gg"
    },
    {
      "code": "GH",
      "name": "Ghana",
      "phone_code": "+233",
      "icon": "drawable.flag_gh"
    },
    {
      "code": "GI",
      "name": "Gibraltar",
      "phone_code": "+350",
      "icon": "drawable.flag_gi"
    },
    {
      "code": "GL",
      "name": "Greenland",
      "phone_code": "+299",
      "icon": "drawable.flag_gl"
    },
    {
      "code": "GM",
      "name": "Gambia",
      "phone_code": "+220",
      "icon": "drawable.flag_gm"
    },
    {
      "code": "GN",
      "name": "Guinea",
      "phone_code": "+224",
      "icon": "drawable.flag_gn"
    },
    {
      "code": "GP",
      "name": "Guadeloupe",
      "phone_code": "+590",
      "icon": "drawable.flag_gp"
    },
    {
      "code": "GQ",
      "name": "Equatorial Guinea",
      "phone_code": "+240",
      "icon": "drawable.flag_gq"
    },
    {
      "code": "GR",
      "name": "Greece",
      "phone_code": "+30",
      "icon": "drawable.flag_gr"
    },
    {
      "code": "GS",
      "name": "South Georgia and the South Sandwich Islands",
      "phone_code": "+500",
      "icon": "drawable.flag_gs"
    },
    {
      "code": "GT",
      "name": "Guatemala",
      "phone_code": "+502",
      "icon": "drawable.flag_gt"
    },
    {
      "code": "GU",
      "name": "Guam",
      "phone_code": "+1",
      "icon": "drawable.flag_gu"
    },
    {
      "code": "GW",
      "name": "Guinea-Bissau",
      "phone_code": "+245",
      "icon": "drawable.flag_gw"
    },
    {
      "code": "GY",
      "name": "Guyana",
      "phone_code": "+595",
      "icon": "drawable.flag_gy"
    },
    {
      "code": "HK",
      "name": "Hong Kong",
      "phone_code": "+852",
      "icon": "drawable.flag_hk"
    },
    {
      "code": "HM",
      "name": "Heard Island and McDonald Islands",
      "phone_code": "",
      "icon": "drawable.flag_hm"
    },
    {
      "code": "HN",
      "name": "Honduras",
      "phone_code": "+504",
      "icon": "drawable.flag_hn"
    },
    {
      "code": "HR",
      "name": "Croatia",
      "phone_code": "+385",
      "icon": "drawable.flag_hr"
    },
    {
      "code": "HT",
      "name": "Haiti",
      "phone_code": "+509",
      "icon": "drawable.flag_ht"
    },
    {
      "code": "HU",
      "name": "Hungary Magyarország Hungría",
      "phone_code": "+36",
      "icon": "drawable.flag_hu"
    },
    {
      "code": "ID",
      "name": "Indonesia",
      "phone_code": "+62",
      "icon": "drawable.flag_id"
    },
    {
      "code": "IE",
      "name": "Ireland Írország ",
      "phone_code": "+353",
      "icon": "drawable.flag_ie"
    },
    {
      "code": "IL",
      "name": "Israel Izrael ",
      "phone_code": "+972",
      "icon": "drawable.flag_il"
    },
    {
      "code": "IM",
      "name": "Isle of Man",
      "phone_code": "+44",
      "icon": "drawable.flag_im"
    },
    {
      "code": "IN",
      "name": "India",
      "phone_code": "+91",
      "icon": "drawable.flag_in"
    },
    {
      "code": "IO",
      "name": "British Indian Ocean Territory",
      "phone_code": "+246",
      "icon": "drawable.flag_io"
    },
    {
      "code": "IQ",
      "name": "Iraq",
      "phone_code": "+964",
      "icon": "drawable.flag_iq"
    },
    {
      "code": "IR",
      "name": "Iran, Islamic Republic of",
      "phone_code": "+98",
      "icon": "drawable.flag_ir"
    },
    {
      "code": "IS",
      "name": "Iceland",
      "phone_code": "+354",
      "icon": "drawable.flag_is"
    },
    {
      "code": "IT",
      "name": "Italy Italia ",
      "phone_code": "+39",
      "icon": "drawable.flag_it"
    },
    {
      "code": "JE",
      "name": "Jersey",
      "phone_code": "+44",
      "icon": "drawable.flag_je"
    },
    {
      "code": "JM",
      "name": "Jamaica",
      "phone_code": "+1",
      "icon": "drawable.flag_jm"
    },
    {
      "code": "JO",
      "name": "Jordan",
      "phone_code": "+962",
      "icon": "drawable.flag_jo"
    },
    {
      "code": "JP",
      "name": "Japan",
      "phone_code": "+81",
      "icon": "drawable.flag_jp"
    },
    {
      "code": "KE",
      "name": "Kenya",
      "phone_code": "+254",
      "icon": "drawable.flag_ke"
    },
    {
      "code": "KG",
      "name": "Kyrgyzstan",
      "phone_code": "+996",
      "icon": "drawable.flag_kg"
    },
    {
      "code": "KH",
      "name": "Cambodia",
      "phone_code": "+855",
      "icon": "drawable.flag_kh"
    },
    {
      "code": "KI",
      "name": "Kiribati",
      "phone_code": "+686",
      "icon": "drawable.flag_ki"
    },
    {
      "code": "KM",
      "name": "Comoros",
      "phone_code": "+269",
      "icon": "drawable.flag_km"
    },
    {
      "code": "KN",
      "name": "Saint Kitts and Nevis",
      "phone_code": "+1",
      "icon": "drawable.flag_kn"
    },
    {
      "code": "KP",
      "name": "North Korea",
      "phone_code": "+850",
      "icon": "drawable.flag_kp"
    },
    {
      "code": "KR",
      "name": "South Korea",
      "phone_code": "+82",
      "icon": "drawable.flag_kr"
    },
    {
      "code": "KW",
      "name": "Kuwait",
      "phone_code": "+965",
      "icon": "drawable.flag_kw"
    },
    {
      "code": "KY",
      "name": "Cayman Islands",
      "phone_code": "+345",
      "icon": "drawable.flag_ky"
    },
    {
      "code": "KZ",
      "name": "Kazakhstan",
      "phone_code": "+7",
      "icon": "drawable.flag_kz"
    },
    {
      "code": "LA",
      "name": "Lao People's Democratic Republic",
      "phone_code": "+856",
      "icon": "drawable.flag_la"
    },
    {
      "code": "LB",
      "name": "Lebanon",
      "phone_code": "+961",
      "icon": "drawable.flag_lb"
    },
    {
      "code": "LC",
      "name": "Saint Lucia",
      "phone_code": "+1",
      "icon": "drawable.flag_lc"
    },
    {
      "code": "LI",
      "name": "Liechtenstein",
      "phone_code": "+423",
      "icon": "drawable.flag_li"
    },
    {
      "code": "LK",
      "name": "Sri Lanka",
      "phone_code": "+94",
      "icon": "drawable.flag_lk"
    },
    {
      "code": "LR",
      "name": "Liberia",
      "phone_code": "+231",
      "icon": "drawable.flag_lr"
    },
    {
      "code": "LS",
      "name": "Lesotho",
      "phone_code": "+266",
      "icon": "drawable.flag_ls"
    },
    {
      "code": "LT",
      "name": "Lithuania",
      "phone_code": "+370",
      "icon": "drawable.flag_lt"
    },
    {
      "code": "LU",
      "name": "Luxembourg",
      "phone_code": "+352",
      "icon": "drawable.flag_lu"
    },
    {
      "code": "LV",
      "name": "Latvia",
      "phone_code": "+371",
      "icon": "drawable.flag_lv"
    },
    {
      "code": "LY",
      "name": "Libyan Arab Jamahiriya",
      "phone_code": "+218",
      "icon": "drawable.flag_ly"
    },
    {
      "code": "MA",
      "name": "Morocco",
      "phone_code": "+212",
      "icon": "drawable.flag_ma"
    },
    {
      "code": "MC",
      "name": "Monaco",
      "phone_code": "+377",
      "icon": "drawable.flag_mc"
    },
    {
      "code": "MD",
      "name": "Moldova, Republic of",
      "phone_code": "+373",
      "icon": "drawable.flag_md"
    },
    {
      "code": "ME",
      "name": "Montenegro",
      "phone_code": "+382",
      "icon": "drawable.flag_me"
    },
    {
      "code": "MF",
      "name": "Saint Martin",
      "phone_code": "+590",
      "icon": "drawable.flag_mf"
    },
    {
      "code": "MG",
      "name": "Madagascar",
      "phone_code": "+261",
      "icon": "drawable.flag_mg"
    },
    {
      "code": "MH",
      "name": "Marshall Islands",
      "phone_code": "+692",
      "icon": "drawable.flag_mh"
    },
    {
      "code": "MK",
      "name": "Macedonia, The Former Yugoslav Republic of",
      "phone_code": "+389",
      "icon": "drawable.flag_mk"
    },
    {
      "code": "ML",
      "name": "Mali",
      "phone_code": "+223",
      "icon": "drawable.flag_ml"
    },
    {
      "code": "MM",
      "name": "Myanmar",
      "phone_code": "+95",
      "icon": "drawable.flag_mm"
    },
    {
      "code": "MN",
      "name": "Mongolia",
      "phone_code": "+976",
      "icon": "drawable.flag_mn"
    },
    {
      "code": "MO",
      "name": "Macao",
      "phone_code": "+853",
      "icon": "drawable.flag_mo"
    },
    {
      "code": "MP",
      "name": "Northern Mariana Islands",
      "phone_code": "+1",
      "icon": "drawable.flag_mp"
    },
    {
      "code": "MQ",
      "name": "Martinique",
      "phone_code": "+596",
      "icon": "drawable.flag_mq"
    },
    {
      "code": "MR",
      "name": "Mauritania",
      "phone_code": "+222",
      "icon": "drawable.flag_mr"
    },
    {
      "code": "MS",
      "name": "Montserrat",
      "phone_code": "+1",
      "icon": "drawable.flag_ms"
    },
    {
      "code": "MT",
      "name": "Malta",
      "phone_code": "+356",
      "icon": "drawable.flag_mt"
    },
    {
      "code": "MU",
      "name": "Mauritius",
      "phone_code": "+230",
      "icon": "drawable.flag_mu"
    },
    {
      "code": "MV",
      "name": "Maldives",
      "phone_code": "+960",
      "icon": "drawable.flag_mv"
    },
    {
      "code": "MW",
      "name": "Malawi",
      "phone_code": "+265",
      "icon": "drawable.flag_mw"
    },
    {
      "code": "MX",
      "name": "Mexico",
      "phone_code": "+52",
      "icon": "drawable.flag_mx"
    },
    {
      "code": "MY",
      "name": "Malaysia",
      "phone_code": "+60",
      "icon": "drawable.flag_my"
    },
    {
      "code": "MZ",
      "name": "Mozambique",
      "phone_code": "+258",
      "icon": "drawable.flag_mz"
    },
    {
      "code": "NA",
      "name": "Namibia",
      "phone_code": "+264",
      "icon": "drawable.flag_na"
    },
    {
      "code": "NC",
      "name": "New Caledonia",
      "phone_code": "+687",
      "icon": "drawable.flag_nc"
    },
    {
      "code": "NE",
      "name": "Niger",
      "phone_code": "+227",
      "icon": "drawable.flag_ne"
    },
    {
      "code": "NF",
      "name": "Norfolk Island",
      "phone_code": "+672",
      "icon": "drawable.flag_nf"
    },
    {
      "code": "NG",
      "name": "Nigeria",
      "phone_code": "+234",
      "icon": "drawable.flag_ng"
    },
    {
      "code": "NI",
      "name": "Nicaragua",
      "phone_code": "+505",
      "icon": "drawable.flag_ni"
    },
    {
      "code": "NL",
      "name": "Netherlands Hollandia ",
      "phone_code": "+31",
      "icon": "drawable.flag_nl"
    },
    {
      "code": "NO",
      "name": "Norway",
      "phone_code": "+47",
      "icon": "drawable.flag_no"
    },
    {
      "code": "NP",
      "name": "Nepal",
      "phone_code": "+977",
      "icon": "drawable.flag_np"
    },
    {
      "code": "NR",
      "name": "Nauru",
      "phone_code": "+674",
      "icon": "drawable.flag_nr"
    },
    {
      "code": "NU",
      "name": "Niue",
      "phone_code": "+683",
      "icon": "drawable.flag_nu"
    },
    {
      "code": "NZ",
      "name": "New Zealand",
      "phone_code": "+64",
      "icon": "drawable.flag_nz"
    },
    {
      "code": "OM",
      "name": "Oman",
      "phone_code": "+968",
      "icon": "drawable.flag_om"
    },
    {
      "code": "PA",
      "name": "Panama",
      "phone_code": "+507",
      "icon": "drawable.flag_pa"
    },
    {
      "code": "PE",
      "name": "Peru",
      "phone_code": "+51",
      "icon": "drawable.flag_pe"
    },
    {
      "code": "PF",
      "name": "French Polynesia",
      "phone_code": "+689",
      "icon": "drawable.flag_pf"
    },
    {
      "code": "PG",
      "name": "Papua New Guinea",
      "phone_code": "+675",
      "icon": "drawable.flag_pg"
    },
    {
      "code": "PH",
      "name": "Philippines",
      "phone_code": "+63",
      "icon": "drawable.flag_ph"
    },
    {
      "code": "PK",
      "name": "Pakistan",
      "phone_code": "+92",
      "icon": "drawable.flag_pk"
    },
    {
      "code": "PL",
      "name": "Poland",
      "phone_code": "+48",
      "icon": "drawable.flag_pl"
    },
    {
      "code": "PM",
      "name": "Saint Pierre and Miquelon",
      "phone_code": "+508",
      "icon": "drawable.flag_pm"
    },
    {
      "code": "PN",
      "name": "Pitcairn",
      "phone_code": "+872",
      "icon": "drawable.flag_pn"
    },
    {
      "code": "PR",
      "name": "Puerto Rico",
      "phone_code": "+1",
      "icon": "drawable.flag_pr"
    },
    {
      "code": "PS",
      "name": "Palestinian Territory, Occupied",
      "phone_code": "+970",
      "icon": "drawable.flag_ps"
    },
    {
      "code": "PT",
      "name": "Portugal",
      "phone_code": "+351",
      "icon": "drawable.flag_pt"
    },
    {
      "code": "PW",
      "name": "Palau",
      "phone_code": "+680",
      "icon": "drawable.flag_pw"
    },
    {
      "code": "PY",
      "name": "Paraguay",
      "phone_code": "+595",
      "icon": "drawable.flag_py"
    },
    {
      "code": "QA",
      "name": "Qatar",
      "phone_code": "+974",
      "icon": "drawable.flag_qa"
    },
    {
      "code": "RE",
      "name": "Réunion",
      "phone_code": "+262",
      "icon": "drawable.flag_re"
    },
    {
      "code": "RO",
      "name": "Romania",
      "phone_code": "+40",
      "icon": "drawable.flag_ro"
    },
    {
      "code": "RS",
      "name": "Serbia",
      "phone_code": "+381",
      "icon": "drawable.flag_rs"
    },
    {
      "code": "RU",
      "name": "Russia",
      "phone_code": "+7",
      "icon": "drawable.flag_ru"
    },
    {
      "code": "RW",
      "name": "Rwanda",
      "phone_code": "+250",
      "icon": "drawable.flag_rw"
    },
    {
      "code": "SA",
      "name": "Saudi Arabia",
      "phone_code": "+966",
      "icon": "drawable.flag_sa"
    },
    {
      "code": "SB",
      "name": "Solomon Islands",
      "phone_code": "+677",
      "icon": "drawable.flag_sb"
    },
    {
      "code": "SC",
      "name": "Seychelles",
      "phone_code": "+248",
      "icon": "drawable.flag_sc"
    },
    {
      "code": "SD",
      "name": "Sudan",
      "phone_code": "+249",
      "icon": "drawable.flag_sd"
    },
    {
      "code": "SE",
      "name": "Sweden Svédország ",
      "phone_code": "+46",
      "icon": "drawable.flag_se"
    },
    {
      "code": "SG",
      "name": "Singapore",
      "phone_code": "+65",
      "icon": "drawable.flag_sg"
    },
    {
      "code": "SH",
      "name": "Saint Helena, Ascension and Tristan Da Cunha",
      "phone_code": "+290",
      "icon": "drawable.flag_sh"
    },
    {
      "code": "SI",
      "name": "Slovenia",
      "phone_code": "+386",
      "icon": "drawable.flag_si"
    },
    {
      "code": "SJ",
      "name": "Svalbard and Jan Mayen",
      "phone_code": "+47",
      "icon": "drawable.flag_sj"
    },
    {
      "code": "SK",
      "name": "Slovakia",
      "phone_code": "+421",
      "icon": "drawable.flag_sk"
    },
    {
      "code": "SL",
      "name": "Sierra Leone",
      "phone_code": "+232",
      "icon": "drawable.flag_sl"
    },
    {
      "code": "SM",
      "name": "San Marino",
      "phone_code": "+378",
      "icon": "drawable.flag_sm"
    },
    {
      "code": "SN",
      "name": "Senegal",
      "phone_code": "+221",
      "icon": "drawable.flag_sn"
    },
    {
      "code": "SO",
      "name": "Somalia",
      "phone_code": "+252",
      "icon": "drawable.flag_so"
    },
    {
      "code": "SR",
      "name": "Suriname",
      "phone_code": "+597",
      "icon": "drawable.flag_sr"
    },
    {
      "code": "SS",
      "name": "South Sudan",
      "phone_code": "+211",
      "icon": "drawable.flag_ss"
    },
    {
      "code": "ST",
      "name": "Sao Tome and Principe",
      "phone_code": "+239",
      "icon": "drawable.flag_st"
    },
    {
      "code": "SV",
      "name": "El Salvador",
      "phone_code": "+503",
      "icon": "drawable.flag_sv"
    },
    {
      "code": "SX",
      "name": "  Sint Maarten",
      "phone_code": "+1",
      "icon": "drawable.flag_sx"
    },
    {
      "code": "SY",
      "name": "Syrian Arab Republic",
      "phone_code": "+963",
      "icon": "drawable.flag_sy"
    },
    {
      "code": "SZ",
      "name": "Swaziland",
      "phone_code": "+268",
      "icon": "drawable.flag_sz"
    },
    {
      "code": "TC",
      "name": "Turks and Caicos Islands",
      "phone_code": "+1",
      "icon": "drawable.flag_tc"
    },
    {
      "code": "TD",
      "name": "Chad",
      "phone_code": "+235",
      "icon": "drawable.flag_td"
    },
    {
      "code": "TF",
      "name": "French Southern Territories",
      "phone_code": "+262",
      "icon": "drawable.flag_tf"
    },
    {
      "code": "TG",
      "name": "Togo",
      "phone_code": "+228",
      "icon": "drawable.flag_tg"
    },
    {
      "code": "TH",
      "name": "Thailand",
      "phone_code": "+66",
      "icon": "drawable.flag_th"
    },
    {
      "code": "TJ",
      "name": "Tajikistan",
      "phone_code": "+992",
      "icon": "drawable.flag_tj"
    },
    {
      "code": "TK",
      "name": "Tokelau",
      "phone_code": "+690",
      "icon": "drawable.flag_tk"
    },
    {
      "code": "TL",
      "name": "East Timor",
      "phone_code": "+670",
      "icon": "drawable.flag_tl"
    },
    {
      "code": "TM",
      "name": "Turkmenistan",
      "phone_code": "+993",
      "icon": "drawable.flag_tm"
    },
    {
      "code": "TN",
      "name": "Tunisia",
      "phone_code": "+216",
      "icon": "drawable.flag_tn"
    },
    {
      "code": "TO",
      "name": "Tonga",
      "phone_code": "+676",
      "icon": "drawable.flag_to"
    },
    {
      "code": "TR",
      "name": "Turkey",
      "phone_code": "+90",
      "icon": "drawable.flag_tr"
    },
    {
      "code": "TT",
      "name": "Trinidad and Tobago",
      "phone_code": "+1",
      "icon": "drawable.flag_tt"
    },
    {
      "code": "TV",
      "name": "Tuvalu",
      "phone_code": "+688",
      "icon": "drawable.flag_tv"
    },
    {
      "code": "TW",
      "name": "Taiwan",
      "phone_code": "+886",
      "icon": "drawable.flag_tw"
    },
    {
      "code": "TZ",
      "name": "Tanzania, United Republic of",
      "phone_code": "+255",
      "icon": "drawable.flag_tz"
    },
    {
      "code": "UA",
      "name": "Ukraine",
      "phone_code": "+380",
      "icon": "drawable.flag_ua"
    },
    {
      "code": "UG",
      "name": "Uganda",
      "phone_code": "+256",
      "icon": "drawable.flag_ug"
    },
    {
      "code": "UM",
      "name": "U.S. Minor Outlying Islands",
      "phone_code": "",
      "icon": "drawable.flag_um"
    },
    {
      "code": "US",
      "name": "United States Egyesült Államok",
      "phone_code": "+1",
      "icon": "drawable.flag_us"
    },
    {
      "code": "UY",
      "name": "Uruguay",
      "phone_code": "+598",
      "icon": "drawable.flag_uy"
    },
    {
      "code": "UZ",
      "name": "Uzbekistan",
      "phone_code": "+998",
      "icon": "drawable.flag_uz"
    },
    {
      "code": "VA",
      "name": "Holy See (Vatican City State)",
      "phone_code": "+379",
      "icon": "drawable.flag_va"
    },
    {
      "code": "VC",
      "name": "Saint Vincent and the Grenadines",
      "phone_code": "+1",
      "icon": "drawable.flag_vc"
    },
    {
      "code": "VE",
      "name": "Venezuela, Bolivarian Republic of",
      "phone_code": "+58",
      "icon": "drawable.flag_ve"
    },
    {
      "code": "VG",
      "name": "Virgin Islands, British",
      "phone_code": "+1",
      "icon": "drawable.flag_vg"
    },
    {
      "code": "VI",
      "name": "Virgin Islands, U.S.",
      "phone_code": "+1",
      "icon": "drawable.flag_vi"
    },
    {
      "code": "VN",
      "name": "Viet Nam",
      "phone_code": "+84",
      "icon": "drawable.flag_vn"
    },
    {
      "code": "VU",
      "name": "Vanuatu",
      "phone_code": "+678",
      "icon": "drawable.flag_vu"
    },
    {
      "code": "WF",
      "name": "Wallis and Futuna",
      "phone_code": "+681",
      "icon": "drawable.flag_wf"
    },
    {
      "code": "WS",
      "name": "Samoa",
      "phone_code": "+685",
      "icon": "drawable.flag_ws"
    },
    {
      "code": "XK",
      "name": "Kosovo",
      "phone_code": "+383",
      "icon": "drawable.flag_xk"
    },
    {
      "code": "YE",
      "name": "Yemen",
      "phone_code": "+967",
      "icon": "drawable.flag_ye"
    },
    {
      "code": "YT",
      "name": "Mayotte",
      "phone_code": "+262",
      "icon": "drawable.flag_yt"
    },
    {
      "code": "ZA",
      "name": "South Africa",
      "phone_code": "+27",
      "icon": "drawable.flag_za"
    },
    {
      "code": "ZM",
      "name": "Zambia",
      "phone_code": "+260",
      "icon": "drawable.flag_zm"
    },
    {
      "code": "ZW",
      "name": "Zimbabwe",
      "phone_code": "+263",
      "icon": "drawable.flag_zw"
    }
  ];