import {
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@material-ui/core";
import { Doughnut } from "react-chartjs-2";
import React from "react";
import { styles } from "../../styles/styles";

const DonutChart = ({ chart_data }) => {
  const style = styles();
  const [value, set_value] = React.useState("performance");
  const performanceData = (canvas) => {
    return {
      labels: [],
      datasets: [
        {
          data:
            chart_data &&
            chart_data.activityPerformance &&
            Object.values(chart_data.activityPerformance),
          backgroundColor:
            chart_data &&
            chart_data.activityPerformance &&
            Object.keys(chart_data.activityPerformance).map((item) => {
              return item === "mutuals"
                ? "#E4B9B9"
                : item === "likes"
                  ? "#65819D"
                  : item === "dislikes"
                    ? "#BA5A6B"
                    : "blue";
              // ["#FEBD38", "#007E47", "#FF6666"],
            }),
          hoverOffset: 7,
        },
      ],
    };
  };
  const genderData = (canvas) => {
    return {
      labels: [],
      datasets: [
        {
          data:
            chart_data &&
            chart_data.totalGenderCount &&
            Object.values(chart_data.totalGenderCount),
          backgroundColor:
            chart_data &&
            chart_data.totalGenderCount &&
            Object.keys(chart_data.totalGenderCount).map((item) => {
              return item === "males"
                ? "#BA5A6B"
                : item === "females"
                  ? "#E4B9B9"
                  : item === "other"
                    ? "#65819D"
                    : "blue";
            }),
          hoverOffset: 7,
        },
      ],
    };
  };
  return (
    <Grid
      item
      xs={12}
      style={{ height: "100%" }}
      className={style.box}
      container
      direction="column"
      justifyContent="space-between"
    >
      {/* ------------------------------------------------------------------------------title section */}
      <Grid
        item
        style={{ height: 50 }}
        container
        justifyContent="center"
        alignItems="center"
      >
        <FormControl component="fieldset" >
          <RadioGroup row>
            {donutChartRadio.map((item) => (
              <FormControlLabel
                checked={value === item.value}
                key={item.value}
                value={item.value}
                onChange={() => {
                  set_value(item.value);
                }}
                control={<Radio />}
                label={item.title}
                classes={{ label: style.radioLabel }}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </Grid>
      {/* ------------------------------------------------------------------------------colors section */}

      <Grid
        item
        style={{ height: "calc(100% - 100px)", position: "relative" }}
        container
        justifyContent="center"
        alignItems="center"
      >
        <Doughnut
          data={value === "performance" ? performanceData : genderData}
          // plugins={plugins}
          style={{ width: "100%", height: "100%" }}
        />
        {value === "performance" &&
          chart_data &&
          chart_data?.activityPerformance &&
          (chart_data?.activityPerformance?.dislikes + chart_data?.activityPerformance?.mutuals + chart_data?.activityPerformance?.likes) > 0 && (
            <span
              style={{
                position: "absolute",
                fontWeight: "700",
                fontSize: "28px",
              }}
            >
              {Math.round(
                (chart_data.activityPerformance.mutuals / chart_data.activityPerformance.likes) * 100
              )}
              %
            </span>
          )}

      </Grid>

      {/* ------------------------------------------------------------------------------colors section */}
      <Grid
        item
        style={{ height: 50 }}
        container
        justifyContent="space-between"
        alignItems="center"
      >
        {(value === "performance" ? PerformanceColors : gendercolors).map(
          (item, i) => (
            <Color key={i} color={item.color} caption={item.title} />
          )
        )}
      </Grid>
    </Grid>
  );
};

export default DonutChart;

const Color = ({ color, caption }) => {
  return (
    <Grid item container style={{ width: "fit-content" }} alignItems="center">
      <span
        style={{
          width: 15,
          height: 15,
          borderRadius: 15,
          backgroundColor: color,
          marginRight: 8,
        }}
      ></span>
      <Typography variant="subtitle1" style={{ color: "black" }}>
        {caption}
      </Typography>
    </Grid>
  );
};
const PerformanceColors = [
  { title: "Likes", color: "#65819D" },
  { title: "Matches", color: "#E4B9B9" },
  { title: "Dislikes", color: "#BA5A6B" },
];
let donutChartRadio = [
  { title: "Performance", value: "performance" },
  { title: "Gender", value: "gender" },
];
const gendercolors = [
  { title: "Male", color: "#BA5A6B" },
  { title: "Female", color: "#E4B9B9" },
  { title: "Other", color: "#65819D" },
];
