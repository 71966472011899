import { useEffect, useState } from 'react'
import {
    Grid,
    Typography,
    Divider,
    Button,
    Menu,
    MenuItem,
} from '@material-ui/core'
import { KeyboardArrowDown } from '@material-ui/icons'
import { styles } from '../../styles/styles'
import {
    get_all_quiz_names,
    get_detailed_quiz_module,
} from '../../../src/api/Quiz_Module_Api'
import { DataGrid } from '@mui/x-data-grid'
import { Stack } from '@mui/material'
import { useHistory } from 'react-router-dom/cjs/react-router-dom'

const QuizModule = () => {
    const style = styles()
    const history = useHistory()
    const [moduleDropdownData, setModuleDropdownData] = useState(null)
    const [selectedQuizname, setSelectedQuizname] = useState('')
    const [detailedQuizData, setDetailedQuizData] = useState()
    const [isLoading, setIsLoading] = useState(false)

    useEffect(async () => {
        setIsLoading(true)
        try {
            const allQuizModuleNames = await get_all_quiz_names({})
            if (
                allQuizModuleNames.data.allActiveQuiz &&
                allQuizModuleNames.data.allActiveQuiz.length > 0
            ) {
                setModuleDropdownData(allQuizModuleNames.data.allActiveQuiz)
            }
            setIsLoading(false)
        } catch (e) {
            console.log(e.message)
            setIsLoading(false)
        }
    }, [])

    useEffect(async () => {
        setIsLoading(true)
        try {
            const { data } = await get_detailed_quiz_module(
                selectedQuizname.type
            )
            setDetailedQuizData(data)
            setIsLoading(false)
        } catch (e) {
            console.log(e.message)
            setIsLoading(false)
        }
    }, [selectedQuizname])

    const [anchorEl, setAnchorEl] = useState(null)

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleModuleDropdownChange = (quizName) => {
        setSelectedQuizname(quizName)
    }

    const rows =
        detailedQuizData?.tableData?.map((row, index) => {
            return {
                index: index + 1,
                questionCategory: row?.question_category,
                question: row?.question_title,
                mostLikeMe: row?.responses['Most Like Me'] || '',
                notLikeMe: row?.responses['Not Like Me'] || '',
                total: row?.total_responses,
                id: index,
            }
        }) ?? []
    const columns = [
        {
            field: 'index',
            headerName: 'Index',
            width: 80,
            sortable: false,
        },
        {
            field: 'questionCategory',
            headerName: 'Question Category',
            width: 150,
            sortable: false,
        },
        {
            field: 'question',
            headerName: 'Question',
            width: 1000,
            sortable: false,
        },
        {
            field: 'mostLikeMe',
            headerName: '#Most Like Me',
            width: 150,
            sortable: false,
        },
        {
            field: 'notLikeMe',
            headerName: '#Not Like Me',
            width: 150,
            sortable: false,
        },
        {
            field: 'total',
            headerName: '#Total Answers',
            width: 150,
            sortable: false,
        },
    ]

    return (
        <Grid container style={{ height: '100%' }}>
            <Grid item xs={12} style={{ height: 60 }}>
                <Stack
                    direction="row"
                    width="100%"
                    justifyContent={'space-between'}
                >
                    <Typography variant="h5">Quiz Module</Typography>
                    {/* <Button
                        onClick={() => {
                            window.open('/quiz')
                        }}
                        // startIcon={<Add />}
                        style={{
                            padding: '8px 16px 8px 12px',
                            marginRight: '10px',
                            border: '2px solid #65819D',
                            borderRadius: '16px',
                            fontWeight: 600,
                            fontSize: '16px',

                            color: '#65819D',
                        }}
                    >
                        All Quiz
                    </Button> */}
                </Stack>

                <Typography
                    style={{ top: 10, position: 'relative' }}
                    variant="body1"
                >
                    All Quiz Modules Information
                </Typography>
            </Grid>

            <Grid
                item
                xs={12}
                container
                // justifyContent="flex-end"
                // alignItems="center"
                style={{ height: 48, marginTop: 30 }}
            >
                <Grid item xs={3} container justifyContent="flex-start">
                    <Typography
                        style={{
                            fontSize: '25px',
                            color: 'black',
                            fontWeight: 'bolder',
                        }}
                    >
                        {selectedQuizname.title}
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={9}
                    container
                    // alignItems="center"
                    justifyContent="flex-end"
                >
                    <Button
                        variant="outlined"
                        style={{
                            borderRadius: 8,
                            height: 40,
                            marginLeft: 16,
                            backgroundColor: '#FBFAFB',
                            position: 'relative',
                            color: '#005441',
                            borderColor: '#005441',
                        }}
                        endIcon={
                            <KeyboardArrowDown
                                style={{
                                    position: 'relative',
                                    top: 2,
                                    transform: `rotate(${
                                        anchorEl ? 180 : 0
                                    }deg)`,
                                    transition: '0.2s',
                                }}
                            />
                        }
                        onClick={handleClick}
                    >
                        Modules
                    </Button>

                    <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        // keepMounted
                        // style={{ position: "absolute", top: 80, borderRadius: '10px' }}
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                        elevation={0}
                        PaperProps={{
                            style: {
                                boxShadow:
                                    '0px 2px 12px rgba(205, 205, 205, 0.7)',
                                border: '1px solid rgba(0, 0, 0, 0.08)',
                                borderRadius: 10,
                                padding: '0px 16px 0px 16px',
                                opacity: 1,
                            },
                        }}
                        style={{
                            position: 'absolute',
                            top: 60,
                            // left: -50,
                            opacity: 1,
                        }}
                    >
                        {moduleDropdownData?.map((name) => {
                            return (
                                <MenuItem
                                    style={{ paddingLeft: 0, opacity: 1 }}
                                    button={false}
                                    key={name._id}
                                    onClick={() => {
                                        handleModuleDropdownChange(name)
                                        handleClose()
                                    }}
                                >
                                    {name.title}
                                </MenuItem>
                            )
                        })}
                    </Menu>
                </Grid>
            </Grid>

            <Grid item xs={12}>
                <Divider />
            </Grid>

            <Grid item xs={12} style={{ max: 'calc(80% - 150px)' }}>
                <Grid
                    // justifyContent="space-between"
                    alignItems="center"
                    item
                    xs={12}
                    container
                    style={{ height: 100, padding: '0 12px' }}
                    className={style.box}
                >
                    {/* <Grid
                        item
                        container
                        alignItems="center"
                        style={{ width: 250 }}
                    > */}
                    <Grid item container>
                        <Grid item xs={2}>
                            <Typography
                                style={{ color: 'black', fontSize: '15px' }}
                            >
                                <b>Total users: </b>
                                {detailedQuizData?.totalEligibleUsers || ''}
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography
                                style={{ color: 'black', fontSize: '15px' }}
                            >
                                <b>Total users attempted quiz: </b>
                                {detailedQuizData?.totalUsersAttemptedQuiz || 0}
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid item container>
                        <Grid item xs={2}>
                            <b>Anxious: </b>
                            {detailedQuizData?.attachmentStyle?.Anxious || 0}
                        </Grid>
                        <Grid item xs={2}>
                            <b>Secure: </b>
                            {detailedQuizData?.attachmentStyle?.Secure || 0}
                        </Grid>
                        <Grid item xs={2}>
                            <b>Avoidant: </b>
                            {detailedQuizData?.attachmentStyle?.Avoidant || 0}
                        </Grid>
                    </Grid>
                    {/* </Grid> */}
                </Grid>
            </Grid>

            <Grid
                item
                xs={12}
                className={style.box}
                container
                style={{ height: 'calc(90% - 180px)', overflow: 'auto' }}
            >
                {!isLoading &&
                    detailedQuizData &&
                    detailedQuizData?.tableData.length > 0 && (
                        <DataGrid
                            rows={rows}
                            columns={columns}
                            pageSizeOptions={false}
                            disableColumnMenu={true}
                        />
                    )}
                {!isLoading && !detailedQuizData && <p>No data to display</p>}
                {isLoading && !detailedQuizData && <p>Loading..</p>}
            </Grid>
        </Grid>
    )
}

export default QuizModule
