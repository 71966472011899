import axios from "axios";
import Store from "../redux/Store";
import {
  set_loading,
  set_Login,
  set_message,
  set_modal_loading,
} from "../redux/ActionCreators";

export const api_instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

api_instance.interceptors.request.use((config) => {
  config.headers["Authorization"] = `Bearer ${localStorage.token}`;
  Store.dispatch(set_loading(true));
  return config;
});

api_instance.interceptors.response.use(
  (config) => {
    Store.dispatch(set_loading(false));
    return config;
  },

  (error) => {
    Store.dispatch(set_loading(false));

    switch (error?.response?.status) {
      case 400:
        Store.dispatch(
          set_message({
            mode: true,
            message: error?.response?.data?.data?.error?.message,
            color: "error",
          })
        );
        Store.dispatch(set_loading(false));
        break;
      case 500:
        Store.dispatch(
          set_message({
            mode: true,
            message: error?.response?.data?.data?.error?.message,
            color: "error",
          })
        );
        Store.dispatch(set_loading(false));
        break;
      case 401:
        Store.dispatch(
          set_message({
            mode: true,
            message: error?.response?.data?.data?.error?.message,
            color: "error",
          })
        );
        Store.dispatch(set_loading(false));
        setTimeout(() => {
          localStorage.clear();
          window.location.pathname = "/";
          Store.dispatch(set_Login(false));
        }, 2000);
        break;

      default:
        break;
    }

    return Promise.reject(error);
  }
);
// --------------------------------------------------------------------------------------- modals_api
export const api_instance_modal = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

api_instance_modal.interceptors.request.use((config) => {
  config.headers["Authorization"] = localStorage.token;
  Store.dispatch(set_modal_loading(true));
  return config;
});

api_instance_modal.interceptors.response.use(
  (config) => {
    Store.dispatch(set_modal_loading(false));
    return config;
  },

  (error) => {
    Store.dispatch(set_modal_loading(false));

    switch (error?.response?.status) {
      case 400:
        Store.dispatch(
          set_message({
            mode: true,
            message: error.response.data.message,
            color: "error",
          })
        );
        Store.dispatch(set_modal_loading(false));
        break;
      case 500:
        Store.dispatch(
          set_message({
            mode: true,
            message: error.response.data.message,
            color: "error",
          })
        );
        Store.dispatch(set_modal_loading(false));
        break;
      case 401:
        Store.dispatch(
          set_message({
            mode: true,
            message: error.response.data.message,
            color: "error",
          })
        );
        Store.dispatch(set_modal_loading(false));
        setTimeout(() => {
          localStorage.clear();
          window.location.hash = "#/";
          Store.dispatch(set_modal_loading(false));
        }, 2000);
        break;

      default:
        break;
    }

    return Promise.reject(error);
  }
);
