import React from 'react'

export const MatchmakeMeIcon = () => {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="28" height="28" rx="6" fill="#65819D" />
      <path d="M10.0625 8.9375C8.20625 8.9375 6.6875 10.4563 6.6875 12.3125C6.6875 13.4285 7.23706 14.4219 8.07631 15.0373C6.593 15.773 5.5625 17.2985 5.5625 19.0625H6.6875C6.6875 17.1927 8.19275 15.6875 10.0625 15.6875C11.9323 15.6875 13.4375 17.1927 13.4375 19.0625H14.5625C14.5625 17.1927 16.0677 15.6875 17.9375 15.6875C19.8073 15.6875 21.3125 17.1927 21.3125 19.0625H22.4375C22.4375 17.2979 21.407 15.773 19.9237 15.0373C20.3532 14.7243 20.7028 14.3145 20.9441 13.841C21.1854 13.3676 21.3116 12.8439 21.3125 12.3125C21.3125 10.4563 19.7937 8.9375 17.9375 8.9375C16.0813 8.9375 14.5625 10.4563 14.5625 12.3125C14.5625 13.4285 15.1121 14.4219 15.9513 15.0373C15.1295 15.4413 14.4492 16.0847 14 16.8828C13.5508 16.0847 12.8705 15.4413 12.0487 15.0373C12.4782 14.7243 12.8278 14.3145 13.0691 13.841C13.3104 13.3676 13.4366 12.8439 13.4375 12.3125C13.4375 10.4563 11.9187 8.9375 10.0625 8.9375ZM10.0625 10.0625C11.3129 10.0625 12.3125 11.0621 12.3125 12.3125C12.3125 13.5629 11.3129 14.5625 10.0625 14.5625C8.81206 14.5625 7.8125 13.5629 7.8125 12.3125C7.8125 11.0621 8.81206 10.0625 10.0625 10.0625ZM17.9375 10.0625C19.1879 10.0625 20.1875 11.0621 20.1875 12.3125C20.1875 13.5629 19.1879 14.5625 17.9375 14.5625C16.6871 14.5625 15.6875 13.5629 15.6875 12.3125C15.6875 11.0621 16.6871 10.0625 17.9375 10.0625Z" fill="white" />
    </svg>


  )
}