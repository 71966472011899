import React, { useEffect } from 'react'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import { FormControlLabel, FormLabel, Grid } from '@material-ui/core'
import { Checkbox } from '@mui/material'
import { Stack } from '@mui/material'
import { Controller } from 'react-hook-form'
const SelectAdvancedPreferences = ({
    name,
    options,
    getValue,
    setValue,
    register,
    control,
    label,
    isOptionName,
}) => {
    const handleSelectChange = (field, event) => {
        const value = event.target.value
        console.log('values_outside====>01', {
            value1: value,
            value2: field.value,
        })

        if (value.includes('all')) {
            console.log(
                'values_outside====>01_all',
                value.length,
                options.length
            )
            if (value.length === 1) {
                let allOptions = isOptionName
                    ? options.map((option) => option.name)
                    : options
                allOptions = [...allOptions]
                field.onChange(allOptions)
            } else if (value.every((e) => options.includes(e))) {
                field.onChange(value.filter((item) => item !== 'all'))
            } else if (value.length > options.length) {
                field.onChange([])
            } else if (
                field.value.length === 1 &&
                field.value.includes('all') &&
                value.length === 2
            ) {
                field.onChange(value.filter((item) => item !== 'all'))
            } else {
                let allOptions = isOptionName
                    ? options.map((option) => option.name)
                    : options
                allOptions = [...allOptions]
                field.onChange(allOptions)
            }
        } else {
            console.log('debugged111', value, options)
            let newValue = value.filter((item) => item !== 'all')
            field.onChange(newValue)
        }
    }

    return (
        <Grid item container xs={4}>
            <Grid item xs={12}>
                <Stack direction="column">
                    <FormLabel htmlFor={name} sx={{ pt: 4 }}>
                        {label}:
                    </FormLabel>

                    <FormControlLabel
                        style={{
                            paddingTop: '16px',
                            paddingLeft: '12px',
                        }}
                        control={
                            <Controller
                                {...register(name)}
                                control={control}
                                render={({ field }) => {
                                    return (
                                        <Select
                                            labelId={name}
                                            id="demo-simple-select"
                                            fullWidth
                                            margin="normal"
                                            size="small"
                                            value={field?.value}
                                            multiple={true}
                                            onChange={(event) =>
                                                handleSelectChange(field, event)
                                            }
                                        >
                                            {console.log(
                                                'debugged11',
                                                field.value
                                            )}
                                            <MenuItem
                                                style={{
                                                    backgroundColor:
                                                        field?.value?.length ===
                                                        options?.length
                                                            ? 'rgba(25, 118, 210, 0.08)'
                                                            : '',
                                                }}
                                                value="all"
                                            >
                                                {field?.value?.length ===
                                                options?.length
                                                    ? 'Open To All'
                                                    : 'Open To All'}
                                            </MenuItem>
                                            {isOptionName
                                                ? options?.map((option) => {
                                                      return (
                                                          <MenuItem
                                                              style={{
                                                                  backgroundColor:
                                                                      field?.value.includes(
                                                                          'all'
                                                                      )
                                                                          ? 'rgba(25, 118, 210, 0.08)'
                                                                          : '',
                                                              }}
                                                              value={
                                                                  option?.name
                                                              }
                                                          >
                                                              {option?.name}
                                                          </MenuItem>
                                                      )
                                                  })
                                                : options?.map((option) => {
                                                      return (
                                                          <MenuItem
                                                              style={{
                                                                  backgroundColor:
                                                                      field?.value.includes(
                                                                          'all'
                                                                      )
                                                                          ? 'rgba(25, 118, 210, 0.08)'
                                                                          : '',
                                                              }}
                                                              value={option}
                                                          >
                                                              {option}
                                                          </MenuItem>
                                                      )
                                                  })}
                                        </Select>
                                    )
                                }}
                            />
                        }
                    />
                </Stack>
            </Grid>
        </Grid>
    )
}

export default SelectAdvancedPreferences
