import { Button, Divider, Menu, MenuItem, Typography } from '@material-ui/core'
import React from 'react'
import more from '../../../../asset/img/More horizontal.png'
import ViewIcon from '../../../../asset/img/matchMakeMeIcon.png'
import EditIcon from '../../../../asset/img/editAdmin.png'
import VeiwProfile from '../../../../../src/pages/ManageUsers/UserProfilePopUp/ViewProfile/ViewProfile'
const RowActionButton = ({
    set_Data,
    value,
    setDeleteMutation = null,
    zodiacDropdownOptions = [],
    sleepingHabbitsDropdownOptions = [],
    drinkingDropdownOptions = [],
    smokingDropdownOptions = [],
    relocateForLoveDropdownOptions = [],
    personalityDropdownValues = [],
    industryDropdownOptions = [],
    educationDropdownOptions = [],
    salaryDropdownOptions = [],
    lookingForDropdownOptions = [],
}) => {
    console.log('value', value)
    const [anchorEl, setAnchorEl] = React.useState(null)

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    return (
        <>
            <Button
                onClick={handleClick}
                variant="outlined"
                startIcon={<img src={more} alt="" />}
            >
                Action
            </Button>
            <Menu
                anchorEl={anchorEl}
                onClose={handleClose}
                elevation={0}
                open={Boolean(anchorEl)}
                PaperProps={{
                    style: {
                        boxShadow: '0px 2px 12px rgba(205, 205, 205, 0.7)',
                        border: '1px solid rgba(0, 0, 0, 0.08)',
                        borderRadius: 10,
                        padding: 16,
                        opacity: 1,
                    },
                }}
                style={{
                    position: 'absolute',
                    top: 40,
                    left: -50,
                    opacity: 1,
                }}
            >
                <Typography style={{ color: 'black' }} variant="body1">
                    Actions
                </Typography>
                <Divider />

                <MenuItem
                    style={{
                        paddingLeft: 0,
                        paddingTop: '6px',
                        paddingBottom: 0,
                        opacity: 1,
                    }}
                    button={false}
                >
                    <Button
                        variant="text"
                        onClick={() => {
                            window.open(
                                `/quiz-attempted-users-questionnair/${value?.row?.userInfo?._id}/${value?.row?.quiz_id}`
                            )
                        }}
                        style={{ paddingLeft: 0 }}
                    >
                        <img
                            src={ViewIcon}
                            alt=""
                            width={20}
                            style={{ marginRight: 8 }}
                        />
                        View Questionnair
                    </Button>
                </MenuItem>
                <MenuItem
                    style={{
                        paddingLeft: 0,
                        paddingTop: 0,
                        opacity: 1,
                    }}
                    button={false}
                >
                    <VeiwProfile
                        isUser={true}
                        setDeleteMutation={setDeleteMutation}
                        data={{ user: value?.row?.userInfo }}
                        set_data={set_Data}
                        zodiacDropdownOptions={zodiacDropdownOptions}
                        sleepingHabbitsDropdownOptions={
                            sleepingHabbitsDropdownOptions
                        }
                        drinkingDropdownOptions={drinkingDropdownOptions}
                        smokingDropdownOptions={smokingDropdownOptions}
                        relocateForLoveDropdownOptions={
                            relocateForLoveDropdownOptions
                        }
                        personalityDropdownValues={personalityDropdownValues}
                        industryDropdownOptions={industryDropdownOptions}
                        educationDropdownOptions={educationDropdownOptions}
                        salaryDropdownOptions={salaryDropdownOptions}
                        lookingForDropdownOptions={lookingForDropdownOptions}
                    />
                </MenuItem>
            </Menu>
        </>
    )
}

export default RowActionButton
