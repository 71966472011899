import * as yup from "yup";

export const Strong_Password =
  "(?=.*[a-z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})";

export const validation = yup.object().shape({
  name: yup.string().required("Type the admin name"),
  emaill: yup.string().required("Type your email").email("Email is wrong"),
  passwordd: yup
    .string()
    .required("Type your password")
    .matches(
      Strong_Password,
      "Password must contain at least 8 charachter length, one number and one special character (@$!%*#?&)"
    ),
});

export const initialValues = {
  emaill: "",
  passwordd: "",
  name: "",
};
export const AddinitialValues = {
  emaill: "",
  passwordd: "",
  name: "",
};

export const UpdateOwnerAdmininitialValues = {
  name: "",
  emaill: "",
  passwordd: "",
  confirm_password: "",
};
