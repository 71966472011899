import { Grid, Typography } from '@material-ui/core'
import { Error } from '@material-ui/icons'
import React from 'react'

const NoData = ({ tab_value = null }) => {
    return tab_value === 'deleted' ? (
        <Grid
            style={{ marginTop: '20px' }}
            container
            alignItems="center"
            justifyContent="center"
        >
            <Grid container alignItems="center" justifyContent="center">
                <Typography variant="body1">No data found</Typography>
            </Grid>
        </Grid>
    ) : (
        <Grid
            container
            style={{ height: '50%' }}
            alignItems="center"
            justifyContent="center"
        >
            <Error color="secondary" style={{ fontSize: 60 }} />
        </Grid>
    )
}

export default NoData
