import { Grid, Typography } from '@material-ui/core'
import React from 'react'
import { styles } from '../../styles/styles'
import { getCountryFlag } from '../../Utils'
import TopLocationModal from './TopLocationModal'

const TopLocation = ({ data }) => {
    const style = styles()

    return (
        <Grid
            alignItems="flex-start"
            direction="column"
            justifyContent="space-between"
            container
            style={{ height: '100%', padding: 24 }}
            className={style.box}
        >
            <Typography variant="body2">User’s Location</Typography>
            <Grid
                container
                justifyContent="center"
                alignItems="center"
                style={{ alignSelf: 'flex-start' }}
            >
                {data && data.length !== 0 ? (
                    <img
                        src={getCountryFlag(!!data ? data[0]?.country : '')}
                        alt={data[0]?.country}
                        width={53}
                        height={40}
                        style={{ borderRadius: 6 }}
                    />
                ) : null}
                {data && data.length !== 0 ? (
                    <Typography variant="body2" style={{ marginLeft: 4 }}>
                        {data[0]?.male + data[0]?.female + data[0]?.other}
                    </Typography>
                ) : null}
            </Grid>
            <Grid container style={{}}>
                {data?.length
                    ? data
                          ?.filter((d, i) => i !== 0 && i < 7)
                          .map((item, i) => {
                              return (
                                  <Grid
                                      key={i}
                                      item
                                      md={4}
                                      style={{ marginBottom: 21 }}
                                      alignItems="center"
                                      justifyContent="center"
                                      container
                                  >
                                      <img
                                          src={getCountryFlag(item.country)}
                                          width={32}
                                          height={24}
                                          style={{
                                              backgroundColor:
                                                  !item.image && 'CaptionText',
                                              borderRadius: 5,
                                          }}
                                          alt={''}
                                      />
                                      <Typography
                                          style={{ marginLeft: 4 }}
                                          variant="body2"
                                      >
                                          {item?.female +
                                              item?.male +
                                              item?.other}
                                      </Typography>
                                  </Grid>
                              )
                          })
                    : null}
            </Grid>
            <TopLocationModal data={data} />
        </Grid>
    )
}

export default TopLocation
