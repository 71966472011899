import { Grid, IconButton, Typography, Box } from '@material-ui/core'
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons'
import React from 'react'
import Slider from 'react-slick'
import moment from 'moment'

import { styles } from '../../../../styles/styles'

const subscriptionType = {
    GUEST: 'GUEST',
    COMMUNITY: 'COMMUNITY',
    MEMBER: 'MEMBER',
    notSubscribe: 'NOT SUBSCRIBED',
}
const MoreInfo = ({ data }) => {
    const style = styles()
    const slideRef = React.createRef()

    const settings = {
        infinite: false,
        speed: 500,
        arrows: true,
        slidesToShow: 4,
        slidesToScroll: 1,
    }
    return (
        <Grid spacing={10} item xs={12}>
            {/* ------------------------------------------------------------------- image and info section */}
            <Grid item container justifyContent="space-between">
                <Grid item container style={{ width: 'calc(100% - 100px)' }}>
                    {/* <Grid item xs={12}>
                        <Typography style={{ fontSize: 14 }} variant="body1">
            
                        </Typography>
                        <Typography style={{ fontSize: 14 }} variant="body1">
                            {`${data?.user.address.city}, ${data?.user.address.country}`}
                        </Typography>
                    </Grid> */}
                    <Box
                        display={'flex'}
                        alignItems={'center'}
                        justifyContent={'space-between'}
                        width={'100%'}
                    >
                        <Box display={'flex'} flexDirection={'column'}>
                            <Typography
                                style={{
                                    fontSize: 14,
                                    fontWeight: 400,
                                    color: 'rgba(5, 6, 15, 0.8)',
                                }}
                            >
                                Date Of Birth
                            </Typography>
                            <Typography
                                style={{
                                    fontSize: 16,
                                    fontWeight: 500,
                                    color: '#05060F',
                                }}
                            >
                                {moment(data?.user.dateOfBirth).format(
                                    'DD.MM.YYYY'
                                )}
                            </Typography>
                        </Box>
                    </Box>

                    <Box
                        display={'flex'}
                        marginTop={3}
                        alignItems={'center'}
                        justifyContent={'space-between'}
                        width={'100%'}
                    >
                        <Box display={'flex'} flexDirection={'column'}>
                            <Typography
                                style={{
                                    fontSize: 14,
                                    fontWeight: 400,
                                    color: 'rgba(5, 6, 15, 0.8)',
                                }}
                            >
                                Address
                            </Typography>
                            <Typography
                                style={{
                                    fontSize: 16,
                                    fontWeight: 500,
                                    color: '#05060F',
                                }}
                            >
                                {`${data?.user.address.city}, ${data?.user.address.country}`}
                            </Typography>
                        </Box>
                    </Box>
                    <Box
                        display={'flex'}
                        marginTop={3}
                        marginBottom={3}
                        alignItems={'center'}
                        justifyContent={'space-between'}
                        width={'100%'}
                    >
                        <Box display={'flex'} flexDirection={'column'}>
                            <Typography
                                style={{
                                    fontSize: 14,
                                    fontWeight: 400,
                                    color: 'rgba(5, 6, 15, 0.8)',
                                }}
                            >
                                Subscription
                            </Typography>
                            <Typography
                                style={{
                                    fontSize: 16,
                                    fontWeight: 500,
                                    color: '#05060F',
                                }}
                            >
                                {data?.user?.subscription === 'NOT SUBSCRIBED'
                                    ? subscriptionType['notSubscribe']
                                    : subscriptionType[
                                          data?.user?.subscription
                                      ]}
                            </Typography>
                        </Box>

                        <Box display={'flex'} flexDirection={'column'}>
                            <Typography
                                style={{
                                    fontSize: 14,
                                    fontWeight: 400,
                                    color: 'rgba(5, 6, 15, 0.8)',
                                }}
                            >
                                Attachment Style
                            </Typography>
                            <Typography
                                style={{
                                    fontSize: 16,
                                    fontWeight: 500,
                                    color: '#05060F',
                                }}
                            >
                                {data?.user?.attachmentStyle
                                    ? data?.user?.attachmentStyle
                                    : 'NA'}
                            </Typography>
                        </Box>

                        <Box display={'flex'} flexDirection={'column'}>
                            <Typography
                                style={{
                                    fontSize: 14,
                                    fontWeight: 400,
                                    color: 'rgba(5, 6, 15, 0.8)',
                                }}
                            >
                                Ethnicity
                            </Typography>
                            <Typography
                                style={{
                                    fontSize: 16,
                                    fontWeight: 500,
                                    color: '#05060F',
                                }}
                            >
                                {data?.user?.ethnicity
                                    ? data?.user?.ethnicity
                                    : 'NA'}
                            </Typography>
                        </Box>
                    </Box>
                    <Grid item xs={4}>
                        <Typography
                            style={{
                                fontSize: 14,
                                fontWeight: 400,
                                color: 'rgba(5, 6, 15, 0.8)',
                            }}
                        >
                            User Level
                        </Typography>
                        <Typography
                            style={{
                                fontSize: 16,
                                fontWeight: 500,
                                color: '#05060F',
                            }}
                        >
                            {data?.user?.leveling ? data?.user?.leveling : '-'}
                        </Typography>
                    </Grid>
                    <Grid item xs={8} style={{ paddingLeft: '14px' }}>
                        <Typography
                            style={{
                                fontSize: 14,
                                fontWeight: 400,
                                color: 'rgba(5, 6, 15, 0.8)',
                            }}
                        >
                            Languages
                        </Typography>
                        {data?.user?.languagesSpoken?.length === 0 ? (
                            <Typography
                                style={{ fontSize: 16, fontWeight: 500 }}
                                variant="p"
                                color="#05060F"
                            >
                                NA
                            </Typography>
                        ) : null}
                        {data?.user?.languagesSpoken.map((language, index) => (
                            <Typography
                                key={language}
                                style={{ fontSize: 16, fontWeight: 500 }}
                                variant="p"
                                color="#05060F"
                            >
                                {language}
                                {data.user?.languagesSpoken.length - 1 == index
                                    ? ''
                                    : ', '}
                            </Typography>
                        ))}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default MoreInfo
