import React from 'react'

export const DeletedIcon = () => {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="28" height="28" rx="6" fill="#E4B9B9" />
      <path d="M11.75 11.75H12.875V18.5H11.75V11.75ZM15.125 11.75H16.25V18.5H15.125V11.75Z" fill="white" />
      <path d="M7.25 8.375V9.5H8.375V20.75C8.375 21.0484 8.49353 21.3345 8.7045 21.5455C8.91548 21.7565 9.20163 21.875 9.5 21.875H18.5C18.7984 21.875 19.0845 21.7565 19.2955 21.5455C19.5065 21.3345 19.625 21.0484 19.625 20.75V9.5H20.75V8.375H7.25ZM9.5 20.75V9.5H18.5V20.75H9.5ZM11.75 6.125H16.25V7.25H11.75V6.125Z" fill="white" />
    </svg>


  )
}
