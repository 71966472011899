import { Grid, Tabs, Tab, Divider } from '@material-ui/core'

import React, { useState } from 'react'

const HubTabs = ({
    tabClickedCallback,
    tabsDataCount,
    loader,
    tabInitialValue,
    setTabInitialValue,
}) => {
    const handleChange = (event, newValue) => {
        setTabInitialValue(newValue)
        if (newValue !== tabInitialValue) {
            tabClickedCallback()
        }
    }
    const routes = [
        {
            title: `Published (${tabsDataCount?.PUBLISHED})`,
            value: 'PUBLISHED',
        },
        {
            title: `Drafts (${tabsDataCount?.DRAFT})`,
            value: 'DRAFT',
        },

        {
            title: `Unpublished (${tabsDataCount?.UNPUBLISHED})`,
            value: 'UNPUBLISHED',
        },
    ]

    return (
        <Grid container alignItems="flex-start">
            {/* ------------------------------------------------------------------------------------------tab section */}
            <Grid
                item
                justifyContent="space-between"
                alignItems="center"
                style={{
                    height: 48,
                    marginTop: 30,
                    display: 'flex',
                    flexDirection: 'row',
                }}
            >
                <Grid item>
                    <Tabs
                        value={tabInitialValue}
                        onChange={handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        style={{ flex: 1 }}
                    >
                        {routes.map((item, i) => (
                            <Tab
                                disabled={loader}
                                key={i}
                                classes={{ wrapper: 'tabsIndicator' }}
                                label={item.title}
                                value={item.value}
                                style={{
                                    fontSize: 16,
                                    textTransform: 'unset',
                                    fontFamily: 'interSemi',
                                    minWidth: 80,
                                    marginRight: 50,
                                    paddingRight: 0,
                                    paddingLeft: 0,
                                }}
                            />
                        ))}
                    </Tabs>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Divider />
            </Grid>
        </Grid>
    )
}

export default HubTabs
