import React from 'react'
import ActionImage from './ActionImage'

const ViewUserImage = ({ setUserImages, imageId, userId, imageURL }) => {
    console.log('imageURL', imageURL)
    return imageURL ? (
        <div style={{ position: 'relative' }}>
            <ActionImage
                setUserImages={setUserImages}
                userId={userId}
                imageId={imageId}
            />
            <img
                src={imageURL}
                alt="User Image"
                style={{ borderRadius: 12, objectFit: 'cover' }}
                height={'242px'}
                width={'60%'}
            />
        </div>
    ) : null
}

export default ViewUserImage
