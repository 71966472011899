import { makeStyles } from '@material-ui/styles'

export const styles = makeStyles(
    (theme) => ({
        lowerCase: {
            textTransform: 'none',
        },
        login: {
            backgroundColor: '#fff',
            borderRadius: 16,
            margin: 'auto',
            padding: '24px 0',
        },
        boxShadow: {
            boxShadow: '0px 2px 6px rgba(188, 188, 188, 0.32)',
        },
        TextField: {
            fontSize: 14,
            borderRadius: 6,
        },
        LayoutRoot: {
            display: 'flex',
            backgroundColor: '#E5E5E5',
            minHeight: '100vh',
        },
        LayoutChildren: {
            width: '100%',
        },
        active_notification: {
            width: 10,
            height: 10,
            backgroundColor: '#F26464',
            borderRadius: 15,
            position: 'relative',
            bottom: 10,
            right: 32,
            border: '1px solid #fff',
        },
        homeTimeLine: {
            border: '1px solid #E9E9E9',
            width: 285,
            height: 54,
            backgroundColor: '#E9E9E9',
            borderRadius: 100,
        },
        activeTimeLine: {
            backgroundColor: 'white',
            textTransform: 'capitalize',
            fontWeight: 500,
            color: 'black',
            boxShadow: '0px 0px 12px rgba(149, 149, 149, 0.25)',
            fontFamily: 'inter',
            fontSize: 16,
        },
        deActiveTimeLine: {
            backgroundColor: '#E9E9E9',
            textTransform: 'capitalize',
            fontFamily: 'inter',
            fontWeight: 400,
            fontSize: 16,
            boxShadow: 'none',
            color: 'rgba(0, 0, 0, 0.6)',
        },
        goMatch: {
            height: 120,
            width: '80%',
            margin: 16,
            border: '1px solid #c9c9c9',
            padding: 24,
            borderRadius: 12,
        },

        addIceBreaker: {
            boxShadow: '0 0 3px rgba(0,0,0,0.5)',
            backgroundColor: 'white',
            border: '1.5px solid black',
            color: 'black',
            fontWeight: 500,
            fontFamily: 'interBold',
            textTransform: 'capitalize',
        },
        addPreferences: {
            boxShadow: '0 0 3px rgba(0,0,0,0.1)',
            backgroundColor: 'inherit',
            border: '2px solid black',
            color: 'black',
            fontWeight: 600,
            fontFamily: 'sora',
            textTransform: 'capitalize',
        },
        box: {
            backgroundColor: 'white',
            borderRadius: 12,
            padding: 12,
            border: '1px solid #E6E6E6',
            marginTop: 24,
        },
        boxQuestionnairDialogue: {
            backgroundColor: 'white',
            borderRadius: 12,
            padding: 12,
            border: '1px solid #E6E6E6',
            marginTop: 12,
        },
        Avatar: {
            backgroundColor: 'black',
            color: theme.palette.primary.main,
            width: 40,
            height: 40,
            fontSize: 16,
        },
        TableHeadCells: {
            color: theme.palette.text.secondary,
            fontSize: 15,
            fontWeight: 'bold',
        },
        TableRows: {
            fontSize: 15,
            fontWeight: 'bold',
            color: theme.palette.text.secondary,
        },
        DataGridHeader: {},
        radioLabel: {
            fontSize: 16,
            fontFamily: 'inter',
            fontWeight: 600,
            color: '#000',
            opacity: 1,
        },
        Notification: {
            borderRadius: 12,
            boxShadow: '0px 2px 6px rgba(188, 188, 188, 0.32)',
            border: '1px solid #E6E6E6',
        },
        dropBox: {
            fontSize: 15,
            fontWeight: 500,
            fontFamily: 'manrope',
        },
        login_disabled: {
            backgroundColor: '#fff',
        },
        reportHeader: {
            backgroundColor: '#F2F2F2',
            height: 60,
            fontSize: 14,
        },
        reportRow: {},
        AutoCompletionBackGround: {
            opacity: 1,
        },
        blogDescription: {
            fontSize: 14,
            fontWeight: 400,
            fontFamily: 'sora',
            width: '100%',
            height: 80,
            overflow: 'auto',
        },
        blogTitle: {
            fontSize: 20,
            fontWeight: 700,
            fontFamily: 'sora',
            margin: '16px 0',
        },
        customMessage: {
            marginTop: 110,

            borderRadius: 16,
        },
        messageColor: {
            color: 'white',
        },
        viewProfileDialog: {
            maxWidth: 666,
        },
        viewQuetionnairDialog: {
            maxWidth: 1500,
        },
        reportReasonDialog: {
            maxWidth: 705,
        },
    }),
    { index: 1 }
)
