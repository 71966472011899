import { Typography } from '@material-ui/core'
import { FormLabel, Grid, MenuItem, Select, Stack } from '@mui/material'
import React from 'react'

const Optional = ({ question, answer, question_type, options, index }) => {
    return (
        <Grid container item xs={6}>
            <Grid item xs={12}>
                <Stack direction="column">
                    <FormLabel
                        style={{
                            marginBottom: '5px',
                        }}
                        htmlFor="first-name"
                    >
                        {/* {item2?.sequence}. */}
                        <span
                            style={{ marginRight: '2px', fontWeight: 'bold' }}
                        >
                            {index + 1} {'.'}
                        </span>
                        {question}
                    </FormLabel>

                    <Select
                        fullWidth
                        multiple={question_type === 'multi_select'}
                        margin="normal"
                        size="small"
                        value={answer ? answer : []}
                        labelId="demo-simple-select-label"
                        label="question"
                        id="demo-simple-select"
                    >
                        {options?.map((option, index) => {
                            return (
                                <MenuItem value={option} key={index}>
                                    {option}
                                </MenuItem>
                            )
                        })}
                    </Select>
                </Stack>
            </Grid>
        </Grid>
    )
}

export default Optional
