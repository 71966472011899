import { Button, Grid, Typography } from "@material-ui/core";
import { useState, useEffect } from "react";
import { styles } from "../../styles/styles";
import { Add } from "@material-ui/icons";
import { Box, CircularProgress } from "@mui/material";
import { Stack } from "@mui/system";
import InfiniteScroll from "react-infinite-scroll-component";
import AddNewPromo from "./AddNewPromo";
import { PromoCodeLabelIcon } from "../../icons/PromoCodeLabelIcon";
import { PromoCodeDesIcon } from "../../icons/PromoCodeDesIcon";
import { PromoCodePerciIcon } from "../../icons/PromoCodePerciIcon";
import { PromoCodeHideIcon } from "../../icons/PromoCodeHideIcon";
import { IOSSwitch } from "../../components/Textfields";
import { PromoCodeMoveBackIcon } from "../../icons/PromoCodeMoveBackIcon";
import { approvedPromotion, delete_promocode, get_promoCodes, update_promocode } from "../../api/PromoCode_Api";


const buttonStyle = {
  padding: "12px 24px 12px 16px",
  background: "rgba(101, 129, 157, 0.1)",
  border: "1px solid #D2D2D2",
  borderRadius: "12px"
};
const typoStyle = { fontWeight: 500, fontSize: "16px", color: "#1C2439", overflowWrap: 'break-word', };

const PromoCode = () => {
  const [openAddNewPlan, setOpenAddNewPlan] = useState(false);
  const [selectedList, setSelectedList] = useState('approved');//deleted or approved
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const style = styles();
  const [data, set_data] = useState([]);

  useEffect(() => {
    get_promoCodes().then((res) => {
      if (!res) return;
      setTotal(res.data.pagination.total);
      set_data(res.data.promotions);
      setIsLoading(false);
    });
  }, []);
  const deletePromotion = (_id) => {
    delete_promocode(_id)
      .then((res) => {
        setTotal(prev => prev - 1);
        set_data(prev => prev.map(item => {
          if (item._id !== _id)
            return item;
          return res.data;
        }))
      });

  };

  return (

    <Grid container>
      <Stack direction='row' width='100%' alignItems="flex-start" justifyContent={'space-between'}>
        <Stack >
          <Typography style={{
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: "32px",
            color: '#05060F',
          }}>Promo Code</Typography>
          <Typography
            onClick={() => {
              selectedList === 'approved'
                ? setSelectedList('deleted')
                : setSelectedList('approved')
            }}

            style={{
              fontWeight: 500,
              fontSize: "14px",
              textDecorationLine: "underline",
              color: "#050B7A",
              cursor: 'pointer'
            }}>{selectedList === 'deleted' ? `View promo codes` : `View hidden promo codes`}</Typography>
        </Stack>


        <Button
          onClick={() => { setOpenAddNewPlan(true); }}
          startIcon={<Add />}
          style={{
            padding: "8px 16px 8px 12px",
            border: "2px solid #65819D",
            borderRadius: "16px",
            fontWeight: 600,
            fontSize: "16px",
            textAlign: "center",
            color: "#65819D",
          }}>Add new code</Button>
      </Stack>

      {
        isLoading
          ? <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            width='100%'
            height='60vh'
          >
            <CircularProgress />
          </Box>
          :
          <>
            <Grid id='scrollableDiv' container item md={12} style={{
              flexGrow: 1,
              overflow: 'auto',
              display: 'flex',
              flexDirection: 'column-reverse',
            }}>
              {data && <InfiniteScroll
                dataLength={data.length}
                style={{ width: '100%' }}
                scrollableTarget="scrollableDiv"
                next={() => {

                  setPage((prev) => prev + 1);
                }}
                hasMore={data.length < total}
                loader={
                  <Stack direction="row" justifyContent="center" p={1}>
                    <CircularProgress />
                  </Stack>
                }
                endMessage={
                  data.length > 0 && (
                    <p style={{ textAlign: "center" }}>

                    </p>
                  )
                }
              >

                {
                  data.length > 0 ?
                    data
                      .filter(c => c.status === selectedList)
                      .map((item, i) => (
                        <Stack
                          flexDirection={'row'}
                          alignItems={'center'}
                          justifyContent={'space-between'}
                          sx={{
                            mb: '20px',
                            p: '32px'
                          }}
                          className={style.box}
                        >
                          <Stack
                            flexDirection={'row'}
                            alignItems={'center'}
                            gap={'28px'}
                          >
                            <PromoCodeLabelIcon />
                            <Typography style={typoStyle}>{item.title}</Typography>
                            <Box
                              style={{
                                width: "5px",
                                height: "5px",
                                background: "#D9D9D9",
                                borderRadius: '8px'
                              }} />

                            <Box
                              display={'flex'}
                              alignItems={'center'}
                              gap={'13px'}
                            >
                              <PromoCodeDesIcon />
                              <Typography
                                style={typoStyle}
                              >
                                {item.code}
                              </Typography>
                            </Box>
                            {/* <Box
                            style={{
                              width: "5px",
                              height: "5px",
                              background: "#D9D9D9",
                              borderRadius: '8px'
                            }} />

                          <Box
                            display={'flex'}
                            alignItems={'center'}
                            gap={'13px'}
                          >
                            <PromoCodePerciIcon />
                            <Typography
                              style={typoStyle}
                            >
                              <span style={{ paddingRight: '5px' }}>£</span> {item.price}
                            </Typography>
                          </Box> */}

                          </Stack>
                          {selectedList === 'deleted' ?
                            <Button
                              onClick={() => {
                                approvedPromotion(item._id)
                                  .then(res => {
                                    set_data(prev => prev.map(item => {
                                      if (item._id !== res.data._id)
                                        return item;
                                      return res.data;

                                    }));
                                  })
                                  ;
                              }}
                              startIcon={<PromoCodeMoveBackIcon />} style={buttonStyle}>Move back to promo codes</Button>
                            :

                            <Stack
                              flexDirection={'row'}
                              alignItems={'center'}
                              gap={'12px'}
                            >
                              <Button
                                onClick={() => {
                                  deletePromotion(item._id);
                                }}
                                startIcon={<PromoCodeHideIcon />} style={buttonStyle}>Deactivate</Button>
                              {/* <IOSSwitch
                                checked={item.status === 'approved' ? true : false}
                                onChange={(e) => {
                                  deletePromotion(item._id);
                                }}
                              /> */}
                            </Stack>
                          }
                        </Stack>
                      ))
                    :
                    null
                }

              </InfiniteScroll>
              }
            </Grid></>
      }
      {
        openAddNewPlan &&
        <AddNewPromo
          open={openAddNewPlan}
          setOpen={setOpenAddNewPlan}
          setData={set_data}
          editMode={false}
          currentItem={null}
        />
      }
    </Grid >
  );
};

export default PromoCode;
