import React from 'react'
import Layout from './components/Layout'
import Login from './pages/Login/Login'
import Router from './components/MainRouter'
import './styles/App.css'
// import "bootstrap/dist/css/bootstrap.min.css";
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import { connect } from 'react-redux'
import MessageHandler from './components/AlertMessage'

const App = ({ isAuth }) => {
    console.log('before app component render')
    return (
        <>
            {!localStorage.token ? (
                <Login />
            ) : (
                <>
                    <Layout>
                        <Router />
                    </Layout>
                </>
            )}

            <MessageHandler />
        </>
    )
}

const mapStateToProps = (props) => {
    const { GeneralReducer } = props
    const { isAuth } = GeneralReducer

    return { isAuth }
}

export default connect(mapStateToProps)(App)
