import {
    Grid,
    IconButton,
    Typography,
    Box,
    Divider,
    Button,
} from '@material-ui/core'
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons'
import React, { useState } from 'react'
import Slider from 'react-slick'
import moment from 'moment'
import { Tooltip, withStyles } from '@material-ui/core'

import apple from '../../../../asset/img/request/apple_request.svg'
// import close from "../../asset/img/close.svg";
import insta from '../../../../asset/img/request/insta_request.svg'
import linkdin from '../../../../asset/img/request/linkedin_request.png'

import qrcode from '../../../../asset/img/request/qr_request.svg'
import google from '../../../../asset/img/request/google_request.svg'
import { styles } from '../../../../styles/styles'
import { Stack } from '@mui/material'

const Overview = ({ data }) => {
    let [slideData, set_sliderData] = React.useState([])
    const [otherInformationArr, setOtherInformationArr] = useState([])
    React.useEffect(() => {
        let d = []

        if (data) {
            d.push({ drinking: data?.user.drinking })
            d.push({ ethnicity: data?.user.ethnicity })
            d.push({ exercise: data?.user.exercise })
            d.push({ marriage: data?.user.marriage })
            d.push({ diet: data?.user.diet })
            d.push({
                height:
                    data?.user.height?.type === 'CM'
                        ? data?.user.height.height_cm
                        : data?.user.height?.height_feet,
            })
            d.push({ smoking: data?.user.smoking })
            d.push({ education: data?.user.education })
            d.push({ industry: data?.user.industry })
            d.push({ instagram: data?.user.instagram })
            d.push({ occupation: data?.user.occupation })
            d.push({ pet: data?.user.pet })
            d.push({ politics: data?.user.politics })
            d.push({ salary: data?.user.salary })
            d.push({ sexualOrientation: data?.user.sexualOrientation })
            d.push({ vaccinationStatus: data?.user.vaccinationStatus })
        }
        set_sliderData(d)

        console.log('data_data', data?.user)
        const userOtherInfoArr = [
            {
                label: 'Religion',
                value: data?.user?.religion,
            },
            {
                label: 'Attachment Style',
                value: data?.user?.attachmentStyle,
            },
            {
                label: 'Ethnicity',
                value: data?.user?.ethnicity,
            },
            {
                label: 'Sexual Orientation',
                value: data?.user?.sexualOrientation,
            },
            {
                label: 'Height',
                value:
                    data?.user.height?.type === 'CM'
                        ? data?.user.height.height_cm
                        : data?.user.height?.height_feet,
            },
            {
                label: 'Smoking',
                value: data?.user?.smoking,
            },
            {
                label: 'Drinking',
                value: data?.user?.drinking,
            },
            {
                label: 'Looking For',
                value: data?.user?.lookingFor[0],
            },
            //        {
            //     label: 'Family',
            //     value: 'data?.user?.religion,
            // },
            {
                label: 'Salary',
                value: data?.user?.salary,
            },
            {
                label: 'Occupation',
                value: data?.user?.occupation,
            },
            {
                label: 'Industry',
                value: data?.user?.industry,
            },
        ]
        setOtherInformationArr(userOtherInfoArr)
    }, [data])

    const style = styles()
    const slideRef = React.createRef()

    const settings = {
        infinite: false,
        speed: 500,
        arrows: true,
        slidesToShow: 4,
        slidesToScroll: 1,
    }

    // const hadleMarginBottom = (value) => {
    //     return ['Attachment Style', 'Sexual Orientation'].includes(value)
    //     // 'Looking For',
    // }
    return (
        <Grid item xs={12}>
            {/* ------------------------------------------------------------------- image and info section */}
            <Grid item container justifyContent="space-between">
                <Grid item>
                    <img
                        src={`${
                            data?.user?.images.length
                                ? data?.user.images[0]?.croped
                                : ''
                        }`}
                        alt=""
                        style={{ borderRadius: 4 }}
                        width={130}
                        height={150}
                    />
                </Grid>
                <Grid item container style={{ width: 'calc(100% - 150px)' }}>
                    <Grid item xs={12}>
                        <Typography variant="body2">
                            {data?.user.firstName} {data?.user.lastName}
                        </Typography>
                        <Typography variant="subtitle1">
                            {data?.user.mobileNumber}
                        </Typography>
                        <Typography color="textPrimary" variant="subtitle2">
                            {data?.user.email}
                        </Typography>
                    </Grid>

                    <Box
                        display={'flex'}
                        alignItems={'center'}
                        justifyContent={'space-between'}
                        width={'100%'}
                    >
                        <Box display={'flex'} flexDirection={'column'}>
                            <Typography
                                style={{
                                    fontSize: 14,
                                    fontWeight: 400,
                                    color: 'rgba(5, 6, 15, 0.8)',
                                }}
                            >
                                Gender
                            </Typography>
                            <Typography
                                style={{
                                    fontSize: 18,
                                    fontWeight: 500,
                                    color: '#05060F',
                                }}
                            >
                                {data?.user.gender}
                            </Typography>
                        </Box>

                        <Box display={'flex'} flexDirection={'column'}>
                            <Typography
                                style={{
                                    fontSize: 14,
                                    fontWeight: 400,
                                    color: 'rgba(5, 6, 15, 0.8)',
                                }}
                            >
                                Age
                            </Typography>
                            <Typography
                                style={{
                                    fontSize: 18,
                                    fontWeight: 500,
                                    color: '#05060F',
                                }}
                            >
                                {moment().diff(
                                    data?.user?.dateOfBirth,
                                    'years'
                                )}
                            </Typography>
                        </Box>

                        <Box display={'flex'} flexDirection={'column'}>
                            <Typography
                                style={{
                                    fontSize: 14,
                                    fontWeight: 400,
                                    color: 'rgba(5, 6, 15, 0.8)',
                                }}
                            >
                                Matches
                            </Typography>
                            <Typography
                                style={{
                                    fontSize: 18,
                                    fontWeight: 500,
                                    color: '#05060F',
                                }}
                            >
                                {data?.user?.matchesArrayCount}
                            </Typography>
                        </Box>
                    </Box>
                    <Grid
                        container
                        alignItems="center"
                        justifyContent="space-between"
                    >
                        <Grid
                            item
                            xs={3}
                            style={{
                                textAlign: 'center',
                                marginRight: 70,
                            }}
                        >
                            {/* <Typography variant="body1">
                {item?.title === "birthDate" ? "Age" : item?.title}
              </Typography> */}
                            {/* <Typography variant="body2">
                {item.title === "Gender"
                  ? data?.gender
                  : item.title === "Diet"
                    ? data?.diet
                    : item.title === "Age"
                      ? moment().diff(data?.dateOfBirth, "years")
                      : null}
              </Typography> */}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {/* ------------------------------------------------------------------- other info section */}
            <Grid item container style={{ margin: '16px 0' }}>
                {/* <Grid item container justifyContent="space-between" alignItems="center">
          <Typography style={{ fontSize: 18 }} variant="h4">
            Other information
          </Typography>
          <Grid item container xs={4} justifyContent="flex-end">
            <IconButton
              onClick={goToPrev}
              disabled={data?.preferences?.length < 5}
            >
              <KeyboardArrowLeft />
            </IconButton>
            <IconButton
              onClick={goToNext}
              disabled={data?.preferences?.length < 5}
            >
              <KeyboardArrowRight />
            </IconButton>
          </Grid>
        </Grid>
        <Grid item className={style.box} style={{ width: "100%" }}>
          <Slider {...settings} ref={slideRef}>
            {slideData?.map((item, i) => (
              <Box display={"flex"} flexDirection={"column"}>
                <Typography variant="body1">{Object.keys(item)[0]}</Typography>
                <Typography variant="body2">
                  {Object.values(item)[0]}
                </Typography>
              </Box>
            ))}
          </Slider>
        </Grid> */}
                <Grid
                    item
                    container
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Typography style={{ fontSize: 18 }} variant="h4">
                        Social Profiles
                    </Typography>
                    <Grid item container xs={4} justifyContent="flex-end">
                        {images.map((item, i) => {
                            console.log(
                                'data.user[item.value]',
                                item.value,
                                data.user[item.value]
                            )
                            if (data.user[item.value])
                                return (
                                    <LightTooltip
                                        key={i}
                                        arrow
                                        title={
                                            data?.user[item.value]
                                                ? data?.user[
                                                      item.value
                                                  ]?.startsWith('http')
                                                    ? data?.user[item.value]
                                                    : item.value === 'instagram'
                                                    ? `https://www.instagram.com/${
                                                          data?.user[item.value]
                                                      }`
                                                    : data?.user[item.value]
                                                : ''
                                        }
                                    >
                                        <div style={{ marginLeft: 30 }}>
                                            {item.value === 'instagram' ? (
                                                <a
                                                    href={
                                                        data?.user[
                                                            item.value
                                                        ]?.startsWith('http')
                                                            ? data?.user[
                                                                  item.value
                                                              ]
                                                            : `https://www.instagram.com/${
                                                                  data?.user[
                                                                      item.value
                                                                  ]
                                                              }`
                                                    }
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    <img
                                                        src={item.icon}
                                                        alt="Instagram URL"
                                                        // width={20}
                                                        // height={20}
                                                    />
                                                </a>
                                            ) : item.value === 'linkedin' ? (
                                                <a
                                                    href={
                                                        data?.user[item.value]
                                                    }
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    <img
                                                        src={item.icon}
                                                        alt="LinkedIn URL"
                                                        // width={20}
                                                        // height={20}
                                                    />
                                                </a>
                                            ) : (
                                                <img src={item.icon} alt="" />
                                            )}
                                        </div>
                                    </LightTooltip>
                                )
                        })}
                    </Grid>
                </Grid>
            </Grid>
            {/* -------------------------------------------------------------------  Biography info section */}
            <Grid item container style={{ marginTop: 30 }}>
                <Grid item xs={12}>
                    <Typography style={{ fontSize: 18 }} variant="h4">
                        Other Information
                    </Typography>

                    <Stack
                        direction="row"
                        divider={<Divider orientation="vertical" flexItem />}
                        spacing={3}
                        style={{
                            border: '2px solid rgba(5, 6, 15, 0.4)',
                            borderRadius: '5px',
                            marginTop: '10px',
                            marginBottom: '10px',
                            // flexWrap: 'nowrap',
                            overflow: 'auto',
                            // width: 'calc(400%)',
                        }}
                    >
                        {otherInformationArr?.map((item) => {
                            return (
                                <div
                                    style={{
                                        padding: '5px',
                                        marginLeft: '5px',
                                        marginRight: '5px',
                                    }}
                                >
                                    <Typography
                                        style={{
                                            color: 'rgba(5, 6, 15, 0.8)',
                                            width: '135px',
                                            fontWeight: 'bold',
                                        }}
                                        variant="body1"
                                    >
                                        {item?.label}
                                    </Typography>

                                    <Typography
                                        style={{
                                            color: 'rgba(5, 6, 15, 0.8)',
                                            marginTop: '12px',
                                            marginBottom: '6px',
                                        }}
                                        variant="body1"
                                    >
                                        {item?.value ? item.value : 'NA'}
                                    </Typography>
                                </div>
                            )
                        })}
                    </Stack>
                </Grid>
                <Grid item xs={12}>
                    <Typography style={{ fontSize: 18 }} variant="h4">
                        Why you joined the REDDI comunity
                    </Typography>
                    <Grid container xs={12} md={10} item>
                        <Typography
                            variant="body1"
                            style={{
                                textAlign: 'justify',
                                marginTop: 24,
                                color: 'rgba(5, 6, 15, 0.8)',
                                paddingBottom: '40px',
                            }}
                        >
                            {data?.user.whyToJoin}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Typography style={{ fontSize: 18 }} variant="h4">
                        Referrals
                    </Typography>
                    {/* <Grid container xs={12} md={10} item>
                        <Typography
                            variant="body1"
                            style={{
                                textAlign: 'justify',
                                marginTop: 24,
                                color: 'rgba(5, 6, 15, 0.8)',
                                paddingBottom: '40px',
                            }}
                        >
                        </Typography>
                    </Grid> */}
                    {/* {data?.user?.referralBy && `${data?.user?.referralBy?.firstName} ${data?.user?.referralBy?.lastName}`} */}
                </Grid>
                <Grid item xs={12}>
                    <Typography
                        style={{ fontSize: 18 }}
                        variant="h4"
                    ></Typography>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default Overview

let details = [
    { title: 'Gender', value: 'gender' },
    { title: 'Age', value: 'dateOfBirth' },
    { title: 'Diet', value: 'diet' },
]

const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 14,
        padding: '8px 16px',
    },
    arrow: {
        color: 'white',
    },
}))(Tooltip)
const images = [
    { icon: qrcode, value: '' },
    { icon: google, value: 'email' },
    // { icon: fb, value: "" },
    { icon: insta, value: 'instagram' },
    { icon: linkdin, value: 'linkedin' },
    { icon: apple, value: '' },
]
