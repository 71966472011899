import {
    Button,
    CircularProgress,
    ClickAwayListener,
    FormControl,
    FormControlLabel,
    FormLabel,
    IconButton,
    ListItemIcon,
    Menu,
    MenuItem,
    Radio,
    RadioGroup,
    Switch,
    TextField,
    Typography,
    useTheme,
    withStyles,
} from '@material-ui/core'
import { AccessTime, KeyboardArrowDown } from '@material-ui/icons'
import { KeyboardDateTimePicker } from '@material-ui/pickers'
import date from '../asset/img/date.png'

import React from 'react'
import { styles } from '../styles/styles'
import icon from '../asset/img/export.png'
import sortBlog from '../asset/img/sortRequsets.png'
import { Autocomplete } from '@material-ui/lab'
import { useField } from 'formik'
import Store from '../redux/Store'
import { search_change } from '../redux/ActionCreators'
import { export_users } from '../api/Users_Api'

import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'

import { DesktopDateRangePicker } from '@mui/lab'
import moment from 'moment'
import { Divider } from '@mui/material'

export const TextFieldWrapper = ({
    name,
    readOnly,
    variant,
    InputProps,
    white,
    ...otherProps
}) => {
    const [field, mata, form] = useField(name)
    const theme = useTheme()
    const { setValue } = form
    const classes = styles()

    const config = {
        ...field,
        ...otherProps,
        fullWidth: true,
        variant: variant ? variant : 'outlined',
        size: 'small',
        color: 'secondary',
    }

    if (mata && mata.error && mata.touched) {
        config.error = true
        config.helperText = mata.error
    }

    return (
        <TextField
            {...config}
            onChange={(e) => {
                setValue(e.target.value)
            }}
            style={{
                paddingTop: !variant && !white && 0,
                backgroundColor: !variant && !white && '#F2F2F2',
                opacity: 1,
            }}
            FormHelperTextProps={{
                style: {
                    backgroundColor: !variant && 'white',
                    margin: !variant && '0px -4px 0px 0px',
                },
            }}
            InputProps={{
                className: classes.TextField,
                style: {
                    color: variant ? 'white' : 'inherit',

                    opacity: 1,
                    backgroundColor: 'transparent',
                    borderRadius: 4,
                    borderBottom:
                        field.name === 'email' || field.name === 'password'
                            ? `2px solid ${
                                  config.error
                                      ? theme.palette.error.main
                                      : '#fff'
                              }`
                            : 'none',
                },
                ...InputProps,
            }}
        />
    )
}

export const HomeTimePicker = ({ value }) => {
    return (
        <KeyboardDateTimePicker
            onKeyPress={(e) => e.preventDefault()}
            // keyboardIcon={<img src={Calender} alt="" />}
            autoOk
            // name={field.name}
            // maxDate={maxdate ? new Date(maxdate) : undefined}
            // minDate={mindate ? new Date(mindate) : undefined}
            value={new Date()}
            onChange={(value) => {
                if (value !== null) {
                }
            }}
            format="dd/MM/yyyy"
            inputProps={{
                style: {
                    fontSize: 14,
                    height: 24,
                },
            }}
            variant="dialog"
            inputVariant="outlined"
            animateYearScrolling
            size="small"
        />
    )
}

export const IOSSwitch = withStyles((theme) => ({
    root: {
        width: 42,
        height: 26,
        padding: 0,
        margin: theme.spacing(1),
    },
    switchBase: {
        padding: 1,
        '&$checked': {
            transform: 'translateX(16px)',
            color: theme.palette.common.white,
            '& + $track': {
                backgroundColor: theme.palette.primary.light,
                opacity: 1,
                border: 'none',
            },
        },
        '&$focusVisible $thumb': {
            color: '#52d869',
            border: '6px solid #fff',
        },
    },
    thumb: {
        width: 24,
        height: 24,
    },
    track: {
        borderRadius: 26 / 2,
        border: `1px solid ${theme.palette.grey[400]}`,
        backgroundColor: theme.palette.grey[50],
        opacity: 1,
        transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {},
    focusVisible: {},
}))(({ classes, ...props }) => {
    return (
        <Switch
            focusVisibleClassName={classes.focusVisible}
            disableRipple
            classes={{
                root: classes.root,
                switchBase: classes.switchBase,
                thumb: classes.thumb,
                track: classes.track,
                checked: classes.checked,
            }}
            {...props}
        />
    )
})

export const RadioG = ({ title, labels, onChange, value }) => {
    return (
        <FormControl component="fieldset">
            <FormLabel component="legend">
                <Typography variant="body1">{title}</Typography>
            </FormLabel>
            <RadioGroup
                row
                defaultValue={value}
                onChange={(e) => {
                    onChange(e.target.value)
                }}
            >
                {labels.map((item, i) => (
                    <FormControlLabel
                        style={{ width: 180 }}
                        key={i}
                        value={item.value}
                        control={<Radio />}
                        label={item.label}
                    />
                ))}
            </RadioGroup>
        </FormControl>
    )
}

export const SearchTextField = ({ user, placeholder }) => {
    const [query, setQuery] = React.useState('')
    const { search } = Store.getState().GeneralReducer

    // React.useEffect(() => {
    //   if (search === "") {
    //     setQuery("");
    //   }
    // }, [search]);
    React.useEffect(() => {
        const timeOutId = setTimeout(
            () => Store.dispatch(search_change(query)),

            500
        )
        return () => {
            clearTimeout(timeOutId)
        }
    }, [query])
    return (
        <CssTextField
            variant="outlined"
            size="small"
            value={query}
            color="secondary"
            placeholder={placeholder}
            style={{
                backgroundColor: '#FBFAFB',
                borderRadius: 8,
            }}
            InputProps={{
                style: {
                    height: user ? 38 : 25,
                    // paddingBottom: 6,
                },
            }}
            onChange={(event) => setQuery(event.target.value)}
        />
    )
}
export const CssTextField = withStyles({
    root: {
        // position: "relative",
        //   bottom: 4,
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                border: '1.5px solid #DEDEDE',
                borderRadius: 8,
            },
        },
    },
})(TextField)

export const SortButton = ({ menu, set_sort, sort }) => {
    const [anchorEl, setAnchorEl] = React.useState(null)
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }
    return (
        <>
            <Button
                variant="outlined"
                style={{
                    borderRadius: 8,
                    height: 40,
                    marginLeft: 16,
                    backgroundColor: '#FBFAFB',
                    position: 'relative',
                    border: '1px solid #DEDEDE',
                    color: 'rgba(14, 14, 16, 0.5)',
                }}
                onClick={handleClick}
                endIcon={
                    <img
                        src={sortBlog}
                        alt=""
                        style={{
                            transform: `rotateX(${
                                Boolean(anchorEl) ? 180 : 0
                            }deg)`,
                            transition: '0.5s',
                        }}
                    />
                }
            >
                Sort by
            </Button>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                style={{
                    position: 'absolute',
                    top: 70,
                    padding: '10px',
                }}
                value={sort}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem disabled>Sort by</MenuItem>
                <Divider orientation="horizontal" />
                {menu.map((item) => (
                    <MenuItem
                        onClick={() => {
                            set_sort(item.value)
                            handleClose()
                        }}
                        value={item.value}
                        key={item.value}
                    >
                        {' '}
                        <ListItemIcon style={{ minWidth: '30px' }}>
                            {item?.icon}
                        </ListItemIcon>
                        {item.title}
                    </MenuItem>
                ))}
            </Menu>
        </>
    )
}

export const ExportTextfield = ({
    tabValue,
    advanceSearchFormData,
    subscription,
    onlyAdvanceSearch,
}) => {
    const [anchorEl, setAnchorEl] = React.useState(null)

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }
    const handleExportUser = (type) => {
        export_users(
            tabValue,
            type,
            advanceSearchFormData,
            subscription,
            onlyAdvanceSearch
        )
    }

    const handleClose = () => {
        setAnchorEl(null)
    }
    return (
        <>
            <Button
                variant="outlined"
                style={{
                    borderRadius: 8,
                    height: 40,
                    marginLeft: 16,
                    backgroundColor: '#FBFAFB',
                    position: 'relative',
                    color: '#005441',
                    borderColor: '#005441',
                }}
                endIcon={
                    <KeyboardArrowDown
                        style={{
                            position: 'relative',
                            top: 2,
                            transform: `rotate(${anchorEl ? 180 : 0}deg)`,
                            transition: '0.2s',
                        }}
                    />
                }
                onClick={handleClick}
                startIcon={<img src={icon} alt="" />}
            >
                Export
            </Button>

            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                style={{ position: 'absolute', top: 45 }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem
                    style={{ width: 140 }}
                    value={0}
                    onClick={() => {
                        handleExportUser('csv')
                    }}
                >
                    CSV
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        handleExportUser('xlsx')
                    }}
                    value={1}
                >
                    XLSX
                </MenuItem>
            </Menu>
        </>
    )
}

export const types = [
    { title: 'safe-dating', value: 'Safe Dating' },
    { title: 'your-match', value: 'Matching' },

    { title: 'dating-styles', value: 'Dating Styles' },
    { title: 'tips-for-success', value: 'Successfull Relationships' },
    { title: 'dating-dynamics', value: 'Dating Dynamics' },
    { title: 'general-advice', value: 'Communication' },
]
export const AutoCompletion = ({ loading, onChange }) => {
    const [open, setOpen] = React.useState(false)
    const style = styles()
    // eslint-disable-next-line

    return (
        <Autocomplete
            fullWidth
            style={{ padding: 0, margin: 0, zIndex: 10 }}
            open={open}
            size="small"
            onOpen={() => {
                setOpen(true)
            }}
            onClose={() => {
                setOpen(false)
            }}
            classes={{ paper: style.AutoCompletionBackGround }}
            options={types}
            onChange={(e, value) => {
                if (value !== null) {
                    onChange(value.title)
                } else {
                    onChange('')
                }
            }}
            getOptionLabel={(option) => option.value}
            renderInput={(params) => (
                <TextField
                    color="primary"
                    variant="outlined"
                    fullWidth
                    value=""
                    size="small"
                    {...params}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {loading ? (
                                    <CircularProgress
                                        color="inherit"
                                        size={20}
                                    />
                                ) : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )}
        />
    )
}

export const ReadMinute = ({ onChange }) => {
    const [open, set_open] = React.useState(false)
    const options = [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
        21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38,
        39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56,
        57, 58, 59, 60,
    ]
    const style = styles()
    return (
        <Autocomplete
            fullWidth
            size="small"
            open={open}
            onOpen={() => set_open(true)}
            onClose={() => set_open(false)}
            options={options}
            getOptionLabel={(o) => `${o.toString()} minute`}
            onChange={(e, value) => {
                if (value === null) {
                    onChange('')
                } else {
                    onChange(value)
                }
            }}
            classes={{ paper: style.AutoCompletionBackGround }}
            renderInput={(params) => (
                <TextField
                    style={{ width: 180 }}
                    color="primary"
                    variant="outlined"
                    value="asdas"
                    size="small"
                    {...params}
                    InputProps={{
                        ...params.InputProps,

                        startAdornment: (
                            <React.Fragment>
                                <AccessTime />
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )}
        />
    )
}

export const CustomDatePicker = ({ rangeTime, set_renge_time }) => {
    const [open, set_open] = React.useState(false)

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <ClickAwayListener onClickAway={() => set_open(false)}>
                <div>
                    <DesktopDateRangePicker
                        value={rangeTime}
                        onChange={(newValue) => {
                            set_renge_time(
                                newValue.map((item) =>
                                    item
                                        ? moment(item).format('YYYY-M-D')
                                        : null
                                )
                            )
                        }}
                        open={open}
                        renderInput={(startProps, endProps) => (
                            <IconButton onClick={() => set_open(!open)}>
                                <img src={date} alt="" />
                            </IconButton>
                        )}
                    />
                </div>
            </ClickAwayListener>
        </LocalizationProvider>
    )
}
