import { Button, Fade } from '@material-ui/core'
import React from 'react'
import { add_to_waiting_list } from '../../api/UserRequest_Api'

const RestoreDeletedUsers = ({ wating_list, status, set_data, set_count }) => {
    const handleAddToWaitingList = () => {
        const ids = []
        wating_list.forEach((element) => {
            ids.push(element._id)
            set_data((prev) => prev.filter((c) => c._id !== element._id))
        })

        set_count((prev) => ({
            ...prev,
            deleted: prev.deleted - ids.length,
            waiting: prev.waiting + ids.length,
        }))

        add_to_waiting_list({ ids })
            .then((res) => {
                console.log(res)
            })
            .catch((error) => {})
    }
    return null
}
{
    /* <>
<Button
    onClick={() => handleAddToWaitingList()}
    variant="text"
    startIcon={null}
    style={{ color: '#005441' }}
>
    {'Reactivate'}
</Button>
</> */
}
export default RestoreDeletedUsers
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Fade timeout={350} ref={ref} {...props} />
})
