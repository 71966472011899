import {
    Button,
    Dialog,
    Fade,
    Grid,
    Input,
    Typography,
} from '@material-ui/core'
import Trash from '../../asset/img/force-user-delete.png'
import React from 'react'

import { Form, Formik } from 'formik'
import { delete_user } from '../../api/Users_Api'
import { CircularProgress, TextField } from '@mui/material'
import { search_change } from '../../redux/GeneralReducer'
import Store from '../../redux/Store'
const UserForceDelete = ({
    userName,
    handleCloseOuter,
    confirmation_text,
    data,
    set_Data,
    open,
    setDeleteMutation,
    setOpen,
}) => {
    const { search } = Store.getState().GeneralReducer
    console.log('store_values', search)
    const [loading, setLoading] = React.useState(false)

    const handleClose = () => {
        setOpen(false)
        handleCloseOuter()
    }
    const buttonStyle = { paddingLeft: 0, borderRadius: 6 }

    return (
        <>
            <Formik
                initialValues={{ confirmation_text: '' }}
                onSubmit={(vlaue, props) => {
                    setLoading(true)
                    delete_user(data._id, true)
                        .then((res) => {
                            if (!res) return
                            props.resetForm()
                            setLoading(false)
                            handleClose()
                            setDeleteMutation({})
                        })
                        .catch((err) => {
                            setLoading(false)
                        })
                }}
                validateOnMount
            >
                {(formik) => {
                    return (
                        <Form>
                            <Dialog
                                maxWidth="sm"
                                fullWidth={true}
                                open={open}
                                onClose={handleClose}
                                TransitionComponent={Transition}
                                onClick={(e) => e.stopPropagation()}
                            >
                                <Grid container style={{ padding: 32 }}>
                                    <Grid
                                        item
                                        xs={12}
                                        style={{ marginBottom: 24 }}
                                    >
                                        <Typography variant="body2">
                                            Are you sure you want to delete{' '}
                                            {userName ? userName?.trim() : null}
                                            ?
                                        </Typography>
                                        <Typography variant="body2">
                                            Once deleted, you will not be able
                                            to recover this user.
                                        </Typography>
                                        <br />
                                        <Typography
                                            variant="h6"
                                            style={{ color: '#05060fcc' }}
                                        >
                                            Type "{confirmation_text}" to
                                            confirm your action
                                        </Typography>
                                        <br />
                                        <TextField
                                            name="confirmation_text"
                                            style={{ width: '350px' }}
                                            placeholder={confirmation_text}
                                            value={
                                                formik.values?.confirmation_text
                                            }
                                            size="small"
                                            id="confirmation_text"
                                            // label='First Name:'
                                            onChange={(event) => {
                                                formik.setFieldValue(
                                                    'confirmation_text',
                                                    event.target.value
                                                )
                                            }}
                                        />
                                    </Grid>

                                    <Grid
                                        style={{ marginTop: 14 }}
                                        container
                                        justifyContent="flex-end"
                                    >
                                        <Button
                                            variant="outlined"
                                            onClick={() => {
                                                handleClose()
                                                formik.resetForm()
                                            }}
                                        >
                                            Cancel
                                        </Button>

                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            disabled={
                                                formik.values
                                                    .confirmation_text !==
                                                confirmation_text
                                            }
                                            onClick={() => formik.submitForm()}
                                            type="submit"
                                            style={{
                                                color: 'white',
                                                marginLeft: 16,
                                            }}
                                        >
                                            {loading ? (
                                                <CircularProgress
                                                    style={{ color: '#ffffff' }}
                                                    size={20}
                                                />
                                            ) : (
                                                'Delete'
                                            )}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Dialog>
                        </Form>
                    )
                }}
            </Formik>
        </>
    )
}

export default UserForceDelete
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Fade timeout={350} ref={ref} {...props} />
})
