import { Button, Grid, Typography, Zoom } from "@material-ui/core";
import React from "react";
import star from "../../asset/img/Star.png";
import warning from "../../asset/img/warning-out.png";
import danger from "../../asset/img/danger-out.png";
import heart from "../../asset/img/heart-out.png";
import profile from "../../asset/img/percent_out.svg";
import check from "../../asset/img/check-out.png";
import close from "../../asset/img/warning-out.png";

import { IOSSwitch } from "../../components/Textfields";
import { styles } from "../../styles/styles";
import EditNotfication from "../Settings/EditNotification";
import {
  get_notifications,
  update_all_active,
  update_notification,
} from "../../api/Setting_Api";
import { send_now_promotion } from "../../api/notification_api";

// import Store from "../../redux/Store";
// import { set_message } from "../../redux/ActionCreators";

const NotificationSettings = () => {
  const style = styles();
  const [notifications, set_notifications] = React.useState({});
  React.useEffect(() => {
    get_notifications().then((res) => {
      if (!res) return;
      set_notifications(res);
    });
  }, []);
  return (
    <Grid container>
      <Grid item xs={12} style={{}}>
        <Typography variant="h5">Notifications</Typography>
      </Grid>
      <Grid
        item
        xs={12}
        container
        justifyContent="space-between"
        alignItems="center"
        className={style.box}
        style={{}}
      >
        <Typography variant="body2">All Notifications</Typography>
        {/* {Object?.keys(notifications).length !== 0 && (
          <Zoom in={true} mountOnEnter>
            <Grid
              style={{ width: 250 }}
              container
              alignItems="center"
              justifyContent="flex-end"
            >
              <Typography
                style={{ color: "#65819D", opacity: 1 }}
                variant="body1"
              >
                Active
              </Typography>
              <IOSSwitch
                checked={notifications?.all?.active}
                onChange={(e) => {
                  update_all_active().then((res) => {
                    if (!res) return;
                    set_notifications(res);
                  });
                }}
              />
            </Grid>
          </Zoom>
        )} */}
      </Grid>
      {/* --------------------------------------------------------------------------------------each Notifications */}
      {Object.keys(notifications).length !== 0 &&
        Object.keys(notifications).map((_el, i) => {
          console.log(_el);
          if (notifications_details.find((c) => c.view === _el))
            return (
              <Zoom key={i} in={true} timeout={i * 80}>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  style={{ marginBottom: 16 }}
                  className={style.box}
                >
                  <Grid
                    item
                    container
                    alignItems="center"
                    style={{ width: "calc(100% - 300px)", padding: 8 }}
                  >
                    <Grid
                      item
                      style={{
                        backgroundColor: "#EBEBEB",
                        padding: 16,
                        borderRadius: 8,
                        width: 52,
                        marginRight: 16,
                        height: 52,
                      }}
                    >
                      <img
                        src={
                          _el
                            ? notifications_details.find(
                                (not) => not.view === _el
                              )?.icon
                            : ""
                        }
                        alt=""
                      />
                    </Grid>
                    <Grid item container style={{ width: "calc(100% - 68px)" }}>
                      <Grid
                        container
                        alignItems="center"
                        style={{ marginBottom: 4 }}
                      >
                        <Typography variant="body2">
                          {_el ? notifications[_el]?.title : ""}
                        </Typography>
                        <Typography
                          style={{
                            marginLeft: 24,
                            backgroundColor: _el
                              ? notifications_details.find(
                                  (not) => not?.view === _el
                                )?.color
                              : "",
                            padding: "0 4px",
                            fontSize: 12,
                            borderRadius: 4,
                            color:
                              _el === "completeYourProfile" ||
                              _el === "announcement"
                                ? "white"
                                : "black",
                          }}
                          variant="caption"
                        >
                           {_el ? notifications[_el]?.title : ""}
                        </Typography>
                      </Grid>
                      <Grid container>
                        <Typography variant="body1">
                          {_el ? notifications[_el]?.description : ""}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* --------------------------------------------------------------------------------------each Notifications  action*/}

                  <Grid
                    item
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    style={{ width: 190 }}
                  >
                    {_el !== "newMessage" ? (
                      <EditNotfication
                        set_notifications={set_notifications}
                        data={{
                          isActive: notifications[_el]?.isActive ?? false,
                          description: notifications[_el]?.description ?? "",
                          title: notifications[_el]?.title ?? "",
                          key: _el,
                        }}
                        color={
                          _el
                            ? notifications_details.find(
                                (not) => not?.view === _el
                              )?.color
                            : ""
                        }
                        badge_title={
                          _el ? notifications[_el]?.title : ""
                        }
                      />
                    ) : (
                      <Typography
                        variant="subtitle1"
                        style={{ color: "rgba(5, 6, 15, 0.8)", fontSize: 14 }}
                      >
                        Non editable
                      </Typography>
                    )}
                    {_el==='promotion'
                    ?<Button onClick={()=>send_now_promotion()} ><Typography style={{color:'65819D', fontSize:'13px' ,fontWeight:'500',textTransform:'unset'}}> Send now</Typography></Button>
                    :<IOSSwitch
                      checked={_el ? notifications[_el]?.isActive : false}
                      onChange={(e) => {
                        update_notification(_el, {
                          isActive: !notifications[_el]?.isActive ?? false,
                          description: notifications[_el]?.description ?? "",
                          title: notifications[_el]?.title ?? "",
                        }).then((res) => {
                          if (!res) return;
                          set_notifications(res);
                        });
                      }}
                    />
        }
                  </Grid>
                </Grid>
              </Zoom>
            );
        })}
    </Grid>
  );
};

export default NotificationSettings;
const notifications_details = [
  {
    icon: star,
    view: "newMatch",
    color: "rgba(0, 126, 71, 0.3)",

  },
  {
    icon: check,
    view: "newMessage",
    color: "rgba(255, 107, 107, 0.3)",
  },
  {
    icon: heart,
    view: "newLike",
    color: "rgba(69, 104, 230, 0.3)",
  },
  {
    icon: profile,
    view: "completeYourProfile",
    color: "rgba(0, 0, 0, 0.71)",
  },

  {
    icon: close,
    view: "promotion",
    color: "rgba(226, 211, 70, 0.3)",
  },
  {
    icon: danger,
    view: "announcement",
    color: "rgba(255, 0, 0, 0.71)",
  },

  {
    icon: warning,
    view: "areYouReddi",
    color: "rgba(172, 172, 172, 0.3)",
  },
];
