import { Typography } from '@material-ui/core'
import { FormLabel, Grid, Stack, TextField } from '@mui/material'
import React from 'react'

const FreeType = ({ question, amswer, index }) => {
    console.log('ddd', question, amswer)
    return (
        <Grid container item xs={6}>
            <Grid item xs={12}>
                <Stack direction="column">
                    <FormLabel
                        style={{
                            marginBottom: '5px',
                        }}
                        htmlFor="first-name"
                    >
                        <span
                            style={{ marginRight: '2px', fontWeight: 'bold' }}
                        >
                            {index + 1}
                            {'.'}
                        </span>
                        {question}
                    </FormLabel>

                    <TextField size="small" value={amswer} />
                </Stack>
            </Grid>
        </Grid>
    )
}

export default FreeType
