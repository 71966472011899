export const IS_AUTH = "IS_AUTH";
export const MESSAGE = "MESSAGE";
export const LOADING = "LOADING";
export const MODAL_LOADING = "MODAL_LOADING";
export const REFRESH = "REFRESH";
export const SEARCH_PAGE = "SEARCH_PAGE";
export const SORT = "SORT";
export const SEARCH = "SEARCH";
export const SIDE_VALUE = "SIDE_VALUE";
export const REQUEST_COUNTER = "REQUEST_COUNTER";
