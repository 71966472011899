import React from "react";
import { connect } from "react-redux";
import { Button, CircularProgress } from "@material-ui/core";

const GrayButton = ({ title, loading, disabled, onClick }) => {
  return (
    <Button
      type="submit"
      style={{
        backgroundColor: !disabled ? "#65819D" : "rgba(0, 0, 0, 0.1)",
        color: !disabled ? "white" : "rgba(133, 133, 133, 1)",
        marginLeft: 16,
      }}
      disabled={disabled || loading}
      onClick={onClick}
    >
      {!loading ? (
        title
      ) : (
        <CircularProgress style={{ color: "white", width: 22, height: 22 }} />
      )}
    </Button>
  );
};

const mapStateToProps = (props) => {
  const { GeneralReducer } = props;

  const { loading, search } = GeneralReducer;

  return { loading, search };
};

export default connect(mapStateToProps)(GrayButton);
