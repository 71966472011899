import React from 'react'

export const PromoCodeLabelIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_3665_9812)">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M10.3169 0.696687L8.50886 2.50309C8.36287 2.64914 8.16497 2.73141 7.95846 2.73189H5.11366C3.79846 2.73189 2.73286 3.79749 2.73286 5.11269V7.95749C2.73246 8.16448 2.65021 8.36291 2.50406 8.50949L0.696064 10.3159C0.249748 10.7624 -0.000976563 11.3678 -0.000976562 11.9991C-0.000976563 12.6304 0.249748 13.2358 0.696064 13.6823L2.50406 15.4903C2.65011 15.6363 2.73238 15.8342 2.73286 16.0407V18.8855C2.73286 20.2007 3.79846 21.2663 5.11366 21.2663H7.95846C8.16545 21.2667 8.36388 21.3489 8.51046 21.4951L10.3169 23.3031C10.7633 23.7494 11.3688 24.0001 12.0001 24.0001C12.6314 24.0001 13.2368 23.7494 13.6833 23.3031L15.4913 21.4951C15.6373 21.349 15.8352 21.2668 16.0417 21.2663H18.8865C20.2017 21.2663 21.2673 20.2007 21.2673 18.8855V16.0407C21.2677 15.8337 21.3499 15.6353 21.4961 15.4887L23.3041 13.6823C23.7504 13.2358 24.0011 12.6304 24.0011 11.9991C24.0011 11.3678 23.7504 10.7624 23.3041 10.3159L21.4961 8.50789C21.35 8.3619 21.2677 8.16399 21.2673 7.95749V5.11269C21.2673 3.79749 20.2017 2.73189 18.8865 2.73189H16.0417C15.8346 2.73183 15.636 2.64953 15.4897 2.50309L13.6833 0.695087C13.2368 0.248772 12.6314 -0.00195313 12.0001 -0.00195312C11.3688 -0.00195313 10.7633 0.250372 10.3169 0.696687ZM7.43366 15.4327L15.4337 7.43269L16.5665 8.56549L8.56646 16.5655L7.43366 15.4327ZM8.00006 7.99909V9.59909H9.60006V7.99909H8.00006ZM14.4001 15.9991H16.0001V14.3991H14.4001V15.9991Z" fill="#0E0F1A" />
      </g>
      <defs>
        <clipPath id="clip0_3665_9812">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>




  )

}