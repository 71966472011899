import { Button, Dialog, Fade, Grid, Typography } from "@material-ui/core";
import { Form, Formik } from "formik";
import React from "react";
import { TextFieldWrapper } from "../../components/Textfields";
import Store from "../../redux/Store";
import { set_message } from "../../redux/ActionCreators";
import { add_memberships, update_membership } from "../../api/Memberships";
import { FormControl, MenuItem, Select } from "@mui/material";
const AddNewPlan = ({ open, setOpen, setData, editMode, currentItem }) => {
  return (
    <Formik
      validateOnMount
      enableReinitialize
      initialValues={
        editMode === false
          ? { title: "", packageId: "", duration: 3, price: "", packageDescription: "", pricePerMonth: "" }
          : {
              title: currentItem.title,
              packageId: currentItem.packageId,
              duration: currentItem.duration,
              price: currentItem.price,
              packageDescription: currentItem.description,
              pricePerMonth: currentItem.pricePerMonth,
              highlightedText: currentItem.highlightedText
            }
      }
      onSubmit={(values, props) => {
        console.log(values)
        if (
          values.title === "" ||
          values.packageId === "" ||
          values.duration === "" ||
          values.price === ""
        ) {
        } else {
          if (editMode === false) {
            add_memberships(values).then((res) => {
              if (!res) return;
              setData((prev) => [...prev, res.data]);
              props.resetForm();

              Store.dispatch(
                set_message({
                  mode: true,
                  message: "Add new plan",
                  color: "success",
                })
              );
              setOpen(false);
            });
          } else {
            update_membership(currentItem._id, values).then((res) => {
              if (!res) return;
              setData((prev) =>
                prev.map((c) => {
                  if (c._id === currentItem._id) return res.data;
                  else return c;
                })
              );
              props.resetForm();

              Store.dispatch(
                set_message({
                  mode: true,
                  message: "Update plan",
                  color: "success",
                })
              );
              setOpen(false);
            });
          }
        }
      }}
    >
      {(formik) => {
        const check = () => {
          if (
            formik.values.title === "" ||
            formik.values.id === "" ||
            formik.values.duration === "" ||
            formik.values.price === ""
          ) {
            return false;
          }
          return true;
        };

        return (
          <Form>
            <Dialog
              maxWidth="sm"
              fullWidth={true}
              open={open}
              onClose={() => {
                setOpen(false);
              }}
              TransitionComponent={Transition}
              onClick={(e) => e.stopPropagation()}
            >
              <Grid
                xs={12}
                container
                item
                spacing={3}
                style={{ padding: "20px" }}
                justifyContent="center"
              >
                <Grid item xs={12} container alignItems="flex-end">
                  <Typography variant="h4">
                    {editMode === false ? `Add new plan` : `Edit plan`}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  container
                  spacing={2}
                  alignItems="center"
                  style={{ margin: "auto" }}
                >
                  <Grid item xs={12}>
                    <Typography variant="body1" style={{ marginBottom: 8 }}>
                      Package title
                    </Typography>
                    <TextFieldWrapper
                      name="title"
                      white={true}
                      placeholder="e.g. 3 Months"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body1" style={{ marginBottom: 8 }}>
                      Package ID
                    </Typography>
                    <TextFieldWrapper
                      name="packageId"
                      white={true}
                      placeholder="e.g. 12jjhkl123"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body1" style={{ marginBottom: 8 }}>
                      Package Description
                    </Typography>
                    <TextFieldWrapper
                      name="packageDescription"
                      white={true}
                      placeholder="e.g. three month free subscrption"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body1" style={{ marginBottom: 8 }}>
                      Duration
                    </Typography>

                    <FormControl
                      fullWidth
                      size="small"
                      sx={{ borderColor: "black" }}
                    >
                      <Select
                        id="duration"
                        style={{
                          display: "block",
                          backgroundColor: "transparent",
                          borderRadius: 4,
                        }}
                        value={formik.values.duration}
                        onChange={(event) => {
                          formik.setValues((prev) => {
                            return { ...prev, duration: event.target.value };
                          });
                        }}
                      >
                        <MenuItem value={1}>1 Month</MenuItem>
                        <MenuItem value={3}>3 Months</MenuItem>
                        <MenuItem value={6}>6 Months</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body1" style={{ marginBottom: 8 }}>
                      Price
                    </Typography>
                    <TextFieldWrapper
                      name="price"
                      InputProps={{
                        startAdornment: (
                          <span style={{ paddingRight: "10px" }}>£</span>
                        ),
                      }}
                      white={true}
                      placeholder="e.g. £49.99"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body1" style={{ marginBottom: 8 }}>
                      Price Per Month
                    </Typography>
                    <TextFieldWrapper
                      name="pricePerMonth"
                      InputProps={{
                        startAdornment: (
                          <span style={{ paddingRight: "10px" }}>£</span>
                        ),
                      }}
                      white={true}
                      placeholder="e.g. £49.99"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body1" style={{ marginBottom: 8 }}>
                    Highlighted Text
                    </Typography>
                    <TextFieldWrapper
                      name="highlightedText"
                      InputProps={{
                        startAdornment: (
                          <span style={{ paddingRight: "10px" }}>£</span>
                        ),
                      }}
                      white={true}
                      placeholder="e.g. 1 month free"
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} container justifyContent="flex-end">
                  <Button
                    variant="outlined"
                    color="default"
                    style={{ borderRadius: 8 }}
                    onClick={() => {
                      formik.setValues(formik.initialValues);
                      setOpen(false);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    disabled={!check()}
                    style={{
                      borderRadius: 8,
                      background: check() ? "#65819D" : "#D2D2D2",
                      color: "#FFFFFF",
                      marginLeft: "8px",
                    }}
                    onClick={() => {
                      formik.submitForm();
                    }}
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </Dialog>
          </Form>
        );
      }}
    </Formik>
  );
};

export default AddNewPlan;
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Fade timeout={350} ref={ref} {...props} />;
});
