import {
  Button,
  Dialog,
  Fade,
  Grid,
  IconButton,
  Typography,
  useTheme,
} from "@material-ui/core";
import editAdmin from "../../asset/img/editAdmin.png";
import { Close } from "@material-ui/icons";
import { Form, Formik } from "formik";
import React from "react";
import { update_admins } from "../../api/Setting_Api";
import { TextFieldWrapper } from "../../components/Textfields";

import {
  initialValues,
  validation,
} from "../../validations/add_Admin_validation";

const EditAdmin = ({ set_data, data }) => {
  const [open, setOpen] = React.useState(false);

  const theme = useTheme();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        onClick={handleOpen}
        startIcon={<img src={editAdmin} height={15} width={15} alt="" />}
      >
        Edit
      </Button>

      <Formik
        validateOnMount
        initialValues={initialValues}
        validationSchema={validation}
        onSubmit={(values, props) => {
          update_admins(data._id, {
            email: values.emaill,
            password: values.passwordd,
            name: values.name,
          }).then((res) => {
            if (!res) return;

            set_data((pre) =>
              pre.map((item) => {
                if (item._id === data._id) {
                  return { ...item, name: values.name, email: values.emaill };
                } else {
                  return item;
                }
              })
            );
            props.resetForm();
            setOpen(false);
          });
        }}
      >
        {(formik) => {
          formik.initialValues.emaill = data.email;
          formik.initialValues.name = data.name;

          return (
            <Dialog
              maxWidth="sm"
              fullWidth={true}
              open={open}
              onClose={() => {
                handleClose();
                formik.resetForm();
              }}
              TransitionComponent={Transition}
              onClick={(e) => e.stopPropagation()}
            >
              <Form>
                <Grid
                  xs={12}
                  container
                  item
                  spacing={6}
                  style={{ margin: "auto" }}
                  justifyContent="center"
                >
                  <Grid
                    item
                    xs={12}
                    container
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography variant="h4">Edit admin</Typography>
                    <IconButton onClick={handleClose}>
                      <Close />
                    </IconButton>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography style={{ marginBottom: 8 }} variant="subtitle2">
                      Full name
                    </Typography>
                    <TextFieldWrapper
                      name="name"
                      placeholder="Enter full name"
                      white={true}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography style={{ marginBottom: 8 }} variant="subtitle2">
                      Email
                    </Typography>
                    <TextFieldWrapper
                      name="emaill"
                      placeholder="Enter email address"
                      white={true}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography style={{ marginBottom: 8 }} variant="subtitle2">
                      Password
                    </Typography>
                    <TextFieldWrapper
                      name="passwordd"
                      type="password"
                      placeholder="Enter password"
                      white={true}
                    />
                  </Grid>
                  <Grid item xs={12} container justifyContent="flex-end">
                    <Button
                      variant="outlined"
                      color="default"
                      style={{ borderRadius: 8 }}
                      onClick={() => {
                        handleClose();
                        formik.resetForm();
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      disabled={!formik.isValid}
                      style={{
                        backgroundColor: formik.isValid
                        ? "#65819D"
                        : "rgba(0, 0, 0, 0.1)",
                        color: formik.isValid
                        ? "white"
                        : "rgba(133, 133, 133, 1)",
                        marginLeft: 16,
                        borderRadius: 8,
                      }}
                      onClick={() => {
                        formik.submitForm();
                      }}
                    >
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            </Dialog>
          );
        }}
      </Formik>
    </>
  );
};

export default EditAdmin;
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Fade timeout={350} ref={ref} {...props} />;
});
