import { set_message } from "../redux/ActionCreators";
import Store from "../redux/Store";
import { api_instance } from "./Base_Api";
export const get_FAQ = async () => {
  // const { search } = Store.getState().GeneralReducer;
  try {
    const { data } = await api_instance.get("/admin/help/faq");
    return data;
  } catch (error) {}
};

export const delete_FAQ = async (id, type) => {
  try {
    const { data } = await api_instance.delete(`/admin/help/faq/${id}`);
    Store.dispatch(
      set_message({
        mode: true,
        message: "FAQ Deleted",
        color: "success",
      })
    );
    return data;
  } catch (error) {}
};

export const update_FAQ = async (id, values) => {
  try {
    const { data } = await api_instance.put(`/admin/help/faq/${id}`, values);
    return data;
  } catch (error) {}
};

export const Add_FAQ = async (values) => {
  try {
    const { data } = await api_instance.post(`/admin/help/faq`, values);

    Store.dispatch(
      set_message({
        color: "info",
        top: true,
        message: "FAQ successfully added",
        mode: true,
      })
    );

    return data;
  } catch (error) {}
};
export const update_orders = async (values) => {
  try {
    const { data } = await api_instance.put(`/admin/help/faq/order`, values);

    return data;
  } catch (error) {}
};

export const get_account = async () => {
  try {
    const { data } = await api_instance.get(`/admin/settings/account`);

    return data;
  } catch (error) {}
};
