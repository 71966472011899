import { Button, Dialog, Fade, Grid, Typography } from '@material-ui/core'

import React, { useState } from 'react'

import { makeProfileUserImageApi } from '../../../../api/Users_Api'
const MakeProfileImage = ({ handleClose, imageId, userId }) => {
    const handleMakeProfile = async () => {
        await makeProfileUserImageApi(imageId, userId)
        handleClose()
    }

    return (
        <>
            <Button
                style={{ color: 'black', fontSize: '1rem' }}
                variant="text"
                onClick={handleMakeProfile}
            >
                Make Profile
            </Button>
        </>
    )
}

export default MakeProfileImage
