import React, { useState } from 'react'
import {
    FormLabel,
    Grid,
    FormControlLabel,
    TextareaAutosize,
} from '@material-ui/core'
import { Button, TextField, Box, Stack, Typography } from '@mui/material'
import { Controller } from 'react-hook-form'
import Select from '@mui/material/Select'
import { Add, Remove } from '@material-ui/icons'
import MenuItem from '@mui/material/MenuItem'
import TagsInput from './tagsInput'
import { v4 as uuidv4 } from 'uuid'

const AddAndRemoveQuestion = ({ rows, setRows }) => {
    const handleAddRow = () => {
        setRows([
            ...rows,
            { question: '', question_type: '', options: [], id: uuidv4() },
        ])
    }

    const handleRemoveRow = (id) => {
        setRows(rows.filter((row) => row.id !== id))
    }

    return (
        <>
            {rows.map((row, index) => (
                <>
                    {/* <div>{index + 1}.</div> */}
                    <Grid container item xs={5}>
                        <Grid item xs={12}>
                            <Stack direction="column">
                                <FormLabel htmlFor="quiz-instructions">
                                    {index + 1}. Question:
                                </FormLabel>

                                <TextareaAutosize
                                    placeholder="Question"
                                    value={row?.question ?? ''}
                                    onChange={(event) => {
                                        const findItem = rows.find(
                                            (item) => item.id === row?.id
                                        )
                                        findItem.question = event.target.value
                                        const allModifiedRows = rows.map(
                                            (item) =>
                                                item.id === row?.id
                                                    ? findItem
                                                    : item
                                        )
                                        setRows(allModifiedRows)
                                    }}
                                    style={{
                                        marginTop: '17px',
                                        padding: '4px',
                                    }}
                                    minRows={3}
                                    size="lg"
                                    variant="solid"
                                />
                            </Stack>
                        </Grid>
                    </Grid>
                    <Grid item container xs={2}>
                        <Grid item xs={12}>
                            <Stack direction="column">
                                <FormLabel htmlFor="gender" sx={{ pt: 4 }}>
                                    Question Type:
                                </FormLabel>

                                <Select
                                    style={{
                                        marginTop: '17px',
                                        padding: '8px',
                                    }}
                                    labelId="gender"
                                    id="demo-simple-select-gender"
                                    fullWidth
                                    margin="normal"
                                    size="small"
                                    value={row?.question_type ?? ''}
                                    onChange={(event) => {
                                        const findItem = rows.find(
                                            (item) => item.id === row?.id
                                        )
                                        findItem.question_type =
                                            event.target.value
                                        const allModifiedRows = rows.map(
                                            (item) =>
                                                item.id === row?.id
                                                    ? findItem
                                                    : item
                                        )
                                        setRows(allModifiedRows)
                                    }}
                                >
                                    <MenuItem value={'0'}>Select Type</MenuItem>
                                    {[
                                        {
                                            name: 'Short Answer',
                                            value: 'short_answer',
                                        },
                                        {
                                            name: 'Multi Select',
                                            value: 'multi_select',
                                        },
                                        {
                                            name: 'Single Select',
                                            value: 'single_select',
                                        },
                                    ]?.map((option) => {
                                        return (
                                            <MenuItem value={option?.value}>
                                                {option?.name}
                                            </MenuItem>
                                        )
                                    })}
                                </Select>
                            </Stack>
                        </Grid>
                    </Grid>
                    <Grid item container xs={4}>
                        {row?.question_type === 'multi_select' ? (
                            <Grid item xs={12}>
                                <Stack direction="column">
                                    <FormLabel
                                        htmlFor="quiz-status"
                                        sx={{ pt: 4 }}
                                    >
                                        Options
                                        {
                                            '(Press Enter to save your text as a option)'
                                        }
                                    </FormLabel>
                                    <TagsInput
                                        tags={row?.options}
                                        handleTags={(tagsArr) => {
                                            const findItem = rows.find(
                                                (item) => item.id === row?.id
                                            )
                                            findItem.options = tagsArr
                                            const allModifiedRows = rows.map(
                                                (item) =>
                                                    item.id === index + 1
                                                        ? findItem
                                                        : item
                                            )
                                            setRows(allModifiedRows)
                                        }}
                                    />
                                </Stack>
                            </Grid>
                        ) : null}
                        {row?.question_type === 'single_select' ? (
                            <Grid item xs={12}>
                                <Stack direction="column">
                                    <FormLabel
                                        htmlFor="quiz-status"
                                        sx={{ pt: 4 }}
                                    >
                                        Options
                                        {
                                            '(Press Enter to save your text as a option)'
                                        }
                                    </FormLabel>
                                    <TagsInput
                                        tags={row?.options}
                                        handleTags={(tagsArr) => {
                                            const findItem = rows.find(
                                                (item) => item.id === row?.id
                                            )
                                            findItem.options = tagsArr
                                            const allModifiedRows = rows.map(
                                                (item) =>
                                                    item.id === index + 1
                                                        ? findItem
                                                        : item
                                            )
                                            setRows(allModifiedRows)
                                        }}
                                    />
                                </Stack>
                            </Grid>
                        ) : null}
                        {row?.question_type === 'short_answer' ? null : null}
                    </Grid>

                    <Grid item container xs={1}>
                        <Grid
                            item
                            xs={10}
                            style={{
                                marginTop: '30px',
                                textAlign: 'end',
                                marginLeft: '10%',
                            }}
                        >
                            <Button
                                variant="contained"
                                color="error"
                                style={{
                                    borderRadius: 8,
                                    color: '#FFFFFF',
                                    padding: '15px 0',
                                }}
                                onClick={() => handleRemoveRow(row?.id)}
                            >
                                <Remove />
                            </Button>
                        </Grid>
                    </Grid>
                </>
            ))}
            <Grid item container xs={2}>
                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        color="primary"
                        style={{
                            borderRadius: 8,
                            color: '#FFFFFF',
                            padding: '15px',
                        }}
                        onClick={handleAddRow}
                    >
                        <Add />
                    </Button>
                </Grid>
            </Grid>
        </>
    )
}

export default AddAndRemoveQuestion
