import { Button } from "@material-ui/core";
import React from "react";
import { update_user } from "../../api/Users_Api";
import { SetAsFakeAccountIcon } from "../../icons/SetAsFakeAccountIcon";

export const SetAsFakeAccount = (props) => {
  const { data, set_Data } = props;
  const handleClick = (value) => {
    update_user(data._id, { isFake: value })
      .then((res) => {
        set_Data((pre) =>
          pre.map((item) => {
            if (item._id === data._id) {
              return { ...item, isFake: value };
            } else {
              return item;
            }
          })
        );
      })
      .catch((error) => {});
  };

  return (
    <Button
      type="text"
      startIcon={<SetAsFakeAccountIcon />}
      onClick={() => handleClick(!data.isFake)}
    >
      {data.isFake === true ? "Remove fake account" : "Set as fake account"}
    </Button>
  );
};
