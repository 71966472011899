import {
  Button,
  Dialog,
  Fade,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core";
import { Add, Close } from "@material-ui/icons";
import { Form, Formik } from "formik";

import React from "react";
import { Add_FAQ } from "../../api/HelpCenter_Api";
import GrayButton from "../../components/GrayButton";
import { TextFieldWrapper } from "../../components/Textfields";
import { styles } from "../../styles/styles";

const AddNewFAQ = ({ curent_category, set_data, tabValue }) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const style = styles();
  return (
    <>
      <Button
        variant="text"
        color="secondary"
        onClick={handleOpen}
        startIcon={<Add />}
        style={{
          // backgroundColor: "rgba(4, 9, 33, 0.1)",
          borderRadius: 12,
        }}
        classes={{ label: style.lowerCase }}
      >
        Add a new FAQ
      </Button>

      <Formik
        initialValues={{ title: "", description: "" }}
        onSubmit={(value, props) => {
          Add_FAQ(value).then((res) => {
            if (!res) return;
            props.resetForm();
            handleClose();
            set_data((pre) => {
              return [...pre, res.data];
            });
          });
        }}
      >
        {(formik) => {
          return (
            <Form>
              <Dialog
                maxWidth="sm"
                fullWidth={true}
                open={open}
                onClose={() => {
                  handleClose();
                  formik.resetForm();
                }}
                TransitionComponent={Transition}
                onClick={(e) => e.stopPropagation()}
              >
                <Grid xs={12} container item style={{}} justifyContent="center">
                  {/* ------------------------------------------------------------------------------------ header section */}
                  <Grid
                    item
                    xs={12}
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    style={{ padding: 16 }}
                  >
                    <Typography variant="h4">Add a new FAQ</Typography>
                    <IconButton
                      onClick={() => {
                        handleClose();
                        formik.resetForm();
                      }}
                    >
                      <Close />
                    </IconButton>
                  </Grid>
                  {/* ------------------------------------------------------------------------------------ content section */}
                  <Grid item container style={{ padding: 16 }}>
                    <Grid item xs={12}>
                      <Typography style={{ margin: "16px 0" }} variant="body1">
                        Question
                      </Typography>
                      <TextFieldWrapper
                        placeholder="Type..."
                        name="title"
                        white={true}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography style={{ margin: "16px 0" }} variant="body1">
                        Answer
                      </Typography>
                      <TextFieldWrapper
                        multiline
                        rows={4}
                        placeholder="Type..."
                        name="description"
                        white={true}
                      />
                    </Grid>
                  </Grid>

                  {/* ------------------------------------------------------------------------------------ footer section */}

                  <Grid
                    item
                    xs={12}
                    container
                    alignItems="center"
                    justifyContent="flex-end"
                    style={{ padding: 16 }}
                  >
                    <Grid item>
                      <Button
                        variant="outlined"
                        style={{ borderRadius: 6 }}
                        color="default"
                        onClick={() => {
                          handleClose();
                          formik.resetForm();
                        }}
                      >
                        Cancel
                      </Button>
                      <GrayButton
                        title={"Add"}
                        disabled={
                          formik.values.description === "" ||
                          formik.values.title === ""
                        }
                        onClick={() => formik.submitForm()}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Dialog>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default AddNewFAQ;
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Fade timeout={350} ref={ref} {...props} />;
});
