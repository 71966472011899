import { Fade, Grid } from "@material-ui/core";

export const TabPanelContainer = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      style={{ width: "100%", height: "100%" }}
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && (
        // <Fade in={value === index} timeout={500}>
          <Grid item xs={12} style={{ height: "100%" }}>
            {children}
          </Grid>
        //  </Fade>
      )}
    </div>
  );
};
