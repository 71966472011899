import {
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import moment from "moment";
import React, { useState } from "react";
import { Line } from "react-chartjs-2";
import { styles } from "../../styles/styles";
// eslint-disable-next-line
import BarChart from "./BarChart";

const LineChart = ({ chart_data, time }) => {
  const genderChartData = [
    {
      _id: 18,
      title: "18-24",
      total: 0,
      male: 0,
      female: 0,
    },
    {
      _id: 25,
      title: "25-34",
      total: 0,
      male: 0,
      female: 0,
    },
    {
      _id: 35,
      title: "35-44",
      total: 0,
      male: 0,
      female: 0,
    },
    {
      _id: 45,
      title: "45-54",
      total: 0,
      male: 0,
      female: 0,
    },
    {
      _id: 55,
      title: "55-64",
      total: 0,
      male: 0,
      female: 0,
    },
    {
      _id: 65,
      title: "65+",
      total: 0,
      male: 0,
      female: 0,
    },
  ].map((item) => {
    const g = chart_data?.gender.find((c) => c._id === item._id);
    if (g) {
      return { ...item, total: g.total, male: g.male, female: g.female };
    }
    return item;
  });
  const style = styles();
  const [value, set_value] = React.useState("users");
  const dateFormat =
    time === "yearly" ? "MMM" : time === "monthly" ? "DD" : "ddd";
  const data = (canvas) => {
    const ctx = canvas.getContext("2d");
    var gradient = ctx.createLinearGradient(0, 0, 0, 510);
    gradient.addColorStop(0, "#F3F0FF");
    gradient.addColorStop(1, "#F3F0FF");


    return {
      labels:
        chart_data === undefined || chart_data[value] === undefined
          ? undefined
          : chart_data[value]?.map((item) =>
            moment(item.date).format(dateFormat)
          ),
      datasets: [
        {
          backgroundColor: "rgba(241, 237, 255, .5)",
          borderColor: "#BA5A6B",
          borderWidth: 2,
          pointColor: "#fff",
          pointStrokeColor: "#ff6c23",
          pointHighlightFill: "#fff",
          pointHighlightStroke: "#ff6c23",
          fill: "start",
          data:
            chart_data === undefined || chart_data[value] === undefined
              ? undefined
              : value === "gender"
                ? chart_data[value]?.map(
                  (item) => item.female + item.male + item.other
                )
                : chart_data[value].map((item) => item.count),
          lineTension: 0.4,
        },
      ],
    };
  };
  const activeUserData = (canvas) => {
    const ctx = canvas.getContext("2d");
    var gradient = ctx.createLinearGradient(0, 0, 0, 510);
    gradient.addColorStop(0, "#F3F0FF");
    gradient.addColorStop(1, "#F3F0FF");


    return {
      labels:
        chart_data?.activeUsers?.map((item) =>
          moment(item.date).format(dateFormat)
        ),
      datasets: [
        {
          backgroundColor: "rgba(241, 237, 255, .5)",
          borderColor: "#BA5A6B",
          borderWidth: 2,
          pointColor: "#fff",
          pointStrokeColor: "#ff6c23",
          pointHighlightFill: "#fff",
          pointHighlightStroke: "#ff6c23",
          fill: "start",
          data:
            chart_data === undefined || chart_data.activeUsers === undefined
              ? undefined
              : chart_data.activeUsers.map((item) => item.count),
          lineTension: 0.4,
        },
      ],
    };
  };
  return (
    <Grid
      item
      container
      xs={12}
      style={{ height: "100%" }}
      className={style.box}
    >
      <Grid item xs={12} style={{ height: 50 }}>
        <FormControl component="fieldset">
          <RadioGroup row>
            {lineCartS.map((item) => (
              <FormControlLabel
                checked={value === item.value}
                key={item.value}
                value={item.value}
                onChange={() => {
                  set_value(item.value);
                }}
                control={<Radio />}
                label={item.title}
                classes={{ label: style.radioLabel }}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </Grid>

      {value === "users" ? (
        <Grid item xs={12} style={{ height: "calc(100% - 50px)" }}>
          <Line
            style={{ backgroundColor: "white" }}
            data={data}
            options={options}
            height="100%"
            width="100%"
          />
        </Grid>
      ) :
        value === "active" ?
          <Grid item xs={12} style={{ height: "calc(100% - 50px)" }}>
            <Line
              style={{ backgroundColor: "white" }}
              data={activeUserData}
              options={options}
              height="100%"
              width="100%"
            />
          </Grid>
          :
          (
            <Grid item xs={12} style={{ height: "calc(100% - 50px)" }}>
              <BarChart chart_data={genderChartData} />
              {/* <BarChart chart_data={chart_data.gender} /> */}

            </Grid>

          )}
    </Grid>
  );
};

export default LineChart;
const options = {
  responsive: true,
  maintainAspectRatio: false,
  datasetStrokeWidth: 3,
  pointDotStrokeWidth: 4,

  borderWidth: 2,
  hoverBorderWidth: 5,
  radius: 2,
  title: false,
  plugins: {
    legend: {
      display: false,
    },
  },
  color: "red",
  scales: {
    y: {
      beginAtZero: true,
      ticks: {
        // forces step size to be 1 units
        stepSize: 1,
      },
    },
  },
};

let lineCartS = [
  { title: "Users", value: "users" },
  // { title: "Total User", value: "users2" },
  { title: "Gender", value: "gender" },
  { title: "Active Users", value: "active" },
];
const genderData = [
  {
    _id: 25,
    total: 1,
    male: 1,
    female: 0,
  },
  {
    _id: 35,
    total: 10,
    male: 1,
    female: 2,
  },
  {
    _id: 45,
    total: 4,
    male: 2,
    female: 0,
  },
];
