import {
    Button,
    Dialog,
    Fade,
    Grid,
    Input,
    Typography,
} from '@material-ui/core'
import Trash from '../../asset/img/force-user-delete.png'
import React from 'react'

import { Form, Formik } from 'formik'
import { delete_user } from '../../api/Users_Api'
import { CircularProgress, TextField } from '@mui/material'

const UserForceDeleteButton = ({ setOpen }) => {
    const buttonStyle = { paddingLeft: 0, borderRadius: 6 }

    return (
        <>
            <Button
                onClick={() => setOpen(true)}
                variant="text"
                style={buttonStyle}
            >
                <img
                    src={Trash}
                    alt="delete user forcefully"
                    width={18}
                    height={18}
                    style={{ marginRight: 8 }}
                />
                Force Delete User
            </Button>
        </>
    )
}

export default UserForceDeleteButton
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Fade timeout={350} ref={ref} {...props} />
})
