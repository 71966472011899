import {
    FormLabel,
    Grid,
    Typography,
    Button as ButtonCore,
    Slider,
    Input,
    Divider,
} from '@material-ui/core'

import {
    TextField,
    FormControl,
    InputLabel,
    Checkbox,
    FormGroup,
    FormControlLabel,
    Button,
    OutlinedInput,
    Select,
    CircularProgress,
    MenuItem,
    Stack,
    Accordion,
    AccordionSummary,
    AccordionDetails,
} from '@mui/material'
import { useState, useEffect } from 'react'
import { styles } from '../../../../styles/styles'
import { get_match_make_me_questionnair } from '../../../../api/UserRequest_Api'
import { Stack as STACK2 } from '@mui/system'
import { useParams, useHistory } from 'react-router-dom'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Range from './Range'
import FreeType from './FreeType'
import Optional from './Optional'

const MatchMakeMeQuestionnair = ({
    matchMakeMeDataObj,
    setMatchMakeMeDataObj,
}) => {
    console.log('matchMakeMeDataObj', matchMakeMeDataObj)
    const params = useParams()
    const [userName, setUserName] = useState('')
    const [matchMakeMeData, setMatchMakeMeData] = useState([])
    const style = styles()
    const history = useHistory()

    useEffect(() => {
        const userId = params?.id
        get_match_make_me_questionnair(userId)
            .then((res) => {
                if (res) {
                    const catgeoryObj = {}
                    res?.data?.questions?.forEach((element) => {
                        element.questionnair.forEach((element2) => {
                            catgeoryObj[element2?._id] =
                                element2?.multiple_choice_answer
                                    ? element2?.response_arr
                                    : element2?.response_text
                        })
                    })
                    setMatchMakeMeData(res?.data?.questions ?? [])
                    setMatchMakeMeDataObj(catgeoryObj)
                    setUserName(res?.data?.userName)
                }
            })
            .catch((e) => console.log('-----------------', e.message))
    }, [])

    const handleChangeQuestionnair = (id, value) => {
        setMatchMakeMeDataObj((prev) => {
            prev[id] = value
            return { ...prev }
        })
    }

    return (
        <Grid spacing={2} className={style.box}>
            <Accordion style={{ boxShadow: 'none' }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header-questionnair"
                >
                    <Typography
                        style={{
                            fontSize: '1.2rem',
                            textDecoration: 'underline',
                        }}
                    >
                        MatchMake Me Questionnair
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={2}>
                        {matchMakeMeData.length === 0 ? 'No Data Found' : null}
                        {matchMakeMeData.map((item, index) => {
                            return (
                                <Grid
                                    key={item?.category}
                                    container
                                    spacing={3}
                                    style={{ padding: '8px' }}
                                    // className={style.box}
                                >
                                    <Grid item xs={12}>
                                        <Typography
                                            style={{
                                                fontSize: '1.2rem',
                                                color: 'black',
                                                textDecoration: 'underline',
                                            }}
                                        >
                                            {item?.category}
                                        </Typography>
                                    </Grid>

                                    {item?.questionnair?.map((item2, index) => {
                                        return item2.question_type ===
                                            'FREE_TYPE' ? (
                                            <FreeType
                                                key={item2?._id}
                                                item2={item2}
                                                matchMakeMeDataObj={
                                                    matchMakeMeDataObj
                                                }
                                                handleChangeQuestionnair={
                                                    handleChangeQuestionnair
                                                }
                                            />
                                        ) : item2.question_type === 'RANGE' ? (
                                            <Range
                                                key={item2?._id}
                                                item2={item2}
                                                matchMakeMeDataObj={
                                                    matchMakeMeDataObj
                                                }
                                                handleChangeQuestionnair={
                                                    handleChangeQuestionnair
                                                }
                                            />
                                        ) : (
                                            <Optional
                                                key={item2?._id}
                                                item2={item2}
                                                matchMakeMeDataObj={
                                                    matchMakeMeDataObj
                                                }
                                                handleChangeQuestionnair={
                                                    handleChangeQuestionnair
                                                }
                                            />
                                        )
                                    })}
                                    {matchMakeMeData?.length - 1 !== index ? (
                                        <Grid item xs={12}>
                                            <Divider />
                                        </Grid>
                                    ) : null}
                                </Grid>
                            )
                        })}
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </Grid>
    )
}

export default MatchMakeMeQuestionnair
