import { useForm } from 'react-hook-form'
import { Dialog, Grid } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import PersonalInformation from './advancedSearchComponent/PersonalInformation'
import LocationInformation from './advancedSearchComponent/LocationInformation'
// import SocialProfiles from './advancedSearchComponent/SocialProfiles'
import EthnicityAndReligion from './advancedSearchComponent/EthnicityAndReligion'
import AppearanceAndLifestyle from './advancedSearchComponent/AppearanceAndLifestyle'
import RelationshipAndCompatibility from './advancedSearchComponent/RelationshipAndCompatibility'
import SubscriptionAndActivity from './advancedSearchComponent/SubscriptionAndActivity'
import DisplayOptions from './advancedSearchComponent/DisplayOptions'
import NotificationPreferences from './advancedSearchComponent/NotificationPreferences'
import OtherInformation from './advancedSearchComponent/OtherInformation'
// import ProfileImages from './advancedSearchComponent/ProfileImages'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { useEffect, useState } from 'react'
import { Button } from '@material-ui/core'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import UserPersonal from './matchmakemaAdvanceSearch/UserPersonal'
import UserPreferences from './matchmakemaAdvanceSearch/UserPreferences'
import {
    get_matchmakeme_form_data,
    submit_advance_search_data,
} from '../../api/Users_Api'

const UserAdvancedSearch = ({ open, onClose, handleRowDataChange }) => {
    // const numberOfColumn = 4
    const schema = yup.object().shape({})
    const formMethod = useForm({
        defaultValues: {},
        resolver: yupResolver(schema),
    })
    const { register, control, setValue, watch, handleSubmit } = formMethod

    const [userData, setUserData] = useState({})
    const [avaliableOptions, setAvaliableOptions] = useState({})

    useEffect(async () => {
        // ;(async () => {
        try {
            const optionsData = await get_matchmakeme_form_data()
            {
                console.log('optionsData', optionsData)
            }
            setAvaliableOptions(optionsData?.result)
        } catch (e) {
            console.log(e.message)
        }
        // })()
    }, [])

    async function onsubmit(searchData) {
        try {
            // const data = {}
            // data.data = searchData
            // const result = await submit_advance_search_data(data)
            handleRowDataChange(searchData)
        } catch (e) {
            console.log(e.message)
        }
        onClose()
    }
    return (
        <>
            <Dialog
                maxWidth="lg"
                fullWidth={true}
                open={open}
                onClose={onClose}
                // TransitionComponent={Transition}
                onClick={(e) => e.stopPropagation()}
            >
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Grid container spacing={3} sx={{ paddingX: '20px' }}>
                        <Grid item xs={12} sm={12}>
                            <UserPersonal
                                form={formMethod}
                                avaliableOptions={avaliableOptions}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <UserPreferences
                                form={formMethod}
                                avaliableOptions={avaliableOptions}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            container
                            justifyContent="flex-end"
                            style={{ padding: '20px' }}
                        >
                            <Button
                                variant="outlined"
                                color="default"
                                style={{ borderRadius: 8 }}
                                onClick={() => {
                                    onClose()
                                }}
                            >
                                Cancel
                            </Button>
                            <Button
                                variant="contained"
                                style={{
                                    borderRadius: 8,
                                    background: '#BA5A6B',
                                    color: '#FFFFFF',
                                    marginLeft: '8px',
                                }}
                                onClick={handleSubmit(onsubmit)}
                            >
                                View result
                            </Button>
                        </Grid>
                    </Grid>
                </LocalizationProvider>
            </Dialog>
        </>
    )
}

export default UserAdvancedSearch
