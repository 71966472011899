import React, { useEffect, useState } from 'react'
import { FormControlLabel, MenuItem, Stack } from '@mui/material'
import Select from '@mui/material/Select'
import { Controller } from 'react-hook-form'
import { FormLabel } from '@material-ui/core'
const SubtypeSelect = ({
    register,
    control,
    categoryDropdown,
    subTypeCategory,
}) => {
    const [combinedOptions, setCombinedOptions] = useState([])
    useEffect(() => {
        let newArrParentChildOption = []
        // Flatten the options into a single list
        categoryDropdown.forEach((option) => {
            // Add the parent category option
            newArrParentChildOption.push({
                value: option._id,
                name: option.name,
                isParent: option?.isParent ?? false,
            })
            // Add child items as sub-options under each parent
            const childCategories = subTypeCategory
                ?.filter((item) => item.parent_id === option._id)
                .map((item) => {
                    item.name = '- ' + item.name
                    return {
                        name: item.name,
                        value: item?._id,
                        isParent: false,
                    }
                })
            newArrParentChildOption =
                newArrParentChildOption.concat(childCategories)
        })
        setCombinedOptions(newArrParentChildOption)
    }, [categoryDropdown, subTypeCategory])

    return (
        <Stack direction="column">
            <FormLabel htmlFor="subtype_category" sx={{ pt: 4 }}>
                Refines Category
            </FormLabel>
            <FormControlLabel
                style={{
                    paddingTop: '16px',
                    paddingLeft: '12px',
                    marginRight: '0',
                }}
                control={
                    <Controller
                        name="subtype_category"
                        {...register('subtype_category')}
                        control={control}
                        render={({ field }) => (
                            <Select
                                multiple
                                labelId="subtype_category"
                                id="demo-simple-select-subtype-category"
                                fullWidth
                                margin="normal"
                                size="small"
                                value={field.value ?? []}
                                onChange={(event) => {
                                    field.onChange(event.target.value)
                                }}
                            >
                                <MenuItem value={'0'}>
                                    Select Refines Category
                                </MenuItem>
                                {combinedOptions?.map((option) => {
                                    return (
                                        <MenuItem
                                            style={{
                                                fontWeight: option?.isParent
                                                    ? 600
                                                    : null,
                                            }}
                                            key={option?.name}
                                            value={option?.value}
                                        >
                                            {option?.name}
                                        </MenuItem>
                                    )
                                })}
                            </Select>
                        )}
                    />
                }
            />
        </Stack>
    )
}

export default SubtypeSelect
