import { Button, Divider, Menu, MenuItem, Typography } from '@material-ui/core'
import React from 'react'
// import BlockUser from './UserBlock'
import more from '../../asset/img/More horizontal.png'
import VeiwProfile from '../ManageUsers/UserProfilePopUp/ViewProfile/ViewProfile'
import { useHistory } from 'react-router-dom'

// import UserForceDelete from './ForceDeletePopUp'
// import UserForceDeleteButton from './deletedUserForcedButton'

const RowAction = ({
    set_Data,
    value,
    setDeleteMutation = null,
    zodiacDropdownOptions = [],
    sleepingHabbitsDropdownOptions = [],
    drinkingDropdownOptions = [],
    smokingDropdownOptions = [],
    relocateForLoveDropdownOptions = [],
    personalityDropdownValues = [],
    industryDropdownOptions = [],
    educationDropdownOptions = [],
    salaryDropdownOptions = [],
    lookingForDropdownOptions = [],
    onDeleteUserEmptyRecommendList = () => {},
}) => {
    const [anchorEl, setAnchorEl] = React.useState(null)
    const history = useHistory()
    const [openForceDeletePopUp, setOpenForceDeletePopUp] =
        React.useState(false)
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
        setOpenForceDeletePopUp(false)
    }
    return (
        <>
            <VeiwProfile
                onDeleteUserEmptyRecommendList={onDeleteUserEmptyRecommendList}
                isUser={true}
                setDeleteMutation={setDeleteMutation}
                data={{ user: value.row }}
                set_data={set_Data}
                zodiacDropdownOptions={zodiacDropdownOptions}
                sleepingHabbitsDropdownOptions={sleepingHabbitsDropdownOptions}
                drinkingDropdownOptions={drinkingDropdownOptions}
                smokingDropdownOptions={smokingDropdownOptions}
                relocateForLoveDropdownOptions={relocateForLoveDropdownOptions}
                personalityDropdownValues={personalityDropdownValues}
                industryDropdownOptions={industryDropdownOptions}
                educationDropdownOptions={educationDropdownOptions}
                salaryDropdownOptions={salaryDropdownOptions}
                lookingForDropdownOptions={lookingForDropdownOptions}
            />
        </>
    )
}

export default RowAction
