import {
    Collapse,
    Fab,
    Grid,
    LinearProgress,
    Typography,
} from '@material-ui/core'
import React from 'react'
import hand from '../../asset/img/hand.png'

import { styles } from '../../styles/styles'
import LineChart from './LineChart'
import 'chart.js/auto'
import DonutChart from './DonutChart'
import DataDetails from './DataDetails'
import heart from '../../asset/img/Heart.svg'

import Swap from '../../asset/img/Swap.svg'
import Users from '../../asset/img/3 User.svg'

import ReportSummery from './ReportSummery'
import TopLocation from './TopLocation'
import { get_init } from '../../api/Home'
import { useHistory } from 'react-router'
import { set_Login } from '../../redux/ActionCreators'
import Store from '../../redux/Store'
import { connect } from 'react-redux'
import moment from 'moment'
import { CustomDatePicker } from '../../components/Textfields'

const Home = ({ isAuth, loading }) => {
    const [time, set_time] = React.useState('yearly')
    const [rangeTime, set_renge_time] = React.useState([null, null])

    const [data, set_data] = React.useState({})
    const style = styles()
    const history = useHistory()
    let user
    let clear = () => {
        localStorage.clear()
        history.push('/')
        Store.dispatch(set_Login(!isAuth))
    }
    try {
        user = JSON.parse(localStorage.userInfo)
    } catch (error) {
        clear()
    }

    let [start, end] = rangeTime

    React.useEffect(() => {
        set_data({})
        get_init(!end ? { duration: time } : { start, end }).then((res) => {
            if (res) {
                set_data(res)
            }
        })
        // eslint-disable-next-line
    }, [time, end])

    return (
        <Grid item xs={12} style={{ minHeight: '100vh' }}>
            {/* ---------------------------------------------------------------------------------headers */}
            <Collapse in={loading} mountOnEnter unmountOnExit timeout={500}>
                <Grid item xs={12}>
                    <LinearProgress />
                </Grid>
            </Collapse>
            <Grid
                item
                container
                xl={9}
                lg={12}
                md={12}
                justifyContent="space-between"
                style={{}}
            >
                {/* -----------------------------------------------------------------------------------wellcome section */}
                <Grid
                    item
                    container
                    alignItems="center"
                    justifyContent="flex-start"
                    style={{ width: 'fit-content', height: 50 }}
                >
                    <Typography style={{ display: 'inline' }} variant="h5">
                        Welcome back ,
                    </Typography>
                    <Typography
                        style={{
                            // margin: "0 16px",
                            width: 'fit-content',
                        }}
                        variant="h4"
                    >
                        {user.name ? user.name : clear()}
                    </Typography>
                    <img src={hand} alt="" style={{ marginLeft: 16 }} />
                </Grid>

                {/* -----------------------------------------------------------------------------------time line section */}
                <Grid
                    item
                    container
                    className={style.homeTimeLine}
                    justifyContent="space-evenly"
                    alignItems="center"
                    style={{ width: 250 }}
                >
                    {timeList.map((item) => (
                        <Fab
                            key={item}
                            variant="extended"
                            color="inherit"
                            onClick={() => {
                                set_renge_time([null, null])
                                set_time(item)
                            }}
                            className={
                                time === item
                                    ? style.activeTimeLine
                                    : style.deActiveTimeLine
                            }
                        >
                            {item}
                        </Fab>
                    ))}
                </Grid>
                <Grid item style={{ width: 244 }}>
                    <Grid
                        item
                        xs={12}
                        container
                        justifyContent="space-evenly"
                        alignItems="center"
                        style={{ backgroundColor: 'white' }}
                        className={style.homeTimeLine}
                    >
                        <CustomDatePicker
                            set_renge_time={set_renge_time}
                            rangeTime={rangeTime}
                        />

                        <Typography
                            variant="body1"
                            style={{
                                color: 'black',
                                opacity: 1,
                                paddingRight: 6,
                            }}
                        >
                            {moment(data?.duration?.start).format(
                                'DD MMM YYYY'
                            )}{' '}
                            -{' '}
                            {moment(data?.duration?.end).format('DD MMM YYYY')}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            {/* ---------------------------------------------------------------------------------charts */}
            <Grid
                item
                xs={12}
                style={{ height: '70vh', margin: '0 auto' }}
                container
                spacing={2}
            >
                <Grid item xs={8} md={9} style={{ height: '100%' }}>
                    <Grid container style={{ height: '100%' }}>
                        <LineChart chart_data={data.chart} time={time} />
                    </Grid>
                </Grid>
                <Grid item xs={4} md={3} style={{ height: '100%' }}>
                    <DonutChart chart_data={data} />
                </Grid>
            </Grid>
            {/* ---------------------------------------------------------------------------------charts details */}
            <Grid
                item
                xs={12}
                style={{ margin: 'auto', minHeight: 350 }}
                container
                spacing={2}
            >
                {titles.map((item) => {
                    return (
                        <Grid item xs={6} md={3} key={item.title}>
                            <DataDetails
                                title={item.title}
                                value={data[item.key]}
                                img={item.img}
                            />
                        </Grid>
                    )
                })}
                <Grid item xs={6} md={3}>
                    <TopLocation data={data?.usersByCountry || []} />
                </Grid>
            </Grid>
            {/* ---------------------------------------------------------------------------------charts details */}
            {/* <Grid
          item
          xs={12}
          style={{ margin: "0 auto 24px" }}
          container
          spacing={2}
        >
          <Grid item xs={12}>
            <ReportSummery data={data.lastReports} />
          </Grid>
        </Grid> */}
        </Grid>
    )
}

const mapStateToProps = (prop) => {
    const { GeneralReducer } = prop
    const { isAuth, loading } = GeneralReducer

    return { isAuth, loading }
}

export default connect(mapStateToProps)(Home)

let timeList = ['weekly', 'monthly', 'yearly']
const titles = [
    { title: 'Total users', value: 1200, img: Users, key: 'totalUsers' },
    { title: 'Matches', value: 2000, img: heart, key: 'totalMutualLikes' },
    { title: 'Request Approval', value: 1500, img: Swap, key: 'requestCount' },
]
