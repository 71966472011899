import { Button, Dialog, Fade, Grid, Typography } from '@material-ui/core'
import block from '../../asset/img/blockmodal.png'
import React from 'react'

import { Form, Formik } from 'formik'
import { block_user, unBlock_user } from '../../api/Users_Api'
//type: for display_Type style true for button and false as menu==>>>
const BlockUser = ({ userName, data, set_Data, display_Type }) => {
    const [open, setOpen] = React.useState(false)

    const handleOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }
    const buttonStyle = display_Type
        ? {
              backgroundColor: '#BA5A6B',
              color: 'white',
              marginLeft: 16,
              borderRadius: 6,
          }
        : { paddingLeft: 0 }

    return (
        <>
            <Button variant="text" onClick={handleOpen} style={buttonStyle}>
                {!display_Type && (
                    <img src={block} alt="" style={{ marginRight: 8 }} />
                )}
                {data.status === 'blocked' ? 'Unblock' : 'Block'}
            </Button>
            <Formik
                initialValues={{ reason: '' }}
                onSubmit={(vlaue, props) => {
                    if (data.status !== 'blocked') {
                        block_user({
                            reason: vlaue.reason,
                            ids: [data._id],
                        }).then((res) => {
                            if (!res) return
                            props.resetForm()
                            set_Data((pre) =>
                                pre.map((item) => {
                                    if (item._id === data._id) {
                                        return { ...item, status: 'blocked' }
                                    } else {
                                        return item
                                    }
                                })
                            )
                            handleClose()
                        })
                    } else {
                        unBlock_user({ ids: [data._id] }).then((res) => {
                            if (!res) return
                            setOpen(false)
                            set_Data((pre) =>
                                pre.map((item) => {
                                    if (item._id === data._id) {
                                        return {
                                            ...item,
                                            status: 'active',
                                        }
                                    } else {
                                        return item
                                    }
                                })
                            )
                            handleClose()
                        })
                    }
                }}
                validateOnMount
            >
                {(formik) => {
                    return (
                        <Form>
                            <Dialog
                                maxWidth="sm"
                                fullWidth={true}
                                open={open}
                                onClose={handleClose}
                                TransitionComponent={Transition}
                                onClick={(e) => e.stopPropagation()}
                            >
                                <Grid container style={{ padding: 32 }}>
                                    <Grid
                                        item
                                        xs={12}
                                        style={{ marginBottom: 24 }}
                                    >
                                        <Typography variant="body2">
                                            Are you sure you want to{' '}
                                            {data.status !== 'blocked'
                                                ? 'block'
                                                : 'unblock'}{' '}
                                            “{userName}” ?
                                        </Typography>
                                    </Grid>

                                    <Grid
                                        style={{ marginTop: 24 }}
                                        container
                                        justifyContent="flex-end"
                                    >
                                        <Button
                                            variant="outlined"
                                            onClick={() => {
                                                handleClose()
                                                formik.resetForm()
                                            }}
                                        >
                                            cancel
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            // disabled={formik.values.reason === ""}
                                            onClick={() => formik.submitForm()}
                                            type="submit"
                                            style={{
                                                color: 'white',
                                                marginLeft: 16,
                                            }}
                                        >
                                            {data.status === 'blocked'
                                                ? 'Unblock'
                                                : 'Block'}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Dialog>
                        </Form>
                    )
                }}
            </Formik>
        </>
    )
}

export default BlockUser
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Fade timeout={350} ref={ref} {...props} />
})
