import { Button, Dialog, Fade, Grid, Typography } from '@material-ui/core'

import React from 'react'

const DeleteUser = ({
    open,
    setOpen = () => {},
    callbackDeactivateHubAndQuiz = () => {},
    disabledButton,
    message,
}) => {
    const handleClose = () => {
        setOpen(false)
    }

    return (
        <>
            <Dialog
                maxWidth="sm"
                fullWidth={true}
                open={open}
                TransitionComponent={Transition}
                onClick={(e) => e.stopPropagation()}
            >
                <Grid container style={{ padding: 16 }}>
                    <Grid item xs={12} style={{ marginBottom: 24 }}>
                        <Typography variant="body2">{message}</Typography>
                    </Grid>

                    <Grid
                        style={{ marginTop: 14 }}
                        container
                        justifyContent="flex-end"
                    >
                        <Button
                            disabled={disabledButton}
                            variant="outlined"
                            onClick={() => {
                                handleClose()
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            disabled={disabledButton}
                            variant="contained"
                            color="secondary"
                            onClick={() => callbackDeactivateHubAndQuiz()}
                            type="submit"
                            style={{
                                color: 'white',
                                marginLeft: 16,
                            }}
                        >
                            Confirm
                        </Button>
                    </Grid>
                </Grid>
            </Dialog>
        </>
    )
}

export default DeleteUser
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Fade timeout={350} ref={ref} {...props} />
})
