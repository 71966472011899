import {
  AppBar,
  Divider,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import React from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
// import { get_notification } from "../api/notification_api";
import { set_Login, set_side_value } from "../redux/ActionCreators";
import Store from "../redux/Store";
// import Notification from "./Notification";
import logout from "../asset/img/bx_log-out-circle.svg";
import userinfo from "../asset/img/bx_user.svg";
import useInterval from "use-interval";
const Navbar = ({ isAuth, loading, modal_loading }) => {
  // eslint-disable-next-line
  const [timer, set_timer] = React.useState(0);
  const history = useHistory();
  let user;
  let clear = () => {
    localStorage.clear();
    history.push("/");
    Store.dispatch(set_Login(!isAuth));
  };
  try {
    user = JSON.parse(localStorage.userInfo);
  } catch (error) {
    clear();
  }
  useInterval(
    () => {
      set_timer((pre) => pre + 1);
    },
    30000,
    false
  );

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  return (
    <AppBar elevation={0} style={{ backgroundColor: "white" }}>
      <Toolbar style={{ backgroundColor: "white" }}>
        <Grid item container style={{}} justifyContent="flex-end">
          <Grid
            item
            style={{ width: 400 }}
            container
            alignItems="center"
            justifyContent="flex-end"
          >
            {/* <Notification
              data={notif}
              set_data={set_notif}
              set_notif_open={set_notif_open}
            /> */}

            <Typography
              variant="body1"
              style={{ cursor: "pointer", color: "black" }}
              onClick={handleClick}
            >
              {user.name ? user?.name : clear()}
            </Typography>
            <IconButton onClick={handleClick}>
              <ExpandMore style={{ color: "#333" }} />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              onClose={handleClose}
              elevation={0}
              open={Boolean(anchorEl)}
              PaperProps={{
                style: {
                  boxShadow: "0px 2px 12px rgba(205, 205, 205, 0.7)",
                  border: "1px solid rgba(0, 0, 0, 0.08)",
                  borderRadius: 10,
                  padding: 16,
                  opacity: 1,
                },
              }}
              style={{
                position: "absolute",
                top: 40,
                left: -25,
                opacity: 1,
              }}
            >
              <Typography style={{ color: "black" }} variant="body1">
                {user.name ? user?.name : clear()}
              </Typography>
              <Divider style={{ margin: "8px 0" }} />

              <MenuItem
                style={{ fontSize: 14, opacity: 1, paddingLeft: 0 }}
                onClick={() => {
                  history.push("/settings");
                  Store.dispatch(set_side_value("settings"));
                  handleClose();
                }}
              >
                <img src={userinfo} alt="" />
                <Typography style={{ color: "black", marginLeft: 8 }}>
                  Account Setting
                </Typography>
              </MenuItem>
              <MenuItem
                style={{ fontSize: 14, opacity: 1, paddingLeft: 0 }}
                onClick={() => {
                  handleClose();
                  localStorage.clear();
                  history.push("/");
                  Store.dispatch(set_Login(!isAuth));
                }}
              >
                <img src={logout} alt="" />{" "}
                <Typography style={{ color: "black", marginLeft: 8 }}>
                  Log Out
                </Typography>
              </MenuItem>
            </Menu>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

const mapStateToProps = (prop) => {
  const { GeneralReducer } = prop;
  const { isAuth, loading, modal_loading } = GeneralReducer;

  return { isAuth, loading, modal_loading };
};

export default connect(mapStateToProps)(Navbar);
