import { set_Login } from "../redux/ActionCreators";
import Store from "../redux/Store";
import { api_instance } from "./Base_Api";
export const setLogin = async (value) => {
  const { isAuth } = Store.getState().GeneralReducer;
  try {
    const { data } = await api_instance.post("/admin/auth/login", value);

    localStorage.token = data.data.token;
    localStorage.userInfo = JSON.stringify(data.data.user);

    Store.dispatch(set_Login(!isAuth));
    return data;
  } catch (error) {}
};
