import { set_message } from "../redux/ActionCreators";
import Store from "../redux/Store";
import { api_instance } from "./Base_Api";
export const get_init = async (params) => {
  try {
    const { data } = await api_instance.get("/admin/init", {
      params: params,
    });
    return data.data;
  } catch (error) {}
};

export const update_fee = async (value) => {
  try {
    const { data } = await api_instance.put("/init/fee", value);
    Store.dispatch(set_message({ mode: true, message: "Joining fee updated" }));
    return data;
  } catch (error) {}
};
