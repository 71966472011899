import {
    Avatar,
    Button,
    Checkbox,
    Divider,
    Grid,
    LinearProgress,
    Typography,
} from '@material-ui/core'
import { DataGrid, GridOverlay } from '@material-ui/data-grid'
import { format } from 'date-fns'
import { Add } from '@material-ui/icons'
import React, { useState } from 'react'
import { styles } from '../../styles/styles'
import RowAction from './RowAction'
import sorter from '../../asset/img/sorter.png'
import RecommededUserIcon from '../../asset/img/Recommend_icon.png'
// import clock from "../../asset/img/clock.png";
import { get_all_dropdowns } from '../../api/UserRequest_Api'
import {
    get_recommended_users_list,
    update_recommended_user,
} from '../../api/Users_Api'
import moment from 'moment'

import { CircularProgress, Stack, TextField } from '@mui/material'

import { useParams } from 'react-router-dom'
import { ArrowDropDown } from '@material-ui/icons'
import BasicPagination from './Pagination'

const UserRecommendedList = ({ loading }) => {
    const params = useParams()
    const [userName, setUserName] = useState('')
    const [loader, setLoader] = useState(true)
    const style = styles()
    const [data, set_Data] = useState([])
    const [error, setError] = useState(false)
    const [deleteMuatation, setDeleteMutation] = useState({})

    const [industryDropdownOptions, setIndustryDropdownOptions] = useState([])
    const [educationDropdownOptions, setEducationDropdownOptions] = useState([])

    const [salaryDropdownOptions, setSalaryDropdownOptions] = useState([])

    const [lookingForDropdownOptions, setLookingForDropdownOptions] = useState(
        []
    )
    const [relocateForLoveDropdownOptions, setRelocateForLoveDropdownOptions] =
        useState([])
    const [personalityDropdownValues, setpersonalityDropdownValues] = useState(
        []
    )
    const [smokingDropdownOptions, setSmokingDropdownOptions] = useState([])
    const [zodiacDropdownOptions, setZodiacDropdownOptions] = useState([])
    const [drinkingDropdownOptions, setDrinkingDropdownOptions] = useState([])
    const [sleepingHabbitsDropdownOptions, setSleepingHabbitsDropdownOptions] =
        useState([])
    const [paginateObj, setPaginateObj] = useState({
        page: 1,
        totalPages: 1,
    })
    const [recommendUserList, setRecommendUserList] = useState([])

    const [searchText, setSearchtext] = useState('')
    const [searchQuery, setSearchQuery] = useState('')
    console.log('paginateObj', paginateObj)

    const onDeleteUserEmptyRecommendList = () => {
        setRecommendUserList([])
    }

    React.useEffect(() => {
        get_all_dropdowns()
            .then((res) => {
                const salaries = res?.find((d) => {
                    if (d.dropdownTitle === 'salary') {
                        return true
                    }
                })
                setSalaryDropdownOptions(
                    salaries?.optionList?.map((item) => item?.name)
                )
                const industry = res?.find((d) => {
                    if (d.dropdownTitle === 'industry') {
                        return true
                    }
                })
                setIndustryDropdownOptions(
                    industry?.optionList?.map((item) => item?.name)
                )
                const education = res?.find((d) => {
                    if (d.dropdownTitle === 'education') {
                        return true
                    }
                })
                setEducationDropdownOptions(
                    education?.optionList?.map((item) => item?.name)
                )

                const smoke = res?.find((d) => {
                    if (d.dropdownTitle === 'smoking') {
                        return true
                    }
                })
                setSmokingDropdownOptions(
                    smoke?.optionList?.map((item) => item?.name)
                )
                const drink = res?.find((d) => {
                    if (d.dropdownTitle === 'drinking') {
                        return true
                    }
                })
                setDrinkingDropdownOptions(
                    drink?.optionList?.map((item) => item?.name)
                )
                const personalityType = res?.find((d) => {
                    if (d.dropdownTitle === 'personalityType') {
                        return true
                    }
                })
                setpersonalityDropdownValues(
                    personalityType?.optionList?.map((item) => item?.name)
                )
                const zodiac = res?.find((d) => {
                    if (d.dropdownTitle === 'zodiac') {
                        return true
                    }
                })
                setZodiacDropdownOptions(
                    zodiac?.optionList?.map((item) => item?.name)
                )
                const relocateForLove = res?.find((d) => {
                    if (d.dropdownTitle === 'relocateForLove') {
                        return true
                    }
                })
                console.log('relocated', relocateForLove?.optionList)
                setRelocateForLoveDropdownOptions(
                    relocateForLove?.optionList?.map((item) => item?.name)
                )

                const sleepingHabbits = res?.find((d) => {
                    if (d.dropdownTitle === 'sleepingHabits') {
                        return true
                    }
                })

                setSleepingHabbitsDropdownOptions(
                    sleepingHabbits?.optionList?.map((item) => item?.name)
                )
                const looking = res?.find((d) => {
                    if (d.dropdownTitle === 'lookingFor') {
                        return true
                    }
                })
                setLookingForDropdownOptions(
                    looking?.optionList?.map((item) => item?.name)
                )
            })
            .catch((e) => console.log(e.message))
    }, [])

    React.useEffect(() => {
        const timeOutId = setTimeout(() => setSearchtext(searchQuery), 500)
        return () => {
            clearTimeout(timeOutId)
        }
    }, [searchQuery])

    React.useEffect(() => {
        setLoader(true)
        const userId = params?.id
        get_recommended_users_list(userId, paginateObj.page, searchText)
            .then((res) => {
                if (res?.response?.data?.data?.error?.message) {
                    setError(true)
                    setLoader(false)
                } else {
                    const responseData = res?.data
                    setPaginateObj({
                        page: responseData.page,
                        totalPages: responseData?.totalPages,
                    })

                    set_Data(responseData?.data)
                    setUserName(responseData?.username)
                    setError(false)
                    setLoader(false)
                }
            })
            .catch((err) => {
                setLoader(false)
                setError(true)
                console.log('error', err?.response)
            })
    }, [paginateObj.page, deleteMuatation, searchText])

    const handle_update_recommended_user = async (isStatusTrue) => {
        try {
            if (recommendUserList.length) {
                const updateRecommendUser = await update_recommended_user(
                    params?.id,
                    recommendUserList,
                    isStatusTrue
                )
                console.log('updateRecommendUser', updateRecommendUser)
                setRecommendUserList([])
                setDeleteMutation({})
            }
        } catch (error) {}
    }

    const header_list = [
        {
            field: 'fullName',
            headerName: (
                <Stack
                    direction="column"
                    justifyContent={'space-around'}
                    alignItems="left"
                >
                    <Typography
                        style={{
                            opacity: 1,
                            color: 'black',
                            // paddingTop: '12px',
                        }}
                        variant="body1"
                    >
                        User
                    </Typography>
                </Stack>
            ),
            minWidth: 370,
            sortable: false,
            flex: 1,
            headerAlign: 'left',
            align: 'left',

            renderCell: (value) => {
                return (
                    <Grid container alignItems="center" spacing={1}>
                        <Grid style={{ marginRight: '10px' }}>
                            <Checkbox
                                style={{ color: 'black' }}
                                checked={recommendUserList.includes(
                                    value.row._id
                                )}
                                onChange={(item) => {
                                    if (item.target.checked) {
                                        const filterRecommendUserList = [
                                            ...recommendUserList,
                                            value.row._id,
                                        ]
                                        const newArr = new Set(
                                            filterRecommendUserList
                                        )
                                        setRecommendUserList([...newArr])
                                    } else {
                                        const newArr = recommendUserList.filter(
                                            (item) => item !== value.row._id
                                        )

                                        setRecommendUserList(newArr)
                                    }
                                }}
                            />
                        </Grid>

                        <Grid>
                            <Avatar
                                src={`${value.row?.images[0]?.croped}`}
                                style={{
                                    marginRight: 16,
                                    width: 45,
                                    height: 45,
                                }}
                            />
                        </Grid>
                        <Grid>
                            <Grid
                                container
                                alignItems="left"
                                direction="column"
                                item
                                style={{ width: 'calc(100% - 62px)' }}
                            >
                                <Grid container alignItems="center">
                                    <Typography
                                        style={{ opacity: 1, color: 'black' }}
                                        variant="body1"
                                    >
                                        {(value?.row?.firstName ?? '') +
                                            ' ' +
                                            (value?.row?.lastName ?? '')}
                                    </Typography>
                                </Grid>
                                <Typography
                                    style={{ fontSize: 12 }}
                                    variant="body1"
                                >
                                    {moment(value.row?.dateOfBirth).format(
                                        'DD.MM.YYYY'
                                    )}
                                </Typography>
                                <Typography
                                    style={{ fontSize: 12 }}
                                    variant="body1"
                                >
                                    {value.row?.address
                                        ? `${value.row?.address?.city} ,${value?.row?.address?.country}`
                                        : null}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                )
            },
        },
        {
            field: 'email',
            headerName: (
                <Stack
                    direction="column"
                    justifyContent={'space-around'}
                    alignItems="left"
                >
                    <Typography
                        style={{
                            opacity: 1,
                            color: 'black',
                            // paddingTop: '12px',
                            marginLeft: '5px',
                        }}
                        variant="body1"
                    >
                        Email
                    </Typography>
                </Stack>
            ),
            minWidth: 200,
            flex: 1,
            sortable: false,
            headerAlign: 'left',
            align: 'left',

            renderCell: (value) => (
                <Stack>
                    <Typography
                        style={{ opacity: 1, color: 'black' }}
                        variant="body1"
                    >
                        {value.row?.email ?? '-'}
                    </Typography>

                    <Typography variant="body1" style={{ fontSize: 12 }}>
                        {value.row.mobileNumber ?? '-'}
                    </Typography>
                </Stack>
            ),
        },

        {
            field: 'lastActiveAt',
            headerName: (
                <Stack
                    direction="column"
                    justifyContent={'space-around'}
                    alignItems="left"
                >
                    <Typography
                        style={{
                            opacity: 1,
                            color: 'black',
                            // paddingTop: '12px',
                            marginLeft: '5px',
                        }}
                        variant="body1"
                    >
                        Last Active
                    </Typography>
                </Stack>
            ),
            minWidth: 100,
            flex: 1,
            sortable: false,
            headerAlign: 'left',
            align: 'left',

            renderCell: (value) => (
                <Grid
                    container
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Grid container alignItems="center">
                        <Grid container alignItems="center">
                            <Typography
                                style={{
                                    fontSize: 12,
                                    color: 'black',
                                    marginLeft: '10px',
                                }}
                                variant="body1"
                            >
                                {format(
                                    new Date(
                                        value?.row?.lastActiveAt
                                            ? value?.row?.lastActiveAt
                                            : 'NA'
                                    ),
                                    'dd LLL uuuu'
                                )}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            ),
        },
        {
            field: 'recommendUser',
            headerName: (
                <Stack
                    direction="column"
                    justifyContent={'space-around'}
                    alignItems="left"
                >
                    <Typography
                        style={{
                            opacity: 1,
                            color: 'black',
                            // paddingTop: '12px',
                        }}
                        variant="body1"
                    >
                        Recommendation Status
                    </Typography>
                </Stack>
            ),
            minWidth: 80,
            flex: 1,
            sortable: false,
            headerAlign: 'left',
            align: 'left',
            renderCell: (value) => (
                <Grid
                    style={{ width: '160px', textAlign: 'center' }}

                    // style={{ marginLeft: '8px' }}
                >
                    {value?.row?.isUserRecommended ? (
                        <img
                            src={RecommededUserIcon}
                            alt="recommend user icon"
                        />
                    ) : (
                        <div>-</div>
                    )}
                </Grid>
            ),
        },

        {
            field: 'action',
            headerName: 'Action',
            minWidth: 95,
            flex: 1,
            headerAlign: 'left',
            align: 'left',
            sortable: false,
            renderCell: (value) => (
                <>
                    <RowAction
                        zodiacDropdownOptions={zodiacDropdownOptions}
                        sleepingHabbitsDropdownOptions={
                            sleepingHabbitsDropdownOptions
                        }
                        drinkingDropdownOptions={drinkingDropdownOptions}
                        smokingDropdownOptions={smokingDropdownOptions}
                        relocateForLoveDropdownOptions={
                            relocateForLoveDropdownOptions
                        }
                        personalityDropdownValues={personalityDropdownValues}
                        industryDropdownOptions={industryDropdownOptions}
                        educationDropdownOptions={educationDropdownOptions}
                        salaryDropdownOptions={salaryDropdownOptions}
                        lookingForDropdownOptions={lookingForDropdownOptions}
                        setDeleteMutation={setDeleteMutation}
                        value={value}
                        onDeleteUserEmptyRecommendList={
                            onDeleteUserEmptyRecommendList
                        }
                    />
                </>
            ),
        },
    ]

    return (
        <>
            <Typography
                style={{ marginLeft: '8px' }}
                variant="h5"
            >{`${userName?.trim()}`}</Typography>

            <Grid
                xs={12}
                item
                style={{ display: 'flex' }}
                justifyContent="space-between"
            >
                <Grid xs={3} justifyContent="start">
                    <TextField
                        style={{
                            backgroundColor: 'white',
                            borderRadius: 12,
                            border: '3px solid #E6E6E6',
                        }}
                        value={searchQuery}
                        onChange={(event) => {
                            setSearchQuery(event.target.value)
                        }}
                        id="search_hub_tex"
                        placeholder="Search"
                        fullWidth
                        margin="normal"
                        size="small"
                    />
                </Grid>

                <Grid xs={5} justifyContent="center" alignContent="center">
                    <Stack
                        direction="row"
                        spacing={1}
                        width="100%"
                        justifyContent={'end'}
                    >
                        <Button
                            disabled
                            style={{
                                marginRight: '10px',
                                fontWeight: 600,
                                fontSize: '16px',
                                color: 'black',
                            }}
                        >
                            User Counts: {recommendUserList.length}{' '}
                        </Button>
                        <Button
                            onClick={() => setRecommendUserList([])}
                            // startIcon={<Add />}
                            style={{
                                // padding: '8px 16px 8px 12px',
                                marginRight: '10px',
                                border: '2px solid #65819D',
                                borderRadius: '16px',
                                fontWeight: 600,
                                fontSize: '16px',

                                color: '#65819D',
                            }}
                        >
                            Reset
                        </Button>
                        <Button
                            onClick={() =>
                                handle_update_recommended_user(false)
                            }
                            // startIcon={<Add />}
                            style={{
                                // padding: '8px 16px 8px 12px',
                                marginRight: '10px',
                                border: '2px solid #65819D',
                                borderRadius: '16px',
                                fontWeight: 600,
                                fontSize: '16px',

                                color: '#65819D',
                            }}
                        >
                            Unrecommend User
                        </Button>
                        <Button
                            onClick={() => handle_update_recommended_user(true)}
                            // startIcon={<Add />}
                            style={{
                                // padding: '8px 16px 8px 12px',
                                marginRight: '10px',
                                border: '2px solid #65819D',
                                borderRadius: '16px',
                                fontWeight: 600,
                                fontSize: '16px',
                                color: '#65819D',
                            }}
                        >
                            Recommend User
                        </Button>
                    </Stack>
                </Grid>
            </Grid>
            {/* <Grid xs={12}>
   
                <Grid xs={4} />
                <Grid xs={4} />
            </Grid> */}
            {!loader ? (
                <>
                    <Grid
                        item
                        xs={12}
                        className={style.box}
                        container
                        style={{
                            height: 'calc(100% - 150px)',
                            overflow: 'auto',
                            paddingTop: '0',
                            paddingBottom: '0',
                            // marginTop: '10px',
                        }}
                    >
                        <DataGrid
                            columns={header_list}
                            autore
                            rows={data}
                            loading={loading}
                            disableColumnMenu
                            noda
                            getRowId={(row) => row._id}
                            hideFooter
                            style={{ border: 'none', fontSize: 14 }}
                            classes={{ cell: style.DataGridHeader }}
                            headerHeight={70}
                            rowHeight={90}
                            disableSelectionOnClick
                            disableColumnSelector
                            components={{
                                ColumnSortedAscendingIcon: CustomSort,
                                ColumnSortedDescendingIcon: CustomSort,
                                LoadingOverlay: CustomLoadingOverlay,
                                NoRowsOverlay: NoRowsOverlay,
                            }}
                        />
                    </Grid>
                    {paginateObj?.page ? (
                        <Grid item xs={12} style={{ marginTop: '10px' }}>
                            <BasicPagination
                                currentPage={paginateObj.page}
                                totalPages={paginateObj.totalPages}
                                onPageChange={(_, value) => {
                                    setPaginateObj({
                                        page: value,
                                        totalPages: paginateObj.totalPages,
                                    })
                                }}
                            />
                        </Grid>
                    ) : null}
                </>
            ) : (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100vh',
                    }}
                >
                    <CircularProgress />
                </div>
            )}
        </>
    )
}

export default UserRecommendedList
export function CustomLoadingOverlay() {
    return (
        <GridOverlay>
            <div style={{ position: 'absolute', top: 0, width: '100%' }}>
                <LinearProgress color="secondary" />
            </div>
        </GridOverlay>
    )
}
export const CustomSort = () => {
    return (
        <img
            src={sorter}
            alt=""
            width={11}
            height={25}
            style={{ position: 'relative', bottom: 3 }}
        />
    )
}

export const NoRowsOverlay = () => {
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
                width: '100%',
            }}
        >
            <p>No data found</p>
        </div>
    )
}
