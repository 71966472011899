import { Button, Divider, Menu, MenuItem, Typography } from '@material-ui/core'
import React from 'react'
import BlockUser from './UserBlock'
import more from '../../asset/img/More horizontal.png'
import VeiwProfile from './UserProfilePopUp/ViewProfile/ViewProfile'

import { SetAsFounder } from './SetAsFounder'
import { SetAsFakeAccount } from './SetAsFakeAccount'
import UserInformation from '../UserInformation/UserInformation'
import { Link } from 'react-router-dom'
import Info from '../../asset/img/Edit.png'
import { useHistory } from 'react-router-dom'
import Store from '../../redux/Store'
import { set_side_value } from '../../redux/ActionCreators'
import DeleteUser from './UserDelete'
import MatchMakeMeQuestionnair from '../UserInformation/UserInformationFormComponents/MatchMakeMeQuestionnairDialogue'
import { DeletedUserReactivated } from './deletedUserReactivate'
import UserForceDelete from './ForceDeletePopUp'
import UserForceDeleteButton from './deletedUserForcedButton'
import { UserRecommendList } from './UserRecommendList'

const RowAction = ({
    set_Data,
    value,
    setDeleteMutation = null,
    zodiacDropdownOptions = [],
    sleepingHabbitsDropdownOptions = [],
    drinkingDropdownOptions = [],
    smokingDropdownOptions = [],
    relocateForLoveDropdownOptions = [],
    personalityDropdownValues = [],
    industryDropdownOptions = [],
    educationDropdownOptions = [],
    salaryDropdownOptions = [],
    lookingForDropdownOptions = [],
}) => {
    const [anchorEl, setAnchorEl] = React.useState(null)
    const history = useHistory()
    const [openForceDeletePopUp, setOpenForceDeletePopUp] =
        React.useState(false)
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
        setOpenForceDeletePopUp(false)
    }
    return (
        <>
            <Button
                onClick={handleClick}
                variant="outlined"
                startIcon={<img src={more} alt="" />}
            >
                Action
            </Button>
            <Menu
                anchorEl={anchorEl}
                onClose={handleClose}
                elevation={0}
                open={Boolean(anchorEl)}
                PaperProps={{
                    style: {
                        boxShadow: '0px 2px 12px rgba(205, 205, 205, 0.7)',
                        border: '1px solid rgba(0, 0, 0, 0.08)',
                        borderRadius: 10,
                        padding: 16,
                        opacity: 1,
                    },
                }}
                style={{
                    position: 'absolute',
                    top: 40,
                    left: -50,
                    opacity: 1,
                }}
            >
                <Typography style={{ color: 'black' }} variant="body1">
                    Actions
                </Typography>
                <Divider />
                <MenuItem style={{ paddingLeft: 0, opacity: 1 }} button={false}>
                    <BlockUser
                        data={value.row}
                        userName={`${value.row.firstName} ${value.row.lastName}`}
                        set_Data={set_Data}
                    />
                </MenuItem>
                <MenuItem style={{ paddingLeft: 0, opacity: 1 }} button={false}>
                    <SetAsFounder data={value.row} set_Data={set_Data} />
                </MenuItem>
                <MenuItem style={{ paddingLeft: 0, opacity: 1 }} button={false}>
                    <SetAsFakeAccount data={value.row} set_Data={set_Data} />
                </MenuItem>
                <MenuItem style={{ paddingLeft: 0, opacity: 1 }} button={false}>
                    <VeiwProfile
                        isUser={true}
                        setDeleteMutation={setDeleteMutation}
                        data={{ user: value.row }}
                        set_data={set_Data}
                        zodiacDropdownOptions={zodiacDropdownOptions}
                        sleepingHabbitsDropdownOptions={
                            sleepingHabbitsDropdownOptions
                        }
                        drinkingDropdownOptions={drinkingDropdownOptions}
                        smokingDropdownOptions={smokingDropdownOptions}
                        relocateForLoveDropdownOptions={
                            relocateForLoveDropdownOptions
                        }
                        personalityDropdownValues={personalityDropdownValues}
                        industryDropdownOptions={industryDropdownOptions}
                        educationDropdownOptions={educationDropdownOptions}
                        salaryDropdownOptions={salaryDropdownOptions}
                        lookingForDropdownOptions={lookingForDropdownOptions}
                    />
                </MenuItem>
                {/* <MenuItem style={{ paddingLeft: 0, opacity: 1 }} button={false}>
                    <UserRecommendList data={value.row} />
                </MenuItem> */}
                {value.row?.status === 'deleted' ? (
                    <>
                        <MenuItem
                            style={{ paddingLeft: 0, opacity: 1 }}
                            button={false}
                        >
                            <DeletedUserReactivated
                                data={{ user: value.row }}
                                set_data={set_Data}
                                setDeleteMutation={setDeleteMutation}
                            />
                        </MenuItem>
                        <MenuItem
                            style={{ paddingLeft: 0, opacity: 1 }}
                            button={false}
                        >
                            <UserForceDeleteButton
                                setOpen={setOpenForceDeletePopUp}
                            />
                        </MenuItem>
                    </>
                ) : null}

                {/* <MenuItem style={{ paddingLeft: 0, opacity: 1 }} button={false}>
                    <MatchMakeMeQuestionnair id={value?.row?._id} />
                </MenuItem> */}
                {/* <MenuItem style={{ paddingLeft: 0, opacity: 1 }} button={false}>
                    <Button
                        variant="text"
                        onClick={() => {
                            history.push(
                                `/matchmake-me-questionnair/${value.row._id}`
                            )
                            // const win = window.open(
                            //     `/matchmake-me-questionnair/${value.row._id}`,
                            //     '_self'
                            // )
                            // Store.dispatch(
                            //     set_side_value(`user-information/new`)
                            // )
                        }}
                        style={{ paddingLeft: 0 }}
                    >
                        <img
                            src={MatchMakingInfo}
                            alt=""
                            style={{ marginRight: 8 }}
                        />
                        MatchMake Me Questionnair
                    </Button>

                </MenuItem> */}
                {/* <MenuItem style={{ paddingLeft: 0, opacity: 1 }} button={false}>
                    <Button
                        variant="text"
                        onClick={() => {
                            console.log('doing something')
                            history.push(`/user-information/${value.row._id}`)
                            const win = window.open(
                                `/user-information/${value.row._id}`,
                                '_blank'
                            )
                            Store.dispatch(
                                set_side_value(`user-information/new`)
                            )
                            win.focus()
                            history.push(`/user-0/${value.row._id}`)
                            history.push(`/user-information/${value.row._id}`)
                            Store.dispatch(set_side_value(`user-information/new`))
                        }}
                        style={{ paddingLeft: 0 }}
                    >
                        <img src={Info} alt="" style={{ marginRight: 8 }} />
                        Edit User
                    </Button>
                 <Link to={`/user-information/${value.row._id}`}>Edit User Profile</Link>  
                </MenuItem> */}
                {/* <MenuItem style={{ paddingLeft: 0, opacity: 1 }} button={false}>
                    <DeleteUser
                        data={value.row}
                        userName={`${value.row.firstName} ${value.row.lastName}`}
                        set_Data={set_Data}
                        status={value.row.status === 'deleted'}
                        setDeleteMutation={setDeleteMutation}
                    />
                </MenuItem> */}
            </Menu>
            {value.row?.status === 'deleted' ? (
                <UserForceDelete
                    data={value.row}
                    handleCloseOuter={handleClose}
                    confirmation_text={
                        value.row.email
                            ? value?.row?.email
                            : value.row?.firstName
                    }
                    userName={`${value.row?.firstName} ${value.row?.lastName}`}
                    set_data={set_Data}
                    setDeleteMutation={setDeleteMutation}
                    open={openForceDeletePopUp}
                    setOpen={setOpenForceDeletePopUp}
                />
            ) : null}
        </>
    )
}

export default RowAction
