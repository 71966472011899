// src/TagsInput.js
import { TextField } from '@mui/material'
import React, { useState } from 'react'

const TagsInput = ({ tags = [], handleTags = () => {} }) => {
    const [input, setInput] = useState('')

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && input) {
            e.preventDefault() // Prevent form submission
            if (!tags.includes(input)) {
                handleTags([...tags, input])
            }
            setInput('')
        }
    }

    const handleRemoveTag = (tagToRemove) => {
        handleTags(tags.filter((tag) => tag !== tagToRemove))
    }

    return (
        <div>
            <div>
                <TextField
                    placeholder="Add a option"
                    value={input}
                    onChange={(e) => setInput(e.target.value)}
                    onKeyDown={handleKeyDown}
                    id="quiz-instructions"
                    fullWidth
                    margin="normal"
                    style={inputStyle}
                />
                {tags.map((tag, index) => (
                    <span key={index} style={tagStyle}>
                        {tag}
                        <button
                            onClick={() => handleRemoveTag(tag)}
                            style={buttonStyle}
                        >
                            x
                        </button>
                    </span>
                ))}
            </div>
        </div>
    )
}

const tagStyle = {
    display: 'inline-block',
    background: '#e0e0e0',
    borderRadius: '3px',
    padding: '5px 10px',
    margin: '5px',
}

const buttonStyle = {
    marginLeft: '5px',
    cursor: 'pointer',
}

const inputStyle = {
    // border: '1px solid #ccc',
    // padding: '5px',
    // borderRadius: '3px',
}

export default TagsInput
