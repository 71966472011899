import { Button, Dialog, Fade, Grid, Typography } from "@material-ui/core";
import { Form, Formik } from "formik";
import React from "react";
import { update_notification } from "../../api/Setting_Api";
import { TextFieldWrapper } from "../../components/Textfields";
import Edit from "../../asset/img/Edit_notification.svg";
import Store from "../../redux/Store";
import { set_message } from "../../redux/ActionCreators";
import GrayButton from "../../components/GrayButton";
const EditNotfication = ({
  data,
  set_notifications,
  Title,
  badge_title,
  color,
}) => {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
    console.log(badge_title);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        variant="outlined"
        onClick={handleOpen}
        color="secondary"
        style={{
          backgroundColor: "rgba(0, 126, 71, 0.1",
          borderRadius: 12,
          color: "#65819D",
          opacity: 1,
          border: "1px solid #D2D2D2",
        }}
        startIcon={<img src={Edit} alt="" />}
      >
        Edit
      </Button>
      <Formik
        validateOnMount
        enableReinitialize
        initialValues={{ subject: "", description: "" }}
        onSubmit={(values, props) => {
          update_notification(data.key, {
            ...values,
            isActive: data.isActive ? data.isActive : false,
          }).then((res) => {
            if (!res) return;
            set_notifications(res);
            props.resetForm();
            handleClose();
            Store.dispatch(
              set_message({
                mode: true,
                message: "Notification Updated",
                color: "success",
              })
            );
          });
        }}
      >
        {(formik) => {
          formik.initialValues.description = data.description;
          formik.initialValues.title = data.title;
          const check = () => {
            if (
              formik.values.description === "" ||
              formik.values.title === ""
            ) {
              return true;
            }
            return false;
          };
          return (
            <Form>
              <Dialog
                maxWidth="sm"
                fullWidth={true}
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
                onClick={(e) => e.stopPropagation()}
              >
                <Grid
                  xs={12}
                  container
                  item
                  spacing={3}
                  style={{ margin: "auto" }}
                  justifyContent="center"
                >
                  <Grid item xs={12} container alignItems="flex-end">
                    <Typography variant="h4">Edit Notification</Typography>{" "}
                    <Typography
                      variant="caption"
                      style={{
                        backgroundColor: color,
                        marginLeft: 8,
                        borderRadius: 4,
                        padding: "0 4px",
                      }}
                    >
                      {badge_title}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    container
                    spacing={2}
                    alignItems="center"
                    style={{ margin: "auto" }}
                  >
                    <Grid item xs={12}>
                      <Typography variant="body1" style={{ marginBottom: 8 }}>
                        Title
                      </Typography>
                      <TextFieldWrapper
                        name="title"
                        white={true}
                        placeholder="Type title..."
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body1" style={{ marginBottom: 8 }}>
                        Description
                      </Typography>
                      <TextFieldWrapper
                        name="description"
                        multiline
                        minRows={4}
                        white={true}
                        placeholder="Type description..."
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} container justifyContent="flex-end">
                    <Button
                      variant="outlined"
                      color="default"
                      style={{ borderRadius: 8 }}
                      onClick={handleClose}
                    >
                      Cancel
                    </Button>
                    <GrayButton
                      disabled={check()}
                      onClick={() => {
                        formik.submitForm();
                      }}
                      title={"Save"}
                    />
                    {/* <Button
                      variant="contained"
                      disabled={check()}
                      style={{
                        backgroundColor: !check()
                          ? "#000"
                          : "rgba(0, 0, 0, 0.1)",
                        color: !check()
                          ? theme.palette.primary.main
                          : "rgba(133, 133, 133, 1)",
                        marginLeft: 16,
                        borderRadius: 8,
                      }}
                  
                    >
                    </Button> */}
                  </Grid>
                </Grid>
              </Dialog>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default EditNotfication;
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Fade timeout={350} ref={ref} {...props} />;
});
