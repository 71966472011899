import React from 'react'

export const CompletedIcon = () => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.375 15.2889L10.9181 13.832L10.125 14.6252L12.375 16.8752L16.875 12.3752L16.0819 11.582L12.375 15.2889Z" fill="black" />
            <path d="M14.0625 2.8125H12.375V2.25C12.3741 1.95191 12.2553 1.66627 12.0445 1.45549C11.8337 1.2447 11.5481 1.12589 11.25 1.125H6.75C6.45191 1.12589 6.16627 1.2447 5.95549 1.45549C5.7447 1.66627 5.62589 1.95191 5.625 2.25V2.8125H3.9375C3.63941 2.81339 3.35377 2.9322 3.14299 3.14299C2.9322 3.35377 2.81339 3.63941 2.8125 3.9375V15.75C2.81339 16.0481 2.9322 16.3337 3.14299 16.5445C3.35377 16.7553 3.63941 16.8741 3.9375 16.875H9V15.75H3.9375V3.9375H5.625V5.625H12.375V3.9375H14.0625V10.125H15.1875V3.9375C15.1866 3.63941 15.0678 3.35377 14.857 3.14299C14.6462 2.9322 14.3606 2.81339 14.0625 2.8125ZM11.25 4.5H6.75V2.25H11.25V4.5Z" fill="black" />
        </svg>

    )
}