import * as yup from "yup";

export const initialValues = {
  profile: "",
  matching: "",
  answers: [],
  profileLimit: "",
  matchingLimit: "",
  required: null,
};
export const initialValuesEdit = {
  profile: "",
  matching: "",
  answers: [],
  profileLimit: "",
  matchingLimit: "",
  required: null,
};
export const validation = yup.object().shape({
  profile: yup.string().required(" This field is required"),
  matching: yup.string().required(" This field is required"),
  profileLimit: yup.string().required(" This field is required"),
  matchingLimit: yup.string().required(" This field is required"),
  required: yup.string().required(" This field is required").nullable(),
});
