import { Toolbar, useMediaQuery } from "@material-ui/core";

import React from "react";
import { styles } from "../styles/styles";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import Container from "@mui/material/Container";
const Layout = ({ children, loading }) => {
  let classes = styles();

  const [sideBarStatus, set_sideBarStatus] = React.useState(true);

  return (
    <div
      className={classes.LayoutRoot}
      style={{ overflow: "hidden", height: "100vh" }}
    >
      <Navbar />
      <Sidebar
        sideBarStatus={sideBarStatus}
        set_sideBarStatus={set_sideBarStatus}
      />
      <div style={{ overflow: "hidden" }} className={classes.LayoutChildren}>
        <Toolbar style={{ height: 65 }} />
        <div
          id="my"
          style={{
            width: "100%",
            height: "calc(100% - 65px)",
            padding: 16,
            overflow: "scroll",
          }}
        >
          {/* <Container sx={{ overflow: 'scroll' }}> */}
          {children}
          {/* </Container> */}
        </div>
      </div>
    </div>
  );
};

export default Layout;
export let useWidth = () => {
  let width;
  let sm = useMediaQuery("(min-width : 600px)");
  let md = useMediaQuery("(min-width : 900px)");
  let lg = useMediaQuery("(min-width : 1200px)");
  let xl = useMediaQuery("(min-width : 1500px)");
  if (xl) {
    width = "xl";
  } else if (lg) {
    width = "lg";
  } else if (md) {
    width = "md";
  } else if (md) {
    width = "md";
  } else if (sm) {
    width = "sm";
  }

  return { width };
};
