import React from "react";

export const SetAsFakeAccountIcon = () => {
  return (
    <svg
      width="17"
      height="12"
      viewBox="0 0 17 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.25 7.5C12.2525 7.5 16.25 8.4975 16.25 10.5V12H4.24997V10.5C4.24997 8.4975 8.24747 7.5 10.25 7.5ZM10.25 6C9.45432 6 8.69126 5.68393 8.12865 5.12132C7.56604 4.55871 7.24997 3.79565 7.24997 3C7.24997 2.20435 7.56604 1.44129 8.12865 0.87868C8.69126 0.31607 9.45432 0 10.25 0C11.0456 0 11.8087 0.31607 12.3713 0.87868C12.9339 1.44129 13.25 2.20435 13.25 3C13.25 3.79565 12.9339 4.55871 12.3713 5.12132C11.8087 5.68393 11.0456 6 10.25 6ZM2.74997 4.1925L4.33997 2.595L5.40497 3.66L3.80747 5.25L5.40497 6.84L4.33997 7.905L2.74997 6.3075L1.15997 7.905L0.0949707 6.84L1.69247 5.25L0.0949707 3.66L1.15997 2.595L2.74997 4.1925Z"
        fill="black"
      />
    </svg>
  );
};
