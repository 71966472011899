import {
    Grid,
    Tabs,
    Typography,
    Tab,
    Divider,
    Checkbox,
    Button,
    TableHead,
    Menu,
    MenuItem,
    // LinearProgress,
    // Collapse,
} from '@material-ui/core'
import { Done, ArrowDropDown, KeyboardArrowDown } from '@material-ui/icons'

import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import Paper from '@mui/material/Paper'
import moment from 'moment'

import React, { useEffect, useState } from 'react'
import {
    accept_requests,
    get_all_dropdowns,
    get_users_request,
} from '../../api/UserRequest_Api'
import { TabPanelContainer } from '../../components/TabPanel'
import { SearchTextField, SortButton } from '../../components/Textfields'
import { styles } from '../../styles/styles'
// import Tooltip from '@mui/material/Tooltip'
import Request from './Request'
import { Button as ButtonCore } from '@material-ui/core'
import { connect } from 'react-redux'
import NoData from '../../components/NoData'
import Reject from './Reject'
import InfiniteScroll from 'react-infinite-scroll-component'
import { CircularProgress, Stack } from '@mui/material'
import { WaitingList } from './WaitingList'
import { DateAscending } from '../../icons/DateAscending'
import { DateDescending } from '../../icons/DateDescending'
import icon from '../../asset/img/export.png'
import RestoreDeletedUsers from './DeletedUserTab'
const UserRequest = ({ search, loading, request_counter }) => {
    const [value, setValue] = useState('pending')
    const [initialLoading, setInitialLoading] = useState(false)
    const [data, set_data] = useState([])
    console.log('data', data)

    const [userReqList, setUserReqList] = useState([])
    const [page, set_page] = useState(1)
    const [counts, set_count] = useState({
        pending: 0,
        rejected: 0,
        waiting: 0,
        golden: 0,
        deleted: 0,
        upcoming: 0,
    })
    console.log('counts_counts', counts)
    const [sort, set_sort] = React.useState('createdAt')
    const [date_order, set_date_order] = React.useState(-1)
    // const [isSortingApplied, setIsSortingApplied] = useState(false)
    const [group_req, set_group_req] = useState([])
    const [checkPage, setCheckPage] = useState(false)

    const [Subscription, setSubscription] = useState([])
    const [anchorEl, setAnchorEl] = React.useState(null)
    const [currentSubscription, setCurrentSubscription] = useState('')
    const [deleteMuatation, setDeleteMutation] = useState({})
    const handleClickSubscription = (event) => {
        setAnchorEl(event.currentTarget)
    }
    const handleCloseSubscription = () => {
        setAnchorEl(null)
    }

    const pendingArr = [
        {
            name: 'All',
            value: '',
        },
        {
            name: 'Golden Ticket',
            value: 'golden',
        },
        {
            name: 'Community Member',
            value: 'community',
        },
        {
            name: 'Referred Five Friends',
            value: 'referredFive',
        },
    ]
    const [dropdowns, setDropdowns] = useState({
        zodiacDropdownOptions: [],
        sleepingHabbitsDropdownOptions: [],
        drinkingDropdownOptions: [],
        smokingDropdownOptions: [],
        relocateForLoveDropdownOptions: [],
        personalityDropdownValues: [],
        industryDropdownOptions: [],
        educationDropdownOptions: [],
        salaryDropdownOptions: [],
        lookingForDropdownOptions: [],
    })
    const style = styles()
    const handleChange = (event, newValue) => {
        if (value !== newValue) {
            setInitialLoading(true)
            setValue(newValue)
            // setIsSortingApplied(false)
        }
    }
    const getUserRequest = () => {
        if (checkPage) {
            set_group_req([])
            get_users_request(
                value,
                page,
                sort,
                date_order,
                currentSubscription
                // isSortingApplied
            )
                .then((res) => {
                    if (res) {
                        set_count(res.data.topBar)
                        if (value === 'upcoming') {
                            set_data(res.data.users)
                        } else {
                            set_data((prev) => prev.concat(res.data.users))
                        }
                    }
                    setInitialLoading(false)
                })
                .catch((err) => {
                    setInitialLoading(false)
                })
        } else {
            set_group_req([])
            get_users_request(
                value,
                page,
                sort,
                date_order,
                currentSubscription
                // isSortingApplied
            )
                .then((res) => {
                    if (res) {
                        set_count(res.data.topBar)
                        set_data(res.data.users)
                    }
                    setInitialLoading(false)
                })
                .catch((err) => {
                    setInitialLoading(false)
                })
        }
    }
    useEffect(() => {
        setCheckPage(false)
        set_page((prev) => 1)
        getUserRequest()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        search,
        deleteMuatation,
        // isSortingApplied,
        sort,
        date_order,
        currentSubscription,
    ])
    useEffect(() => {
        setCheckPage(false)
        set_page((prev) => 1)
        getUserRequest()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value])

    useEffect(() => {
        setUserReqList(data)
    }, [data])

    useEffect(() => {
        get_all_dropdowns()
            .then((res) => {
                const salaries = res?.find((d) => {
                    if (d.dropdownTitle === 'salary') {
                        return true
                    }
                })

                const industry = res?.find((d) => {
                    if (d.dropdownTitle === 'industry') {
                        return true
                    }
                })

                const education = res?.find((d) => {
                    if (d.dropdownTitle === 'education') {
                        return true
                    }
                })
                const smoke = res?.find((d) => {
                    if (d.dropdownTitle === 'smoking') {
                        return true
                    }
                })
                const drink = res?.find((d) => {
                    if (d.dropdownTitle === 'drinking') {
                        return true
                    }
                })
                const personalityType = res?.find((d) => {
                    if (d.dropdownTitle === 'personalityType') {
                        return true
                    }
                })
                const zodiac = res?.find((d) => {
                    if (d.dropdownTitle === 'zodiac') {
                        return true
                    }
                })
                const relocateForLove = res?.find((d) => {
                    if (d.dropdownTitle === 'relocateForLove') {
                        return true
                    }
                })
                const sleepingHabbits = res?.find((d) => {
                    if (d.dropdownTitle === 'sleepingHabits') {
                        return true
                    }
                })
                const looking = res?.find((d) => {
                    if (d.dropdownTitle === 'lookingFor') {
                        return true
                    }
                })
                setDropdowns(() => {
                    return {
                        drinkingDropdownOptions: drink?.optionList?.map(
                            (item) => item?.name
                        ),
                        educationDropdownOptions: education?.optionList?.map(
                            (item) => item?.name
                        ),
                        industryDropdownOptions: industry?.optionList?.map(
                            (item) => item?.name
                        ),
                        lookingForDropdownOptions: looking?.optionList?.map(
                            (item) => item?.name
                        ),
                        personalityDropdownValues:
                            personalityType?.optionList?.map(
                                (item) => item?.name
                            ),
                        relocateForLoveDropdownOptions:
                            relocateForLove?.optionList?.map(
                                (item) => item?.name
                            ),
                        salaryDropdownOptions: salaries?.optionList?.map(
                            (item) => item?.name
                        ),
                        sleepingHabbitsDropdownOptions:
                            sleepingHabbits?.optionList?.map(
                                (item) => item?.name
                            ),
                        smokingDropdownOptions: smoke?.optionList?.map(
                            (item) => item?.name
                        ),
                        zodiacDropdownOptions: zodiac?.optionList?.map(
                            (item) => item?.name
                        ),
                    }
                })

                console.log('relocated', relocateForLove?.optionList)
            })
            .catch((e) => console.log(e.message))
    }, [])
    // const handleChangeRowsPerPage = (event) => {
    //   setRowsPerPage(parseInt(event.target.value, 10));
    //   set_page(0);
    // };

    // useEffect(() => {
    //   const rejectedCount = data.filter((item) => item.status === "rejected");
    //   const pendingCount = data.filter((item) => item.status === "pending");
    //   rejectedCount.length &&
    //     set_count((prev) => ({ ...prev, rejected: ++prev.rejected }));
    //   console.log(pendingCount);
    //   pendingCount.length > 10 &&
    //     set_count((prev) => ({ ...prev, pending: --prev.rejected }));
    // }, [userReqList]);

    useEffect(() => {
        getUserRequest()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page])

    const routes = [
        {
            title: `Pending Actions (${counts.pending})`,
            value: 'pending',
        },

        {
            title: `Rejected (${counts.rejected})`,
            value: 'rejected',
        },
        { title: `Waiting List (${counts.waiting})`, value: 'waiting' },
        // { title: `Golden ticket (${counts.golden})`, value: 'golden' },
        { title: `Deleted Users List (${counts.deleted})`, value: 'deleted' },
        { title: `Upcoming (${counts.upcoming})`, value: 'upcoming' },
    ]

    const rowsPerPage = 10 // Fixed rows per page

    const handleChangePage = (event, newPage) => {
        setCheckPage(true)
        set_page(newPage + 1)
    }

    return (
        <Grid container alignItems="flex-start" style={{ height: '100%' }}>
            <Grid item xs={12} style={{ height: 80 }}>
                <Typography variant="h5">User Request</Typography>
                <Typography
                    style={{ top: 10, position: 'relative' }}
                    variant="body1"
                >
                    <b style={{ color: 'black', opacity: 1 }}>
                        {counts.pending}
                    </b>{' '}
                    request
                    {counts.pending > 1 && 's'} need action
                </Typography>
            </Grid>
            {/* ------------------------------------------------------------------------------------------tab section */}
            <Grid
                item
                // xs={12}
                // container
                justifyContent="space-between"
                alignItems="center"
                style={{
                    height: 48,
                    marginTop: 30,
                    display: 'flex',
                    flexDirection: 'row',
                }}
            >
                <Grid item>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        style={{ flex: 1 }}
                    >
                        {routes.map((item, i) => (
                            <Tab
                                key={i}
                                classes={{ wrapper: 'tabsIndicator' }}
                                label={item.title}
                                value={item.value}
                                title={
                                    item.value === 'upcoming'
                                        ? 'some data will be missing as user would not have been entered details yet'
                                        : ''
                                }
                                style={{
                                    fontSize: 16,
                                    textTransform: 'unset',
                                    fontFamily: 'interSemi',
                                    minWidth: 50,
                                    marginRight: 12,
                                    paddingRight: 0,
                                    paddingLeft: 0,
                                }}
                            />
                        ))}
                    </Tabs>
                </Grid>
                <div
                    style={{
                        width: 380,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                    }}
                >
                    {value !== 'upcoming' && (
                        <SearchTextField placeholder={'Search name...'} user />
                    )}
                    <SortButton
                        menu={[
                            {
                                title: 'Date ascending',
                                value: 1,
                                icon: <DateAscending />,
                            },
                            {
                                title: 'Date descending',
                                value: -1,
                                icon: <DateDescending />,
                            },
                        ]}
                        set_sort={(value) => {
                            set_date_order(value)
                            // setIsSortingApplied(true)
                        }}
                        sort={date_order}
                    />
                </div>
            </Grid>
            <Grid item xs={12}>
                <Divider />
            </Grid>
            {value === 'pending' && (
                <Grid
                    item
                    xs={12}
                    container
                    justifyContent="flex-end"
                    style={{ padding: '20px' }}
                    className={style.box}
                >
                    <Button
                        variant="outlined"
                        style={{
                            borderRadius: 8,
                            height: 40,
                            marginLeft: 16,
                            backgroundColor: '#FBFAFB',
                            position: 'relative',
                            color: '#005441',
                            borderColor: '#005441',
                            width: 300,
                        }}
                        endIcon={
                            <KeyboardArrowDown
                                style={{
                                    position: 'relative',
                                    top: 2,
                                    transform: `rotate(${
                                        anchorEl ? 180 : 0
                                    }deg)`,
                                    transition: '0.2s',
                                }}
                            />
                        }
                        onClick={handleClickSubscription}
                        startIcon={<img src={icon} alt="" />}
                    >
                        Pending
                    </Button>

                    <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        style={{
                            position: 'absolute',
                            top: 45,

                            width: 400,
                        }}
                        open={Boolean(anchorEl)}
                        onClose={handleCloseSubscription}
                    >
                        {pendingArr?.map((item, index) => {
                            return (
                                <MenuItem
                                    sx={{ fontWeight: '10px' }}
                                    style={{ width: 500 }}
                                    value={item?.value}
                                    onClick={(e) => {
                                        setCurrentSubscription(item?.value)
                                        handleCloseSubscription()
                                    }}
                                >
                                    {item?.name}
                                </MenuItem>
                            )
                        })}
                    </Menu>
                </Grid>
            )}

            <Grid item xs={12} style={{ minHeight: 'calc(100% - 150px)' }}>
                {value !== 'upcoming' &&
                    (value == 'deleted' ? (
                        <RestoreDeletedUsers
                            wating_list={group_req}
                            status={value}
                            set_data={set_data}
                            set_count={set_count}
                        />
                    ) : (
                        <Grid
                            justifyContent="space-between"
                            alignItems="center"
                            item
                            xs={12}
                            container
                            style={{ height: 100, padding: '0 12px' }}
                            className={style.box}
                        >
                            <Grid
                                item
                                container
                                alignItems="center"
                                style={{ width: 250 }}
                            >
                                <Checkbox
                                    style={{ color: 'black' }}
                                    checked={
                                        data.length !== 0 &&
                                        data.length === group_req.length
                                    }
                                    onChange={() => {
                                        if (group_req.length === 0) {
                                            data.forEach((item) => {
                                                set_group_req((pre) => [
                                                    ...pre,
                                                    {
                                                        name: item.name,
                                                        id: item._id,
                                                    },
                                                ])
                                            })
                                        } else {
                                            set_group_req([])
                                        }
                                    }}
                                />
                                <ArrowDropDown />
                                <Typography
                                    variant="subtitle2"
                                    style={{
                                        fontSize: 14,
                                        fontFamily: 'interSemi',

                                        opacity: 1,
                                    }}
                                >
                                    {group_req.length} item
                                    {group_req.length > 1 && 's'} selected
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                justifyContent="flex-end"
                                style={{ width: 550 }}
                                container
                            >
                                <>
                                    <Grid item>
                                        <WaitingList
                                            wating_list={group_req}
                                            status={value}
                                            set_data={set_data}
                                            set_count={set_count}
                                        />
                                        <Reject
                                            req_ids={group_req}
                                            set_Data={set_data}
                                            set_req={set_group_req}
                                            set_count={set_count}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            color="primary"
                                            disabled={group_req.length === 0}
                                            onClick={() => {
                                                let d = group_req.map(
                                                    (item) => item.id
                                                )
                                                accept_requests({
                                                    ids: d,
                                                }).then((res) => {
                                                    if (!res) return
                                                    set_data((pre) =>
                                                        pre.filter(
                                                            (item) =>
                                                                d.find(
                                                                    (i) =>
                                                                        pre._id !==
                                                                        i
                                                                ) === undefined
                                                        )
                                                    )

                                                    set_group_req([])
                                                })
                                            }}
                                            variant="contained"
                                            startIcon={<Done />}
                                            style={{
                                                borderRadius: 12,
                                                color: 'white',
                                                marginLeft: 16,
                                                backgroundColor:
                                                    group_req.length !== 0
                                                        ? '#65819D'
                                                        : 'rgba(0, 0, 0, 0.2)',
                                            }}
                                        >
                                            Accept
                                        </Button>
                                    </Grid>
                                </>
                            </Grid>
                        </Grid>
                    ))}

                {
                    value === 'upcoming' &&
                        // <TabPanelContainer value={value} index={value}>

                        (initialLoading ? (
                            <Stack
                                direction="row"
                                justifyContent="center"
                                p={10}
                            >
                                <CircularProgress />
                            </Stack>
                        ) : (
                            <>
                                <TableContainer component={Paper}>
                                    <Table>
                                        <TableHead>
                                            <TableCell>step</TableCell>
                                            <TableCell>mobileNumber</TableCell>
                                            <TableCell>email</TableCell>
                                            <TableCell>instagram</TableCell>
                                            <TableCell>Name</TableCell>
                                            <TableCell>lastActiveAt</TableCell>
                                            <TableCell>createdAt</TableCell>
                                            <TableCell>date of birth</TableCell>
                                            <TableCell>
                                                expire package
                                            </TableCell>
                                        </TableHead>
                                        <TableBody>
                                            {data.map((item, index) => (
                                                <TableRow key={index}>
                                                    <TableCell>
                                                        {item.step}
                                                    </TableCell>
                                                    <TableCell>
                                                        {item.mobileNumber}
                                                    </TableCell>
                                                    <TableCell>
                                                        {item.email}
                                                    </TableCell>
                                                    <TableCell>
                                                        {item.instagram}
                                                    </TableCell>
                                                    <TableCell>{`${item.firstName} ${item.lastName}`}</TableCell>
                                                    <TableCell>
                                                        {item.lastActiveAt
                                                            ? moment(
                                                                  item?.lastActiveAt
                                                              ).format(
                                                                  'DD.MM.YYYY'
                                                              )
                                                            : ''}
                                                    </TableCell>
                                                    <TableCell>
                                                        {item.createdAt
                                                            ? moment(
                                                                  item?.createdAt
                                                              ).format(
                                                                  'DD.MM.YYYY'
                                                              )
                                                            : ''}
                                                    </TableCell>
                                                    <TableCell>
                                                        {item.dateOfBirth
                                                            ? moment(
                                                                  item?.dateOfBirth
                                                              ).format(
                                                                  'DD.MM.YYYY'
                                                              )
                                                            : ''}
                                                    </TableCell>
                                                    <TableCell>
                                                        {item.expirePackage
                                                            ? moment(
                                                                  item?.expirePackage
                                                              ).format(
                                                                  'DD.MM.YYYY'
                                                              )
                                                            : ''}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>

                                <TablePagination
                                    component="div"
                                    count={counts.upcoming}
                                    page={page - 1}
                                    rowsPerPage={rowsPerPage}
                                    onPageChange={handleChangePage}
                                    rowsPerPageOptions={[rowsPerPage]} // Set the only available option to be the fixed rowsPerPage
                                />
                            </>
                        ))
                    // </TabPanelContainer>
                }
                {console.log('initialLoading', initialLoading)}
                {value !== 'upcoming' &&
                    (initialLoading ? (
                        <Stack direction="row" justifyContent="center" p={10}>
                            <CircularProgress />
                        </Stack>
                    ) : (
                        <TabPanelContainer value={value} index={value}>
                            <InfiniteScroll
                                dataLength={data.length}
                                scrollableTarget="my"
                                next={() => {
                                    setCheckPage(true)
                                    set_page((prev) => prev + 1)
                                }}
                                hasMore={data.length < counts[value]}
                                loader={
                                    <Stack
                                        direction="row"
                                        justifyContent="center"
                                        p={1}
                                    >
                                        <CircularProgress />
                                    </Stack>
                                }
                                endMessage={
                                    data.length > 0 && (
                                        <p style={{ textAlign: 'center' }}>
                                            <b>End of request</b>
                                        </p>
                                    )
                                }
                            >
                                {console.log(
                                    'data.length',
                                    data.length,
                                    loading
                                )}

                                {data.length !== 0 // && !loading
                                    ? userReqList
                                          // .filter((item) => item.status === "pending")
                                          .map((item, i) => (
                                              <Request
                                                  setDeleteMutation={
                                                      setDeleteMutation
                                                  }
                                                  dropdowns={dropdowns}
                                                  key={i}
                                                  data={item}
                                                  set_group_req={set_group_req}
                                                  group_req={group_req}
                                                  set_data={set_data}
                                                  set_count={set_count}
                                                  tab_value={value}
                                              />
                                          ))
                                    : !loading && <NoData tab_value={value} />}
                            </InfiniteScroll>
                        </TabPanelContainer>
                    ))}

                {/* <TabPanelContainer value={value} index={value}>
          {data.length !== 0 // &&!loading
            ? userReqList
                // .filter((item) => item.status === value)
                .map((item, i) => (
                  <Request
                    key={i}
                    data={item}
                    set_group_req={set_group_req}
                    group_req={group_req}
                    set_count={set_count}
                    set_data={set_data}
                    tab_value={value}
                  />
                ))
            : !loading && <NoData />}
        </TabPanelContainer> */}
            </Grid>
        </Grid>
    )
}

const mapStateToProps = ({ GeneralReducer }) => {
    const { search, loading, request_counter } = GeneralReducer

    return { search, loading, request_counter }
}

export default connect(mapStateToProps)(UserRequest)
