import { useState, useEffect } from 'react'
import { Grid, Typography } from '@material-ui/core'

import { styles } from '../../../styles/styles'
import { useForm } from 'react-hook-form'
import { Button as ButtonCore } from '@material-ui/core'
import AddQuizComponent from './AddQuizComponent'
import {
    getQuizDetailsById,
    updateQuizDetailsById,
} from '../../../api/Quiz_Module_Api'
import Store from '../../../redux/Store'
import { set_message } from '../../../redux/ActionCreators'
import { useParams } from 'react-router-dom'
import { useHistory } from 'react-router-dom/cjs/react-router-dom'
import AlertDialog from './AlertDialog'
const EditHubQuiz = () => {
    const [open, setOpen] = useState(false)
    const [disabledButton, setDisabledButton] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const form = useForm()
    const history = useHistory()
    const [rows, setRows] = useState([])
    console.log('rows11', rows)
    const {
        register,
        setValue,
        watch,
        control,
        handleSubmit,
        reset,

        formState: { errors },
    } = form
    const params = useParams()
    const style = styles()
    const callbackDeactivateHubAndQuiz = () => {
        setDisabledButton(true)
        onSubmit(form.getValues(), null, true)
    }
    const onSubmit = (data, events, isDeactivateHubAndQuiz) => {
        let isError = false,
            errorMessage = ''
        if (!data?.title) {
            isError = true
            errorMessage = 'Title is required'
        }
        if ((!data?.status || data?.status === '0') && isError === false) {
            isError = true
            errorMessage = 'Status is required'
        }
        if (rows?.length === 0 && isError === false) {
            isError = true
            errorMessage = 'Questions are required'
        }
        if (isError === false) {
            rows.forEach((element) => {
                if (element.question == '') {
                    isError = true
                    errorMessage = 'Question is required'
                    return
                }
                if (element.question_type == '') {
                    isError = true
                    errorMessage = 'Question type is required'
                    return
                }
                if (
                    element.options?.length === 0 &&
                    element.question_type === 'multi_select'
                ) {
                    isError = true
                    errorMessage = 'Options are required'
                    return
                }
                if (
                    element.options?.length === 0 &&
                    element.question_type === 'single_select'
                ) {
                    isError = true
                    errorMessage = 'Options are required'
                    return
                }
                console.log('element', element)
            })
        }
        if (isError) {
            Store.dispatch(
                set_message({
                    mode: true,
                    message: errorMessage,
                    color: 'error',
                })
            )
            return true
        }

        data.questions = rows
        data.isHubQuiz = true

        updateQuizDetailsById(params.id, data, isDeactivateHubAndQuiz)
            .then((res) => {
                setDisabledButton(false)
                setOpen(false)
                if (res?.data?.data?.error) {
                    setOpen(true)
                    setErrorMessage(res?.data?.data?.error)
                } else {
                    history.push('/quiz')
                }
            })
            .catch((err) => {
                setDisabledButton(false)
            })
    }
    console.log('res_data_info', form.getValues())
    useEffect(() => {
        getQuizDetailsById(params.id)
            .then((res) => {
                const quizDetails = res?.data

                const quizDetailsObj = {
                    title: quizDetails?.title,
                    category: quizDetails?.type,
                    instructions: quizDetails.help_text,
                    module_name: 'HUB_QUIZ',
                    status: quizDetails?.active_status ? 'Active' : 'In-active',
                    prompt:
                        quizDetails?.prompt ??
                        `{{question_answer}} The user has completed the {{quiz_title}} quiz. Based on their answers, summarize whether they and their partner made and responded to bids for connection, such as attention, affection, and emotional support. Highlight any questions where the user selected "Disagree" or "Strongly Disagree" to indicate areas where bids were missed or rejected. Provide personalized feedback on how they can improve their responsiveness to bids in future relationships.
                    Please summarize the responses and highlight key points.
                    
                    Example Report Output:
                Personalized Report: Reflecting on Your Past Dating Choices
                Summary of Your Past Dating History
                
                Based on your answers, you’ve reflected on the health of your past relationships and the types of dating choices you’ve made. Understanding your dating history can help you identify patterns—both positive and negative—that influence the quality of your relationships. Below, we’ll explore areas where you’ve made healthy choices and areas where you might need to consider a different approach.
                
                Key Insights Based on Your Responses:
                
                    1. Emotional Availability of Partners:
                
                        • You indicated that your past partners may not always have been emotionally available or communicative. This pattern can lead to frustration and feelings of disconnection in relationships.
                        • Tip: In future relationships, focus on choosing partners who are open, communicative, and emotionally available. Look for someone who actively engages in conversations about feelings and is willing to support you emotionally.
                    2. Repeating Unhealthy Patterns:
                
                        • Your responses suggest that you may have a tendency to repeat unhealthy patterns in your relationships, such as staying with partners who are not right for you or not addressing red flags early on.
                        • Tip: Reflect on the patterns that have been present in your past relationships. Consider setting clear boundaries and identifying deal-breakers early on in future relationships to avoid repeating the same mistakes.
                
                    3. Security and Confidence:
                
                        • You mentioned that you may not always have felt secure or confident in your past relationships. A lack of emotional security can create anxiety and instability in a relationship.
                        • Tip: In future relationships, prioritize building trust and security with your partner. Communicate openly about your needs and work on developing mutual respect and emotional safety.
                
                Highlighted Areas for Growth:
                
                    • Choose Emotionally Available Partners: You reflected that some of your past partners were emotionally unavailable. Focus on dating individuals who are open, communicative, and supportive, as this will lead to healthier and more fulfilling relationships.
                
                    • Break Unhealthy Patterns: You mentioned that you may have repeated unhealthy patterns in your dating history. Reflect on why these patterns may have persisted and consider changing your approach in future relationships to prioritize your emotional well-being.
                
                Personalized Actionable Insights:
                
                    1. Set Clear Boundaries:
                        • One of the most important steps in making healthier dating choices is setting clear boundaries about what you will and won’t tolerate in a relationship. Being clear about your needs from the start will help you avoid partners who don’t meet them.
                        • Action: Before entering a new relationship, write down your personal boundaries and deal-breakers. Communicate these clearly with potential partners.
                
                    2. Focus on Emotional Availability:
                        • Emotional availability is key to a successful relationship. Make it a priority to seek partners who are willing to share their feelings and be present emotionally.
                        • Action: When dating, ask questions that reveal how open a potential partner is to discussing their feelings, such as “How do you usually handle tough emotional situations?” This will help you gauge their emotional availability.
                
                    3. Learn from Past Relationships:
                        • Reflect on the positive and negative aspects of your past relationships. Take note of what worked well and what didn’t so that you can make better choices in the future.
                        • Action: Make a list of the qualities you want in a future partner based on what you’ve learned from past relationships. Use this list as a guide when evaluating potential partners.
                
                Next Steps:
                
                    • Prioritize emotional health: Focus on dating individuals who are emotionally available and communicative. This will lead to stronger, healthier relationships.
                    • Break negative cycles: Reflect on the patterns that have repeated in your past relationships and make a conscious effort to break them in future relationships.
                    • Set boundaries early: Clearly define your boundaries and communicate them with potential partners to avoid entering unhealthy relationships.
                
                This Reflecting on Your Past Dating Choices quiz encourages users to assess the health of their past relationships and consider how they can make better dating choices moving forward.
                    `,
                }
                for (const field in quizDetailsObj) {
                    setValue(field, quizDetailsObj[field])
                }
                setRows(
                    quizDetails?.questions?.map((item) => ({
                        id: item?._id,
                        question: item?.question_title,
                        options: item?.question_option_list,
                        question_type: item?.ui_control_type,
                    }))
                )
            })

            .catch((err) => {})
    }, [])

    return (
        <Grid container>
            {open && (
                <AlertDialog
                    open={open}
                    setOpen={(value) => setOpen(value)}
                    callbackDeactivateHubAndQuiz={() =>
                        callbackDeactivateHubAndQuiz()
                    }
                    disabledButton={disabledButton}
                    message={errorMessage}
                />
            )}
            <Grid item xs={12}>
                <AddQuizComponent
                    setRows={setRows}
                    rows={rows}
                    register={register}
                    control={control}
                />
            </Grid>
            <Grid
                item
                xs={12}
                container
                justifyContent="flex-end"
                style={{ padding: '20px' }}
                className={style.box}
            >
                <ButtonCore
                    variant="outlined"
                    color="default"
                    style={{ borderRadius: 8 }}
                    onClick={() => {
                        reset()
                    }}
                >
                    clear
                </ButtonCore>
                <ButtonCore
                    variant="contained"
                    style={{
                        borderRadius: 8,
                        background: '#BA5A6B',
                        color: '#FFFFFF',
                        marginLeft: '8px',
                    }}
                    onClick={handleSubmit(onSubmit)}
                >
                    Submit
                </ButtonCore>
            </Grid>
        </Grid>
    )
}

export default EditHubQuiz
