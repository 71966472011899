import { Grid } from "@material-ui/core";
import moment from "moment";
import React from "react";
import { Bar } from "react-chartjs-2";

export const options = {
  responsive: true,
  title: false,
  scales: {
    y: {
      beginAtZero: true,
      ticks: {
        format: {
          style: "percent",
        },
        stepSize: 0.25,
      },
    },
  },
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      callbacks: {
        label: function (context) {
          let label = context.dataset.label || "";

          if (label) {
            label += ": ";
          }
          if (context.parsed.y !== null) {
            label += `${(context.parsed.y * 100).toFixed(2)} %`;
          }
          return label;
        },
      },
    },
  },
};

const BarChart = ({ chart_data }) => {
  const data = {
    labels: chart_data?.map((item) => item.title),
    datasets: [
      {
        label: "Male",
        data: chart_data?.map((item) => item.male / item.total),
        backgroundColor: "#65819D",
      },
      {
        label: "Female",
        data: chart_data?.map((item) => item.female / item.total),
        backgroundColor: "#E4B9B9",
      },
      {
        label: "Other",
        data: chart_data?.map(
          (item) => (item.total - item.male - item.female) / item.total
        ),
        backgroundColor: "#BA5A6B",
      },
    ],
  };

  return (
    <Grid
      item
      xs={12}
      container
      direction="row"
      alignItems="flex-end"
      style={{ height: "100%" }}
    >
      <Bar options={options} data={data} height={"100%"} />
    </Grid>
  );
};

export default BarChart;
