import React, { useEffect, useState } from 'react'
import {
    Button,
    Divider,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
} from '@material-ui/core'
import { KeyboardArrowDown } from '@material-ui/icons'
import { FakeAccountOutlineIcon } from '../icons/FakeAccountOutlineIcon'
import { BlockedOutlineIcon } from '../icons/BlockedOutlineIcon'
import { GoldenTicketOutlineIcon } from '../icons/GoldenTicketOutlineIcon'
import { MatchmakeMeOutlineIcon } from '../icons/MatchmakeMeOutlineIcon'
import { FounderOutlineIcon } from '../icons/FounderOutlineIcon'
import { Done, DoneAll } from '@material-ui/icons'
import { DeletedAccountIcon } from '../icons/DeletedAccountIcon'
import { CompletedIcon } from '../icons/completedIcon'
import { Typography } from '@mui/material'
// import { MoneyIcon } from '../icons/MoneyIcon'
export const StatusFilter = (props) => {
    const [statusItemsList, setStatusItemsList] = useState([
        {
            title: 'Founder',
            icon: <FounderOutlineIcon />,
            checked: true,
            value: 'Founder',
        },
        // {
        //     title: 'Leveling',
        //     icon: <MoneyIcon />,
        //     checked: true,
        //     value: 'leveling',
        // },
        {
            title: 'Fake Account',
            icon: <FakeAccountOutlineIcon />,
            checked: true,
            value: 'Fake',
        },
        {
            title: 'Blocked',
            icon: <BlockedOutlineIcon />,
            checked: false,
            value: 'Blocked',
        },
        {
            title: 'Golden Ticket',
            icon: <GoldenTicketOutlineIcon />,
            checked: true,
            value: 'Golden',
        },
        {
            title: 'Matchmake Me',
            icon: <MatchmakeMeOutlineIcon />,
            checked: true,
            value: 'Matchmake',
        },
        {
            title: 'Completed',
            icon: <CompletedIcon />,
            checked: true,
            value: 'Completed',
        },
        {
            title: 'Deleted account',
            icon: <DeletedAccountIcon />,
            checked: false,
            value: 'Deleted',
        },
    ])

    const [anchorEl, setAnchorEl] = React.useState(null)

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }
    useEffect(() => {
        const temp = statusItemsList
            .filter((item) => item.checked === true)
            .map((item) => item.value)
            .join(',')
        props.setSelectedItems(temp)
    }, [statusItemsList])
    return (
        <>
            <Button
                variant="outlined"
                style={{
                    borderRadius: 8,
                    height: 40,
                    marginLeft: 16,
                    backgroundColor: '#FBFAFB',
                    position: 'relative',
                    color: '#005441',
                    borderColor: '#005441',
                }}
                endIcon={
                    <KeyboardArrowDown
                        style={{
                            position: 'relative',
                            top: 2,
                            transform: `rotate(${anchorEl ? 180 : 0}deg)`,
                            transition: '0.2s',
                        }}
                    />
                }
                onClick={handleClick}
            >
                Status
            </Button>

            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                // keepMounted
                // style={{ position: "absolute", top: 80, borderRadius: '10px' }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                elevation={0}
                PaperProps={{
                    style: {
                        boxShadow: '0px 2px 12px rgba(205, 205, 205, 0.7)',
                        border: '1px solid rgba(0, 0, 0, 0.08)',
                        borderRadius: 10,
                        padding: '0px 16px 0px 16px',
                        opacity: 1,
                    },
                }}
                style={{
                    position: 'absolute',
                    top: 60,
                    // left: -50,
                    opacity: 1,
                }}
            >
                <MenuItem style={{ paddingLeft: 0, opacity: 1 }} button={false}>
                    <ListItemText>Status</ListItemText>
                </MenuItem>
                <Divider />
                <MenuItem
                    style={{ paddingLeft: 0, paddingRight: 0, opacity: 1 }}
                    button={false}
                    onClick={() => {
                        setStatusItemsList((prev) => {
                            return prev.filter((c) => c.checked === false)
                                .length === prev.length ||
                                prev.filter((c) => c.checked === true)
                                    .length === prev.length
                                ? prev.map((p) => {
                                      return { ...p, checked: !p.checked }
                                  })
                                : prev.map((p) => {
                                      return { ...p, checked: false }
                                  })
                        })
                    }}
                >
                    <Button
                        style={{
                            width: '180px',
                            justifyContent: 'flex-start',
                        }}
                        startIcon={<DoneAll />}
                    >
                        <Typography
                            style={{
                                fontWeight: 400,
                                fontFamily: "'Inter'",
                                fontStyle: 'normal',

                                fontSize: '14px',
                                lineHeight: '17px',
                                color: '#0E0F1A',
                            }}
                        >
                            All
                        </Typography>
                    </Button>
                </MenuItem>
                {statusItemsList.map((item, index) => (
                    <MenuItem
                        style={{ paddingLeft: 0, paddingRight: 0, opacity: 1 }}
                        button={false}
                        onClick={() => {
                            setStatusItemsList((prev) =>
                                prev.map((p) => {
                                    if (p.value === item.value)
                                        return { ...p, checked: !item.checked }
                                    else return p
                                })
                            )
                            handleClose()
                        }}

                        // value={item.value}
                    >
                        <Button
                            style={{
                                width: '180px',
                                justifyContent: 'flex-start',
                            }}
                            startIcon={item.icon}
                            // endIcon={item.checked ? <Done /> : null}
                        >
                            <Typography
                                style={{
                                    fontWeight: item.checked ? 700 : 400,
                                    fontFamily: "'Inter'",
                                    fontStyle: 'normal',

                                    fontSize: '14px',
                                    lineHeight: '17px',
                                    color: '#0E0F1A',
                                }}
                            >
                                {item.title}
                            </Typography>
                        </Button>

                        <ListItemIcon
                            style={{ justifyContent: 'flex-end', padding: 0 }}
                        >
                            {item.checked ? <Done /> : null}
                        </ListItemIcon>
                    </MenuItem>
                ))}
            </Menu>
        </>
    )
}
