import { Grid, IconButton, Typography } from "@material-ui/core";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@material-ui/icons";
import React from "react";
import { connect } from "react-redux";
import { page_change } from "../../redux/ActionCreators";
import Store from "../../redux/Store";

const Pagination = ({ pagination, data, loading }) => {
  return (
    <Grid container justifyContent="flex-end" alignItems="center">
      {pagination.total / pagination.page >= 100 ? (
        <Typography
          variant="body2"
          style={{ fontSize: 14, fontFamily: "inter", fontWeight: 400 }}
        >
          {pagination.page === 1
            ? pagination.page
            : ((pagination.page - 1) * data)+1}
          -{pagination.page * data} of {pagination.total}
        </Typography>
      ) : (
        <Typography
          variant="body2"
          style={{ fontSize: 14, fontFamily: "inter", fontWeight: 400 }}
        >
          {pagination.total - data}-{pagination.total} of {pagination.total}
        </Typography>
      )}
      <IconButton
        disabled={pagination.page === 1 || loading}
        onClick={() =>
          Store.dispatch(
            page_change({ ...pagination, page: pagination.page - 1 })
          )
        }
      >
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        disabled={
          pagination.total / pagination.page < pagination.limit || loading
        }
        onClick={() =>
          Store.dispatch(
            page_change({ ...pagination, page: pagination.page + 1 })
          )
        }
      >
        <KeyboardArrowRight />
      </IconButton>
    </Grid>
  );
};

const mapStateToProps = ({ GeneralReducer }) => {
  const { pagination, loading } = GeneralReducer;

  return { pagination, loading };
};

export default connect(mapStateToProps)(Pagination);
