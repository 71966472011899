import React from 'react'
import { Grid } from '@material-ui/core'

import { styles } from '../../styles/styles'
import ViewUserImage from './ViewUserImage'

import UploadUserImage from './UploadUserImage'

const UserImageUploadSection = ({
    images = [],
    setUserImages,
    blobImageLocalURL,
    setBlobImageLocalURL,
}) => {
    return (
        <>
            {blobImageLocalURL ? (
                <Grid xs={3}>
                    <ViewUserImage
                        setUserImages={setUserImages}
                        setBlobImageLocalURL={setBlobImageLocalURL}
                        imageURL={blobImageLocalURL}
                    />
                </Grid>
            ) : (
                <Grid
                    spacing={2}
                    alignItems="center"
                    style={{
                        border: '2px solid #05060fb3',
                        borderRadius: '10px',
                        maxWidth: '15.333333%',
                        padding: '20px',
                        height: '242.25px',
                    }}
                >
                    <UploadUserImage
                        setUserImages={setUserImages}
                        setBlobImageLocalURL={setBlobImageLocalURL}
                    />
                </Grid>
            )}
        </>
    )
}

export default UserImageUploadSection
