import {
    Button,
    CircularProgress,
    Divider,
    Grid,
    IconButton,
    InputAdornment,
    Typography,
} from '@material-ui/core'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import { Form, Formik } from 'formik'
import React, { useState } from 'react'
import { get_account } from '../../api/HelpCenter_Api'
import {
    delete_admins,
    get_admins,
    update_account,
} from '../../api/Setting_Api'
import { TextFieldWrapper } from '../../components/Textfields'
import { styles } from '../../styles/styles'
import { validation } from '../../validations/EditAccoutSettings_validation'
import AddNewAdmin from './AddNewAdmin'

import deleteAdmin from '../../asset/img/deleteAdmin.png'
import EditAdmin from './EditAdmin'
import Store from '../../redux/Store'
import { set_Login } from '../../redux/ActionCreators'
import { UpdateOwnerAdmininitialValues } from '../../validations/add_Admin_validation'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
const AccountSetting = ({ loading }) => {
    const [showPassNew, setShowPassNew] = useState(true)
    const [showPassConf, setShowPassConf] = useState(true)
    const [data, set_data] = React.useState({})
    const [admins, set_admins] = React.useState([])
    const history = useHistory()
    React.useEffect(() => {
        get_account().then((res) => {
            if (!res) return
            set_data(res.data.user)
        })
    }, [])
    React.useEffect(() => {
        if (data.type === 'superadmin') {
            get_admins().then((res) => {
                set_admins(res.users)
            })
        }
    }, [data])
    const style = styles()

    return (
        <Formik
            // enableReinitialize
            validationSchema={validation}
            onSubmit={(values, props) => {
                update_account({
                    email: values.emaill,
                    password: values.passwordd,
                    name: values.name,
                }).then((res) => {
                    if (!res) return

                    localStorage.userInfo = JSON.stringify(res.data.user)
                    set_data(res.data.user)
                    Store.dispatch(
                        set_Login(!Store.getState().GeneralReducer.isAuth)
                    )
                    props.resetForm()
                })
            }}
            validateOnMount
            initialValues={UpdateOwnerAdmininitialValues}
        >
            {(formik) => {
                if (Object.keys(data).length !== 0) {
                    formik.initialValues.name = data.name
                    formik.initialValues.emaill = data.email
                    // formik.initialValues.name = data.name;
                }

                return (
                    <Form>
                        <Grid container>
                            {/* -------------------------------------------------------------------------------------------------------------- change password admin */}
                            <Grid
                                item
                                container
                                md={7}
                                style={{ paddingRight: 24 }}
                            >
                                <Grid
                                    item
                                    container
                                    xs={12}
                                    className={style.box}
                                    style={{ padding: 24 }}
                                >
                                    <Grid
                                        item
                                        container
                                        justifyContent="space-between"
                                        alignItems="center"
                                    >
                                        <Grid item xs={8}>
                                            <Typography variant="h4">
                                                Account Settings
                                            </Typography>
                                            <Typography
                                                variant="body1"
                                                style={{ margin: '8px 0' }}
                                            >
                                                You can modify your account
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        style={{ margin: '16px 0 24px 0' }}
                                    >
                                        <Divider />
                                    </Grid>
                                    <Grid
                                        item
                                        container
                                        style={{ margin: '16px 0 16px 0' }}
                                    >
                                        <Grid item xs={12}>
                                            <Typography variant="body2">
                                                Profile information
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} container>
                                        <Typography
                                            variant="body1"
                                            style={{ margin: '16px 0 8px 0' }}
                                        >
                                            Full name
                                        </Typography>
                                        <TextFieldWrapper name="name" />
                                    </Grid>
                                    <Grid item xs={12} container>
                                        <Typography
                                            variant="body1"
                                            style={{ margin: '16px 0 8px 0' }}
                                        >
                                            Email
                                        </Typography>
                                        <TextFieldWrapper name="emaill" />
                                    </Grid>
                                    <Grid
                                        item
                                        container
                                        style={{ margin: '16px 0 16px 0' }}
                                    >
                                        <Grid
                                            item
                                            xs={12}
                                            style={{ marginTop: 24 }}
                                        >
                                            <Typography variant="body2">
                                                Update your password
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        container
                                        style={{ margin: '16px 0 8px 0' }}
                                    >
                                        <Typography
                                            variant="body1"
                                            style={{ margin: '0 0 8px 0' }}
                                        >
                                            New password
                                        </Typography>
                                        <TextFieldWrapper
                                            name="passwordd"
                                            white
                                            type={
                                                showPassNew
                                                    ? 'password'
                                                    : 'text'
                                            }
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            onClick={(e) => {
                                                                setShowPassNew(
                                                                    !showPassNew
                                                                )
                                                                // e.stopPropagation();
                                                            }}
                                                        >
                                                            {!showPassConf ? (
                                                                <VisibilityOff
                                                                    color="action"
                                                                    fontSize="small"
                                                                    style={{
                                                                        cursor: 'pointer',
                                                                    }}
                                                                />
                                                            ) : (
                                                                <Visibility
                                                                    color="action"
                                                                    fontSize="small"
                                                                    style={{
                                                                        cursor: 'pointer',
                                                                    }}
                                                                />
                                                            )}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        container
                                        style={{ margin: '8px 0 8px 0' }}
                                    >
                                        <Typography
                                            variant="body1"
                                            style={{ margin: '0 0 8px 0' }}
                                        >
                                            Confirm New password
                                        </Typography>
                                        <TextFieldWrapper
                                            name="confirm_password"
                                            type={
                                                showPassConf
                                                    ? 'password'
                                                    : 'text'
                                            }
                                            white
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            onClick={(e) => {
                                                                setShowPassConf(
                                                                    !showPassConf
                                                                )
                                                                // e.stopPropagation();
                                                            }}
                                                        >
                                                            {!showPassConf ? (
                                                                <VisibilityOff
                                                                    color="action"
                                                                    fontSize="small"
                                                                    style={{
                                                                        cursor: 'pointer',
                                                                    }}
                                                                />
                                                            ) : (
                                                                <Visibility
                                                                    color="action"
                                                                    fontSize="small"
                                                                    style={{
                                                                        cursor: 'pointer',
                                                                    }}
                                                                />
                                                            )}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Grid>

                                    <Grid
                                        item
                                        xs={12}
                                        container
                                        justifyContent="flex-end"
                                        style={{ marginTop: 32 }}
                                    >
                                        <Button
                                            onClick={() => {
                                                history.goBack()
                                            }}
                                            variant="outlined"
                                            style={{ borderRadius: 8 }}
                                            color="default"
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            variant="contained"
                                            type="submit"
                                            disabled={
                                                !formik.isValid || loading
                                            }
                                            style={{
                                                backgroundColor: formik.isValid
                                                    ? '#65819D'
                                                    : 'rgba(0, 0, 0, 0.1)',
                                                color: formik.isValid
                                                    ? 'white'
                                                    : 'rgba(133, 133, 133, 1)',
                                                marginLeft: 16,
                                                borderRadius: 8,
                                                width: 135,
                                            }}
                                        >
                                            {!loading ? (
                                                '  Save Changes'
                                            ) : (
                                                <CircularProgress
                                                    style={{
                                                        color: 'white',
                                                        width: 22,
                                                        height: 22,
                                                    }}
                                                />
                                            )}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {/* --------------------------------------------------------------------------------------------------------------adming list  */}

                            <Grid
                                item
                                xs={12}
                                md={5}
                                className={style.box}
                                style={{ padding: 24 }}
                            >
                                {/* --------------------------------------------------------------------------------------------------------------title and add admin section   */}
                                <Grid
                                    container
                                    justifyContent="space-between"
                                    alignItems="flex-start"
                                    style={{ height: 100 }}
                                >
                                    <Typography variant="h4">
                                        Admin list
                                    </Typography>
                                    <Grid item>
                                        <AddNewAdmin set_data={set_admins} />
                                    </Grid>
                                    {/* --------------------------------------------------------------------------------------------------------------title of the admin section   */}
                                </Grid>

                                <Grid item container style={{}}>
                                    <Grid item xs={12}>
                                        <Grid
                                            item
                                            xs={12}
                                            container
                                            alignItems="center"
                                        >
                                            <Typography variant="body2">
                                                {data?.name}
                                            </Typography>
                                            <Typography
                                                style={{
                                                    marginLeft: 40,
                                                    backgroundColor: '#E8E8E8',
                                                    padding: '2px 8px',
                                                    borderRadius: 4,
                                                }}
                                            >
                                                You
                                            </Typography>
                                        </Grid>

                                        <Typography variant="subtitle2">
                                            {data?.email}
                                        </Typography>
                                    </Grid>
                                </Grid>

                                <Grid
                                    item
                                    container
                                    style={{ maxHeight: 500, overflow: 'auto' }}
                                >
                                    {admins.map((item, i) => {
                                        return (
                                            <React.Fragment key={i}>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    style={{ margin: '16px 0' }}
                                                >
                                                    <Divider />
                                                </Grid>
                                                <Grid
                                                    item
                                                    container
                                                    alignItems="center"
                                                    justifyContent="space-between"
                                                >
                                                    <Grid item xs={6}>
                                                        <Typography variant="body2">
                                                            {item?.name}
                                                        </Typography>
                                                        <Typography variant="subtitle2">
                                                            {item?.email}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        container
                                                        style={{ width: 180 }}
                                                    >
                                                        <Grid
                                                            container
                                                            style={{
                                                                width: 70,
                                                            }}
                                                            alignItems="center"
                                                            item
                                                        >
                                                            <EditAdmin
                                                                set_data={
                                                                    set_admins
                                                                }
                                                                data={item}
                                                            />
                                                        </Grid>
                                                        <Grid
                                                            container
                                                            style={{
                                                                width: 90,
                                                            }}
                                                            alignItems="center"
                                                            item
                                                        >
                                                            <Button
                                                                startIcon={
                                                                    <img
                                                                        src={
                                                                            deleteAdmin
                                                                        }
                                                                        height={
                                                                            15
                                                                        }
                                                                        width={
                                                                            15
                                                                        }
                                                                        alt=""
                                                                    />
                                                                }
                                                                onClick={() => {
                                                                    delete_admins(
                                                                        item._id
                                                                    ).then(
                                                                        (
                                                                            res
                                                                        ) => {
                                                                            if (
                                                                                !res
                                                                            )
                                                                                return
                                                                            set_admins(
                                                                                (
                                                                                    pre
                                                                                ) =>
                                                                                    pre.filter(
                                                                                        (
                                                                                            p
                                                                                        ) =>
                                                                                            p._id !==
                                                                                            item._id
                                                                                    )
                                                                            )
                                                                        }
                                                                    )
                                                                }}
                                                            >
                                                                Delete
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </React.Fragment>
                                        )
                                    })}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Form>
                )
            }}
        </Formik>
    )
}

const mapStateToProps = (props) => {
    const { GeneralReducer } = props

    const { loading, search } = GeneralReducer

    return { loading, search }
}

export default connect(mapStateToProps)(AccountSetting)
