import { Slider, Typography } from '@material-ui/core'
import { FormLabel, Grid, Stack } from '@mui/material'
import React from 'react'

const Range = ({ item2, matchMakeMeDataObj, handleChangeQuestionnair }) => {
    return (
        <Grid container item xs={6}>
            <Grid item xs={12}>
                <Stack direction="column">
                    <FormLabel
                        style={{
                            marginBottom: '5px',
                        }}
                        htmlFor="first-name"
                    >
                        {/* {item2?.sequence}.  */}
                        {item2?.question_title}
                    </FormLabel>
                    {/* <Typography
                        variant="body1"
                        style={{ fontSize: 14, marginBottom: '5px' }}
                        color="textSecondary"
                    >
                        <b>Subtext</b>:
                        {item2?.question_subtext
                            ? item2?.question_subtext
                            : ' NA'}
                    </Typography> */}
                    {matchMakeMeDataObj[item2?._id]
                        ? +matchMakeMeDataObj[item2?._id]
                        : 1}
                    <Slider
                        id={item2?._id}
                        value={+matchMakeMeDataObj[item2?._id]}
                        onChange={(event, newValue) => {
                            console.log('newValue', event.target.id, newValue)

                            const id = event.target.id

                            const value = newValue

                            handleChangeQuestionnair(id, value)
                        }}
                        min={1}
                        max={10}
                        aria-label="Default"
                        valueLabelDisplay="auto"
                    />

                    {item2?.question_subtext ? (
                        <Typography
                            variant="body1"
                            style={{ fontSize: 14, marginTop: '4px' }}
                            color="textSecondary"
                        >
                            <b>Note</b>: <i>{item2?.question_subtext}</i>
                        </Typography>
                    ) : (
                        ''
                    )}
                </Stack>
            </Grid>
        </Grid>
    )
}

export default Range
