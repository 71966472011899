import { makeStyles } from "@material-ui/core";

export const drawerWidth = 250;

export const sideStyles = makeStyles((theme) => ({
  drawer: {
    zIndex: theme.zIndex.appBar - 1,
    width: drawerWidth - 15,
    flexShrink: 0,
  },
  drawerOpen: {
    border: "none",
    width: drawerWidth,
    backgroundColor: theme.palette.primary.main,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.complex,
    }),
  },
  drawerClose: {
    border: "none",
    backgroundColor: theme.palette.primary.main,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.complex,
    }),
    overflowX: "hidden",
    width: theme.spacing(6) + 5,
  },
  titleOpen: {
    width: "170px",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.complex,
    }),
  },

  activelistItem: {
    backgroundColor: theme.palette.primary.main,
    color: "white",
  },
  activelistIcon: {
    color: "white",
  },
  SideBarTab: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    left: 10,
    position: "relative",
    width: 260,
    overflow: "hidden",
  },
  tabIndicator: {
    backgroundColor: theme.palette.primary.main,
    right: 250,
    position: "absolute",

    width: 5,
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
  },
  sidebarRoot: {
    height: 500,
  },
  test: {
    minHeight: 50,
  }
}));
