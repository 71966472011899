import * as actionTypes from "./ActionTypes";
const initialState = {
  isAuth: false,
  message: "",
  loading: false,
  modal_loading: false,
  refresh: 0,
  search: "",
  pagination: {
    total: 0,
    page: 1,
    limit: 10,
  },
  sort: {
    name: "",
    mode: 1,
  },
  sideValue: "",
  request_counter: 0,
};

const GeneralReducer = (state = initialState, action) => {
  let ret;
  switch (action.type) {
    case actionTypes.IS_AUTH:
      ret = "isAuth";
      break;
    case actionTypes.MESSAGE:
      ret = "message";
      break;
    case actionTypes.LOADING:
      ret = "loading";
      break;
    case actionTypes.MODAL_LOADING:
      ret = "modal_loading";
      break;

    case actionTypes.REFRESH:
      ret = "refresh";
      break;

    case actionTypes.SEARCH_PAGE:
      ret = "pagination";
      break;
    case actionTypes.SORT:
      ret = "sort";
      break;
    case actionTypes.SEARCH:
      ret = "search";
      break;
    case actionTypes.SIDE_VALUE:
      ret = "sideValue";
      break;
    case actionTypes.REQUEST_COUNTER:
      ret = "request_counter";
      break;
    default:
      return state;
  }
  return { ...state, [ret]: action.payload };
};

export default GeneralReducer;
