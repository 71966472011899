import {
    Button,
    Dialog,
    Divider,
    Fade,
    Grid,
    IconButton,
    Typography,
} from '@material-ui/core'
import { Close } from '@material-ui/icons'

import React, { useEffect, useState } from 'react'
import Info from '../../../../asset/img/Info.png'
import EditInfo from '../../../../asset/img/Edit.png'
import { TabPanelContainer } from '../../../../components/TabPanel'
import { styles } from '../../../../styles/styles'
import Overview from './Overview'

import Photos from './Photos'
import { block_user, unBlock_user } from '../../../../api/Users_Api'
import { clear_reports } from '../../../../api/Reports_Api'
import BlockUser from '../../../ManageUsers/UserBlock'
import MoreInfo from './MoreInfo'
import DeleteUser from '../../UserDelete'
import DownloadUserProfile from '../../downloadUserProfile'
import { get_match_make_me_questionnair } from '../../../../api/UserRequest_Api'
import { MatchMakeMeButton } from '../../matchMakeMeButton'
import AttachmentStyleReport from './AttachmentStyleRport'

const VeiwProfile = ({
    setDeleteMutation,
    data,
    isUser,
    set_data,
    zodiacDropdownOptions = [],
    sleepingHabbitsDropdownOptions = [],
    drinkingDropdownOptions = [],
    smokingDropdownOptions = [],
    relocateForLoveDropdownOptions = [],
    personalityDropdownValues = [],
    industryDropdownOptions = [],
    educationDropdownOptions = [],
    salaryDropdownOptions = [],
    lookingForDropdownOptions = [],
    onDeleteUserEmptyRecommendList,
}) => {
    console.log('salaryDropdownOptions', salaryDropdownOptions)
    console.log('data_data', data)
    const classes = styles()

    const [open, setOpen] = React.useState(false)
    const [tab_index, set_tab_index] = React.useState(1)
    const [matchMakeMeQuestions, setMatchMakeMeQuestions] = React.useState([])
    const handleOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }
    React.useEffect(() => {
        if (open) {
            get_match_make_me_questionnair(data?.user?._id)
                ?.then((res) => {
                    console.log('res', res.data?.questions)
                    setMatchMakeMeQuestions(res.data?.questions)
                })
                .catch((err) => {
                    console.log('errr_get_match_make_me_questionnair', err)
                })
        }
    }, [data?.user?._id, open])

    return (
        <>
            <Button
                variant="text"
                onClick={handleOpen}
                style={{ paddingLeft: 0 }}
            >
                <img src={Info} alt="" style={{ marginRight: 8 }} />
                User Profile
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
                onClick={(e) => e.stopPropagation()}
                classes={{
                    paper: classes.viewProfileDialog,
                }}
            >
                <Grid
                    xs={12}
                    container
                    item
                    spacing={0}
                    style={{}}
                    justifyContent="center"
                >
                    <Grid
                        item
                        xs={12}
                        container
                        justifyContent="space-between"
                        alignItems="center"
                        style={{ padding: 16 }}
                    >
                        <Typography variant="h4">Profile View</Typography>

                        <IconButton onClick={handleClose}>
                            <Close />
                        </IconButton>
                    </Grid>
                    {/* ------------------------------------------------------------------------------------ header section */}
                    <Grid
                        item
                        xs={12}
                        container
                        spacing={2}
                        alignItems="center"
                        style={{ margin: 'auto', padding: 16 }}
                    >
                        {menus.map((item) => (
                            <Button
                                key={item.index}
                                // hidden={isUser && item.title === "Reported Messages"}
                                style={{
                                    marginRight: 16,
                                    borderRadius: 12,
                                    height: 44,
                                    width: item.index !== 4 ? 96 : 200,
                                    backgroundColor:
                                        tab_index !== item.index
                                            ? '#F4F5F5'
                                            : '#65819D',
                                    color:
                                        tab_index !== item.index
                                            ? '#000'
                                            : '#fff',
                                }}
                                variant={
                                    tab_index === item.index
                                        ? 'contained'
                                        : 'text'
                                }
                                onClick={() => set_tab_index(item.index)}
                            >
                                {item.title}
                            </Button>
                        ))}
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    {/* ------------------------------------------------------------------------------------ pages section */}
                    <Grid
                        item
                        xs={12}
                        style={{ padding: tab_index !== 4 ? 16 : 0 }}
                    >
                        <TabPanelContainer value={tab_index} index={1}>
                            <Overview data={data} />
                        </TabPanelContainer>
                        <TabPanelContainer value={tab_index} index={2}>
                            <MoreInfo data={data} />
                        </TabPanelContainer>

                        <TabPanelContainer value={tab_index} index={3}>
                            <Photos data={data?.user?.images} />
                        </TabPanelContainer>
                        <TabPanelContainer value={tab_index} index={4}>
                            <AttachmentStyleReport
                                attachment_style_report={
                                    data?.user?.attachment_style_report
                                }
                            />
                        </TabPanelContainer>
                    </Grid>
                    {/* ------------------------------------------------------------------------------------ footer section */}
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        container
                        alignItems="center"
                        justifyContent="space-between"
                        style={{ padding: 16 }}
                    >
                        {!isUser && (
                            <Grid item>
                                <Button
                                    variant="text"
                                    onClick={() => {
                                        clear_reports({ ids: [data._id] }).then(
                                            (res) => {
                                                if (!res) return
                                                set_data((pre) =>
                                                    pre.filter(
                                                        (item) =>
                                                            item._id !==
                                                            data._id
                                                    )
                                                )
                                                setOpen(false)
                                            }
                                        )
                                    }}
                                >
                                    Clear Report
                                </Button>
                            </Grid>
                        )}
                        <Grid item>
                            {data?.user?._id ? (
                                <Button
                                    onClick={() => {
                                        const win = window.open(
                                            `/user-information/${data?.user?._id}`,
                                            '_blank'
                                        )

                                        win.focus()
                                    }}
                                    variant="outlined"
                                    style={{
                                        padding: '10px 15px',
                                        borderRadius: 6,
                                    }}
                                >
                                    <img
                                        width={15}
                                        height={15}
                                        src={EditInfo}
                                        alt=""
                                        style={{ marginRight: 6 }}
                                    />
                                </Button>
                            ) : null}
                        </Grid>
                        {data?.user?.status === 'deleted' ? null : (
                            <Grid item>
                                {data?.user?._id ? (
                                    <DeleteUser
                                        data={data?.user}
                                        userName={`${data?.user?.firstName} ${data?.user?.lastName}`}
                                        set_Data={set_data}
                                        status={data?.user.status === 'deleted'}
                                        setDeleteMutation={setDeleteMutation}
                                        handleCloseOuter={handleClose}
                                        onDeleteUserEmptyRecommendList={
                                            onDeleteUserEmptyRecommendList
                                        }
                                    />
                                ) : null}
                            </Grid>
                        )}

                        <Grid item>
                            {data?.user?._id ? (
                                <DownloadUserProfile
                                    zodiacDropdownOptions={
                                        zodiacDropdownOptions
                                    }
                                    sleepingHabbitsDropdownOptions={
                                        sleepingHabbitsDropdownOptions
                                    }
                                    drinkingDropdownOptions={
                                        drinkingDropdownOptions
                                    }
                                    smokingDropdownOptions={
                                        smokingDropdownOptions
                                    }
                                    relocateForLoveDropdownOptions={
                                        relocateForLoveDropdownOptions
                                    }
                                    personalityDropdownValues={
                                        personalityDropdownValues
                                    }
                                    industryDropdownOptions={
                                        industryDropdownOptions
                                    }
                                    educationDropdownOptions={
                                        educationDropdownOptions
                                    }
                                    salaryDropdownOptions={
                                        salaryDropdownOptions
                                    }
                                    lookingForDropdownOptions={
                                        lookingForDropdownOptions
                                    }
                                    matchMakeMeQuestions={matchMakeMeQuestions}
                                    data={data?.user}
                                    userName={`${data?.user?.firstName} ${data?.user?.lastName}`}
                                    set_Data={set_data}
                                    status={data?.user.status === 'deleted'}
                                    setDeleteMutation={setDeleteMutation}
                                    handleCloseOuter={handleClose}
                                />
                            ) : null}
                        </Grid>
                        <Grid item>
                            {data?.user?._id ? (
                                <Button
                                    onClick={() => {
                                        window.open(
                                            `/recommend-list/${data?.user?._id}`
                                        )
                                    }}
                                    variant="outlined"
                                    style={{
                                        padding: '7px 15px',
                                        borderRadius: 6,
                                        fontSize: '18px',
                                    }}
                                >
                                    Recommend
                                </Button>
                            ) : null}
                        </Grid>
                        <Grid item>
                            <MatchMakeMeButton userId={data?.user?._id} />
                        </Grid>

                        <Grid item>
                            <Button
                                variant="outlined"
                                style={{ borderRadius: 6, padding: '6px 15px' }}
                                color="default"
                                onClick={handleClose}
                            >
                                Cancel
                            </Button>
                            {/* <BlockUser
                                data={data}
                                userName={`${data.firstName} ${data.lastName}`}
                                set_Data={set_data}
                                display_Type={true}
                            /> */}
                            {/* {!isUser ? (
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: "#E14E4E",
                    color: "white",
                    marginLeft: 16,
                    borderRadius: 6,
                  }}
                  onClick={() => {
                    !data.user.isBlocked
                      ? block_user({
                        ids: [data._id],
                      }).then((res) => {
                        if (!res) return;
                        setOpen(false);
                        set_data((pre) =>
                          pre.map((item) => {
                            if (item._id === data._id) {
                              return {
                                ...item,

                                status: "blocked",
                              };
                            } else {
                              return item;
                            }
                          })
                        );
                      })
                      : unBlock_user({ ids: [data._id] }).then((res) => {
                        if (!res) return;
                        setOpen(false);
                        set_data((pre) =>
                          pre.map((item) => {
                            if (item._id === data._id) {
                              return {
                                ...item,

                                status: "active",
                              };
                            } else {
                              return item;
                            }
                          })
                        );
                      });
                  }}
                >
                  {!data.user.isBlocked ? "Block" : "Unblocked"}
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="secondary"
                  style={{
                    color: "white",
                    marginLeft: 16,
                    borderRadius: 6,
                  }}
                  onClick={() => {
                    data.status !== "blocked" &&
                      block_user({
                        ids: [data._id],
                      }).then((res) => {
                        if (!res) return;
                        setOpen(false);
                        set_data((pre) =>
                          pre.map((item) => {
                            if (item._id === data._id) {
                              return { ...item, status: "blocked" };
                            } else {
                              return item;
                            }
                          })
                        );
                      });
                    data.status === "blocked" &&
                      unBlock_user({ ids: [data._id] }).then((res) => {
                        if (!res) return;
                        setOpen(false);
                        set_data((pre) =>
                          pre.map((item) => {
                            if (item._id === data._id) {
                              return { ...item, status: "active" };
                            } else {
                              return item;
                            }
                          })
                        );
                      });
                  }}
                >
                  {data.status !== "blocked" && "Block"}
                  {data.status === "blocked" && "Unblocked"}
                </Button>
              )} */}
                        </Grid>
                    </Grid>
                </Grid>
            </Dialog>
        </>
    )
}

export default VeiwProfile
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Fade timeout={350} ref={ref} {...props} />
})

const menus = [
    { title: 'Overview', index: 1 },
    { title: 'More Info', index: 2 },
    { title: 'Photos', index: 3 },
    { title: 'Attachment Style Report', index: 4 },
    // { title: "Reported Messages", index: 4 },
]
