import * as actionTypes from "./ActionTypes";
import Store from "./Store";

const template = (data, type) => {
  return {
    type: actionTypes[type],
    payload: data,
  };
};

export const set_Login = (mode) => {
  return template(mode, "IS_AUTH");
};
export const set_loading = (mode) => {
  return template(mode, "LOADING");
};
export const set_modal_loading = (mode) => {
  return template(mode, "MODAL_LOADING");
};

export const set_message = (data) => {
  return template(data, "MESSAGE");
};
export const set_refresh = (data) => {
  return template(data, "REFRESH");
};

// -----------------------------------------------------------------------search actions
export const page_change = (data) => {
  return template(data, "SEARCH_PAGE");
};
export const search_change = (data) => {
  return template(data, "SEARCH");
};
export const set_sort = (data) => {
  return template(data, "SORT");
};
export const set_side_value = (data) => {
  return template(data, "SIDE_VALUE");
};
export const set_req_counter = (data) => {
  return template(data, "REQUEST_COUNTER");
};

export const clear_search = () => {
  Store.dispatch(
    page_change({
      total: 0,
      page: 1,
      limit: 10,
    })
  );
  Store.dispatch(search_change(""));
  Store.dispatch(
    set_sort({
      name: "",
      mode: 1,
    })
  );
};
export const onDragEndPrefences = (result, state, onChange, setOrders) => {
  // dropped outside the list
  if (!result.destination) {
    return;
  }

  const items = reorderPreferences(
    state,
    result.source.index,
    result.destination.index,
    setOrders
  );
  onChange(items);
};
export const reorderPreferences = (list, startIndex, endIndex, setOrders) => {
  let d = [];
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  result.forEach((item) => {
    d.push({ ...item, order: item.order });
  });

  setOrders(d);
  return result;
};
export const onDragUpdatePreferences = (
  list,
  startIndex,
  endIndex,
  setOrders
) => {
  let d = [];
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  result.forEach((item, i) => {
    d.push({ ...item, order: i });
  });
  setOrders(d);
};
