import { Grid, Typography } from '@material-ui/core'

import { useState, useEffect } from 'react'
import { styles } from '../../../../../styles/styles'

import { useParams } from 'react-router-dom'
import FreeType from './FreeType'
import Optional from './Optional'
import { get_quiz_questionnair } from '../../../../../../src/api/Quiz_Module_Api'

const QuizQuestionnaire = () => {
    const params = useParams()
    const [userName, setUserName] = useState('')
    const [quizTitle, setQuizTitle] = useState('')
    const [generatedResponseText, setGeneratedResponseText] = useState('')
    const [quizQuestionnaireData, setQuizQuestionnaireData] = useState([])
    const style = styles()

    useEffect(() => {
        const userId = params?.userId
        const quizId = params?.id
        get_quiz_questionnair(userId, quizId)
            .then((res) => {
                console.log('hello', res)
                if (Array.isArray(res?.data?.result)) {
                    setQuizQuestionnaireData(
                        res?.data?.result[0].questionAndAnswerArr ?? []
                    )
                    setGeneratedResponseText(res?.data?.generated_response_text)
                }
                setUserName(res?.data?.userFullName)
                setQuizTitle(res?.data?.quizTitle)
            })
            .catch((e) => console.log('-----------------', e.message))
    }, [])

    return (
        <>
            <Grid
                container
                alignItems="center"
                item
                xs={12}
                style={{ paddingTop: '10px' }}
            >
                <Grid item xs={4}>
                    <Typography variant="h5" component="h5">
                        {userName}
                    </Typography>
                    <Typography variant="body1" style={{ fontSize: '20px' }}>
                        Quiz - {quizTitle}
                    </Typography>
                </Grid>
            </Grid>
            <Grid className={style.box} style={{ padding: '20px 15px' }}>
                <Grid container spacing={3}>
                    {quizQuestionnaireData.length === 0
                        ? 'No Data Found'
                        : null}
                    {quizQuestionnaireData.map((item, index) => {
                        return (
                            <>
                                {item.question_type === 'short_answer' ? (
                                    <FreeType
                                        key={item?._id}
                                        index={index}
                                        question={item?.question}
                                        amswer={item?.answer}
                                    />
                                ) : (
                                    <Optional
                                        index={index}
                                        key={item?._id}
                                        question={item?.question}
                                        answer={item?.answer}
                                        options={item.question_option_list}
                                        question_type={item.question_type}
                                    />
                                )}
                            </>
                        )
                    })}
                </Grid>
            </Grid>

            <Grid className={style.box} style={{ padding: '20px 15px' }}>
                <Typography
                    variant="h5"
                    component="span"
                    style={{ lineHeight: '45px' }}
                >
                    Generated Report
                </Typography>
                <Grid spacing={3} style={{ marginTop: '10px' }}>
                    <div
                        style={{
                            border: '2px solid #ccc',
                            borderRadius: '5px',
                            padding: '10px',
                            width: '80%',
                            height: 'auto',
                            overflowY: 'auto',
                        }}
                    >
                        {generatedResponseText
                            .split('\n')
                            .map((line, index) => (
                                <p
                                    key={index}
                                    style={{
                                        fontSize: '16px',
                                        fontWeight: 400,
                                    }}
                                >
                                    {line
                                        .split(/(\*\*.*?\*\*)/g)
                                        .map((part, i) =>
                                            part.startsWith('**') ? (
                                                <strong key={i}>
                                                    {part.replace(/\*\*/g, '')}
                                                </strong>
                                            ) : (
                                                part
                                            )
                                        )}
                                </p>
                            ))}
                    </div>
                </Grid>
            </Grid>
        </>
    )
}

export default QuizQuestionnaire
