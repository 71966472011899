import { useState, useEffect } from 'react'
import {
    Box,
    FormControl,
    FormHelperText,
    FormLabel,
    Grid,
    Typography,
} from '@material-ui/core'
import {
    TextField,
    InputLabel,
    Checkbox,
    FormGroup,
    FormControlLabel,
    Stack,
} from '@mui/material'
import { styles } from '../../../styles/styles'

import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { Controller } from 'react-hook-form'
import { useField } from 'formik'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'

const UserPreferences = ({ form, avaliableOptions }) => {

    const style = styles()
    const numberOfColumn = 4
    const {
        register,
        setValue,
        watch,
        control,
        trigger,
        formState: { errors, isDirty },
        reset,
        getValues,
        handleSubmit,
        setErro,
    } = form

    return (
        <Grid container spacing={3} className={style.box}>
            <Grid item xs={12}>
                <Typography
                    style={{ fontSize: '1.2rem', textDecoration: 'underline' }}
                >
                    Preferences Information
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={numberOfColumn}>
                        <Stack direction="column">
                            <FormLabel htmlFor="preferences.lookingFor">
                                Looking For:
                            </FormLabel>
                            <FormControlLabel
                                style={{
                                    paddingLeft: '12px',
                                }}
                                control={
                                    <Controller
                                        {...register('preferences.lookingFor')}
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                labelId="preferences.lookingFor"
                                                id="demo-simple-select"
                                                fullWidth
                                                margin="normal"
                                                size="small"
                                                {...field}
                                                defaultValue={[]}
                                                multiple={true}
                                            >
                                                {avaliableOptions?.preferences?.lookingFor?.map(
                                                    (option, index) => {
                                                        return (
                                                            <MenuItem
                                                                value={option}
                                                                key={index}
                                                            >
                                                                {option}
                                                            </MenuItem>
                                                        )
                                                    }
                                                )}
                                            </Select>
                                        )}
                                    />
                                }
                            />
                        </Stack>
                    </Grid>
                    <Grid item xs={12} sm={numberOfColumn}>
                        <Stack direction="column">
                            <FormLabel htmlFor="preferences.ethnicity">
                                Ethnicity:
                            </FormLabel>
                            <FormControlLabel
                                style={{
                                    paddingLeft: '12px',
                                }}
                                control={
                                    <Controller
                                        {...register('preferences.ethnicity')}
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                labelId="preferences.ethnicity"
                                                id="demo-simple-select"
                                                fullWidth
                                                margin="normal"
                                                size="small"
                                                {...field}
                                                defaultValue={[]}
                                                multiple={true}
                                            >
                                                {avaliableOptions?.preferences?.ethnicity?.map(
                                                    (option, index) => {
                                                        return (
                                                            <MenuItem
                                                                value={option}
                                                                key={index}
                                                            >
                                                                {option}
                                                            </MenuItem>
                                                        )
                                                    }
                                                )}
                                            </Select>
                                        )}
                                    />
                                }
                            />
                        </Stack>
                    </Grid>
                    <Grid item xs={12} sm={numberOfColumn}>
                        <Stack direction="column">
                            <FormLabel htmlFor="preferences.religion">
                                Religion:
                            </FormLabel>
                            <FormControlLabel
                                style={{
                                    paddingLeft: '12px',
                                }}
                                control={
                                    <Controller
                                        {...register('preferences.religion')}
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                labelId="preferences.religion"
                                                id="demo-simple-select"
                                                fullWidth
                                                margin="normal"
                                                size="small"
                                                {...field}
                                                defaultValue={[]}
                                                multiple={true}
                                            >
                                                {avaliableOptions?.preferences?.religion?.map(
                                                    (option, index) => {
                                                        return (
                                                            <MenuItem
                                                                value={option}
                                                                key={index}
                                                            >
                                                                {option}
                                                            </MenuItem>
                                                        )
                                                    }
                                                )}
                                            </Select>
                                        )}
                                    />
                                }
                            />
                        </Stack>
                    </Grid>
                    <Grid item xs={12} sm={numberOfColumn}>
                        <Stack direction="column">
                            <FormLabel htmlFor="preferences.address.country">
                                Country:
                            </FormLabel>
                            <FormControlLabel
                                style={{
                                    paddingLeft: '12px',
                                }}
                                control={
                                    <Controller
                                        {...register(
                                            'preferences.address.country'
                                        )}
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                labelId="preferences.address.country.map("
                                                id="demo-simple-select"
                                                fullWidth
                                                margin="normal"
                                                size="small"
                                                {...field}
                                                defaultValue={[]}
                                                multiple={true}
                                            >
                                                {avaliableOptions?.preferences?.address?.country.map(
                                                    (option, index) => {
                                                        return (
                                                            <MenuItem
                                                                value={option}
                                                                key={index}
                                                            >
                                                                {option}
                                                            </MenuItem>
                                                        )
                                                    }
                                                )}
                                            </Select>
                                        )}
                                    />
                                }
                            />
                        </Stack>
                    </Grid>
                    <Grid item xs={12} sm={numberOfColumn}>
                        <Stack direction="column">
                            <FormLabel htmlFor="preferences.address.state">
                                State:
                            </FormLabel>
                            <FormControlLabel
                                style={{
                                    paddingLeft: '12px',
                                }}
                                control={
                                    <Controller
                                        {...register(
                                            'preferences.address.state'
                                        )}
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                labelId="preferences.address.state.map("
                                                id="demo-simple-select"
                                                fullWidth
                                                margin="normal"
                                                size="small"
                                                {...field}
                                                defaultValue={[]}
                                                multiple={true}
                                            >
                                                {avaliableOptions?.preferences?.address?.state.map(
                                                    (option, index) => {
                                                        return (
                                                            <MenuItem
                                                                value={option}
                                                                key={index}
                                                            >
                                                                {option}
                                                            </MenuItem>
                                                        )
                                                    }
                                                )}
                                            </Select>
                                        )}
                                    />
                                }
                            />
                        </Stack>
                    </Grid>
                    <Grid item xs={12} sm={numberOfColumn}>
                        <Stack direction="column">
                            <FormLabel htmlFor="preferences.address.city">
                                City:
                            </FormLabel>
                            <FormControlLabel
                                style={{
                                    paddingLeft: '12px',
                                }}
                                control={
                                    <Controller
                                        {...register(
                                            'preferences.address.city'
                                        )}
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                labelId="preferences.address.city"
                                                id="demo-simple-select"
                                                fullWidth
                                                margin="normal"
                                                size="small"
                                                {...field}
                                                defaultValue={[]}
                                                multiple={true}
                                            >
                                                {avaliableOptions?.preferences?.address?.city.map(
                                                    (option, index) => {
                                                        return (
                                                            <MenuItem
                                                                value={option}
                                                                key={index}
                                                            >
                                                                {option}
                                                            </MenuItem>
                                                        )
                                                    }
                                                )}
                                            </Select>
                                        )}
                                    />
                                }
                            />
                        </Stack>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default UserPreferences
