import { Avatar, Divider, Grid, Typography, useTheme } from "@material-ui/core";
import { Add, Remove } from "@material-ui/icons";
import React from "react";
import { styles } from "../../styles/styles";

import up from "../../asset/img/up.svg";
import down from "../../asset/img/down.png";

const DataDetails = ({ title, img, value }) => {
  const style = styles();

  return (
    <Grid
      item
      xs={12}
      container
      style={{ height: "100%", padding: 16 }}
      className={style.box}
      direction="column"
      justifyContent="space-between"
    >
      <Grid item container justifyContent="space-between" alignItems="center">
        <Typography variant="body2">{title}</Typography>
        <Avatar style={{ backgroundColor: "#EEEEEE", width: 50, height: 50 }}>
          <img src={img} alt="" />
        </Avatar>
      </Grid>
      <Grid item container alignItems="center">
        <Typography variant="h4" style={{ fontSize: 36 }}>
          {value?.thisDuration?.toLocaleString()}
          <img
            src={value?.thisDuration > value?.lastDuration ? up : down}
            alt=""
          />
        </Typography>
      </Grid>
      {/* <Grid item container> */}
      <Divider />
      {/* </Grid> */}
      <Grid item container alignItems="center" style={{}}>
        {value?.thisDuration > value?.lastDuration ? (
          <Upward value={(value?.thisDuration / value?.lastDuration) * 100} />
        ) : (
          <Downward value={(value?.lastDuration / value?.thisDuration) * 100} />
        )}
        <Typography style={{ fontSize: 14, marginLeft: 16 }} variant="body2">
          From previous period ({(value?.thisDuration - value?.lastDuration) > 0 ? '+' : ''}{value?.thisDuration - value?.lastDuration})
        </Typography>
      </Grid>
    </Grid>
  );
};

export default DataDetails;
export const Downward = ({ value }) => {
  const theme = useTheme();
  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      style={{
        width: "fit-content",
        backgroundColor: theme.palette.secondary.main,
        padding: "4px 2px",
        borderRadius: 4,
      }}
    >
      <Remove style={{ color: "white" }} fontSize="small" />
      <Typography style={{ color: "white", fontSize: 12 }} variant="body2">
        {(isNaN(value) || value == 'Infinity') ? 0 : value.toFixed(2)}%
      </Typography>
    </Grid>
  );
};
export const Upward = ({ value }) => {
  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      style={{
        padding: "4px 2px",
        borderRadius: 4,
        backgroundColor: "#BA5A6B",
        width: "fit-content",
      }}
    >
      <Add style={{ color: "white" }} fontSize="small" />
      <Typography style={{ color: "white", fontSize: 12 }} variant="body2">
        {(isNaN(value) || value == 'Infinity') ? 0 : value.toFixed(2)}%
      </Typography>
    </Grid>
  );
};
