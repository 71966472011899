import { useState, useEffect } from 'react'
import {
    Grid,
    Typography,
    Accordion,
    AccordionDetails,
    AccordionSummary,
} from '@material-ui/core'
import {
    TextField,
    InputLabel,
    Checkbox,
    FormGroup,
    FormControlLabel,
    Stack,
} from '@mui/material'
import { styles } from '../../../styles/styles'
import { GridExpandMoreIcon } from '@mui/x-data-grid'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { Controller } from 'react-hook-form'

const UserSettings = ({ form, userData, watch }) => {
    const style = styles()
    // console.log('getttt', watch('pausedProfile'))
    const { register, control } = form
    return (
        <Grid spacing={2} className={style.box}>
            <Accordion style={{ boxShadow: 'none' }} defaultExpanded>
                <AccordionSummary
                    expandIcon={<GridExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-settings-info"
                >
                    <Typography
                        style={{
                            fontSize: '1.2rem',
                            textDecoration: 'underline',
                        }}
                    >
                        Settings
                    </Typography>
                </AccordionSummary>

                <AccordionDetails>
                    <Grid container spacing={2}>
                        <Grid item xs={3}>
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Controller
                                            {...register('isHideProfile')}
                                            control={control}
                                            render={({ field }) => (
                                                <Checkbox
                                                    disabled={
                                                        watch(
                                                            'isStealthMode'
                                                        ) ||
                                                        watch(
                                                            'pausedProfile.status'
                                                        )
                                                    }
                                                    {...field}
                                                    checked={
                                                        field?.value || false
                                                    }
                                                    onChange={(e) =>
                                                        field.onChange(
                                                            e.target.checked
                                                        )
                                                    }
                                                />
                                            )}
                                        />
                                    }
                                    label="Hide Profile"
                                />
                            </FormGroup>
                        </Grid>
                        <Grid item xs={3}>
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Controller
                                            {...register('isStealthMode')}
                                            control={control}
                                            render={({ field }) => (
                                                <Checkbox
                                                    disabled={
                                                        watch(
                                                            'isHideProfile'
                                                        ) ||
                                                        watch(
                                                            'pausedProfile.status'
                                                        )
                                                    }
                                                    {...field}
                                                    checked={
                                                        field?.value || false
                                                    }
                                                    onChange={(e) =>
                                                        field.onChange(
                                                            e.target.checked
                                                        )
                                                    }
                                                />
                                            )}
                                        />
                                    }
                                    label="Stealth Mode"
                                />
                            </FormGroup>
                        </Grid>
                        <Grid item xs={3}>
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Controller
                                            {...register(
                                                'pausedProfile.status'
                                            )}
                                            control={control}
                                            render={({ field }) => (
                                                <Checkbox
                                                    disabled={
                                                        watch(
                                                            'isStealthMode'
                                                        ) ||
                                                        watch('isHideProfile')
                                                    }
                                                    {...field}
                                                    checked={
                                                        field?.value || false
                                                    }
                                                    onChange={(e) =>
                                                        field.onChange(
                                                            e.target.checked
                                                        )
                                                    }
                                                />
                                            )}
                                        />
                                    }
                                    label="Paused Profile Status"
                                />
                            </FormGroup>
                        </Grid>

                        {watch('pausedProfile.status') ? (
                            <Grid item xs={3}>
                                <Stack direction="column">
                                    {/* <FormLabel htmlFor="dateOfBirth">
                                            Date Of Birth:
                                        </FormLabel> */}
                                    <LocalizationProvider
                                        dateAdapter={AdapterDateFns}
                                    >
                                        <FormControlLabel
                                            style={{ paddingLeft: '12px' }}
                                            control={
                                                <Controller
                                                    {...register(
                                                        'pausedProfile.paused_profile_end_time'
                                                    )}
                                                    control={control}
                                                    render={({ field }) => (
                                                        <DatePicker
                                                            minDate={new Date()}
                                                            // maxDate={
                                                            //     new Date(
                                                            //         new Date().setMonth(
                                                            //             new Date().getMonth() +
                                                            //                 1
                                                            //         )
                                                            //     )
                                                            // }
                                                            {...field}
                                                            slotProps={{
                                                                textField: {
                                                                    fullWidth: true,
                                                                    size: 'small',
                                                                    marginTop: 0,
                                                                },
                                                            }}
                                                            renderInput={(
                                                                params
                                                            ) => (
                                                                <TextField
                                                                    {...params}
                                                                />
                                                            )}
                                                            value={
                                                                (field?.value
                                                                    ? new Date(
                                                                          field?.value
                                                                      )
                                                                    : null) ||
                                                                null
                                                            }
                                                            onChange={(e) => {
                                                                field.onChange(
                                                                    new Date(e)
                                                                )
                                                            }}
                                                        />
                                                    )}
                                                />
                                            }
                                        />
                                    </LocalizationProvider>
                                </Stack>
                            </Grid>
                        ) : null}
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </Grid>
    )
}

export default UserSettings
