import {
  Avatar,
  Button,
  Dialog,
  Fade,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import React from "react";
import { styles } from "../../styles/styles";
import flag from "../../asset/img/Document.svg";
import moment from "moment";
const ReportReasons = ({ data }) => {
  const [open, setOpen] = React.useState(false);
  const style = styles();
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Button
        variant="text"
        onClick={handleOpen}
        style={{
          color: "black",
          paddingLeft: 0,
        }}
      >
        <img src={flag} alt="" style={{ marginRight: 8 }} />
        Report and reason
      </Button>
      <Dialog
        maxWidth="md"
        fullWidth={true}
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        classes={{
          paper: style.viewProfileDialog
        }}
      >
        <Grid
          xs={12}
          container
          item
          spacing={5}
          style={{ margin: "0 auto" }}
          justifyContent="center"
        >
          <Grid
            item
            xs={12}
            container
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography
              variant="h4"
            // style={{ fontFamily: "manrope", fontWeight: 700 }}
            >
              Report & Reason
            </Typography>
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          </Grid>
          <Grid container item style={{ marginTop: -24 }}>
            <Typography
              style={{
                fontSize: 14,
                opacity: 1,
                color: "#000",
                fontFamily: "manrope",
                fontWeight: 500,
              }}
              variant="subtitle1"
            >
              {data?.reports?.length} Users Reported
            </Typography>
          </Grid>
          {/* -------------------------------------------------------------------reports section */}
          <Grid item container style={{ maxHeight: 500, overflow: "auto" }}>
            {
              data?.reports.length ? data?.reports?.map((_el, i) => {
                return (
                  <Grid
                    key={i}
                    container
                    item
                    className={style.box}
                    style={{ backgroundColor: "#F8F8F8", marginBottom: 24 }}
                  >
                    {/* -------------------------------------------------------------------user info section */}

                    <Grid
                      item
                      xs={4}
                      alignItems="center"
                      justifyContent="flex-start"
                      container
                    // style={{ backgroundColor: "blue" }}
                    >
                      <Avatar
                        src={`${_el?.reporter.images[0].croped ?? ''}`}
                        style={{ marginRight: 16 }}
                      />
                      <Grid item>
                        <Typography
                          style={{
                            opacity: 1,
                            fontFamily: "inter",
                            fontWeight: 500,
                            fontSize: 16,
                            marginBottom: 8,
                          }}
                          variant="body1"
                        >
                          {_el?.reporter.firstName}
                        </Typography>
                        <Typography style={{ fontSize: 12 }} variant="body1">
                          {_el?.reporter.lastName}
                        </Typography>
                      </Grid>
                    </Grid>
                    {/* -------------------------------------------------------------------report info section */}
                    <Grid
                      item
                      container
                      xs={8}
                      style={{
                        borderLeft: "1px solid #CBCBCB",
                        paddingLeft: 24,
                        position: "relative",
                        top: 8,
                      }}
                      alignItems="center"
                    >
                      <Grid
                        container
                        justifyContent="space-between"
                        alignItems="center"
                        style={{}}
                      >
                        <Typography
                          style={{ opacity: 1, fontSize: 16 }}
                          variant="body1"
                        >
                          Reason
                        </Typography>
                        <Typography
                          style={{ color: "rgba(5, 6, 15, 0.6)", fontSize: 15 }}
                          variant="body1"
                        >
                          {moment(_el?.createdAt).format("DD MMMM")} ,{" "}
                          {new Date(_el?.createdAt)
                            .toLocaleTimeString()
                            .replace(/(.*)\D\d+/, "$1")}
                        </Typography>
                      </Grid>
                      <Grid
                        container
                        justifyContent="flex-start"
                        alignItems="center"
                      >
                        <Typography
                          fontSize={14}
                          fontWeight={400}
                          sx={{ color: '#0E0E10 !important' }}
                        >
                          {_el.reason}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                );
              })
                :
                null
            }
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
};

export default ReportReasons;
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Fade timeout={350} ref={ref} {...props} />;
});
