import React from "react";

export const PackageIcon = () => {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.875 4.2793C3.97989 4.2793 3.12145 4.63488 2.48851 5.26781C1.85558 5.90075 1.5 6.75919 1.5 7.6543V8.7793H22.5V7.6543C22.5 6.75919 22.1444 5.90075 21.5115 5.26781C20.8785 4.63488 20.0201 4.2793 19.125 4.2793H4.875ZM1.5 17.4043V10.2793H22.5V17.4043C22.5 18.2994 22.1444 19.1578 21.5115 19.7908C20.8785 20.4237 20.0201 20.7793 19.125 20.7793H4.875C3.97989 20.7793 3.12145 20.4237 2.48851 19.7908C1.85558 19.1578 1.5 18.2994 1.5 17.4043ZM15.75 14.7793C15.5511 14.7793 15.3603 14.8583 15.2197 14.999C15.079 15.1396 15 15.3304 15 15.5293C15 15.7282 15.079 15.919 15.2197 16.0596C15.3603 16.2003 15.5511 16.2793 15.75 16.2793H18C18.1989 16.2793 18.3897 16.2003 18.5303 16.0596C18.671 15.919 18.75 15.7282 18.75 15.5293C18.75 15.3304 18.671 15.1396 18.5303 14.999C18.3897 14.8583 18.1989 14.7793 18 14.7793H15.75Z" fill="#0E0F1A" />
    </svg>

  );
};
