import { Button, Dialog, Fade, Grid, Typography } from '@material-ui/core'
import Trash from '../../asset/img/DeleteBlack.png'
import React from 'react'

import { Form, Formik } from 'formik'
import { delete_user } from '../../api/Users_Api'
import { CircularProgress } from '@mui/material'

const DeleteUser = ({
    userName,
    data,
    handleCloseOuter,
    set_Data,
    setDeleteMutation,
    status,
    onDeleteUserEmptyRecommendList = () => {},
}) => {
    const [open, setOpen] = React.useState(false)
    const [loading, setLoading] = React.useState(false)
    const handleOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }
    const buttonStyle = { padding: '10px 15px', borderRadius: 6 }

    return (
        <>
            <Button
                onClick={handleOpen}
                disabled={status}
                variant="outlined"
                style={buttonStyle}
            >
                <img
                    src={Trash}
                    alt="delete user"
                    width={15}
                    height={15}
                    style={{ marginRight: 3 }}
                />
            </Button>
            <Formik
                initialValues={{ reason: '' }}
                onSubmit={(vlaue, props) => {
                    setLoading(true)
                    delete_user(data._id)
                        .then((res) => {
                            if (!res) return
                            props.resetForm()
                            // set_Data((pre) =>
                            //     pre.map((item) => {
                            //         if (item._id === data._id) {
                            //             return { ...item, status: 'deleted' }
                            //         } else {
                            //             return item
                            //         }
                            //     })
                            // )
                            if (onDeleteUserEmptyRecommendList) {
                                onDeleteUserEmptyRecommendList()
                            }
                            setLoading(false)
                            handleClose()
                            handleCloseOuter()
                            setDeleteMutation({})
                        })
                        .catch((err) => {})
                }}
                validateOnMount
            >
                {(formik) => {
                    return (
                        <Form>
                            <Dialog
                                maxWidth="sm"
                                fullWidth={true}
                                open={open}
                                onClose={handleClose}
                                TransitionComponent={Transition}
                                onClick={(e) => e.stopPropagation()}
                            >
                                <Grid container style={{ padding: 32 }}>
                                    <Grid
                                        item
                                        xs={12}
                                        style={{ marginBottom: 24 }}
                                    >
                                        <Typography variant="body2">
                                            Are you sure you want to delete{' '}
                                            {userName ? userName?.trim() : null}
                                            ?
                                        </Typography>
                                    </Grid>

                                    <Grid
                                        style={{ marginTop: 24 }}
                                        container
                                        justifyContent="flex-end"
                                    >
                                        <Button
                                            variant="outlined"
                                            onClick={() => {
                                                handleClose()
                                                formik.resetForm()
                                            }}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            // disabled={formik.values.reason === ""}
                                            onClick={() => formik.submitForm()}
                                            type="submit"
                                            style={{
                                                color: 'white',
                                                marginLeft: 16,
                                            }}
                                        >
                                            {loading ? (
                                                <CircularProgress
                                                    style={{ color: '#ffffff' }}
                                                    size={20}
                                                />
                                            ) : (
                                                'Delete'
                                            )}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Dialog>
                        </Form>
                    )
                }}
            </Formik>
        </>
    )
}

export default DeleteUser
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Fade timeout={350} ref={ref} {...props} />
})
