import { createStore, combineReducers } from "redux";

import GeneralReducer from "./GeneralReducer";

const RootReducer = combineReducers({
  GeneralReducer,
});

const Store = createStore(RootReducer);

export default Store;
